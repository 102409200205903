import React from 'react'
import { GetAuditTrailsCancelToken, GetSmartSignageAuditTrialDropdownCancelToken } from '../../../services/SmartSignageService';
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function AuditTrails() {

  const { id } = useParams()

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  const handleRedirect = (id, type, otherData) => {
    return `/campusAdministration/smartsignage/${id}`
  }

  return (
    <AuditTrailsTable
      apiFunction={GetAuditTrailsCancelToken}
      columnsToShow={columnsToShow}
      apiParams={id ? {parent_action_id: id} : {}}
      filterArrApi={GetSmartSignageAuditTrialDropdownCancelToken}
      actionId={id}
      exportFileName={"SmartSignage"}
      resourceNameRedirect={handleRedirect}
    />
  )
}

export default AuditTrails