import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderPMCStatus } from "../../../utils/CommonGroupingItem";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import {
    getStaticPagesBrandList,
    getStaticPagesHeaderList,
    getStaticPagesPopupList
} from "../../../services/StaticPgesServer";
import { getCampusPagesStatusList } from "../../../services/CampusPagesServer";
import { getCategoryLandingCategoryDropdown } from "../../../services/CategoryLandingPageServer";
import { AddCourseLandingPages, GetCourseLandingUpdateList, GetCoursePageAssignedProgrammeList, updateCourseLandingPages } from "../../../services/CourseLandingPageServer";
import CourseAssignedProgrammes from "./assignedProgrammes/CourseAssignedProgrammes";
import axios, { Axios } from "axios";
import { getBlockVariantTypeDropDown, getBrandTemplateStatus, getHeaderDropDownListStatic, getPopUpListStatic } from "../../../services/BrandTemplateServices";
import SectionColorOverrideComponent from "../../website/SectionColorOverrideComponent";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField } from "../../common/FormFields";
import { getAssigendPagesAndProgrammeShortcourse } from "../../../services/PopupsPagesServer";

const CourseLandingPagesOpt = ({ id }) => {
    const history = useHistory();
    const { type } = useParams();
    console.log('type:', type)
    const [updateData, setUpdateData] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [linkedCampusData, setLinkedCampusData] = useState([]);
    const [statusArr, setStatusArr] = useState([]);
    const [popup, setPopup] = useState([]);
    const [header, setHeader] = useState([]);
    const [assignedRec, setAssignedRec] = useState([]);
    const [loading, setLoading] = useState(false);
    const [assignloading, setAssignLoading] = useState(false);
    const [refresh, setRefresh] = useState(Date.now())
    const [saveToggle,setSaveToggle] = useState(false);
    const isFirstRender = useRef(true);
    
    
    const [headerArr, setHeaderArr] = useState([]);
    const [popUpArr, setPopUpArr] = useState([]);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
    const [prevAssignedRec, setPrevAssignedRec] = useState(assignedRec);
    
    const givenPermissions = useSelector((state) => state.givenPermission);
    
    let brandID = updateData?.brandTemplete.map((data) => data?.brandID);
    
    
    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);
    
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (JSON.stringify(prevAssignedRec) !== JSON.stringify(assignedRec)) {
            setSaveToggle(true);
            setPrevAssignedRec(assignedRec);
        }
    }, [assignedRec]);
    
    useEffect(() => {
        formik.setFieldValue('programme',assignedRec.map((item) => item.id) || [])
    },[assignedRec])
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            getCampusPagesStatusList()
            .then(res => setStatusArr(res.data.publishedStatus))
            .catch(error => console.error("Error fetching status data", error))
            .finally(() => setLoading(false))
            
            getCategoryLandingCategoryDropdown()
            .then(res => setLinkedCampusData(res.data.Data))
            .catch(error => console.error("Error fetching Linked Category data", error))
            .finally(() => setLoading(false))
            
            getStaticPagesPopupList()
            .then(res => setPopup(res.data.Data))
            .catch(error => console.error("Error fetching Popup data", error))
            .finally(() => setLoading(false))
            
            getStaticPagesHeaderList()
            .then(res => setHeader(res?.data?.Data))
            .catch(error => console.error("Error fetching Header data", error))
            .finally(() => setLoading(false))
            
            getStaticPagesBrandList()
            .then(res => setBrandList(res.data.brandTypesFilters))
            .catch(error => console.error("Error fetching Assigned Brand Templates data", error))
            .finally(() => setLoading(false))
        };
        
        fetchData();
    }, [refresh]);
    
    useEffect(() => {
        const getProgramsList = async () => {
            setAssignLoading(true);
            
            // const values = {
            //     TrainingCourseLandingPageID: type === 'open' && Number(id),
            //     isAssigned: "1",
            //     exportStatus: "true"
            // }
            
            try {
                let res = await getAssigendPagesAndProgrammeShortcourse(id, "2");
                
                if (res.status === 200) {
                    setAssignedRec(res?.data ? res?.data : []);
                    setAssignLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAssignLoading(false)
                }
            }
        }
        
        { type === "open" && getProgramsList() }
    }, [id, refresh])
    
    useEffect(() => {
        if(type == "open"){
            
            setLoading(true);
            GetCourseLandingUpdateList(id)
            .then(res => {
                if(res.status ==200){
                    setUpdateData(res?.data?.Data);
                }
            })
            .catch(err => {
                console.log("Error fetching Get For Update data", err);
                if (type == 'open' && err?.response?.data?.message === "No record found with such ID") {
                    Swal.fire({
                        // icon: "info", 
                        title: "Info",
                        text: "No record found with this id",
                    });
                    history.push(`/website_management/pages/courselandingpages/table`);
                }
            })
            .finally(() => setLoading(false));
        }
        
    }, [id,refresh]);
    
    
    const formik = useFormik({
        enableReinitialize: type == "open" ? true : false,
        initialValues: {
            TrainingCourseLandingPageID: updateData?.TrainingCourseLandingPageID || "",
            PageTitle: updateData?.PageTitle || "",
            status: updateData ? updateData?.status : "",
            category: updateData?.category || "",
            ShowTitleInBody: updateData?.ShowTitleInBody || 0,
            UseBottomForm: updateData?.UseBottomForm || 0,
            BodyContent: updateData?.BodyContent || "",
            SliderID: updateData?.SliderID || "",
            PopupID: updateData?.PopupID || "",
            formEmail: updateData?.formEmail || "",
            conversionCode: updateData ? updateData?.conversionCode : "",
            metaTitle: updateData?.MetaTitle || "",
            metaDescription: updateData?.MetaDescription || "",
            metaKeywords: updateData?.MetaKeywords || "",
            checkCustomCss: updateData?.checkCustomCss || 0,
            customCss: updateData?.customCss || "",
            Brand: brandID || [],
            programme: assignedRec.map((item) => item.id) || [],
            
            
            school_category_show_block: updateData?.school_category_show_block || 0,
            school_category_search_bar: updateData?.school_category_search_bar || 0,
            school_category_text: updateData?.school_category_text || "",
            secondary_category_show_block: updateData?.secondary_category_show_block || 0,
            secondary_category_text: updateData?.secondary_category_text || "",
            upcoming_opendays_show_block: updateData?.upcoming_opendays_show_block || 0,
            upcoming_opendays_text: updateData?.upcoming_opendays_text || "",
            learning_method_show_block: updateData?.learning_method_show_block || 0,
            learning_method_block_variant: updateData?.learning_method_block_variant || "",
            learning_method_block_text: updateData?.learning_method_block_text || "",
            testimonials_block_show: updateData?.testimonials_block_show || 0,
            testimonials_block_variant: updateData?.testimonials_block_variant || "",
            testimonials_block_text: updateData?.testimonials_block_text || "",
            news_block_show: updateData?.news_block_show || 0,
            news_block_text: updateData?.news_block_text || "",
            
            school_override_color: updateData?.school_override_color || 0,
            school_accentColor: updateData?.school_accentColor || "#007BFF",
            school_textColorOne: updateData?.school_textColorOne || "#007BFF",
            school_textColorTwo: updateData?.school_textColorTwo || "#007BFF",
            school_backgroundColor: updateData?.school_backgroundColor || "#007BFF",
            openday_override_color: updateData?.openday_override_color || 0,
            openday_accentColor: updateData?.openday_accentColor || "#007BFF",
            openday_textColorOne: updateData?.openday_textColorOne || "#007BFF",
            openday_textColorTwo: updateData?.openday_textColorTwo || "#007BFF",
            openday_backgroundColor: updateData?.openday_backgroundColor || "#007BFF",
            learning_method_override_color: updateData?.learning_method_override_color || 0,
            learning_method_accentColor: updateData?.learning_method_accentColor || "#007BFF",
            learning_method_textColorOne: updateData?.learning_method_textColorOne || "#007BFF",
            learning_method_textColorTwo: updateData?.learning_method_textColorTwo || "#007BFF",
            learning_method_backgroundColor: updateData?.learning_method_backgroundColor || "#007BFF",
            testimonials_override_color: updateData?.testimonials_override_color || 0,
            testimonials_accentColor: updateData?.testimonials_accentColor || "#007BFF",
            testimonials_textColorOne: updateData?.testimonials_textColorOne || "#007BFF",
            testimonials_textColorTwo: updateData?.testimonials_textColorTwo || "#007BFF",
            testimonials_backgroundColor: updateData?.testimonials_backgroundColor || "#007BFF",
            news_override_color: updateData?.news_override_color || 0,
            news_accentColor: updateData?.news_accentColor || "#007BFF",
            news_textColorOne: updateData?.news_textColorOne || "#007BFF",
            news_textColorTwo: updateData?.news_textColorTwo || "#007BFF",
            news_backgroundColor: updateData?.news_backgroundColor || "#007BFF",
            toggle_brand_template: updateData?.toggle_brand_template || 0,
            groupByCourseLandingPages: updateData?.groupByCourseLandingPages || 0,
            dashboard_image: updateData?.dashboard_image || "",
            category_text: updateData?.category_text || '',
            category_show_title: updateData?.category_show_title || 0
        },
        validationSchema: Yup.object({
            PageTitle: Yup.string().required("Page Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            // BodyContent: Yup.string().required("Page Content is required"),
            status : Yup.number().required("Status is required").nullable(),
            customCss: Yup.string().when('checkCustomCss', {
                is: (value) => value == 1,
                then: Yup.string().required("Custom CSS is required"),
                otherwise: Yup.string().nullable()
            }),
            // dashboard_image : Yup.mixed().required('Dashboard image is required'),
            
            // school_category_text: Yup.string().required('Schools Category Section Title is required'),
            // secondary_category_text: Yup.string().required('Secondary Category Section Title is required'),
            // upcoming_opendays_text: Yup.string().required('Upcoming Open Days Section Title is required'),
            // learning_method_block_text: Yup.string().required('Learning Methods Section Title is required'),
            // testimonials_block_text: Yup.string().required('Testimonials Section Title is required'),
            // news_block_text: Yup.string().required('News Section Title is required'),
        }),
        onSubmit: async (values, actions) => {
            setLoading(true)
            
            const formData = new FormData();
            
            // Trim and clean values, replace undefined or null with empty string
            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );
            
            // Append values to FormData
            Object.keys(cleanedValues).forEach((key) => {
                const value = cleanedValues[key];
                
                if (Array.isArray(value)) {
                    if (key === "assignedPagesAndPMC") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    }
                } else {
                    formData.append(key, value !== undefined && value !== null ? value : "");
                }
            });
            
            if (type === "open" || type == 're-arrange') {
                updateCourseLandingPages(formData)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated Successfully",
                    });
                    actions.resetForm();
                    setRefresh(Date.now())
                })
                .catch(err => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.response?.data?.message || "An error occurred",
                    });
                })
                .finally(() => setLoading(false))
            } else {
                try {
                    await AddCourseLandingPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    setLoading(false)
                    history.push("/website_management/pages/courselandingpages/table");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                    setLoading(false)
                }
            }
        }
    });
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statusRes, headerRes, BlockVariantTypeRes, PopUpRes] = await Promise.all([
                    getBrandTemplateStatus(),
                    getHeaderDropDownListStatic(),
                    getBlockVariantTypeDropDown(),
                    getPopUpListStatic()
                ]);
                const statusFilters = statusRes.data.statusFilters || [];
                const popUpFilters = PopUpRes.data.Data || [];
                const headerFilters = headerRes.data.Data || [];
                const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
                const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
                setStatusArr(statusFilters);
                setHeaderArr(headerFilters);
                setPopUpArr(popUpFilters);
                setLearnignBlockArr(learnignBlockFilters);
                setTestimonialsBlockArr(testimonialsBlockFilters);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };
        
        fetchData();
    }, [refresh, type]);
    return (
        <div className="card card-profile-info-card">
        <div>
        <div className="new-card-header">
        <div className="card-header pt-0">Page Information {loading ? <i className="fa fa-cog fa-spin"></i> : null}</div> 
        </div>
        <div className="card-body-inr card-body-info">
        <form onSubmit={formik.handleSubmit}>
        <div className="row">
        <div className="col-md-6 col-lg-4">
        <div className="form-group-blk mb-3" title="Page Title">
        <label>Page Title *</label>
        <input
        type="text"
        className={
            "form-control" +
            (formik.errors.PageTitle && formik.touched.PageTitle
                ? " is-invalid"
                : "")
            }
            placeholder="Enter Page Title"
            name="PageTitle"
            id="PageTitle"
            value={formik.values.PageTitle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3" title="Assigned Brand Templates">
            <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
            <Select
            className={
                "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                    ? " is-invalid"
                    : "")
                }
                name="Brand"
                value={brandList.filter((data) => formik.values.Brand.includes(data.value))}
                onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                onBlur={formik.handleBlur}
                options={brandList}
                maxMenuHeight={175}
                isMulti
                placeholder={"Select Assigned Brand Templates"}
                />
                </div>
                </div>
                <div className="col-md-6 col-lg-4">
                <div className="form-group-blk mb-3" title="Status">
                <label>Status *</label>
                <Select
                className={
                    "form-control custom-select-box" +
                    (formik.errors.status && formik.touched.status
                        ? " is-invalid"
                        : "")
                    }
                    name="status"
                    value={statusArr.find(val => val.value === formik.values.status)}
                    onChange={(option) => {
                        formik.setFieldValue("status", option ? option.value : "");
                    }}
                    onBlur={formik.handleBlur}
                    options={statusArr}
                    maxMenuHeight={175}
                    placeholder={
                        formik.values.status || "Select Status"
                    }
                    isClearable
                    />
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title="Status">
                    <label>Linked Category</label>
                    <Select
                    className={
                        "form-control custom-select-box" +
                        (formik.errors.category && formik.touched.category
                            ? " is-invalid"
                            : "")
                        }
                        name="status"
                        value={linkedCampusData.find(val => val.value == formik.values.category)}
                        onChange={(option) => {
                            formik.setFieldValue("category", option ? option.value : "");
                        }}
                        onBlur={formik.handleBlur}
                        options={linkedCampusData}
                        maxMenuHeight={175}
                        placeholder={"Select category"}
                        isClearable
                        />
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-center" title="Show Page Title">
                        <label className="mb-0">
                        Show Page Title <i className="fal fa-info-circle"></i>
                        </label>
                        <div className="toggle-switch ml-2">
                        <label className="switch">
                        <input
                        type="checkbox"
                        checked={formik.values.ShowTitleInBody == 1}
                        onChange={(e) => formik.setFieldValue("ShowTitleInBody", e.target.checked ? 1 : 0)}
                        />
                        <span className="slider slider-round"></span>
                        </label>
                        </div>
                        </div>
                        <FileUploadField
                        label={"Dashboard Image"}
                        name={'dashboard_image'}
                        required={true}
                        accept=".jpg,.png,.jpeg"
                        formik={formik}
                        />
                        <div className="col-md-12">
                        <div className="form-group-blk mb-3" title="Page Content">
                        <label>Page Content</label>
                        <HtmlInputEditor
                        editorState={formik.values?.BodyContent}
                        setEditorState={(editorState) => {
                            formik.setFieldValue('BodyContent', editorState);
                        }}
                        hideSign
                        isCKEditor
                        />
                        </div>
                        </div>
                        <div className="col-md-12">
                        <hr className="border border-2 mt-0" style={{ borderColor: "rgb(230, 235, 241)" }} />
                        </div>
                        <div className="new-card-header col-md-12" title="Page Settings">
                        <h4 className="card-header pt-0">
                        <i
                        className="fal fa-cog"
                        style={{ color: "var(--topbar-color)" }}
                        ></i> Page Settings</h4>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="Header">
                        <label>Header <i className="fal fa-info-circle"></i></label>
                        <Select
                        className={`form-control custom-select-box ${formik.errors.SliderID && formik.touched.SliderID
                            && "is-invalid"}`}
                            name="SliderID"
                            value={header.find(val => val.value === formik.values.SliderID)}
                            onChange={(option) => {
                                formik.setFieldValue("SliderID", option ? option.value : "");
                            }}
                            onBlur={formik.handleBlur}
                            options={header}
                            maxMenuHeight={175}
                            placeholder={"AIE Default"}
                            isClearable
                            />
                            </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                            <div className="form-group-blk mb-3" title="Popup">
                            <label>Popup <i className="fal fa-info-circle"></i></label>
                            <Select
                            className={
                                "form-control custom-select-box" +
                                (formik.errors.PopupID && formik.touched.PopupID
                                    ? " is-invalid"
                                    : "")
                                }
                                name="PopupID"
                                value={popup.find(val => val.value === formik.values.PopupID)}
                                onChange={(option) => {
                                    formik.setFieldValue("PopupID", option ? option.value : "");
                                }}
                                onBlur={formik.handleBlur}
                                options={popup}
                                maxMenuHeight={175}
                                placeholder={"Default"}
                                isClearable
                                />
                                </div>
                                </div>
                                {/* <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3">
                                    <label>Form Email</label>
                                    <input
                                    type="email"
                                    className={
                                    "form-control" +
                                    (formik.errors.formEmail && formik.touched.formEmail
                                    ? " is-invalid"
                                    : "")
                                    }
                                    placeholder="Enter Form Email"
                                    name="formEmail"
                                    id="formEmail"
                                    title="formEmail"
                                    value={formik.values.formEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    />
                                    </div>
                                    </div> */}
                                    <div className="col-md-6 col-lg-3 d-flex align-items-center">
                                    <label className="mb-0">
                                    Show Bottom Form <i className="fal fa-info-circle"></i>
                                    </label>
                                    <div className="toggle-switch ml-2">
                                    <label className="switch">
                                    <input
                                    type="checkbox"
                                    checked={formik.values.UseBottomForm == 1}
                                    onChange={(e) => formik.setFieldValue("UseBottomForm", e.target.checked ? 1 : 0)}
                                    />
                                    <span className="slider slider-round"></span>
                                    </label>
                                    </div>
                                    </div>
                                    <div className="new-card-header col-md-12">
                                    <div className="row mb-4">
                                    <div className="col-md-12">
                                    <div className="edit-icon new-card-header">
                                    <h4 className="card-header">
                                    <i className="fal fa-cog" style={{ color: "var(--topbar-color)" }}></i> Section Settings                                        
                                    </h4>
                                    </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">Qualifications/Short Courses Section</h4>
                                        </div>
                                        </div>
                                        
                                        
                                        <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Section Settings"}>
                                        Section Settings
                                        </label>
                                        <div className="form-icon-group mt-3">
                                        <div className="custom-control custom-checkbox text-left mb-3 mr-3" 
                                        title="This will have no effect if the course landing has both qualifications and short courses assigned.">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="category_show_title"
                                        name="category_show_title"
                                        checked={formik.values.category_show_title == 1}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "category_show_title",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        title="This will have no effect if the course landing has both qualifications and short courses assigned."
                                        className="custom-control-label"
                                        htmlFor="category_show_title"
                                        >
                                        Show Title
                                        </label>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6 col-lg-9">
                                        <label htmlFor="category_text" 
                                        title="This will have no effect if the course landing has both qualifications and short courses assigned."
                                        >
                                            Section Title</label>
                                        <div className="form-icon-group" title="site_Name">
                                        <input
                                        
                                        type="text"
                                        className={`form-control ${formik.touched.category_text && formik.errors.category_text && "is-invalid"
                                        }`}
                                        name="category_text"
                                        id="category_text"
                                        title="This will have no effect if the course landing has both qualifications and short courses assigned."
                                        placeholder="Section Title"
                                        value={formik.values?.category_text}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                        </div>
                                        </div>
                                        </div>
                                        <hr/>
                                        </div>
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">Other Sections </h4>
                                        </div>
                                        </div>
                                    <div
                                    className="d-flex col-md-12" 
                                    title="Overwrite Section Content and settings of page components, this overwrites the settings on the brand template and is only applicable to this page."
                                    >
                                    <label className="mb-0">
                                    Override Brand Template Section Settings <i className="fal fa-info-circle grade-icon"></i>
                                    </label>
                                    <div className="toggle-switch ml-2">
                                    <label class="switch">
                                    <input
                                    type="checkbox"
                                    checked={formik.values.toggle_brand_template == 1 }
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "toggle_brand_template", 
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                    />
                                    <span class="slider slider-round"></span>
                                    </label>
                                    </div>
                                    </div>
                                    
                                    </div>
                                    </div>
                                    {formik.values.toggle_brand_template == 1 ? (<>
                                        <div className="col-12">
                                        <div className="row">
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">Schools Category Section </h4>
                                        </div>
                                        </div>
                                        
                                        
                                        <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Section Settings"}>
                                        Section Settings
                                        </label>
                                        <div className="form-icon-group mt-3">
                                        <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="school_category_show_block"
                                        name="school_category_show_block"
                                        checked={formik.values.school_category_show_block == 1}
                                        onChange={(e) => {
                                            console.log("Updating school_category_show_block:", e.target.checked);
                                            formik.setFieldValue(
                                                "school_category_show_block",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        className="custom-control-label"
                                        htmlFor="school_category_show_block"
                                        >
                                        Show Section
                                        </label>
                                        </div>
                                        <div className="custom-control custom-checkbox text-left" title="Show Course Search Bar">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="school_category_search_bar"
                                        name="school_category_search_bar"
                                        checked={formik.values.school_category_search_bar == 1}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "school_category_search_bar",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        className="custom-control-label"
                                        htmlFor="school_category_search_bar"
                                        >
                                        Show Course Search Bar
                                        </label>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6 col-lg-9">
                                        <label htmlFor="school_category_text" title="Site Name">Section Title</label>
                                        <div className="form-icon-group" title="site_Name">
                                        <input
                                        type="text"
                                        className={`form-control ${formik.touched.school_category_text && formik.errors.school_category_text && "is-invalid"
                                        }`}
                                        name="school_category_text"
                                        id="school_category_text"
                                        title="Section Title"
                                        placeholder="Section Title"
                                        value={formik.values?.school_category_text}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                        </div>
                                        </div>
                                        </div>
                                        <div
                                        className="d-flex my-20"
                                        title="Group Content by Course Landing Pages and not by schools"
                                        >
                                        <label className="mb-0">
                                        Group Content by Course Landing Pages
                                        <i className="fal fa-info-circle grade-icon ml-2"></i>
                                        {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
                                        </label>
                                        <div className="toggle-switch ml-2">
                                        <label className="switch">
                                        <input
                                        type="checkbox"
                                        checked={formik.values.groupByCourseLandingPages == 1}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'groupByCourseLandingPages',
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <span className="slider slider-round"></span>
                                        </label>
                                        </div>
                                        </div>
                                        <SectionColorOverrideComponent 
                                        prefix="school_" 
                                        formik={formik} 
                                        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                        <hr />
                                        </div>
                                        
                                        <div className="col-12">
                                        <div className="row pt-1">
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">Secondary Category Section</h4>
                                        </div>
                                        </div>
                                        
                                        <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Section Settings"}>
                                        Section Settings
                                        </label>
                                        <div className="form-icon-group mt-3">
                                        <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="secondary_category_show_block"
                                        name="secondary_category_show_block"
                                        checked={formik.values.secondary_category_show_block == 1}
                                        onChange={(e) => {
                                            console.log("Updating secondary_category_show_block:", e.target.checked);
                                            formik.setFieldValue(
                                                "secondary_category_show_block",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        className="custom-control-label"
                                        htmlFor="secondary_category_show_block"
                                        >
                                        Show Section
                                        </label>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6 col-lg-9">
                                        <label htmlFor="secondary_category_text" title="Site Name">Section Title</label>
                                        <div className="form-icon-group" title="site_Name">
                                        <input
                                        type="text"
                                        className={`form-control ${formik.touched.secondary_category_text && formik.errors.secondary_category_text && "is-invalid"
                                        }`}
                                        name="secondary_category_text"
                                        id="secondary_category_text"
                                        title="Section Title"
                                        placeholder="Section Title"
                                        value={formik.values?.secondary_category_text}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                        </div>
                                        </div>
                                        </div>
                                        <hr />
                                        </div>
                                        
                                        
                                        <div className="col-12">
                                        <div className="row pt-1">
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">Upcoming Open Days Block</h4>
                                        </div>
                                        </div>
                                        
                                        <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Section Settings"}>
                                        Section Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                        <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="upcoming_opendays_show_block"
                                        name="upcoming_opendays_show_block"
                                        checked={formik.values.upcoming_opendays_show_block == 1}
                                        onChange={(e) => {
                                            console.log("Updating upcoming_opendays_show_block:", e.target.checked);
                                            formik.setFieldValue(
                                                "upcoming_opendays_show_block",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        className="custom-control-label"
                                        htmlFor="upcoming_opendays_show_block"
                                        >
                                        Show Section
                                        </label>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6 col-lg-9">
                                        <label htmlFor="upcoming_opendays_text" title="Site Name">Section Title</label>
                                        <div className="form-icon-group" title="site_Name">
                                        <input
                                        type="text"
                                        className={`form-control ${formik.touched.upcoming_opendays_text && formik.errors.upcoming_opendays_text && "is-invalid"
                                        }`}
                                        name="upcoming_opendays_text"
                                        id="upcoming_opendays_text"
                                        title="Section Title"
                                        placeholder="Section Title"
                                        value={formik.values?.upcoming_opendays_text}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                        </div>
                                        </div>
                                        </div>
                                        <SectionColorOverrideComponent 
                                        prefix="openday_" 
                                        formik={formik} 
                                        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                        <hr />
                                        </div>
                                        
                                        <div className="col-12">
                                        <div className="row pt-1">
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">Learning Methods Block</h4>
                                        </div>
                                        </div>
                                        
                                        <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Section Settings"}>
                                        Section Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                        <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="learning_method_show_block"
                                        name="learning_method_show_block"
                                        checked={formik.values.learning_method_show_block == 1}
                                        onChange={(e) => {
                                            console.log("Updating learning_method_show_block:", e.target.checked);
                                            formik.setFieldValue(
                                                "learning_method_show_block",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        className="custom-control-label"
                                        htmlFor="learning_method_show_block"
                                        >
                                        Show Section
                                        </label>
                                        </div>
                                        </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-lg-4">
                                        <div className="form-group-blk mb-3" title="Section Variant">
                                        <label htmlFor="learning_method_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
                                        </label>
                                        <Select
                                        className={
                                            "form-control custom-select-box " +
                                            (formik.errors.learning_method_block_variant && formik.touched.learning_method_block_variant
                                                ? " is-invalid"
                                                : "")
                                            }
                                            name="learning_method_block_variant"
                                            value={learnignBlockArr.filter(
                                                (item) => item.value == formik.values.learning_method_block_variant
                                            )}
                                            onChange={(value) => {
                                                if (value) {
                                                    formik.setFieldValue("learning_method_block_variant", value.value);
                                                } else {
                                                    formik.setFieldValue("learning_method_block_variant", "");
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                            options={learnignBlockArr}
                                            maxMenuHeight={175}
                                            placeholder={"Select Section Variant"}
                                            />
                                            </div>
                                            </div>
                                            
                                            <div className="col-md-6 col-lg-4">
                                            <label htmlFor="learning_method_block_text" title="Site Name">Section Title</label>
                                            <div className="form-icon-group" title="site_Name">
                                            <input
                                            type="text"
                                            className={`form-control ${formik.touched.learning_method_block_text && formik.errors.learning_method_block_text && "is-invalid"
                                            }`}
                                            name="learning_method_block_text"
                                            id="learning_method_block_text"
                                            title="Section Title"
                                            placeholder="Section Title"
                                            value={formik.values?.learning_method_block_text}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            />
                                            </div>
                                            </div>
                                            </div>
                                            <SectionColorOverrideComponent 
                                            prefix="learning_method_" 
                                            formik={formik} 
                                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                            <hr />
                                            </div>
                                            
                                            <div className="col-12">
                                            <div className="row pt-1">
                                            <div className="col-md-12">
                                            <div className="edit-icon new-card-header">
                                            <h4 className="card-header">Testimonials Block</h4>
                                            </div>
                                            </div>
                                            
                                            <div className={`col-md-6 col-lg-3`}>
                                            <label htmlFor={"Section Settings"}>
                                            Section Settings
                                            </label>
                                            <div className="form-icon-group mb-4 mt-3">
                                            <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                            <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="testimonials_block_show"
                                            name="testimonials_block_show"
                                            checked={formik.values.testimonials_block_show == 1}
                                            onChange={(e) => {
                                                console.log("Updating testimonials_block_show:", e.target.checked);
                                                formik.setFieldValue(
                                                    "testimonials_block_show",
                                                    e.target.checked ? 1 : 0
                                                );
                                            }}
                                            />
                                            <label
                                            className="custom-control-label"
                                            htmlFor="testimonials_block_show"
                                            >
                                            Show Section
                                            </label>
                                            </div>
                                            </div>
                                            </div>
                                            
                                            <div className="col-md-6 col-lg-4">
                                            <div className="form-group-blk mb-3" title="Section Variant">
                                            <label htmlFor="testimonials_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
                                            </label>
                                            <Select
                                            className={
                                                "form-control custom-select-box " +
                                                (formik.errors.testimonials_block_variant && formik.touched.testimonials_block_variant
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                name="Linked PMC Programme"
                                                value={testimonialsBlockArr.filter(
                                                    (item) => item.value == formik.values.testimonials_block_variant
                                                )}
                                                onChange={(value) => {
                                                    if (value) {
                                                        formik.setFieldValue("testimonials_block_variant", value.value);
                                                    } else {
                                                        formik.setFieldValue("testimonials_block_variant", "");
                                                    }
                                                }}
                                                onBlur={formik.handleBlur}
                                                options={testimonialsBlockArr}
                                                maxMenuHeight={175}
                                                placeholder={"Select Section Variant"}
                                                />
                                                </div>
                                                </div>
                                                
                                                <div className="col-md-6 col-lg-4">
                                                <label htmlFor="testimonials_block_text" title="Site Name">Section Title</label>
                                                <div className="form-icon-group" title="site_Name">
                                                <input
                                                type="text"
                                                className={`form-control ${formik.touched.testimonials_block_text && formik.errors.testimonials_block_text && "is-invalid"
                                                }`}
                                                name="testimonials_block_text"
                                                id="testimonials_block_text"
                                                title="Section Title"
                                                placeholder="Section Title"
                                                value={formik.values?.testimonials_block_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                />
                                                </div>
                                                </div>
                                                </div>
                                                <SectionColorOverrideComponent 
                                                prefix="testimonials_" 
                                                formik={formik} 
                                                fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                                <hr />
                                                </div>
                                                
                                                <div className="col-12">
                                                <div className="row pt-1">
                                                <div className="col-md-12">
                                                <div className="edit-icon new-card-header">
                                                <h4 className="card-header">News Block</h4>
                                                </div>
                                                </div>
                                                
                                                <div className={`col-md-6 col-lg-3`}>
                                                <label htmlFor={"Section Settings"}>
                                                Section Settings
                                                </label>
                                                <div className="form-icon-group mb-4 mt-3">
                                                <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                                <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="news_block_show"
                                                name="news_block_show"
                                                checked={formik.values.news_block_show == 1}
                                                onChange={(e) => {
                                                    console.log("Updating news_block_show:", e.target.checked);
                                                    formik.setFieldValue(
                                                        "news_block_show",
                                                        e.target.checked ? 1 : 0
                                                    );
                                                }}
                                                />
                                                <label
                                                className="custom-control-label"
                                                htmlFor="news_block_show"
                                                >
                                                Show Section
                                                </label>
                                                </div>
                                                </div>
                                                </div>
                                                <div className="col-md-6 col-lg-9">
                                                <label htmlFor="news_block_text" title="Site Name">Section Title</label>
                                                <div className="form-icon-group" title="site_Name">
                                                <input
                                                type="text"
                                                className={`form-control ${formik.touched.news_block_text && formik.errors.news_block_text && "is-invalid"
                                                }`}
                                                name="news_block_text"
                                                id="news_block_text"
                                                title="Section Title"
                                                placeholder="Section Title"
                                                value={formik.values?.news_block_text}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                />
                                                </div>
                                                </div>
                                                </div>
                                                <SectionColorOverrideComponent 
                                                prefix="news_" 
                                                formik={formik} 
                                                fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                                </div>
                                                <hr/>
                                                </>): "" }
                                                <div className="col-md-12">
                                                <div className="edit-icon new-card-header mb-4 mt-1">
                                                <h4 className="card-header">
                                                <i
                                                className="fal fa-cog"
                                                style={{ color: "var(--topbar-color)" }}
                                                ></i> SEO Settings
                                                </h4>
                                                </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                <div className="form-group-blk mb-3">
                                                <label htmlFor="code">Conversion Code</label>
                                                <input
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (formik.errors.conversionCode && formik.touched.conversionCode
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    placeholder="Enter Conversion Code"
                                                    name="conversionCode"
                                                    id="conversion"
                                                    title="conversion"
                                                    maxLength={20}
                                                    value={formik.values.conversionCode}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    />
                                                    </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6">
                                                    <div className="form-group-blk mb-3">
                                                    <label>Meta Title</label>
                                                    <input
                                                    type="text"
                                                    className={
                                                        "form-control" +
                                                        (formik.errors.metaTitle && formik.touched.metaTitle
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                        placeholder="Enter Meta Title"
                                                        title="metaTitle"
                                                        name="metaTitle"
                                                        value={formik.values.metaTitle}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        />
                                                        </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-6">
                                                        <div className="form-group-blk mb-3">
                                                        <label>Meta Description</label>
                                                        <input
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (formik.errors.metaDescription && formik.touched.metaDescription
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                            placeholder="Enter Meta Description"
                                                            title="metaDescription"
                                                            name="metaDescription"
                                                            value={formik.values.metaDescription}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            />
                                                            </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6">
                                                            <div className="form-group-blk mb-3">
                                                            <label>Meta Keywords</label>
                                                            <input
                                                            type="text"
                                                            className={
                                                                "form-control" +
                                                                (formik.errors.metaKeywords && formik.touched.metaKeywords
                                                                    ? " is-invalid"
                                                                    : "")
                                                                }
                                                                placeholder="Enter Meta Keywords"
                                                                title="metaKeywords"
                                                                name="metaKeywords"
                                                                value={formik.values.metaKeywords}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                />
                                                                </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                <div className="edit-icon new-card-header mb-4 mt-1">
                                                                <h4 className="card-header">
                                                                <i
                                                                className="fal fa-cog"
                                                                style={{ color: "var(--topbar-color)" }}
                                                                ></i> Style Settings
                                                                </h4>
                                                                </div>
                                                                </div>
                                                                <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
                                                                <label className="mb-0">
                                                                Add Custom CSS <i className="fal fa-info-circle"></i>
                                                                </label>
                                                                <div className="toggle-switch ml-2">
                                                                <label className="switch">
                                                                <input
                                                                type="checkbox"
                                                                checked={formik.values.checkCustomCss == 1}
                                                                onChange={(e) => formik.setFieldValue("checkCustomCss", e.target.checked ? 1 : 0)}
                                                                />
                                                                <span className="slider slider-round"></span>
                                                                </label>
                                                                </div>
                                                                </div>
                                                                
                                                                {formik.values.checkCustomCss == 1 && <div className="col-md-12">
                                                                    <div className=" mb-3 mt-3" title="Customs CSS">
                                                                    <label>Customs CSS*</label>
                                                                    <div className="html-editor-input">
                                                                    <textarea value={formik.values.customCss} className={`textarea bg-white form-control ${formik.touched.customCss && formik.errors.customCss && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="customCss"></textarea>
                                                                    <div className="headerf">
                                                                    <span className="title fw-bolds"> CSS</span>
                                                                    </div>
                                                                    </div>
                                                                    </div>
                                                                    </div>}
                                                                    
                                                                    <div className="col-md-12">
                                                                    <div className="edit-icon new-card-header mb-4 mt-1">
                                                                    <h4 className="card-header">
                                                                    Assigned Programmes
                                                                    </h4>
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-md-12">
                                                                    <CourseAssignedProgrammes
                                                                    id={id}
                                                                    assignedRec={assignedRec}
                                                                    setAssignedRec={setAssignedRec}
                                                                    assignloading={assignloading}
                                                                    setAssignLoading={setAssignLoading}
                                                                    type={type}
                                                                    refresh={refresh}
                                                                    
                                                                    />
                                                                    </div>
                                                                    </div>
                                                                    <div className="form-group form-group-save-cancel mt-4">
                                                                    <PermissionsGate scopes={['wbscourselpageedit']} RenderError={() => (
                                                                        <button className="btn btn-save btn-success" disabled>
                                                                        <i className="fal fa-save"></i> Save
                                                                        </button>
                                                                    )}>
                                                                    
                                                                    <button
                                                                    className="btn btn-save btn-success"
                                                                    disabled={formik.isSubmitting}
                                                                    type="submit"
                                                                    title="Save"
                                                                    >
                                                                    <i className="fal fa-save"></i> Save
                                                                    </button>
                                                                    </PermissionsGate>
                                                                    
                                                                    <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => {
                                                                        setRefresh(Date.now())
                                                                        type === "open" ? formik.resetForm() : history.goBack("/website_management/pages/courselandingpages/table");
                                                                    }}>
                                                                    <i className="fal fa-times"></i>
                                                                    Cancel
                                                                    </button>
                                                                    </div>
                                                                    {Object.keys(formik.errors).map((key) => {
                                                                        if (formik.touched[key]) {
                                                                            return (
                                                                                <div className="invalid-feedback d-block" key={key}>
                                                                                {formik.errors[key]}
                                                                                </div>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                                    </form>
                                                                    </div>
                                                                    </div>
                                                                    </div>
                                                                );
                                                            };
                                                            
                                                            export default CourseLandingPagesOpt;
                                                            