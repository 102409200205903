import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {  TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { TrimText } from "../../common/TrimText";
import {getPartnersCancelToken, deletePartner} from "../../../services/WebsiteListServer";
import { error } from "jquery";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const ListOfPartners = () => {
  const loc = useLocation();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [PartnerData, setPartnerData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [deleteRow, setDeleteRow] = useState(false);
  
  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])


  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  function renderStatusUI(status){
      return (
          <label htmlFor={`id-${status}`}>
            <span
              className={
                "cat cat-"
                 + (status == 0 ? "green" : "red")
              }
            >
              <i className="fas fa-circle mr-1"></i> {status == 0 ? 'Active' : 'Disabled'}
            </span>
          </label>
      );
    };


  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
  };

  // TODO Data

  const dataToRender = () => {
    let updatedData = [];
    let allData = PartnerData;
    if (search.length) {
      let tempname = allData.filter((item) => {
        let includes = item.name && item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLastname = allData.filter((item) => {
        let includes = item.referrer_link && item.referrer_link.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempname,
        ...tempLastname,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post) {
          if (post === item.status) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (status.length || search.length) {
      return updatedData;
    } else {
      return PartnerData;
    }
  };
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await getPartnersCancelToken(dropdownSource.token);
        setPartnerData(response?.data?.data);
        if(response?.status == 200){
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
}, []);
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await getPartnersCancelToken(dropdownSource.token);
        setPartnerData(response?.data?.data);
        if(response?.status == 200){
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
}, [deleteRow]);


  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm ">
            <PermissionsGate scopes={["partnerview"]}>
              <Link className="as-text-blue curser " to={`/website_management/partnerDetails/${row.id}`}>
                {row.name}
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    { name: "Form Link", selector: "referrer_link", sortable: true, cell: (row) => (row.referrer_link ? row.referrer_link : "NA") },
    { name: "Status",
       selector: "status",
       sortable: true,
       cell: (row) => (row.status != null ? renderStatusUI(row.status) : "-") 
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link className="btn btn-primary rounded-circle" to={`/website_management/partnerDetails/${row.id}`} title="Open">
              <i className="fal fa-folder-open"></i>
            </Link>
            <PermissionsGate scopes={["partnerdelete"]}>
              <button className="btn btn-danger rounded-circle" onClick={() => handleDelete(row.id)} title="Delete">
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);
  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deletePartner(ID).then((res) => {
            setDeleteRow(!deleteRow);
            history.push("/website_management/partners/table");
            Swal.fire("Deleted!", "Partner has been deleted.", "success");
          });
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setDeleteRow(!deleteRow);
        history.push("/website_management/partners/table");
      });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "referrer_link", "Status"];

    data = data?.map((row) => ({
      ...row,
      Name: row?.name ? row?.name : "NA",
      referrer_link: row.referrer_link ? row.referrer_link : "NA",
      Status: row.status == 0 ? "Active" : "Disabled"
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.name, row.domain_url];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                  <div className="filter-scroll">
                      <div className={`filter-scroll-inner  filter-custom-new`}>
                      </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Brand_Setting");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Brand_Setting");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Brand_Setting");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <Link to={`/website_management/partnerDetails/0`}>
                  <PermissionsGate scopes={["partneradd"]} errorProps={{ disabled: true }}>
                    <button className="btn btn-primary" title="New Partner">
                      <i className="fal fa-plus"></i>New Partner
                    </button>
                  </PermissionsGate>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isLoaded ? <SkeletonTicketList />
        :<DataTable
          data={dataToRender()}
          defaultSortField="name"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
        }
      </div>
    </div>
  );
};

export default ListOfPartners;
