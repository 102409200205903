import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { GetQuizTopics, GetQuizTopicsCancelToken } from "../../../services/SmartAssessmentService";
import HtmlParser from "react-html-parser";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AddGradeLetterTemplate,
  EditGradeLetterTemplate,
  GradeLetterTemplateDetail,
  GradeLetterTemplateDetailCancelToken,
} from "../../../services/GradeSettingService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const AddTemplate = (props) => {
  const { tab, id } = useParams();
  const history = useHistory();
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [quizTopics, setQuizTopics] = useState([]);
  const [data, setData] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [loading, setLoading] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({
      scopes: tab == "create" ? ["gradingLetterTemplateAdd"] : ["gradingLetterTemplateView"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetQuizTopicsCancelToken({},dropdownSource.token);
        if(res.status == 200){
          let tempArr = res?.data?.map((item) => {
            return {
              value: item?.id,
              label: HtmlParser(
                `<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`
              ),
            };
          });
          setQuizTopics(tempArr);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const data = {
        id: id,
      };
  
      try {
        const res = await GradeLetterTemplateDetailCancelToken(data, source.token);
        if (res.status === 200) {
          const keywordData =
            res?.data?.list_data[0]?.keywords &&
            res?.data?.list_data[0]?.keywords?.length > 0 &&
            Array.isArray(JSON.parse(res?.data?.list_data[0]?.keywords))
              ? JSON.parse(res?.data?.list_data[0]?.keywords).map((item) => item)
              : [];
          const selectedTopicData =
            res?.data?.list_data[0]?.topics[0] &&
            res?.data?.list_data[0]?.topics?.length > 0 &&
            Array.isArray(res?.data?.list_data[0]?.topics.split(","))
              ? res?.data?.list_data[0]?.topics?.split(",").map((item) => item)
              : [];
          let dataWithId = res?.data?.list_data[0]?.gradeOptions?.map((item) => {
            return { ...item, _id: item.id };
          });
          setTemplateData(res?.data?.list_data[0]);
          setData(dataWithId);
          setSelectedTopic(selectedTopicData);
          setSelectedKeywords(keywordData);
          setLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    if (tab != "create"){
      getProgramsList();
    }

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData]);

  let selectedTopics = [];
  let topicss = quizTopics?.map((item) => {
    return selectedTopic?.map((val) => {
      if (item.value == val) {
        return selectedTopics.push(item);
      }
    });
  });

  const formik = useFormik({
    enableReinitialize: tab == "create" ? false : true,
    initialValues: {
      name: templateData?.name ? templateData?.name : "",
      keywords: selectKeywords,
      topic: selectedTopics,
      gradeletterData: data
    },
    validationSchema: Yup.object({
    gradeletterData: Yup.string().test("required","All fields are Required",function (value){
        let isValid = false
      let tempData = data?.map(val => {
          if (val.text != "" && val?.min_score != "" && val?.max_score != "" && !isValid) {
            isValid = true
          }
        })
     return isValid
    }),
      name: Yup.string().required("Name is required"),
      keywords: Yup.array()
        .min(1, "Atleast one Keyword is required")
        .required("Keyword is required")
        .nullable(),
      topic: Yup.array()
        .min(1, "Atleast one Topic is required")
        .required("Topic is required")
        .nullable(),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      values.topics = values.topic
        .map((item) => {
          return item?.value;
        })
        .join(",");
      values.keywords = JSON.stringify(values.keywords);
      const letterData = data.map((obj) => {
        const { _id, ...rest } = obj;
        return rest;
      });
      delete values.topic;
      values = { ...values, options: letterData };
      if (tab == "create") {
        AddGradeLetterTemplate(values)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            history.push(`/courseAdministration/gradingTemplate/table`);
            setDisabled(false);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
            setDisabled(false);
          });
      } else {
        EditGradeLetterTemplate({ ...values, id: id })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated Successfully",
            });
            history.push(`/courseAdministration/gradingTemplate/table`);
            // setDisabled(false)
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message,
            });
            // setDisabled(false)
          });
      }
    },
  });

  const handleKeyDown1 = (event) => {
    event.stopPropagation();
    var keyword = selectKeywords;
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.value) {
        keyword.push(event.target.value);
        event.target.value = "";
        formik.setFieldValue("keywords", keyword);
      }
    }
  };

  const removeKeyword = (index) => {
    var keyword = selectKeywords;
    var keywords = keyword.splice(index, 1);
    formik.setFieldValue("keywords", keyword);
  };
  const multiSelectColorStyles = {
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      "& span": {
        background: "transparent !important",
        color: "#333 !important",
      },
    }),
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    event.stopPropagation()
    const newRow = {
      text: "",
      min_score: "",
      max_score: "",
      _id: data?.length,
    };
    setData([...data, newRow]);
  };

  // const handleChangeLetterName = (e, rowId) => {
  //   let tempUserData = data;
  //   tempUserData.map((item) => {
  //     if (item.id == rowId) {
  //       item.qualification = e.target.value;
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   formik.setFieldValue("Qualification", e.target.value);
  //   setData((tempUserData) => [...tempUserData]);
  // };

  // const handleChangeMinScore = (e, rowId) => {
  //   let tempUserData = data;
  //   tempUserData.map((item) => {
  //     if (item.id == rowId) {
  //       item.place = e.target.value;
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   formik.setFieldValue("Place", e.target.value);
  //   setData((tempUserData) => [...tempUserData]);
  // };

  // const handleChangeMaxScore = (e, rowId) => {
  //   let tempUserData = data;
  //   tempUserData.map((item) => {
  //     if (item.id == rowId) {
  //       item.place = e.target.value;
  //       return item;
  //     } else {
  //       return item;
  //     }
  //   });
  //   formik.setFieldValue("Place", e.target.value);
  //   setData((tempUserData) => [...tempUserData]);
  // };

  const handleInputChange = (e, rowId, field) => {
    let { value } = e.target;

    // Parse value as a number if the field is a number type
    if (e.target.type === "number") {
      value = parseFloat(value);
    }

    let prevData = data;
    const updatedData = prevData.map((row) => {
      if (row._id === rowId) {
        return { ...row, [field]: value };
      }
      return row;
    });

    setData([...updatedData]);
  };

  // const handleDeleteRow = rowIndex => {
  //   setData(prevData => {
  //     const updatedData = [...prevData];
  //     updatedData.splice(rowIndex, 1);
  //     return updatedData;
  //   });
  // };

  const handleDeleteRow = (rowIndex) => {
    // console.log(rowIndex, "rowIndex");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Deleted Successfully",
        });
        const updatedData = data;
        updatedData.splice(rowIndex, 1);
        setData([...updatedData]);
      }
    });
  };

  // const generateId = () => {
  //   // Generate a unique ID for new rows
  //   return Math.floor(Math.random() * 10000);
  // };

  const columns = [
    {
      name: "Letter",
      selector: "text",
      cell: (row) => (
        <div className="input_wt01 resultstatus_flex arrowhide">
          <div className="form-icon-group status-select-form">
            <input
              className={"form-control " + (false ? " is-invalid" : "")}
              type="text"
              title="Letter"
              defaultValue={row.text}
              onBlur={(e) => handleInputChange(e, row._id, "text")}
            />
          </div>
        </div>
      ),
    },
    {
      name: `Min Score (≥)`,
      selector: "min_score",
      cell: (row) => (
        <div className="input_wt01 resultstatus_flex arrowhide">
          <div className="form-icon-group status-select-form">
            <input
              className={"form-control " + (false ? " is-invalid" : "")}
              type="number"
              title="Min Score (≥)"
              // onChange={(e) => handleInputChange(e, row.id, 'minScore')}
              onBlur={(e) => handleInputChange(e, row._id, "min_score")}
              defaultValue={row.min_score}
            />
            <span>%</span>
          </div>
        </div>
      ),
    },
    {
      name: "Max Score (≤)",
      selector: "max_score",
      cell: (row) => (
        <div className="input_wt01 resultstatus_flex arrowhide">
          <div className="form-icon-group status-select-form">
            <input
              className={"form-control " + (false ? " is-invalid" : "")}
              type="number"
              title="Max Score ≤"
              // onChange={(e) => handleInputChange(e, row.id, 'maxScore')}
              onBlur={(e) => handleInputChange(e, row._id, "max_score")}
              defaultValue={row.max_score}
            />
            <span>%</span>
          </div>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row, index) => (
        // <PermissionsGate
        // scopes={["gradingLetterTemplateDelete"]}
        // errorProps={{ disabled: true }}
        //  >
        <button
          className="btn btn-danger rounded-circle"
          title="Delete"
          onClick={() => handleDeleteRow(index)}
        >
          <i className="fal fa-trash-alt"></i>
        </button>
        // </PermissionsGate>
      ),
    },
  ];

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom add-template-table">
        <div className="grade-setting-box">
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">Template Information {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            </div>
            <div className="card-body-inr card-body-info">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <label htmlFor="question_name">Name *</label>
                  <div className="form-icon-group mb-4">
                    <input
                      type="text"
                      // className={"form-control"}
                      className={
                        "form-control" +
                        (formik.errors.name && formik.touched.name
                          ? " is-invalid"
                          : "")
                      }
                      name="question_name"
                      id="question_name"
                      title="Name"
                      value={formik.values.name}
                      placeholder="Name"
                      onChange={(e) =>
                        formik.setFieldValue("name", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 mb-4">
                  <label>Keywords*</label>
                  <div className="form-icon-group">
                    <div className="form-control custom-select-box">
                      <input
                        type="text"
                        className={
                          "form-control " +
                          (formik.errors.keywords && formik.touched.keywords
                            ? " is-invalid"
                            : "")
                        }
                        name="keywords"
                        placeholder="keywords"
                        title="Keywords"
                        onBlur={formik.handleBlur}
                        onKeyDown={handleKeyDown1}
                      />
                    </div>
                  </div>
                  {selectKeywords != "" && (
                    <ul className="tag-list mb-0">
                      {selectKeywords?.map((ele, index) => (
                        <li key={index}>
                          {ele}
                          <span onClick={() => removeKeyword(index)}>
                            <i className="fal fa-times"></i>
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="form-group-blk mb-3" title="Topics">
                    <label htmlFor="Topic">Topics *</label>
                    <Select
                      // isDisabled={!isEditable}
                      styles={multiSelectColorStyles}
                      className={
                        "form-control color-drop custom-select-box is-multi" +
                        (formik.errors.topic && formik.touched.topic
                          ? " is-invalid"
                          : "")
                      }
                      name="topic"
                      title="topic"
                      placeholder={
                        formik.values.topic && formik.values.topic == null
                          ? formik.values.topic
                          : "Select"
                      }
                      onChange={(value) => formik.setFieldValue("topic", value)}
                      value={formik.values.topic}
                      isMulti={true}
                      options={quizTopics}
                      maxMenuHeight={175}
                      onBlur={formik.handleBlur}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="edit-icon new-card-header justify-content-between mb-2">
                <div className="card-header">
                  Manage Grading Letter Template Options {loading ? <i className="fas fa-cog fa-spin"></i> : ""}
                </div>
                <button
                  className="btn btn-primary"
                  title="Add Option"
                  type="button"
                  onClick={(event)=>handleAddRow(event)}
                >
                  <i className="fal fa-plus"></i>Add Option
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="filter-button-group">
                <div className="reset-btn-group"></div>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          data={data}
          columns={columns}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
        />
        <div className="form-group form-group-save-cancel mt-4">

        <PermissionsGate
         scopes={tab == "create" ? ["gradingLetterTemplateAdd"] : ["gradingLetterTemplateEdit"]}
         errorProps={{ disabled: true }}
        >
          <button
            className="btn btn-save btn-success"
            type="button"
            title="Save"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => {
              if(id) {
                formik.resetForm();
                setUpdateData(!updateData)
              } else {
                history.goBack();
              }
            }}
          >
            <i className="fal fa-times"></i>Cancel
          </button>

          {Object.keys(formik.values).map((key) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={key}>
                  {formik.errors[key]}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
