import React, { useEffect, useMemo, useState } from "react";
import { useHistory} from "react-router";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {GetDisplayUsers, DeleteDisplayUser} from "../../../services/SmartSignageService";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";

const ListOfLayouts = () => {
    
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [displays, setDisplays] = useState([]);
    const [search, setSearch] = useState("");
    const [displayConnected, setDisplayConnected] = useState({ arr: [], checkObj: {} });
    const [campusFilter, setCampusFilter] = useState({ arr: [], checkObj: {} });
    const [dataToRender, setDataToRender] = useState([]);
    const [campusesOptions, setCampusesOptions] = useState([]);
    const [reloadDisplays, setReloadDisplays] = useState(false);
    useEffect(() => {
        const getDisplays = async () => {
            setLoading(true);
            const response = await GetDisplayUsers();
            if(response?.data?.data){
                // console.log("response",response?.data?.data)
                setDisplays(response?.data?.data);
                setCampusesOptions(response?.data?.campusesOptions || []);
                setLoading(false);
            }
        }
        getDisplays();
    }, [])
    useEffect(() => {
        const getDisplays = async () => {
            setLoading(true);
            const response = await GetDisplayUsers();
            if(response?.data?.data){
                // console.log("response",response?.data?.data)
                setDisplays(response?.data?.data);
                setCampusesOptions(response?.data?.campusesOptions || []);
                setLoading(false);
            }
        }
        getDisplays();
    }, [reloadDisplays])
    useEffect(() => {
        if (displays.length > 0 ) {
            // Filter out items not selected by displayConnected filter
            const filteredItems = displays.filter((item) => {
                const connection = item?.connected?.toString(); // Ensure it's a string
                const campusId = item?.campus?.toString();
                return ((displayConnected.arr.length === 0 || displayConnected.arr.includes(connection)) && 
                (campusFilter.arr.length === 0 || campusFilter.arr.includes(campusId)) &&
                (search.length == 0 || item.name.toLowerCase().includes(search.toLowerCase()))); // Compare as strings
            });
            // console.log("displays", displays);
            setDataToRender(filteredItems);
        } else {
            // console.log("displays2", displays);
            setDataToRender(displays);
        }
    }, [displays, displayConnected, campusFilter, search]);
    
    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // Delete function
                DeleteDisplayUser(id).then((res) => {
                    Swal.fire("Deleted!", "Your display has been deleted.", "success");
                    setReloadDisplays(!reloadDisplays);
                })
                .catch((error) => Swal.fire("Error!", error, "error"));
            }
        });
    };
    
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link to={`/campusAdministration/smartsignage/displayusers/edit/${row.id}`}>
        <span title={row.name} className="as-text-blue curser feature-name">
          <span className="textLimit100"  data-toggle="modal" data-target="#programmeDateForm" >{row.name}</span>
        </span>
        </Link>
      ),
    },
    {
        name: "Status",
        selector: "connected",
        sortField: "connected",
        sortable: true,
        cell: (row) => (
          <span className={row.connected == 0 ? 'as-red-bg-smooth' : 'as-green-bg-smooth'} title={row.connected} >{row.connected === null ? "-" : row.connected === 0 ? "OFFLINE" : "ONLINE"}</span>
        ),
      },
    {
      name: "Campus",
      selector: "campus_name",
      sortField: "campus_name",
      sortable: true,
      cell: (row) => (
        <span title={row.campus_name} >{row.campus_name || "-"}</span>
      ),
    },
    {
        name: "Location",
        selector: "room",
        sortField: "room",
        sortable: true,
        cell: (row) => (<span title={row.room} >{row.room || "_"}</span>)
    },
    {
        name: "App ID",
        selector: "pairing_id",
        sortField: "pairing_id",
        sortable: true,
        cell: (row) => (<span title={row.pairing_id} >{row.pairing_id || <span class='as-red-bg-smooth'>NOT SET</span> }</span>)
    },
    {
        name: "Linked Display Group",
        selector: "group_name",
        sortField: "group_name",
        sortable: true,
        cell: (row) => (<span title={row.group_name} >{row.group_name || "-"}</span>)
    },
    {
        name: "Actions",
        selector: "actions",
        width: "100px",
        cell: (row) => (
            <>
            <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignuserview"]} >
                <button
                    type="button"
                    className="btn btn-primary rounded-circle"
                    title="Edit"
                    onClick={() => history.push(`/campusAdministration/smartsignage/displayusers/edit/${row.id}`)}
                >
                    <i className="fal fa-edit"></i> 
                </button>
                </PermissionsGate>
                <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignuserdelete"]} >
                <button
                    type="button"
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.id)}
                >
                    <i className="fal fa-trash"></i> 
                </button>
                </PermissionsGate>
                </div>
                </div>
            </>
        ),
    },
  ], []);

    return (         
        <>
        {loading ? (
            <SkeletonTicketList />
        ) : 
        <DataTableComponentFrontPagination
        isActionAlignRight={true}
        data={dataToRender}
        columns={columns}
        loading={loading}
        defaultSort={'name'}
        search={search}
        setSearch={setSearch}
        isExport={false}
        // exportFunction={exportData}
        // exportFileName={"Layouts"}
        filters={[
          {
            filterName: "Connection",
            optionArr: [
                {label: "Online", value: 1},
                {label: "Offline", value: 0},
            ],
            state: displayConnected,
            setState: setDisplayConnected,
            // renderLabelFunction: RenderLearningMethod,
          },
          {
            filterName: "Campus",
            optionArr: campusesOptions,
            state: campusFilter,
            setState: setCampusFilter,
            // renderLabelFunction: RenderLearningMethod,
          }
        ]}
        tableButton={[
            <Link to={`/campusAdministration/smartsignage/displayusers/add`}>
            <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignuseradd"]} >
            <button
            type="button"
            className="btn btn-primary"
            title="Create new"
            >
            <i className="fal fa-plus"></i>Create New
            </button>
            </PermissionsGate>
            </Link>
        ]}
      />
    }
    </>
)

}

export default ListOfLayouts;