import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../../utils/permissionGate";
import { RenderQuizAttemptResultStatus } from "../../../../utils/CommonStatusItems";
import Swal from "sweetalert2";
import DataTableComponent from "../../../common/DataTableComponent";
import {
  assessmentExemptionToggle,
  getGradeOfStudentViaCourse,
  getStudentBrand,
  getStudentGradeViaCourse,
  updateCousesScore,
} from "../../../../services/GradeSettingService";
import moment from "moment";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import GradeBookSaveBar from "../../../common/GradeBookSaveBar";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import $, { parseHTML } from "jquery";
import LetterheadIntakePDF from "../../../../LetterheadIntakePDF";
import { RenderLearningMethodGradebook } from "../../../../utils/CommonGroupingItem";
import html2canvas from "html2canvas";
import { GetBrandSettingDetail } from "../../../../services/BrandServices";

const StudentGrades = ({ courseName, stdName }) => {
  const history = useHistory();
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const { tab, id, stdId } = useParams();
  let stdId_param = stdId.split("?")[0]
  const [search, setSearch] = useState("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [percentageView, setPercentageView] = useState(false);
  const [gradingType, setGradingType] = useState();
  const [isCourseEdited, setIsCourseEdited] = useState(0);
  const [
    calculateGradeOfStudentViaCourse,
    setCalculateGradeOfStudentViaCourse,
  ] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowFinal, setIsShowFinal] = useState(false);
  const [isExcludeGrade, setIsExcludeGrade] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [pdfData, setPDFData] = useState([]);
  const [brandData, setBrandData] = useState({})
  const [studentDetails, setStudentDetails] = useState({})
  const [programmeDetails, setProgrammeDetails] = useState({})
  const [isSupplementary, setIsSupplementary] = useState()
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [brand_override, setBrandOverride] = useState("")
  const [isBrandOverride, setIsBrandOverride] = useState(false)
  const [disclaimer,setDisclaimer] = useState("")
  const [studentID, setStudentID] = useState('');
  const [pdfsData, setPDFsData] = useState([]);
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "ASC",
  });

  useEffect(() => {
    let response = hasPermission({
      scopes: ["casvview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const data = {
      id: [stdId_param]
    }
    if(isBrandOverride && brand_override){
      GetBrandSettingDetail(brand_override)
      .then((res) => {
        setBrandData(res?.data?.brand_setting);
      })
      .catch((err) => console.log(err));
    }else{
      
      getStudentBrand(data)
      .then((res) => {
        setBrandData(res?.data?.getBrandDetail);
      })
      .catch((err) => console.log(err));
    }
  }, [brand_override,isBrandOverride]);

  const handleSaveGrades = () => {
    setSaveGradesLoading(true);
    const values = {
      intake_id: id,
      student_id: stdId_param,
      assessment: updateValueArr
    };
    updateCousesScore(values)
      .then((res) => {
        setSaveGradesLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setUpdateData(!updateData));
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    let data = {
      intake_id: id,
      student_id: stdId_param,
      page: tableState.page,
      limit: tableState.perPage,
      search: search,
      exportStatus: "false",
      key: tableState.sortKey,
      sort: tableState.sortOrder,
    };
    getStudentGradeViaCourse(data)
      .then((res) => {
        let details = res.data?.data;
        setStudentDetails(res?.data?.studentData)
        setBrandOverride(res.data?.getCourse?.override_brand)
        setIsBrandOverride(res.data?.getCourse?.override_sor_brand != 0)
        setProgrammeDetails(res?.data?.getCourse)
        setIsCourseEdited(res.data?.allUpdatedCourse);
        setAssessmentList(res.data?.data);
        setIsSupplementary(res.data?.isSupplementaryForThisStudent && !res.data?.isSickForThisStudent)
        setTotalRows(res.data?.pagination?.total);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error <<<<<< : ", error);
        setLoading(false);
      });
  }, [id, stdId_param, tableState, search, updateData]);

  useEffect(() => {
    const values = {
      intake_id: id,
      student_id: stdId_param,
    };
    getGradeOfStudentViaCourse(values)
      .then((res) => {
        setCalculateGradeOfStudentViaCourse(
          res?.data?.calculateGradeOfStudentViaCourse
        );
        setGradingType(
          res?.data?.calculateGradeOfStudentViaCourse?.grading_type
        );
      })
      .catch((err) => console.error(err));
  }, [assessmentList]);

  useEffect(() => {
    let data = {
      intake_id: id,
      student_id: stdId_param,
      exportStatus: "true",
    };
    getStudentGradeViaCourse(data)
    .then((res) => {
      setDisclaimer(res.data?.getCourse?.st_disclaimer)
     if (calculateGradeOfStudentViaCourse && res.data?.data) {
      let details = res.data?.data;
      let options = [
        { label: "Red", value: "0" },
        { label: "Yellow", value: "49" },
        { label: "Green", value: "100" },
      ];
      let calculateGrade = calculateGradeOfStudentViaCourse;
      const pdfDetails = details?.map((assessment) => ({
        name: assessment?.name,
        weight: assessment?.grant_exemption
          ? "Exempted"
          : assessment?.ass_weighting,
        grade: assessment?.grant_exemption
          ? "Exempted"
          : percentageView
          ? assessment?.ass_percentage + "%"
          : assessment?.submited == "0" ||
            moment.unix(assessment?.close_date_time) > moment.unix(new Date())
          ? "N/A"
          : assessment?.grading_type === 0
          ? assessment?.ass_percentage + "%"
          : assessment?.grading_type === 1
          ? options.filter((option) => {
              let selectedValue =
                assessment?.grade == 0
                  ? 0
                  : assessment?.grade > 0 &&
                    (assessment?.grade < 49 || assessment?.grade == 49)
                  ? 49
                  : 100;
              return option?.value == selectedValue;
            }).length
            ? options?.find(function (option) {
                let selectedValue =
                  assessment?.grade == 0
                    ? 0
                    : assessment?.grade > 0 &&
                      (assessment?.grade < 49 || assessment?.grade == 49)
                    ? 49
                    : 100;
                return option?.value == selectedValue;
              }).label
            : ""
          : assessment?.grading_type === 2
          ? assessment?.letterGrades?.length && assessment?.letterGrades?.filter(
              (option) => option?.value === assessment?.grade
            ).length
            ? assessment?.letterGrades?.length && assessment?.letterGrades?.find(
                (option) => option?.value === assessment?.grade
              ).label
            : ""
          : assessment?.ass_mark,
        result: assessment?.grant_exemption
          ? "Exempted"
          : assessment?.submited == "0" ||
            moment.unix(assessment?.close_date_time) > moment.unix(new Date())
          ? "N/A"
          : assessment?.pass_status
          ? assessment?.pass_status
          : "-",
        isExclude: assessment?.isExclude,
        final_status: calculateGrade?.pass_status,
        isAssignedSickStudent : assessment?.isAssignedSickStudent,
        isSickForThisStudent : assessment?.isSickForThisStudent,
        isSupplementaryForThisStudent : assessment?.isSupplementaryForThisStudent,
        final_grade:
          calculateGrade?.pass_status === "N/A"
            ? "N/A"
            : percentageView
            ? calculateGrade?.scoreRecieved &&
              (typeof calculateGrade?.scoreRecieved === "string"
                ? calculateGrade?.scoreRecieved.includes("%")
                  ? Math.round(
                      parseFloat(calculateGrade?.scoreRecieved.replace("%", ""))
                    ).toString() + "%"
                  : Math.round(
                      parseFloat(calculateGrade?.scoreRecieved)
                    ).toString() + "%"
                : typeof calculateGrade?.scoreRecieved === "number"
                ? calculateGrade?.scoreRecieved + "%"
                : calculateGrade?.scoreRecieved)
              ? Math.round(
                parseFloat(calculateGrade?.scoreRecieved)
              ).toString() + "%"
              : "N/A"
            : calculateGrade?.grading_type === 3
            ? `${calculateGrade?.allAss_ass_mark}/${calculateGrade?.allAss_total_mark}`
            : calculateGrade?.grading_type === 0
            ? calculateGrade?.gradeText &&
              (typeof calculateGrade?.gradeText === "string"
                ? calculateGrade?.gradeText.includes("%")
                  ? Math.round(
                      parseFloat(calculateGrade?.gradeText.replace("%", ""))
                    ).toString() + "%"
                  : Math.round(
                      parseFloat(calculateGrade?.gradeText)
                    ).toString() + "%"
                : typeof calculateGrade?.gradeText === "number"
                ? calculateGrade?.gradeText + "%"
                : calculateGrade?.gradeText)
              ? calculateGrade?.gradeText
              : "N/A"
            : calculateGrade?.gradeText,
      }));
      setPDFData(pdfDetails)
    }
  }).catch((err)=>console.log(err))
  }, [assessmentList, calculateGradeOfStudentViaCourse, percentageView]);

  const handlepdfData = () => {
    setPdfDownloading(true);
    const swalPro = Swal.mixin({
      title: "Downloading PDF",
      text: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    swalPro.fire();
    if (pdfData && brandData) {
      let modifiedSoR = pdfData?.map((item) => ({
        ...item,
        final_grade: !isShowFinal ? "" : item?.final_grade,
        final_status: !isShowFinal ? "" : item?.final_status
      })).filter(assessment => !isExcludeGrade || !assessment.isExclude);
      const uniqueId = 'st_disclaimer_' + Math.random() * 100
              // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
              let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
              ${disclaimer}
              </div>`
              kjs = parseHTML(kjs)
              // document.body.appendChild(kjs[0])
              document.querySelector("body").appendChild(kjs[0])
              let divHeight = document.getElementById(uniqueId).clientHeight
              divHeight = divHeight < 670 ? divHeight : 670
            
              let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
              let divWidth = document.getElementById(uniqueId).clientWidth
              
              divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
              html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
              var dataURL = canvas.toDataURL();
              return dataURL
              }).then(noteImage => {

                pdf(
                  <LetterheadIntakePDF
                  detailData={modifiedSoR}
                  noteImage={disclaimer ? noteImage : null}
                divMinHeight = {divMinHeight}
                divHeight={divHeight+300}
                divWidth={divWidth > 565 ? 565 : divWidth}
                divOrgHeight={divHeight}
                divTopPosition = {1197 - divHeight - 130}
                  studentAndCourseDetails={{
                    course_name: programmeDetails?.name,
                    Student_name: studentDetails.full_name,
                    ID_number: studentDetails?.number,
                    Student_number: studentDetails?.student_crm_id
                    ? studentDetails?.student_crm_id
                    : studentDetails?.student_num,
                    start: programmeDetails?.start
                    ? programmeDetails.start?.toString().length == 10
                    ? moment.unix(programmeDetails?.start).format("DD MMMM YYYY")
                    : moment(programmeDetails?.start).format("DD MMMM YYYY")
                    : "",
                    end: programmeDetails?.end
                    ? programmeDetails.end?.toString().length == 10
                    ? moment.unix(programmeDetails?.end).format("DD MMMM YYYY")
                    : moment(programmeDetails?.end).format("DD MMMM YYYY")
                    : "",
                    intake:
                    programmeDetails?.number && programmeDetails?.year
                    ? `${programmeDetails?.number}-${programmeDetails?.year}`
                    : "",
                    learning_method: programmeDetails?.type
                    ? RenderLearningMethodGradebook(programmeDetails?.type).text
                    : "",
                  }}
                  brandData={brandData}
                  />
                )
              
        .toBlob()
        .then((response) => {
          const blobURL = window.URL.createObjectURL(response);
          const a = document.createElement("a");
          a.href = blobURL;
          a.download = `${
            studentDetails?.student_crm_id
              ? studentDetails?.student_crm_id
              : studentDetails?.student_num
          }_${studentDetails.full_name}_${
            programmeDetails?.name
          }_SOR_${brandVisuals?.brand_name || ""}_${moment(new Date()).format(
            "DD MM YY"
          )}.pdf`; // Set the desired filename for the download
          a.click();

          URL.revokeObjectURL(blobURL);
          setPdfDownloading(false);
          setIsChecked(false);
          setIsShowFinal(false);
          setIsExcludeGrade(false);
          swalPro.close();
          $("#downloadSoRModal").modal("hide")
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Download Successfully",
          });
        })
        .catch((err) => {
          setPdfDownloading(false);
          setIsChecked(false);
          setIsShowFinal(false);
          setIsExcludeGrade(false);
          swalPro.close();
          $("#downloadSoRModal").modal("hide")
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err,
          });
        });
      }).catch(err => console.log(err))
    }
  };
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return (
          <span title={row.name} className={`overflow-ellipsis2 ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ?  "supplementry-pointer-events" : ""}`}>
            {row.name ? (
              <Link
                className={`as-text-blue curser feature-name ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}
                to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/Details/${row.id}`}
              >
                <span className="textLimit100">{row.name}</span>
              </Link>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      name: "Weight",
      selector: "weight",
      sortField: "ass_weighting",
      sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row.ass_weighting}</span>
        ),
    },
    {
      name: "Score Recieved",
      selector: "ass_mark",
      sortable: false,
      cell: (row) => (
        <div className="d-flex">
          {row.grant_exemption ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row.ass_mark + "/" + row.ass_total_mark}</span>
          )}
          {!row.grant_exemption && (
            <div className={`d-flex ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              {row?.assessmentMarkingStatus?.toString() === "notsubmitted" ||
              row?.assessmentMarkingStatus?.toString() === "NOT SUBMITTED" ? (
                <div
                  className="grade-info-ic grade-info-red ml-2"
                  title="Not Submitted"
                >
                  <i className="fal fa-file-times"></i>
                </div>
              ) : (
                ""
              )}
              {!row?.mark_edited || row?.mark_edited == "0" ? (
                ""
              ) : (
                <div
                  className="grade-info-ic grade-info-orange ml-2"
                  title="Mark Edited"
                >
                  <i className="fal fa-edit"></i>
                </div>
              )}
              {row?.assessmentStatus?.toString() === "OPEN" ? (
                <div
                  className="grade-info-ic grade-info-green ml-2"
                  title="Open/Extension"
                >
                  <i className="fal fa-calendar-day"></i>
                </div>
              ) : (
                ""
              )}
              {row?.assessmentStatus?.toString() === "PENDING" ? (
                <div
                  className="grade-info-ic grade-info-blue ml-2"
                  title="Upcoming Assessment"
                >
                  <i className="fal fa-calendar-alt"></i>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Range",
      selector: "ass_total_mark",
      sortable: false,
      cell: (row) =>
        row.grant_exemption ? (
          <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : (
          <p className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
            {row.ass_total_mark ? <span>0 - {row.ass_total_mark}</span> : "N/A"}
          </p>
        ),
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: false,
      cell: (row) => {
        let options = [
          { label: "Red", value: "0" },
          { label: "Yellow", value: "49" },
          { label: "Green", value: "100" },
        ];
        return row.grant_exemption ? (
          <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : percentageView ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row.ass_percentage + "%"}</span>
        ) : row?.submited == "0" ||
          moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : (
          <>
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
            {row.grading_type === 0
              ? row.ass_percentage + "%"
              : row.grading_type === 1
              ? options.filter((option) => {
                  let selectedValue =
                    row.grade == 0
                      ? 0
                      : row.grade > 0 && (row.grade < 49 || row.grade == 49)
                      ? 49
                      : 100;
                  return option.value == selectedValue;
                }).length
                ? options.find(function (option) {
                    let selectedValue =
                      row.grade == 0
                        ? 0
                        : row.grade > 0 && (row.grade < 49 || row.grade == 49)
                        ? 49
                        : 100;
                    return option.value == selectedValue;
                  }).label
                : ""
              : row?.grading_type === 2
              ? row?.letterGrades?.length && row.letterGrades.filter((option) => option.value === row.grade)
                  .length
                ? row?.letterGrades?.length && row.letterGrades.find((option) => option.value === row.grade)
                    .label
                : ""
              : row?.gradeText + "/" + row?.ass_total_mark}
              </span>
          </>
        );
      },
    },
    //  ((gradingType == 0 || gradingType == 3 )|| percentageView) && {
    //     name: "Contribution to Final Score ",
    //     selector: "contributionMark",
    //     sortable: false,
    //     cell: (row) => {
    //       return row.grant_exemption ? (
    //         <span className="exempted-text">
    //           Exempted <i className="fal fa-info-circle"></i>
    //         </span>
    //       ) : row?.submited == "0" ||
    //         moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
    //         "N/A"
    //       ) : row.contributionMark.includes("%") ? (
    //         Math.round(
    //           parseFloat(row.contributionMark.replace("%", ""))
    //         ).toString() + "%"
    //       ) : (
    //         row.contributionMark
    //       );
    //     },
    //   },
    {
      name: "Result",
      selector: "pass_status",
      sortable: false,
      cell: (row) =>
        row.grant_exemption ? (
          <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : row?.submited == "0" ||
          moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : row.pass_status ? (
          RenderQuizAttemptResultStatus(row.pass_status).html
        ) : (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>-</span>
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {row.grant_exemption ? (
              <PermissionsGate
                scopes={["coursegge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-success rounded-circle"
                  title="Remove from Exemption"
                  disabled={(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent || (row?.ass_type == 9 && !row.isAssignedSickStudent) || (row?.ass_type == 8 && !row.isSupplementaryForThisStudent) || isSupplementary}
                  onClick={() => handleUpdateExemption(row.id, 0)}
                >
                  <i className="fal fa-user-plus"></i>
                </button>
              </PermissionsGate>
            ) : (
              <PermissionsGate
                scopes={["coursegge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-danger rounded-circle"
                  title="Grant Exemption"
                  disabled={(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent || (row?.ass_type == 9 && !row.isAssignedSickStudent) || (row?.ass_type == 8 && !row.isSupplementaryForThisStudent) || isSupplementary}
                  onClick={() => handleUpdateExemption(row.id, 1)}
                >
                  <i className="fal fa-user-times"></i>
                </button>
              </PermissionsGate>
            )}
            {/* <PermissionsGate
              scopes={["casvedit"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-primary rounded-circle"
                title="Save Grades"
                // onClick={() => handleUpdateExemption(row.assessment_id, 0)}
              >
                <i className="fal fa-save"></i>
              </button>
            </PermissionsGate> */}
          </div>
        </div>
      ),
    },
  ]);

  const handleUpdateExemption = (assId, val) => {
    const updatedAssessmentList = assessmentList.map(assessment => {
      if (assessment.id === assId) {
        const arr = updateValueArr
        let ifAssessmentExists = false
        arr.forEach((item, index) => {
          if (item.assessment_id === assId) {
            ifAssessmentExists = index
          }
        })
        if (ifAssessmentExists === false) {
          arr.push({
            student_id: stdId_param,
            assessment_id: assId,
            grant_exemption: val,
          })
        } else {
          arr[ifAssessmentExists] = { ...arr[ifAssessmentExists], grant_exemption: val }
        }
        setUpdateValueArr(() => [...arr])
        setIsCourseEdited(true)
        return {
          ...assessment,
          grant_exemption: val
        };
      }
      return assessment;
    });
    setAssessmentList(() => [...updatedAssessmentList]);
  };

  const exportData = (fileType, fileName) => {
    let options = [
      { label: "Red", value: "0" },
      { label: "Yellow", value: "49" },
      { label: "Green", value: "100" },
    ];
    const header = [
      "Name",
      "Weight",
      "Score Recieved",
      "Range",
      "Grade",
      // "Contribution to Final Score",
      "Result",
      "Final Grade",
    ];
    const apiData = {
      intake_id: id,
      student_id: stdId_param,
      page: tableState.page,
      limit: tableState.perPage,
      search: search,
      exportStatus: "true",
      key: tableState.sortKey,
      sort: tableState.sortOrder,
    };
    getStudentGradeViaCourse(apiData).then((res) => {
      let data = res.data.data;
      data.push({
        "Final Grade": percentageView
          ? calculateGradeOfStudentViaCourse?.scoreRecieved + "%"
          : calculateGradeOfStudentViaCourse.grading_type == 3 &&
            calculateGradeOfStudentViaCourse?.allAss_ass_mark &&
            calculateGradeOfStudentViaCourse?.allAss_total_mark
          ? calculateGradeOfStudentViaCourse?.allAss_ass_mark +
            "/" +
            calculateGradeOfStudentViaCourse?.allAss_total_mark
          : calculateGradeOfStudentViaCourse?.gradeText,
        "Final Result": calculateGradeOfStudentViaCourse?.pass_status
          ? RenderQuizAttemptResultStatus(
              calculateGradeOfStudentViaCourse?.pass_status
            ).text
          : "N/A",
      });
      data = data?.map((row) => {
        return {
          ...row,
          Name: row?.name
            ? row.name
            : row["Final Grade"]
            ? "Final Course Grade"
            : "-",
          Weight: row.grant_exemption
            ? "Exempted"
            : row?.ass_weighting
            ? row.ass_weighting
            : row["Final Grade"]
            ? ""
            : row.ass_weighting,
          "Score Recieved": row.grant_exemption
            ? "Exempted"
            : row.ass_mark && row.ass_total_mark
            ? `${row.ass_mark}/${row.ass_total_mark}`
            : row["Final Grade"]
            ? ""
            : `${row.ass_mark}/${row.ass_total_mark}`,
          Range: row.grant_exemption
            ? "Exempted"
            : row.ass_total_mark
            ? "0 - " + row.ass_total_mark
            : row["Final Grade"]
            ? ""
            : "0 - " + row.ass_total_mark,
          Grade: row.grant_exemption
            ? "Exempted"
            : row?.submited == "0" ||
              moment.unix(row?.close_date_time) > moment.unix(new Date())
            ? "N/A"
            : row.grading_type == 2
            ? row.letterGrades.find(function (option) {
                return option.value == row.grade;
              })
              ? row.letterGrades.find(function (option) {
                  return option.value == row.grade;
                }).label
              : ""
            : row.grading_type == 1
            ? options.find(function (option) {
                let selectedValue =
                  row.grade == 0
                    ? 0
                    : row.grade > 0 && row.grade < 49
                    ? 49
                    : 100;
                return option.value == selectedValue;
              })
              ? options.find(function (option) {
                  let selectedValue =
                    row.grade == 0
                      ? 0
                      : row.grade > 0 && row.grade < 49
                      ? 49
                      : 100;
                  return option.value == selectedValue;
                }).label
              : ""
            : row.grade
            ? row.grading_type == 0
              ? row.grade + "%"
              : row.ass_mark
            : row["Final Grade"]
            ? ""
            : "N/A",
          // "Contribution to Final Score": row.grant_exemption
          //   ? "Exempted"
          //   : row?.submited == "0" ||
          //     moment.unix(row?.close_date_time) > moment.unix(new Date())
          //   ? "N/A"
          //   : row.contributionMark?.includes("%")
          //   ? Math.round(
          //       parseFloat(row.contributionMark.replace("%", ""))
          //     ).toString() + "%"
          //   : row.contributionMark,
          Result: row.grant_exemption
            ? "Exempted"
            : row?.submited == "0" ||
              moment.unix(row?.close_date_time) > moment.unix(new Date())
            ? "N/A"
            : row?.pass_status
            ? row?.pass_status
            : row["Final Grade"]
            ? ""
            : row?.pass_status,
          "Final Grade": row["Final Grade"]
            ? `${row["Final Grade"]} ${row["Final Result"]}`
            : "",
          // "Final Result": row["Final Result"]
        };
      });

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 1000);
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);

        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row.Name,
            row.Weight,
            row["Score Recieved"],
            row.Range,
            row.Grade,
            // row["Contribution to Final Score"],
            row.Result,
            row["Final Grade"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);

        return false;
      }
    });
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleShowFinalToggle = () => {
    setIsShowFinal(!isShowFinal);
  };

  const handleExcludeFinal = () => {
    setIsExcludeGrade(!isExcludeGrade);
  };

  return (
    <>
      <DataTableComponent
        data={assessmentList}
        loading={loading}
        state={tableState}
        setState={setTableState}
        setSearch={setSearch}
        totalRows={totalRows}
        columns={columns.filter((item) => item)}
        exportFunction={exportData}
        exportFileName={`${courseName} - ${stdName} Grade Book`}
        filters={[]}
        tableButton={[
          <PermissionsGate scopes={["castadd"]} errorProps={{ disabled: true }}>
            <button
              className="btn btn-primary"
              onClick={() => setPercentageView(!percentageView)}
              title={percentageView ? "Standard View" : "Percentage View"}
            >
              <i className="fal fa-eye"></i>
              {percentageView ? "Standard View" : "Percentage View"}
            </button>
          </PermissionsGate>,
        ]}
      />
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom mt-4">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <i className="fal fa-chart-bar mr-2 font-weight-bold"></i>
              <span className="curser feature-name font-weight-bold">
                Final Course Grade
              </span>
            </div>
            {calculateGradeOfStudentViaCourse?.pass_status == "N/A" ? (
                 <div>
                 <span className="mr-3">N/A</span> 
                 {
                  programmeDetails.subject_type == "shortcourse" || !programmeDetails.qualification ?
                 <button
                 className="btn btn-primary rounded-circle"
                 title="Download SoR PDF"
                 data-toggle="modal"
                 data-target="#downloadSoRModal"
                 onClick={() => {
                   setIsShowFinal(programmeDetails.show_final_results);
                   setIsExcludeGrade(programmeDetails.show_empty_grades);
                  }}
                  // onClick={() => handlepdfData()}
                  
                  >
                   {pdfDownloading ? (
                     <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                    ) : (
                      <i className="fal fa-download download-SoR-icon"></i>
                    )}
                 </button> : ""
                  }
               </div>
            ) : (
              <div className="resultstatus_flex arrowhide">
                {percentageView ? (
                  <>
                    {(calculateGradeOfStudentViaCourse?.scoreRecieved &&
                      calculateGradeOfStudentViaCourse?.scoreRecieved !=
                        "N/A") ||
                    calculateGradeOfStudentViaCourse?.scoreRecieved === 0
                      ? typeof calculateGradeOfStudentViaCourse?.scoreRecieved ==
                          "string" &&
                        calculateGradeOfStudentViaCourse?.scoreRecieved.includes(
                          "%"
                        )
                        ? Math.round(
                            parseFloat(
                              calculateGradeOfStudentViaCourse?.scoreRecieved.replace(
                                "%",
                                ""
                              )
                            )
                          ).toString() + "%"
                        : typeof calculateGradeOfStudentViaCourse?.scoreRecieved ==
                            "string" &&
                          !calculateGradeOfStudentViaCourse?.scoreRecieved.includes(
                            "%"
                          )
                        ? Math.round(
                            parseFloat(
                              calculateGradeOfStudentViaCourse?.scoreRecieved
                            )
                          ).toString() + "%"
                        : typeof calculateGradeOfStudentViaCourse?.scoreRecieved ===
                          "number"
                        ? calculateGradeOfStudentViaCourse?.scoreRecieved + "%"
                        : calculateGradeOfStudentViaCourse?.scoreRecieved
                        ? calculateGradeOfStudentViaCourse?.scoreRecieved
                        : "N/A"
                      : "N/A"}
                    <p className="m-0 mr-2 ml-2">
                      {
                        RenderQuizAttemptResultStatus(
                          calculateGradeOfStudentViaCourse?.pass_status
                        ).html
                      }
                    </p>
                  </>
                ) : calculateGradeOfStudentViaCourse?.grading_type == 3 ? (
                  <>
                    <PermissionsGate
                      scopes={["cassgradeassedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <input
                        className={
                          "form-control" + (false ? " is-invalid" : "")
                        }
                        type="number"
                        defaultValue={
                          calculateGradeOfStudentViaCourse?.allAss_ass_mark
                            ? calculateGradeOfStudentViaCourse?.allAss_ass_mark
                            : ""
                        }
                        disabled
                        // onBlur={(e) => handlegradeChange(e, row.id, "inputbox", row.ass_total_mark, row)}
                      />
                    </PermissionsGate>
                    <span>{`/${calculateGradeOfStudentViaCourse?.allAss_total_mark}`}</span>
                    <p className="m-0 mr-2 ml-2">
                      {
                        RenderQuizAttemptResultStatus(
                          calculateGradeOfStudentViaCourse?.pass_status
                        ).html
                      }
                    </p>
                  </>
                ) : calculateGradeOfStudentViaCourse?.grading_type == 0 ? (
                  <>
                    {(calculateGradeOfStudentViaCourse?.gradeText &&
                      calculateGradeOfStudentViaCourse?.gradeText != "N/A") ||
                    calculateGradeOfStudentViaCourse?.gradeText === 0
                      ? typeof calculateGradeOfStudentViaCourse?.gradeText ==
                          "string" &&
                        calculateGradeOfStudentViaCourse?.gradeText.includes(
                          "%"
                        )
                        ? Math.round(
                            parseFloat(
                              calculateGradeOfStudentViaCourse?.gradeText.replace(
                                "%",
                                ""
                              )
                            )
                          ).toString() + "%"
                        : typeof calculateGradeOfStudentViaCourse?.gradeText ==
                            "string" &&
                          !calculateGradeOfStudentViaCourse?.gradeText.includes(
                            "%"
                          )
                        ? Math.round(
                            parseFloat(
                              calculateGradeOfStudentViaCourse?.gradeText
                            )
                          ).toString() + "%"
                        : typeof calculateGradeOfStudentViaCourse?.gradeText ===
                          "number"
                        ? calculateGradeOfStudentViaCourse?.gradeText + "%"
                        : calculateGradeOfStudentViaCourse?.gradeText
                        ? calculateGradeOfStudentViaCourse?.gradeText
                        : "N/A"
                      : "N/A"}
                    <p className="m-0 mr-2 ml-2">
                      {
                        RenderQuizAttemptResultStatus(
                          calculateGradeOfStudentViaCourse?.pass_status
                        ).html
                      }
                    </p>
                  </>
                ) : (
                  <>
                    {calculateGradeOfStudentViaCourse?.gradeText}
                    <p className="m-0 mr-2 ml-2">
                      {
                        RenderQuizAttemptResultStatus(
                          calculateGradeOfStudentViaCourse?.pass_status
                        ).html
                      }
                    </p>
                  </>
                )}
                {
                   programmeDetails.subject_type == "shortcourse" || !programmeDetails.qualification ?
                  <button
                   className="btn btn-primary rounded-circle"
                   title="Download SoR PDF"
                   data-toggle="modal"
                   data-target="#downloadSoRModal"
                   onClick={() => {
                    setIsShowFinal(programmeDetails.show_final_results);
                    setIsExcludeGrade(programmeDetails.show_empty_grades);
                   }}
                   // onClick={() => handlepdfData()}
                 >
                   {pdfDownloading ? (
                     <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                   ) : (
                     <i className="fal fa-download download-SoR-icon"></i>
                   )}
                 </button> : ""
                }
             
              </div>
            )}
          </div>
        </div>
        {assessmentList?.length && isCourseEdited ? (
          <GradeBookSaveBar
            handleClick={() => handleSaveGrades()}
            saveGradesLoading={saveGradesLoading}
          />
        ) : (
          <></>
        )}
        <div
          className="topic-add-modal modal fade"
          id="downloadSoRModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            className="modal-600 modal-dialog modal-dialog-centered modal-m"
            role="document"
          >
            <div className="modal-content modal-border-update sor">
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-download"></i> Statement of Results
                    Download Options
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#downloadSoRModal"
                    onClick={() => {
                      setIsChecked(false);
                      setIsShowFinal(false);
                      setIsExcludeGrade(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-17px">
                  <i className="fal fa-cog SoR-icon"></i>
                  <span className="SoR-settings"> Settings</span>
                  <div className="toogles-inline mt-3 d-flex align-items">
                    {/* <div className="d-flex align-items mr-3">
                      <label className="mb-0">Show Assessments</label>
                      <div className="toggle-switch ml-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span className="slider slider-round"></span>
                        </label>
                      </div>
                    </div> */}
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Show Final Results</label>
                      <div className="toggle-switch ml-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isShowFinal}
                            onChange={handleShowFinalToggle}
                          />
                          <span className="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Exclude Empty Grades</label>
                      <div className="toggle-switch ml-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isExcludeGrade}
                            onChange={handleExcludeFinal}
                          />
                          <span className="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group form-group-save-cancel mt-4">
                    <button
                      className="btn btn-save btn-success"
                      type="submit"
                      title="Save"
                      onClick={() => handlepdfData()}
                    >
                      <i className="fal fa-download"></i> Download SoR
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                    >
                      <i
                        className="fal fa-times"
                        onClick={() => {
                          setIsChecked(false);
                          setIsShowFinal(false);
                          setIsExcludeGrade(false);
                          $("#downloadSoRModal").modal("hide");
                        }}
                      ></i>{" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentGrades;
