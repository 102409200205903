  import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AssessmentForm from "./AssessmentForm";
import SubmissionAndResults from "./SubmissionAndResults";
import SingleAssessmentAuditTrail from "./SingleAssessmentAuditTrail";
import SubmissionAndResultForm from "./SubmissionAndResultForm";
import { GlobalIconNames } from "../../../../utils/GlobalIconNames";
import GradeMixSubmissionAndResults from "./GradeMixSubmissionAndResults";
import PermissionsGate from "../../../../utils/permissionGate";
import AssessmentMarking from "./AssessmentMarking";

const AssessmentTabs = ({assName, lmType, gradingStandard}) => {
  const { id, subId, openTabs, subType } = useParams();
  const history = useHistory();

  return (
    <>
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
          <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/Details/${subId}`)}>
            <a className={`nav-link cursor-pointer ${openTabs === "Details" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
              {GlobalIconNames("details").html}
            </a>
          </li>
          <PermissionsGate scopes={["casubmissionresultview"]} >
          <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}`)}>
            <a
              className={`nav-link cursor-pointer ${openTabs === "submissionsAndResults" ? "active" : ""}`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-books"></i>Submissions and Results
            </a>
          </li>
          </PermissionsGate>
          {(openTabs === "assessmentMarking" || openTabs === "assessmentMarkingReview") && <li className="nav-item">
            <a
              className={`nav-link cursor-pointer ${openTabs === "assessmentMarking" || openTabs === "assessmentMarkingReview" ? "active" : ""}`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-clipboard-check"></i>Assessment Marking
            </a>
          </li>}
          <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/SingleAssessmentAuditTrail/${subId}`)}>
            <a
              className={`nav-link cursor-pointer ${openTabs === "SingleAssessmentAuditTrail" ? "active" : ""}`}
              id="pills-tab3"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab13"
              aria-selected="true"
            >
              {GlobalIconNames("audittrail").html}
            </a>
          </li>
        </ul>
        {/* <div className="card card-profile-info-card mb-30">
              <div className="card-body">
                <div className="card-body-inr card-body-info"> */}
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
            {openTabs == "Details" && <AssessmentForm lmType={lmType} gradingStandard={gradingStandard}/>}
          </div>
          <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
            {openTabs == "submissionsAndResults" ? subType == "update" ? <SubmissionAndResultForm /> : gradingStandard == 2 ? <GradeMixSubmissionAndResults assName={assName}/> : <SubmissionAndResults assName={assName} /> : ""}
          </div>
          <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
            {openTabs == "assessmentMarking" || openTabs === "assessmentMarkingReview" ? <AssessmentMarking subId={subId} /> : ""}
          </div>
          <div className="tab-pane fade active show" id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
            {openTabs == "SingleAssessmentAuditTrail" && <SingleAssessmentAuditTrail />}
          </div>
        </div>
        {/* </div></div></div> */}
      </div>
      {/* </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default AssessmentTabs;
