import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Link, useHistory, useLocation } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  GetAllDropdownValues,
  reRegistrationList,
  reRegistrationDelete,
} from "../../../services/RegistrationService";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import Tablefilter from "../../common/Tablefilter";
import { RenderSchool } from "../../../utils/CommonGroupingItem";
import { RenderAssessmentStatus } from "../../../utils/CommonStatusItems";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { GetArticulationRegistrationsListFilterCancelToken, GetArticulationsRegistrationsListCancelToken, articulationRegistrationDelete, articulationsRegistrationsList } from "../../../services/programArticulationService";
import { getArticulationRegistrationsListFilter } from "../../../services/programArticulationService";
import PermissionsGate from "../../../utils/permissionGate";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";

const ListArticulations = () => {

  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [intakeArr, setIntakeArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [deleteRow, setDeleteRow] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );
  const [schoolArr, setSchoolArr] = useState([]);
  const [searchSchoolCheck, setSearchSchoolCheck] = useState({});
  const [intake, setIntake] = useState({ arr: [], checkObj: {} })
  const [status, setStatus] = useState({ arr: [], checkObj: {} })
  const [school, setSchool] = useState({ arr: [], checkObj: {} })
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("start_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [resetFilterData, setResetFilterData] = useState([]);
  const [debouncedTerm, setDebouncedTerm] = useState(search);

  useEffect(() => {
    handleTableScroll()
  }, [loading])
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateArticulations"));
    if (storedFilters) {
      setStatus(storedFilters.status);
      setIntake(storedFilters.intake);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      intake
    };
    localStorage.setItem("filterStateArticulations", JSON.stringify(filterState));
  }, [  status, intake]);

  useEffect(() => {
    setPage(1);
  }, [location]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 500);
    return () => {
        clearTimeout(timer);
    }
}, [debouncedTerm])

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true)

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        page: page,
        limit: perPage,
        viaIntake: intake.arr,
        key: sortkey,
        sort: sortOrder,
        viaStatus: status.arr,
        search: search,
      };

      try {
        const res = await GetArticulationsRegistrationsListCancelToken(data, source.token);
        setStudentList(res?.data?.data && res?.data?.data?.data ? res?.data?.data?.data : []);
        setTotalRows(res.data?.data.total);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [deleteRow,page,perPage,sortkey,sortOrder,status,intake,search]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetArticulationRegistrationsListFilterCancelToken(dropdownSource.token);
        setIntakeArr(res?.data?.yearsAndIntaksFilter);
        setStatusArr(res?.data?.statusFiltes)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentList;
    if (search.length) {
      // let tempFirstname = allData.filter((item) => {
      //   let includes =
      //     item.academy_type &&
      //     item.academy_type.toLowerCase().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempLastname = allData.filter((item) => {
        let includes =
          item.description &&
          item.description.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes =
          item.intake_year.toString().toLowerCase() &&
          item.intake_year
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentId = allData.filter((item) => {
        let includes =
          item.start_date &&
          moment
            .unix(item.start_date)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSchool = allData.filter((item) => {
        let includes =
          item.end_date &&
          moment
            .unix(item.end_date)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStatus = allData.filter((item) => {
        let includes =
          item.status &&
          item.status.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempLastname,
        ...tempEmail,
        ...tempStudentId,
        ...tempSchool,
        ...tempStatus,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (school.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = school.arr.find(function (user, index) {
          if (
            user.toLowerCase() ===
            (item.academy_type ? item.academy_type.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }

    if (intake.arr.length) {
      let tempStatus = updatedData;
      let tempIntakeYear = tempStatus.filter((item) => {
        const startsIntakeYear = intake.arr.find(function (post, index) {
          if (post === (item.intake_year && item.intake_number ? item.intake_year + "/" + item.intake_number : "")) return item;
        });
        if (startsIntakeYear) {
          return startsIntakeYear;
        } else return null;
      });

      updatedData = tempIntakeYear;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          let status = item.reg_status ? item.reg_status.toLowerCase() : "";
          if (post.toLowerCase() === status) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }
    if (
      search.length ||
      school.arr.length ||
      status.arr.length ||
      intake.arr.length
    ) {
      return updatedData;
    } else {
      return studentList;
    }
  };

  function getValue(arrName, label) {
    let labelName = "";
    for (let item of dropdownValues[arrName]) {
      if (item.value.toString() == label.toString()) {
        labelName = item.label;
        break;
      }
    }

    if (labelName === "" && arrName === "re_registration_status") return label;
    return labelName;
  }

  const columns = useMemo(() => [
    {
      name: "Description",
      selector: "description",
      sortField: "description",
      sortable: true,
      cell: (row) => (
          <Link
            className="as-text-blue curser feature-name"
            title={row.description}
            to={`/courseAdministration/articulation/open-articulation-registration/open/${row.id}?description=${row.description}`}
          >
            <span className="textLimit100">{row.description}</span>
          </Link>
      ),
    },
    // {
    //   name: "School",
    //   selector: "academy_type",
    //   sortable: true,
    //   cell: (row) => row.academy_type ? <span className="feature-name"><span className="textLimit100">{RenderSchool(row.academy_type).html} </span></span> : "-"
    // },
    {
      name: "Intake",
      selector: "intake_year",
      sortField: "intaketYearType",
      sortable: true,
      cell: (row) => row.intake_year + "-" + row.intake_number,
    },
    {
      name: "Start",
      selector: "start_date",
      sortField: "start_date",
      sortable: true,
      cell: (row) => (
        <>
          {row.start_date ? (
            <div className="">
              <p className="right-space">
                {row.start_date.toString().length == 10
                  ? moment.unix(row.start_date).format(TABLE_DATE_FORMAT)
                  : moment(parseInt(`${row.start_date}`)).format(TABLE_DATE_FORMAT)}
              </p>
              {/* <p>
                {row.start_date.toString().length == 10
                  ? moment.unix(row.start_date).format(TABLE_TIME_FORMAT)
                  : moment(parseInt(`${row.start_date}`)).format(TABLE_TIME_FORMAT)}
              </p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end_date",
      sortField: "end_date",
      sortable: true,
      cell: (row) => (
        <>
          {row.end_date ? (
            <div className="">
              <p className="right-space">
                {row.end_date.toString().length == 10
                  ? moment.unix(row.end_date).format(TABLE_DATE_FORMAT)
                  : moment(parseInt(`${row.end_date}`)).format(TABLE_DATE_FORMAT)}
              </p>
              {/* <p>
                {row.end_date.toString().length == 10
                  ? moment.unix(row.end_date).format(TABLE_TIME_FORMAT)
                  : moment(parseInt(`${row.end_date}`)).format(TABLE_TIME_FORMAT)}
              </p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Hard Launch",
      selector : "hard_launch_date",
      sortField : "hard_launch_date",
      sortable : true,
      cell: (row) => (
        <>
          {row.hard_launch_date ? (
            <div className="">
              <p className="right-space">
                {row.hard_launch_date.toString().length == 10
                  ? moment.unix(row.hard_launch_date).format(TABLE_DATE_FORMAT)
                  : moment(parseInt(`${row.hard_launch_date}`)).format(TABLE_DATE_FORMAT)}
              </p>
              {/* <p>
                {row.end_date.toString().length == 10
                  ? moment.unix(row.end_date).format(TABLE_TIME_FORMAT)
                  : moment(parseInt(`${row.end_date}`)).format(TABLE_TIME_FORMAT)}
              </p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => row.status ? RenderAssessmentStatus(row.status).html : "-",
    },
    {
      name: "Students",
      selector: "totalStudent",
      sortField: "totalStudent",
      sortable: false,
      cell: (row) => row.totalStudent != null && row.totalStudent != undefined ? row.totalStudent : "-",
    },
    {
      name: "Students Interested",
      selector: "interestedStudent",
      sortField: "interestedStudent",
      sortable: false,
      cell: (row) => row.interestedStudent != null && row.interestedStudent != undefined ? row.interestedStudent : "-",
    },

    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08  btn-dropdown-grp">
          <div className="as-buttons d-flex">
              <Link
                to={`/courseAdministration/articulation/open-articulation-registration/open/${row.id}?description=${row.description}`}
                title="Open"
                className="btn btn-primary rounded-circle"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            <PermissionsGate
              scopes={["cpardelete"]}
              errorProps={{ disabled: true }}
            >
              <button
                title="Delete"
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          articulationRegistrationDelete(ID).then((res) => {
            setDeleteRow(!deleteRow);
          });

          Swal.fire("Deleted!", "Articulation has been deleted.", "success");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setDeleteRow(!deleteRow);
        history.push("/administration/brandSetting/list");
      });
  };

  function resetFilter() {
    setSearch("");
    setSchool({ arr: [], checkObj: {} })
    setIntake({ arr: [], checkObj: {} })
    setStatus({ arr: [], checkObj: {} })
  }

  const handleFilters = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Description",
      "Intake",
      "Start",
      "End",
      "Hard Launch",
      "Status",
      "Students",
      "Students Interested",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const detail = {
      exportStatus: "true",
      page: page,
      limit: perPage,
      viaIntake: intake.arr,
      key: sortkey,
      sort: sortOrder,
      viaStatus: status.arr,
      search: search,
    };
     articulationsRegistrationsList(detail)
      .then((res) => {
        data = res?.data?.data && res?.data?.data.length ? res?.data?.data : []
        data = data?.map((row) => ({
          ...row,
          // School: row.academy_type ? getValue("academy", row.academy_type) : "-",
          Description: row?.description ? row?.description : "-",
          Intake: row.intake_year
            ? row.intake_year + "-" + row.intake_number
            : "-",
          "Start": row.start_date
            ? moment.unix(row.start_date).format(TABLE_DATE_FORMAT)
            : "-",
          "End": row.end_date
            ? moment.unix(row.end_date).format(TABLE_DATE_FORMAT)
            : "-",
          "Hard Launch": row.hard_launch_date
            ? moment.unix(row.hard_launch_date).format(TABLE_DATE_FORMAT)
            : "-",
          Status: row.status ? row.status : "-",
          Students: row.totalStudent != null && row.totalStudent != undefined ? row.totalStudent : "-",
          "Students Interested": row.interestedStudent != null && row.interestedStudent != undefined? row.interestedStudent : "-",

        }));
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close()
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close()
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Description"],
              row["Intake"],
              row["Start"],
              row["End"],
              row["Hard Launch"],
              row["Status"],
              row["Students"],
              row["Students Interested"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close()
          return false;
        }
      })
      .catch((error) => {
        console.log("error =-=--> ", error);
        setLoading(false);
      });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">

        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={e => setDebouncedTerm(e.target.value)}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName={"Status"}
                      optionArr={statusArr}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderAssessmentStatus}
                    />
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={intakeArr}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Reset"
                      onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Articulation List");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Articulation List");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Articulation List");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  scopes={["cparadd"]}
                  RenderError={() => <button title="Create New" className="btn btn-primary" disabled><i className="fal fa-plus"></i>Create New</button>}
                >
                  <Link
                    to={`/courseAdministration/articulation/open-articulation-registration/add`}
                    title="Create New"
                    className="btn btn-primary"
                  >
                    <i className="fal fa-plus"></i>Create New
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
          <DataTable
            data={studentList}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            defaultSortField={"start_date"}
            defaultSortAsc={false}
            columns={columns}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
      </div>
    </div>
  );
};

export default ListArticulations;
