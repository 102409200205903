import React, { createRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import Select from "react-select";
import { GetCampusDropdown, GetCampusDropdownCancelToken, addResourceManagement } from "../../../services/StudentService";
import PermissionsGate from "../../../utils/permissionGate";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "../../../assets/css/filePond.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { FORM_DATE_FORMAT } from "../../../utils/Constants";
import hasPermission from "../../../utils/hasMultiplePermission";
import AddResourceNewDesign from "./AddResourceNewDesign";
import { GetListOfBrandSetting, GetListOfBrandSettingCancelToken } from "../../../services/BrandServices";
import axios from "axios";


registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);


const AddResource = () => {
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);
    const filePondRef = createRef();
    const [files, setFiles] = useState([]);
    const [thumbnailFiles, setThumbnailFiles] = useState([]);
    const [typeArr, setTypeArr] = useState([]);
    const [condition, setCondition] = useState([]);
    const [campus, setCampus] = useState([]);
    const [brands, setBrands] = useState([]);
    const [language, setLanguage] = useState([]);
    const [thumbnailFile, setThumbnailFile] = useState([])
    const [isDisabled, setIsDisabled] = useState(false);
    const [keywordsValues, setKeywordValues] = useState([]);
    const [hashValues, setHashValues] = useState([]);
    const [filePreview, setFilePreview] = useState();

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sresourcemadd"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    const CommonDropdownData = useSelector(state => state.registerDropdownValues && Object.keys(state.registerDropdownValues).length ?
        state.registerDropdownValues : {});

    function setDropdownData() {
        if (Object.keys(CommonDropdownData).length) {
            if (CommonDropdownData.LibraryResourceType) {
                setTypeArr(CommonDropdownData?.LibraryResourceType);
                if (CommonDropdownData?.LibraryResourceType.filter((data) => (data.value == "Book")).length) {
                    setValues({ ...values, type: "Book" })
                }
            }
            if (CommonDropdownData.BookConditionsType) {
                setCondition(CommonDropdownData?.BookConditionsType)
            }
            if (CommonDropdownData.language) {
                setLanguage(CommonDropdownData?.language)
            }
            // if (CommonDropdownData.campus) {
            //     setCampus(CommonDropdownData.campus)
            // }
            setIsLoaded(true);
        }
    }

    useEffect(() => {
        setDropdownData();
        const dropdownSource = axios.CancelToken.source();
      
        const fetchData = async () => {
          try {
            const res = await GetListOfBrandSettingCancelToken(dropdownSource.token);
            const x = res.data?.brand_setting_list.map((data) => {
                return {                  
                  value: `${data.id}`,
                  label: `${data.brand_name}`,                  
                };
            });
            setBrands(x)
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
            }
          }
        };
      
        fetchData();
      
        return () => {
          dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    
    const fetchData = async () => {
        try {
        const res = await GetCampusDropdownCancelToken(dropdownSource.token);
        setCampus(res.data.dropdown)
        } catch (error) {
        if (!axios.isCancel(error)) {
            console.error(error);
        }
        }
    };
    
    fetchData();
    
    return () => {
        dropdownSource.cancel('Component unmounted');
    };
    }, []);

    const ResourceValidation = Yup.object({
        title: Yup.string().required("Title is required").max(255, "Title is too big"),
        brand: Yup.array().min(1, "At least one linked brand is required").required(),
        keywords: Yup.array().when("type", {
            is: (type) => type,
            then: Yup.array().required("Keyword value is required")
        }),
        type: Yup.string().required("Type is required"),

        document: Yup.mixed().test("required", "Document is required", function (value) {
            let type = this.parent.type
            if (type !== "document" && type !== "pdf") {
                return true
            }
            return files.length ? true : false
        }),

        textBody: Yup.string().when("type", {
            is: (type) => type == "Article" || type == "article" || type == "Label",
            then: Yup.string().required("Content is required")
        }),

        // condition: Yup.string().when("type", {
        //     is: (type) => type == "Book",
        //     then: Yup.string().required("Condition is required"),
        // }),

        videoId: Yup.string().when("type", {
            is: (type) => type == "vimeo",
            then: Yup.string().required("Video Id is required"),
        }),

        // Distributor: Yup.string().when("type", {
        //     is: (type) => type == "Book" || type == "ebook",
        //     then: Yup.string().required("Distributor is required")
        // }),

        url: Yup.string().when("type", {
            is: (type) => type == "link" || type == "white_paper" || type == "Youtube" || type == "ebook",
            then: Yup.string().required("URL is required"),
        }),
    })

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            type: "",
            dewey: "",
            author: "",
            barcode: "",
            title: "",
            subtitle: "",
            condition: "",
            conditionLabel: "",
            isbn: "",
            place: "",
            date: "",
            language: "",
            publisher: "",
            series: "",
            campus: "",
            campusLabel: "",
            hash: hashValues,
            description: "",
            keywords: keywordsValues,
            topic: [],
            duration: "",
            videoId: "",
            url: "",
            published: false,
            featured: false,
            TimeLimitHours: "00h",
            TimeLimitMinutes: "0m",
            TimeLimitSeconds: "0s",
            textBody: "",
            thumbnail: "",
            thumbnail_link: "",
            thumbnail_type: 0,
            document: [],
            brand:[],
            Distributor: "",
        },
        validationSchema: ResourceValidation,
        onSubmit: (values) => {
            setIsDisabled(true)
            let date = values.date

            if (values.date) {
                date = new Date(date).getTime().toString() / 1000;
            }
            values = { ...values }
            const data = new FormData();
            data.append("thumbnail_type", values.thumbnail_type);
            if(values.thumbnail_type==0 && thumbnailFiles.length > 0){
                data.append("thumbnail", thumbnailFiles[0].file)
            }
            if(values.thumbnail_type==1){
                data.append("thumbnail", values.thumbnail_link)
            }
            data.append("topic", values.topic.join(","))
            data.append("keywords", values.keywords.join(","))
            data.append("hash", values.hash.join(","))
            data.append("sub_title", values.subtitle)
            data.append("type", values.type)
            data.append("title", values.title)
            data.append("description", values.description)
            data.append("author", values.author)
            data.append("brand", values.brand)
            // data.append("hash", values.hash)
            if (values.type == "Book") {
                data.append("publisher", values.publisher)
                data.append("dewey", values.dewey)
                data.append("place", values.place)
                data.append("barcode", values.barcode)
                data.append("isbn", values.isbn)
                data.append("series", values.series)
                data.append("publish_date", date)
                data.append("language", values.language)
                data.append("published", values.published == true ? 1 : 0)                
                data.append("campus", values.campus)
                data.append("book_condition", values.condition)
                data.append("Distributor", values.Distributor) 
            }
            if(values.type == "ebook"){                
                data.append("publisher", values.publisher)
                // data.append("dewey", values.dewey)
                data.append("place", values.place)
                data.append("barcode", values.barcode)
                data.append("isbn", values.isbn)
                data.append("series", values.series)
                data.append("publish_date", date)
                data.append("language", values.language)
                data.append("published", values.published == true ? 1 : 0)
                data.append("url", values.url)                
                data.append("Distributor", values.Distributor) 
            }
            if(values.type == "link" || values.type == "Link"){
                data.append("publisher", values.publisher)
                data.append("dewey", values.dewey)
                data.append("place", values.place)
                data.append("barcode", values.barcode)
                data.append("isbn", values.isbn)
                data.append("series", values.series)
                data.append("publish_date", date)
            }
            if (values.type == "vimeo") {
                data.append("url", "https://player.vimeo.com/video/")
                data.append("video_id", values.videoId)
                data.append("published", values.published == true ? 1 : 0)
                data.append("featured", values.featured == true ? 1 : 0)
                // data.append("hours", values.TimeLimitHours)
                // data.append("minutes", values.TimeLimitMinutes)
                data.append("duration", `${values.TimeLimitHours} ${values.TimeLimitMinutes} ${values.TimeLimitSeconds}`)
                data.append("language", values.language)
            }
            if (values.type == "link" || values.type == "white_paper") {
                data.append("url", values.url)
                data.append("published", values.published == true ? 1 : 0)
                data.append("language", values.language)
            }
            if (values.type == "Document" || values.type == "pdf") {
                data.append("published", values.published == true ? 1 : 0)
                data.append("language", values.language)
                files.length && data.append("document", files[0].file)
            }
            if (values.type == "Youtube") {
                data.append("published", values.published == true ? 1 : 0)
                data.append("url", values.url)
            }
            if (values.type == "Article" || values.type == "article") {
                data.append("text_body", values.textBody)
                data.append("published", values.published == true ? 1 : 0)
                data.append("language", values.language)
            }
            if (values.type == "Label") {
                data.append("text_body", values.textBody)
                data.append("published", values.published == true ? 1 : 0)
            }
            addResourceManagement(data)
                .then((res) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                        onClose: goToListing
                    });
                })
                .catch((err) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${err?.response?.data?.data}`,
                    });
                });
        },
    });

    const { values, errors, touched, setValues, setFieldValue, handleChange, handleSubmit, handleBlur } = formik;


    const goToListing = () => {
        history.push(`/support/student-hub-library/resource-management/table`)
    }

    function addDays(date, days) {
        var date = new Date(date);
        date.setDate(date.getDate() + days);
        return date;
    }

    const handleKeyDown = (event) => {
        let hash = values.hash;
        if (event.key === "Enter") {
            if (event.target.value) {
                hash.push(event.target.value);
                event.target.value = ""
                setHashValues(hash)
                setFieldValue("hash", hash);
            }

        }
    };

    const handleKeyDown1 = (event) => {
        let keywords = values.keywords;
        if (event.key === "Enter") {
            if (event.target.value) {
                keywords.push(event.target.value);
                event.target.value = ""
                setKeywordValues(keywords)
                setFieldValue("keywords", keywords);
            }

        }
    };


    const removeHash = (index) => {
        let keyword = values.hash;
        let keywords = keyword.splice(index, 1)
        setHashValues(keyword)
        setFieldValue("hash", keyword);
    };

    const removeKeywords = (index) => {
        let keyword = [];
        let keywords = keyword.splice(index, 1)
        setKeywordValues(keyword)
        setFieldValue("keywords", keyword);
    };


    return (
        <>
            <AddResourceNewDesign
                isLoaded={isLoaded}
                values={values}
                errors={errors}
                touched={touched}
                setValues={setValues}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                removeHash={removeHash}
                removeKeywords={removeKeywords}
                typeArr={typeArr}
                handleKeyDown1={handleKeyDown1}
                language={language}
                files={thumbnailFile}
                setFiles={setThumbnailFile}
                setThumbnailFiles={setThumbnailFiles}
                thumbnailFiles={thumbnailFiles}
            />
            <div className="card card-profile-info-card">
                <div className="card-body-inr card-body-info">
                    <div className="my-tickets-info-list Tickets-main-wrap">
                        <div className="edit-icon new-card-header">
                            <div className="card-header">Additional Information</div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk">
                                        <label>Linked Brands*</label>
                                        <Select
                                            className={"form-control color-drop custom-select-box is-multi" + (errors.brand && touched.brand ? " is-invalid" : "")}
                                            name="topic"
                                            value={brands.filter(val => values.brand.includes(val.value))}
                                            onChange={(value) => {
                                                let tempArr = []
                                                if (value?.length) {
                                                    value.map(item => {
                                                        tempArr.push(item.value)
                                                    })
                                                }
                                                setFieldValue("brand", tempArr);
                                            }}
                                            placeholder={values.brand.length ? values.brand : "Select..."}
                                            onBlur={handleBlur}
                                            options={brands}
                                            maxMenuHeight={175}
                                            isClearable
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                                {/* Book and link Section  */}

                                {(values.type == "Book" || values.type == "link" || values.type == "ebook") &&
                                    <>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>Publisher</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        className={"form-control" + (errors.publisher && touched.publisher ? " is-invalid" : "")}
                                                        name="publisher"
                                                        placeholder="Publisher"
                                                        title="Publisher"
                                                        value={values.publisher}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {values.type=="Book" && <div className="col-md-6 col-lg-3">
                                            <div className="form-group" >
                                                <label>Campus</label>
                                                <div className="form-icon-group mb-4">
                                                    <div className="form-control custom-select-box">

                                                        <Select
                                                            className={"form-control custom-select-box" + (errors.campus && touched.campus ? " is-invalid" : "")}
                                                            name="campus"
                                                            value={campus?.filter(val => val.value === values.campus)}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setValues({ ...values, campus: value.value, campusLabel: value.label })
                                                                } else {
                                                                    setValues({ ...values, campus: "", campusLabel: "" })
                                                                }
                                                            }}
                                                            placeholder={values.campusLabel ? values.campusLabel : "Select..."}
                                                            onBlur={handleBlur}
                                                            options={campus}
                                                            maxMenuHeight={175}
                                                            isClearable
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {values.type!="ebook" && <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>Dewey</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        className={"form-control" + (errors.dewey && touched.dewey ? " is-invalid" : "")}
                                                        name="dewey"
                                                        placeholder="Dewey"
                                                        title="Dewey"
                                                        value={values.dewey}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>Pub Location</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        className={"form-control" + (errors.place && touched.place ? " is-invalid" : "")}
                                                        name="place"
                                                        placeholder="Pub Location"
                                                        title="Pub Location"
                                                        value={values.place}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group" >
                                                <label>Bar Code</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        className={"form-control" + (errors.barcode && touched.barcode ? " is-invalid" : "")}
                                                        name="barcode"
                                                        placeholder="Bar Code"
                                                        title="Bar Code"
                                                        value={values.barcode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>ISBN</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        className={"form-control" + (errors.isbn && touched.isbn ? " is-invalid" : "")}
                                                        name="isbn"
                                                        placeholder="ISBN"
                                                        title="ISBN"
                                                        value={values.isbn}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>Series</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        className={"form-control" + (errors.series && touched.series ? " is-invalid" : "")}
                                                        name="series"
                                                        placeholder="Series"
                                                        title="Series"
                                                        value={values.series}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {values.type=="Book" && <div className="col-md-6 col-lg-3">
                                            <div className="form-group" >
                                                <label>Condition</label>
                                                <div className="form-icon-group mb-4">
                                                    <div className="form-control custom-select-box">
                                                        <Select
                                                            className={"form-control custom-select-box" + (errors.condition && touched.condition ? " is-invalid" : "")}
                                                            name="condition"
                                                            value={condition?.filter(val => val.value === values.condition)}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setValues({ ...values, condition: value.value, conditionLabel: value.label })
                                                                } else {
                                                                    setValues({ ...values, condition: "", conditionLabel: "" })
                                                                }
                                                            }}
                                                            placeholder={values.conditionLabel ? values.conditionLabel : "Select..."}
                                                            onBlur={handleBlur}
                                                            options={condition}
                                                            maxMenuHeight={175}
                                                            isClearable
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>Pub Date</label>
                                                <DatePicker
                                                    autoComplete="off"
                                                    selected={values.date}
                                                    onChange={(date) => {
                                                        setFieldValue("date", date);
                                                    }}
                                                    name="date"
                                                    dateFormat={"yyyy"}
                                                    showYearPicker
                                                    dateFormatCalendar="MMMM"
                                                    className={
                                                        "form-control cursor-pointer" +
                                                        (errors.date &&
                                                            touched.date
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    title="Pub Date"
                                                    placeholderText="Pub Date"
                                                    timeIntervals={15}
                                                    onChangeRaw={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    minDate={addDays(values.startDate, 1)}
                                                />
                                            </div>
                                        </div>
                                        {(values.type=="Book" || values.type == "ebook") && <>
                                            <div className="col-md-6 col-lg-3">
                                                <label>Keywords*</label>
                                                <div className="form-icon-group">
                                                    <div className="form-control custom-select-box">
                                                        <input
                                                            type="text"
                                                            className={
                                                                "form-control " +
                                                                (errors.keywords && touched.keywords
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            name="keywords"
                                                            placeholder="keywords"
                                                            title="keywords"
                                                            onBlur={handleBlur}
                                                            onKeyDown={handleKeyDown1}
                                                        />
                                                    </div>
                                                </div>
                                                {values.keywords.length > 0 && (
                                                    <ul className="tag-list">
                                                        {values.keywords?.map((ele, index) => (
                                                            <li>
                                                                {ele}
                                                                <span onClick={() => removeKeywords(index)}>
                                                                    <i className="fal fa-times"></i>
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            {values.type == "ebook" && <div className="col-md-6 col-lg-3">
                                                <div className="form-group">
                                                    <label>URL*</label>
                                                    <div className="form-icon-group mb-4">
                                                        <input
                                                            type="text"
                                                            title="Url"
                                                            className={"form-control " + (errors.url && touched.url ? " is-invalid" : "")}
                                                            placeholder="URL"
                                                            name="url"
                                                            value={values.url}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}
                                        </>}
                                    </>
                                }
                                {/* Document Section */}
                                {(values.type == "Document" || values.type == "pdf") &&
                                    <>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Keywords*</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">
                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.keywords && touched.keywords
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="keywords"
                                                        placeholder="keywords"
                                                        title="keywords"
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown1}
                                                    />
                                                </div>
                                            </div>
                                            {values.keywords.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.keywords?.map((ele, index) => (
                                                        <li>
                                                            {ele}
                                                            <span onClick={() => removeKeywords(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Upload Document*</label>
                                            <div className="">
                                                <div className={"drag_card_btn" + (errors.document && touched.document
                                                    ? " file-pond-req is-invalid"
                                                    : "")}>
                                                    <FilePond
                                                        name="post"
                                                        allowMultiple={true}
                                                        maxFiles={1}
                                                        ref={filePondRef}
                                                        files={files}
                                                        onupdatefiles={(e) => {
                                                            setFiles(e);
                                                            formik.setFieldValue("document", e && e.length && e[0].file ? e[0].file : [])
                                                            if (e.length) {
                                                                let reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    setFilePreview(e.target.result);
                                                                };
                                                                reader.readAsDataURL(e[0].file);
                                                            }
                                                        }}
                                                        allowFileTypeValidation={true}
                                                        // onaddfile={(err, fileItem) => {
                                                        //     if (err) {
                                                        //         setFiles([])
                                                        //     } else {
                                                        //         setShowFilePond(true);
                                                        //     }
                                                        // }}
                                                        acceptedFileTypes={['application/pdf']}
                                                    />
                                                </div>
                                                {files && files[0] && filePreview && (
                                                    <div className="frm-group">
                                                        <ul className="list-unstyled attached-file-ul m-0">
                                                            <li>
                                                                <a href="#" onClick={() => {
                                                                    var type = 'application/pdf';
                                                                    let blob = null;
                                                                    const blobURL = URL.createObjectURL(pdfBlobConversion(filePreview, 'application/pdf'));
                                                                    const theWindow = window.open(blobURL);
                                                                    const theDoc = theWindow.document;
                                                                    const theScript = document.createElement('script');
                                                                    function injectThis() {
                                                                        window.print();
                                                                    }
                                                                    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
                                                                    theDoc.body.appendChild(theScript);
                                                                    function pdfBlobConversion(b64Data, contentType) {
                                                                        contentType = contentType || '';
                                                                        var sliceSize = 512;
                                                                        b64Data = b64Data.replace(/^[^,]+,/, '');
                                                                        b64Data = b64Data.replace(/\s/g, '');
                                                                        var byteCharacters = window.atob(b64Data);
                                                                        var byteArrays = [];

                                                                        for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
                                                                            var slice = byteCharacters.slice(offset, offset + sliceSize);

                                                                            var byteNumbers = new Array(slice.length);
                                                                            for (var i = 0; i < slice.length; i++) {
                                                                                byteNumbers[i] = slice.charCodeAt(i);
                                                                            }

                                                                            var byteArray = new Uint8Array(byteNumbers);

                                                                            byteArrays.push(byteArray);
                                                                        }

                                                                        var blob = new Blob(byteArrays, { type: contentType });
                                                                        return blob;
                                                                    }
                                                                }}>
                                                                    <i className="fal fa-file"></i>
                                                                    &nbsp;{files && files[0] ? files && files[0]?.file?.name : ""}
                                                                </a>
                                                                <button className="btn btn-danger" onClick={() => {
                                                                    setFilePreview()
                                                                    setFiles([])
                                                                }} type={"button"}>
                                                                    <i className="fal fa-trash-alt"></i>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Hash</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">
                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.hash && touched.hash
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="hash"
                                                        placeholder="Hash"
                                                        title="Hash"
                                                        // value={valueView}
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                </div>
                                            </div>
                                            {values.hash.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.hash?.map((ele, index) => (
                                                        <li>
                                                            {ele}
                                                            <span onClick={() => removeHash(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </>
                                }

                                {/* Link Section and White Paper Section */}
                                {(values.type == "link" || values.type == "white_paper") &&
                                    <>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Keywords*</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">
                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.keywords && touched.keywords
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="keywords"
                                                        placeholder="keywords"
                                                        title="keywords"
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown1}
                                                    />
                                                </div>
                                            </div>
                                            {values.keywords.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.keywords?.map((ele, index) => (
                                                        <li>
                                                            {ele}
                                                            <span onClick={() => removeKeywords(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>URL*</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        title="Url"
                                                        className={"form-control " + (errors.url && touched.url ? " is-invalid" : "")}
                                                        placeholder="URL"
                                                        name="url"
                                                        value={values.url}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Hash</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">

                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.hash && touched.hash
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="hash"
                                                        placeholder="Hash"
                                                        title="Hash"
                                                        // value={valueView}
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                </div>
                                            </div>
                                            {values.hash.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.hash?.map((ele, index) => (
                                                        <li>
                                                            {ele}
                                                            <span onClick={() => removeHash(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </>
                                }

                                {/* Article, Vimeo and Label Section */}
                                {(values.type == "Article" || values.type == "article" || values.type == "Label" || values.type == "vimeo" || values.type == "Youtube") &&
                                    <>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Keywords*</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">
                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.keywords && touched.keywords
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="keywords"
                                                        placeholder="keywords"
                                                        title="keywords"
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown1}
                                                    />
                                                </div>
                                            </div>
                                            {values.keywords.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.keywords?.map((ele, index) => (
                                                        <li>
                                                            {ele}
                                                            <span onClick={() => removeKeywords(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Hash</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">

                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.hash && touched.hash
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="hash"
                                                        placeholder="Hash"
                                                        title="Hash"
                                                        // value={valueView}
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                </div>
                                            </div>
                                            {values.hash.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.hash?.map((ele, index) => (
                                                        <li>
                                                            {ele}
                                                            <span onClick={() => removeHash(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-3">
                                    <div className="d-flex">
                                        <div className="form-group mr-3 mt-4">
                                            <div className="custom-control custom-checkbox text-left">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="published"
                                                    name="published"
                                                    checked={values.published}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <label className="custom-control-label" htmlFor="published">
                                                    Published{(values.type == "vimeo" || values.type == "link" || values.type == "white_paper") && "?"}
                                                </label>
                                            </div>
                                        </div>
                                        {(values.type == "vimeo" || values.type == "Youtube") && <div className="form-group mt-4">
                                            <div className="custom-control custom-checkbox text-left">
                                                <input type="checkbox" className="custom-control-input" id="featured" name="featured" checked={values.featured} onChange={handleChange} onBlur={handleBlur} />
                                                <label className="custom-control-label" htmlFor="featured">
                                                    Featured?
                                                </label>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group-save-cancel mt-4">
                                <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                                    <button className="btn btn-save btn-success" type="button" onClick={handleSubmit} title="Submit" >
                                        {isDisabled === false ? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                                        Submit
                                    </button>
                                </PermissionsGate>
                                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                                    <i className="fal fa-times"></i>
                                    Cancel
                                </button>
                            </div>

                            <div className="form-group form-group-save-cancel">
                                {Object.keys(errors).length ? Object.keys(errors).map((key) => (
                                    touched[key] && errors[key] && (
                                        <div className="invalid-feedback d-block">
                                            {errors[key]}
                                        </div>
                                    )
                                )) : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddResource;
