import React, { useEffect, useRef } from "react";

const DisplayCardPreview = ({
  videoUrl,
  scrollingText,
}) => {
  const marqueeRef = useRef();



  useEffect(() => {
    if (marqueeRef.current && scrollingText) {
      // Duplicate the text to ensure smooth scrolling
      marqueeRef.current.innerHTML = scrollingText + scrollingText 


      // Animate the scrolling text
      const keyframes = [
        { transform: "translateX(0%)" },
        { transform: "translateX(-200%)" },
      ];

      marqueeRef.current.animate(keyframes, {
        duration: 10000, // Adjust speed as needed
        iterations: Infinity,
      });
    }
  }, [scrollingText]);

  return (
    <div className="display-card">
      <div className="preview-title">Preview:</div>
      <div className="video-container">
        {videoUrl ? (
          <video
            src={videoUrl}
            autoPlay
            muted
            loop
            className="video-element"
          />
        ) : (
          <div className="no-video">No video added</div>
        )}
        {scrollingText && (
          <div className="scrolling-text-bg">
          <div ref={marqueeRef} className="scrolling-text">
            {scrollingText}
          </div>
          </div>
        )}
      </div>  
    </div>
  );
};

export default DisplayCardPreview;
