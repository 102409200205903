// this component holds the functionality of editing the already created booking
// the main component which is responsible here is the BookingForm
// the BookingForm.js holds another component called the BookingConfirmation which is in BookingConfirmation.js
// here we are trying the use the same BookingForm.js for both creating and editing the booking.
// we have tried to make the code as easy as possible to understand

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import BookingForm from './BookingForm';
import { useState, useEffect } from 'react';
import React from 'react';
import {
  GetBookingById,
  UpdateBooking,
} from '../../../services/BookingServices';
import Swal from 'sweetalert2';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import PermissionsGate from '../../../utils/permissionGate';
export default function EditBooking() {
  const { id } = useParams();

  const [bookingDetails_, setBookingDetails_] = useState({});
  const [changedBookingLogs, setChangedBookingLogs] = useState({
    bookingStatus: false,
    bookingInfoChanged: false,
  });
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState("PENDING APPROVAL");
  // const history = useHistory();

  // this function is used to immediately update the booking confirmation logs
  // since this is a parent class and BookingConfirmation.js is an ancestor of this class
  // we have to use callback to return the values from the BookingConfirmation.js to EditBooking.js
  // Structure of the code is like this EditBooking.js --> BookingForm.js ---> BookingConfirmation.js

  const handleBookingConfirmationChanges = (statusChange, infoChange) => {
    setChangedBookingLogs({
      changedBookingLogs: statusChange,
      bookingInfoChanged: infoChange,
    });
  };

  const handleStatusChanged = (updatedStatus_) => {

    if (updatedStatus_ === 'approve') {
      setUpdatedStatus('APPROVED');
    }
    else if (updatedStatus_ === 'decline') {
      setUpdatedStatus('REJECTED');
    }
    else {
      setUpdatedStatus('PENDING APPROVAL')
    }
  }
 
  // this effect works when the id or the booking info or the booking status gets changed
  // the reason to keep like this was since we want to update the booking logs as soon the status changes
  // since the booking logs comes under this api
  useEffect(() => {
    const getBookingDetails = async () => {
      try {
        const response = await GetBookingById(id);
        const bookingDetails = response?.data?.booking;
        const booking_logs = response?.data?.booking_logs;

        setBookingDetails_(bookingDetails);
        setLogs(booking_logs);
        setLoading(true);
      } catch (error) {
        console.log('Error', error);
      }
    };
    getBookingDetails();
  }, [
    id,
    changedBookingLogs.bookingInfoChanged,
    changedBookingLogs.bookingStatus,
  ]);

  // here the initialValues are kept like this to autopopulate the booking form with the required values

  const initialValues = bookingDetails_ && {
    name: bookingDetails_?.name || '',
    campus: bookingDetails_?.campus || '',
    campus_id: bookingDetails_?.campus_id || 0,
    room_no: bookingDetails_?.room_no || '',
    booking_date: new Date(bookingDetails_?.booking_date || ''),
    booking_start_time: bookingDetails_?.booking_start_time
      ? new Date(`2000-01-01T${bookingDetails_?.booking_start_time}`)
      : new Date(),
    booking_end_time: bookingDetails_?.booking_end_time
      ? new Date(`2000-01-01T${bookingDetails_?.booking_end_time}`)
      : new Date(),
    linked_course: bookingDetails_?.linked_course || '',
    linked_course_id: bookingDetails_?.linked_course_id || 0,
    booking_reason: bookingDetails_?.booking_reason || '',
    request_type: bookingDetails_?.request_type || '',
    booking_for: bookingDetails_?.booking_for || '',
    additional_information: logs.length > 0 && logs[logs.length - 1]?.additional_info || '',
    booking_status: bookingDetails_?.booking_status || '',
    room_id: bookingDetails_?.room_id || 0,
  };

  // this is the main function that is responsible for editing the form
  const editBooking = async (values) => {
    try {
      const {
        name,
        campus,
        campus_id,
        room_no,
        booking_date,
        booking_start_time,
        booking_end_time,
        linked_course,
        linked_course_id,
        booking_reason,
        request_type,
        booking_for,
        additional_information,
        room_id,
      } = values;

      const formattedDate = moment(booking_date).format('YYYY-MM-DD');
      const formattedStartTime = moment(booking_start_time, 'HH:mm').format(
        'HH:mm:ss'
      );
      const formattedEndTime = moment(booking_end_time, 'HH:mm').format(
        'HH:mm:ss'
      );

      const formData = new FormData();

      formData.append('name', name);
      formData.append('campus', campus);
      formData.append('campus_id', campus_id);
      formData.append('room_no', room_no);
      formData.append('room_id', room_id);
      formData.append('booking_date', formattedDate);
      formData.append('booking_start_time', formattedStartTime);
      formData.append('booking_end_time', formattedEndTime);
      formData.append('linked_course', linked_course);
      formData.append('linked_course_id', linked_course_id);
      formData.append('booking_reason', booking_reason);
      formData.append('request_type', request_type);
      formData.append('booking_for', booking_for);
      formData.append('additional_information', additional_information);
      formData.append('booking_status', updatedStatus);

      await UpdateBooking(id, formData);
      Swal.fire({
        title: 'Success',
        text: 'Booking updated Successfully',
        icon: 'success',
      });
      // history.push('/campusAdministration/bookings/table');
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Unable to update booking',
        icon: 'error',
      });
      console.log('Error', error);
    }
  };

  return (
    <>
        {loading && (
          <BookingForm
            initialValues={initialValues}
            onSubmit={editBooking}
            showApprovalRequest={true}
            log_data={logs ?? []}
            onChange={(statusChanged, bookingLogsChanged) =>
              handleBookingConfirmationChanges(
                statusChanged,
                bookingLogsChanged
              )
            }
          onUpdatedStatus = {(status) => handleStatusChanged(status)}
          />
        )}
    </>
  );
}
