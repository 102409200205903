import React, { useEffect, useMemo, useState } from "react";
import { useHistory} from "react-router";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {GetDisplayGroups, DeleteDisplayGroup} from "../../../services/SmartSignageService";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";

const ListOfLayouts = () => {
    
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [displayGroups, setDisplayGroups] = useState([]);
    const [search, setSearch] = useState("");
    const [dataToRender, setDataToRender] = useState([]);
    const [reloadDisplays, setReloadDisplays] = useState(false);
    useEffect(() => {
        const getDisplays = async () => {
            setLoading(true);
            const response = await GetDisplayGroups();
            if(response?.data?.data){
                // console.log("response",response?.data?.data)
                setDisplayGroups(response?.data?.data);
                setLoading(false);
            }
        }
        getDisplays();
    }, [])
    useEffect(() => {
        const getDisplays = async () => {
            setLoading(true);
            const response = await GetDisplayGroups();
            if(response?.data?.data){
                // console.log("response",response?.data?.data)
                setDisplayGroups(response?.data?.data);
                setLoading(false);
            }
        }
        getDisplays();
    }, [reloadDisplays])
    useEffect(() => {
        if (displayGroups.length > 0 ) {
            // Filter out items not selected by displayConnected filter
            const filteredItems = displayGroups.filter((item) => {
                return ((search.length == 0 || item.name.toLowerCase().includes(search.toLowerCase()))); // Compare as strings
            });
            setDataToRender(filteredItems);
        } else {
            setDataToRender(displayGroups);
        }
    }, [displayGroups, search]);
    
    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // Delete function
                DeleteDisplayGroup(id).then((res) => {
                    Swal.fire("Deleted!", "Your display has been deleted.", "success");
                    setReloadDisplays(!reloadDisplays);
                })
                .catch((error) => Swal.fire("Error!", error, "error"));
            }
        });
    };
    
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link to={`/campusAdministration/smartsignage/displaygroups/edit/${row.id}`}>
        <span title={row.name} className="as-text-blue curser feature-name">
          <span className="textLimit100"  data-toggle="modal" data-target="#programmeDateForm" >{row.name}</span>
        </span>
        </Link>
      ),
    },
    {
      name: "Linked Layout",
      selector: "layout_name",
      sortField: "layout_name",
      sortable: true,
      cell: (row) => (
        <span title={row.layout_name} >{row.layout_name || "-"}</span>
      ),
    },
    {
        name: "Linked Displays",
        selector: "displays",
        sortField: "displays",
        sortable: true,
        cell: (row) => {
            let groups = row.displays ? row.displays.split(",") : [];
            return (
                <>
                <ul>
                    {groups.length == 0 ? "-" : groups.map((group, index) => (
                        <li key={index} title={group}>
                            {group}
                        </li>
                    ))}
                </ul>
                </>
            );
        },
    },
    {
        name: "Actions",
        selector: "actions",
        width: "100px",
        cell: (row) => (
            <>
            <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignuserview"]} >
                <button
                    type="button"
                    className="btn btn-primary rounded-circle"
                    title="Edit"
                    onClick={() => history.push(`/campusAdministration/smartsignage/displaygroups/edit/${row.id}`)}
                >
                    <i className="fal fa-edit"></i> 
                </button>
                </PermissionsGate>
                <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignuserdelete"]} >
                <button
                    type="button"
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.id)}
                >
                    <i className="fal fa-trash"></i> 
                </button>
                </PermissionsGate>
                </div>
                </div>
            </>
        ),
    },
  ], []);

    return (         
        <>
        {loading ? (
            <SkeletonTicketList />
        ) : 
        <DataTableComponentFrontPagination
        isActionAlignRight={true}
        data={dataToRender}
        columns={columns}
        loading={loading}
        defaultSort={'name'}
        search={search}
        setSearch={setSearch}
        isExport={false}
        tableButton={[
            <Link to={`/campusAdministration/smartsignage/displaygroups/add`}>
            <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignuseradd"]} >
            <button
            type="button"
            className="btn btn-primary"
            title="Create new"
            >
            <i className="fal fa-plus"></i>Create New
            </button>
            </PermissionsGate>
            </Link>
        ]}
      />
    }
    </>
)

}

export default ListOfLayouts;