import React, { useEffect, useState } from "react";
import { useFormik, FieldArray, FormikProvider, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { FileUploadField, FormField } from "../../../common/FormFields";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { getFromEmails } from "../../../../services/BrandTemplateServices";
import {
  addInfoPackPage,
  addUpcomingEventPage,
  getInfoPackDetails,
  getUpcomingEventDetails,
  updateInfoPackPage,
  updateUpcomingEventPage,
} from "../../../../services/EventService";
import types from "react-scrollbars-custom";
import AssignedEvents from "./AssignedEvents";
import PermissionsGate from "../../../../utils/permissionGate";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";


const InfoPacksDetails = ({ setHeaderName }) => {
  const { subId, subTab,type } = useParams();
  const history = useHistory();
  const [eventData, setEventData] = useState({});
  const [isOverrideBrand,setIsOverrideBrand] = useState([])
  const [eventsData, setEventsData] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [emailsOptions, setEmailsOptions] = useState([]);
  const [headerContent, setHeaderContent] = useState("");
  const [footerContent, setFooterContent] = useState("");
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    let response = hasPermission({
      scopes: ["wbseventinfopview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }

    if ((subId && (subTab == "open" || subTab == "update")) && response ) {
      setLoading(true);
      const fetchEventData = async () => {
        try {
          const res = await getInfoPackDetails(subId);
          const event = res.data.Data;
          setEventData(event);
          setHeaderName(event?.from_title);
          setHeaderContent(event?.header || "");
          setFooterContent(event?.footer || "");
           localStorage.setItem("headerName", event?.from_title);
           await getFromEmails()
           .then(res => {
               if (res.status === 200) {
                   setEmailsOptions(res?.data);
               }
           })
          setLoading(false);
        } catch (error) {
          console.error(error);
          if (error.response.data?.message !== "") {
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push(`/websiteManagement/events/info_packs/table`)
          }
          setLoading(false);
        }
      };
      fetchEventData();
    }
  }, [subId]);

  const initialValues = {
    name : eventData.name || "",
    from_title: eventData.from_title || "",
    from_email: eventData.from_email || "",
    subject: eventData.subject || "",
    isOverrideBrand: eventData.isOverrideBrand || 0,
    tags: eventData.tags || "",
    header: eventData.header || "",
    footer: eventData.footer || "",
    attachment: eventData.attachment || "",
    events: eventsData?.map((item) => item.EventId) || [],
  };

  const validationSchema = Yup.object().shape({

    name: Yup.string()
      .required("Email name is required")
      .trim()
      .nullable(),
    // from_email: isOverrideBrand ? Yup.string()
    //   .required("From email is required")
    //   .email("Invalid email format") 
    //   .trim()
    //   .nullable() : "",
    subject: Yup.string()
      .required("Email subject Title is required")
      .trim()
      .nullable(),
    // tags: Yup.string()
    //   .required("Email Tags Title is required")
    //   .trim()
    //   .nullable(),
    header: Yup.string().required("Email content is required"),
  });

  const formik = useFormik({
    enableReinitialize: subTab == "open" ? true : false,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if(values.isOverrideBrand == 0){
        values.from_title = '';
        values.from_email = '';
    }
      values.events = eventsData?.map((item) => item.EventId) || [] 
      try {
        const formData = new FormData();
        // formData.set('events',eventsData?.map((item) => item.EventId) || []) 
        if(values.events.length == 0){
          formData.append("events",JSON.stringify(values.events))
        }
        // const eventArr3 = eventsData.map(d => d.EventId)
        // formData.append("events", eventArr3);
        // const trimmedValues = Object.fromEntries(
        //   Object.entries(values).map(([key, value]) => [
        //     key,
        //     typeof value === "string"
        //       ? value.trim().replace(/ +/g, " ")
        //       : value,
        //   ])
        // );
        // Object.keys(trimmedValues).forEach((key) => {
        //   if (Array.isArray(trimmedValues[key])) {
        //     trimmedValues[key].forEach((val, idx) => {
        //       formData.append(`${key}[${idx}]`, val);
        //     });
        //   } else {
        //     formData.append(key, trimmedValues[key]);
        //   }
        // }); 
        const cleanedValues = Object.fromEntries(
          Object.entries(values).map(([key, value]) => [
            key,
            value === undefined || value === null
              ? ''
              : typeof value === 'string'
                ? value.trim().replace(/ +/g, ' ')
                : value,
          ])
        );
  
        // Append cleaned values to FormData
        Object.keys(cleanedValues).forEach((key) => {
          if (key != 'header' && key != 'footer') {
          if (Array.isArray(cleanedValues[key])) {
            cleanedValues[key].forEach((val, idx) => {
              formData.append(`${key}[${idx}]`, val === undefined || val === null ? '' : val);
            });
          } else {
            formData.append(key, cleanedValues[key]);
          }
        }
        });
        formData.append("header", headerContent);
        formData.append("footer", footerContent);
        if (subTab === "create") {
          
          await addInfoPackPage(formData);
          Swal.fire("Success!", "Email info created successfully!", "success");
          history.push("/websiteManagement/events/info_packs/table");
        } else if (subTab == "open") {
          formData.append("id", subId);
          await updateInfoPackPage(formData);
          Swal.fire("Success!", "Email info updated successfully!", "success");
        }
        // history.push(`/websiteManagement/events/info_packs/email_details/open/${subId}`);
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${subTab === "create" ? "create" : "update"} Email info`,
          "error"
        );
        console.error(error);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setIsOverrideBrand(formik.values.isOverrideBrand == 1)
  },[formik.values.isOverrideBrand])

  return (
    <div className="card card-body-inr">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header">Email Details {loading && <i className="fa fa-cog fa-spin" />}</h4> 
              </div>
            </div>
            <FormField
              field={{ name: "name", label: "Name" }}
              formik={formik}
              col_lg={4}
              col_md={4}
            />
            <FormField
              field={{ name: "subject", label: "Email Subject" }}
              formik={formik}
              col_lg={4}
              col_md={4}
            />
            <div className="col-md-4 col-lg-4 d-flex align-items-center" title="Override Brand From Email & Title">
              <label className="mb-0">
                Override Brand From Email & Title{" "}
                <i className="fal fa-info-circle"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={formik.values?.isOverrideBrand === 1}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "isOverrideBrand",
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                  <span className="slider slider-round"></span>
                </label>
              </div>
            </div>
            {formik.values?.isOverrideBrand === 1 && <> 
            {/* <FormField
              field={{ name: "from_email", label: "From Email" }}
              formik={formik}
              col_lg={4}
              col_md={4}
            /> */}
            <div className="col-md-6 col-lg-4">
                            <div className="form-group-blk mb-4" title="Email address used to send emails from the system, ex. email used for email sentto user when filling in form">
                            <label>System Sent Email From Address * <i className="fal fa-info-circle"></i></label>
                            <Select
                            className={
                                "form-control custom-select-box" +
                                (formik.errors.from_title && formik.touched.from_title ? " is-invalid" : "")
                            }
                            name="from_title"
                            value={
                                formik.values.from_title !== ""
                                ? emailsOptions.find((val) => val?.value == formik.values.from_title) 
                                : ""
                            }
                            onChange={(option) => {
                                formik.setFieldValue("from_title", option ? option.value : "");
                            }}
                            onBlur={formik.handleBlur}
                            options={emailsOptions}
                            maxMenuHeight={175}
                            placeholder={"Select Email"}
                            isClearable
                            />
                            
                            </div>
                            </div>   
            </>}
            <div className="col-md-12 col-lg-12">
              <label htmlFor={"tags"} title="Useful Tags">
                Useful Tags <i className="fal fa-info-circle"></i>
              </label>
              <div className="form-icon-group mb-4">
                <textarea
                  type="text"
                  rows={5}
                  className={`form-control ${
                    formik.touched.tags && formik.errors.tags && "is-invalid"
                  }`}
                  name={"tags"}
                  id={"tags"}
                  title={"Useful Tags"}
                  value={formik.values["tags"]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-12" title="Email Content">
              <label htmlFor={"disclaimer_tc"}>Email Content *</label>
              <div className="form-icon-group mb-6 laptop-pricing">
                <HtmlInputEditor
                  editorState={headerContent}
                  setEditorState={setHeaderContent}
                  isInValid={formik.errors.header}
                  isCKEditor={true}
                  hideSign={true}
                />{loading && <i className="fa fa-cog fa-spin" />}
              </div>
            </div>
            <div className="col-md-12" title="Email footer">
              <label htmlFor={"disclaimer_tc"}>Email footer</label>
              <div className="form-icon-group mb-6 laptop-pricing">
                <HtmlInputEditor
                  editorState={footerContent}
                  setEditorState={setFooterContent}
                  isCKEditor={true}
                  hideSign={true}
                />{loading && <i className="fa fa-cog fa-spin" />}
              </div>
            </div>

            <FileUploadField
              isMulti={true}
              name="attachment"
              label="Email Attachment"
              formik={formik}
              // accept=".png,.jpg,.jpeg"
            />
            <div className="col-md-12">
              <hr
                className="border border-2"
                style={{ borderColor: "#E6EBF1" }}
              />
            </div>
          </div>
          <AssignedEvents
            subId={subId}
            eventsData={eventsData}
            setEventsData={setEventsData}
          />
          <PermissionsGate scopes={[subTab == "create" ? 'wbseventinfopadd' : 'wbseventinfopedit']} RenderError={() => (
            <button type="submit" className="btn btn-primary" disabled>
            <i className="fal fa-save"></i>
            Save
          </button>
          )}>

          <div className="form-group form-group-save-cancel mt-4">
            <button type="submit" className="btn btn-primary">
              <i className="fal fa-save"></i>
              Save
            </button>
          </div>
          </PermissionsGate>
          {Object.keys(formik.errors).map((key) => {
            if (formik.touched[key]) {
              return (
                <div className="invalid-feedback d-block" key={key}>
                  {formik.errors[key]}
                </div>
              );
            }
            return null;
          })}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default InfoPacksDetails;




