import React, { useEffect, useState } from "react";
import Select from "react-select";

function CoreModuleForm(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    setparentSubjectReSubLength1,
    parentSubjectReSubLength1,
    reRegSubject,
    selectedData,
    progressionData,
    modulesData
  } = props;

  const [fields, setFields] = useState([{ subject: "" }]);
  const [fields1, setFields1] = useState([{ subject: "" }]);
  let formatted = []
  let obj = {}

  const renderOptions = (data) => {
    const options = data.map(field => {
      return {
        label: field?.name,
        value: field?.netsuite_product_id
      };
    })
    return options;
  }
  const getLabel = (value,data) => {
    const options = data.map(field => {
      return {
        label: field?.name,
        value: field?.netsuite_product_id
      };
    })
    const selected = options.find(d => d.value == value);
    return selected;
  }

  function handleAdd(key){
    const values = [...parentSubjectReSubLength[key]]
    values.push("");
    setFields(values);
    setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [key]: values
    });
  }

  function handleRemove(i,flag=0,key){
    const values = [...parentSubjectReSubLength[key]]
      if (values.length > 1) {
        values.splice(i, 1);
        setFields(values);
        props.setparentSubjectReSubLength({
          ...parentSubjectReSubLength,
          [key] : values
        });
      }
  }
  function handleSubjectChange(i, selectedOption, key) {
    // Ensure that the array for the key exists before modifying
    const values = [...(parentSubjectReSubLength[key] || [])];  // Default to an empty array if it's undefined
    
    // Update the value at the given index (i) with the selectedOption's value
    values[i] = selectedOption ? selectedOption.value : null;  // Using label instead of value
  
    // Update the state and formik field
    setFields(values);
    props.setparentSubjectReSubLength({
      ...parentSubjectReSubLength,
      [key]: values,
    });
  
    // Update Formik field value, storing label as the value
    formik.setFieldValue(`core_arr[${i}]`, selectedOption ? selectedOption.value : null);
  }
  
  

  useEffect(() => {
    const selected = JSON.parse(selectedData)
    const convertedData = selected.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      acc[key] = curr[key];
      return acc;
    }, {});
    
    let updatedData = {}
    modulesData.map((d,i) => {
      updatedData[d.key] = [""]
    })
    props.setparentSubjectReSubLength(convertedData)
  },[])
  
  const getTotalCredits = (array) => {
    let totalCredits = 0;
    const totality = array.map((d,i) => {
      totalCredits += d.credits;
      if(i == array.length - 1) return ` (${totalCredits} Credits)`
    })
    return totality;
  }
  
  return (
    <>
            <h5 className="heading-5 mt-30"> Core Modules</h5>
            {modulesData.map((d, moduleIdx) => (
            <div key={moduleIdx} className="mb-2 mt-3">
              <b>{d.name}</b> 
              {getTotalCredits(d.modules.modules)}
              <span>
                <b className="credits-outstanding ml-2">Credits Outstanding: {d.modules.credits_outstanding || d.modules["credits outstanding"]}</b>
              </span>
              {(formik.values.core_arr[d.key] || [""]).map((item, idx) => (
                <div key={idx} className="row align-items-center">
                  <div className="col-sm-6 mt-2">
                  <div className="form-icon-group">
                  <Select
                      className="form-control custom-select-box "
                      name={`subjectReSub_[${idx}].subject`}
                      onChange={(selectedOption) => handleSubjectChange(idx, selectedOption, d.key)}  // Handle the selection change
                      value={parentSubjectReSubLength[d.key] && parentSubjectReSubLength[d.key][idx] 
                        ? getLabel(parentSubjectReSubLength[d.key][idx],d.modules.modules)
                        : null}// Bind value safely, defaulting to null if undefined
                      isClearable
                      onBlur={formik.handleBlur}
                      options={renderOptions(d.modules.modules)}  // Ensure this function returns options in { value, label } format
                      maxMenuHeight={175}
                  />
                  </div>


                    {formik.touched.subjectReSub_ &&
                    formik.errors.subjectReSub_ &&
                    formik.touched.subjectReSub_[idx] &&
                    formik.errors.subjectReSub_[idx] &&
                    formik.touched.subjectReSub_[idx].subject &&
                    formik.errors.subjectReSub_[idx].subject ? (
                      <div className="invalid-feedback" style={{ display: "block" }}>
                        {formik.errors.subjectReSub_[idx].subject}
                      </div>
                    ) : null}
                  </div>
                  <button className="btn btn-danger rounded-circle" title="Delete"
                      onClick={() => handleRemove(idx,moduleIdx,d.key)}
                  >
                      <i className="fal fa-trash-alt"></i>
                  </button>
                </div>
              ))}
              {(formik.values.core_arr[d.key]?.length != d.modules.modules.length) && <div className="form-group m-0">
                <a
                  href="#"
                  className="btn btn-link p-0 btn-additional"
                  onClick={() => handleAdd(d.key)}
                >
                  Add additional line item <i className="fal ml-2 fa-plus"></i> 
                </a>
              </div>}
            </div>
          ))}
      
    </>
  );
}

export default CoreModuleForm;
