import { useFormik } from 'formik'
import React, { useState } from 'react'
import { IMAGE_URL } from '../../../../utils/Constants'
import { TrimText } from '../../../common/TrimText'

function CriteriaSubmission({ openTabs }) {
  const [previewPhotos, setPreviewPhotos] = useState([])
  const [previewVideos, setPreviewVideos] = useState([])
  const [previewDocument, setPreviewDocument] = useState([])
  const [accordionStates, setAccordionStates] = useState({
    photo: false,
    video: false,
    document: false
  });

  const formik = useFormik({
    initialValues: {
      photos: [],
      videos: [],
      document: [],
      uploadFeedback: "",
      lecturer: openTabs == "assessmentMarking" ? 1 : 0,
      feedback: ""
    },
    onSubmit: (values, action) => {
      console.log("Submitted values:", values)
    }
  });

  const handleUpload = (event, type) => {
    const files = Array.from(event.target.files);

    const photoFiles = files.filter(file => file.type.startsWith('image'));
    const videoFiles = files.filter(file => file.type.startsWith('video'));
    const documentFiles = files.filter(file => file.type.startsWith('application/pdf'));

    if (photoFiles.length > 0) {
      const updatedPhotos = [...formik.values.photos, ...photoFiles];
      formik.setFieldValue('photos', updatedPhotos);
      const previewPhotoURLs = photoFiles.map(file => URL.createObjectURL(file));
      setPreviewPhotos(prevState => [...prevState, ...previewPhotoURLs]);
    }

    if (videoFiles.length > 0) {
      const updatedVideos = [...formik.values.videos, ...videoFiles];
      formik.setFieldValue('videos', updatedVideos);
      const previewVideoURLs = videoFiles.map(file => URL.createObjectURL(file));
      setPreviewVideos(prevState => [...prevState, ...previewVideoURLs]);
    }

    if (documentFiles.length > 0) {
      const updatedDocument = [...formik.values.document, ...documentFiles];
      formik.setFieldValue('document', updatedDocument);
      const previewVideoURLs = documentFiles.map(file => URL.createObjectURL(file));
      setPreviewDocument(prevState => [...prevState, ...previewVideoURLs]);
    }
  };

  const handleDelete = (index, type) => {
    if (type === 'photo') {
      const updatedPhotos = [...formik.values.photos];
      updatedPhotos.splice(index, 1);
      formik.setFieldValue('photos', updatedPhotos);

      const updatedPreviews = [...previewPhotos];
      updatedPreviews.splice(index, 1);
      setPreviewPhotos(updatedPreviews);
    } else if (type === 'video') {
      const updatedVideos = [...formik.values.videos];
      updatedVideos.splice(index, 1);
      formik.setFieldValue('videos', updatedVideos);

      const updatedPreviews = [...previewVideos];
      updatedPreviews.splice(index, 1);
      setPreviewVideos(updatedPreviews);
    } else if (type === 'document') {
      const updatedDocument = [...formik.values.document];
      updatedDocument.splice(index, 1);
      formik.setFieldValue('document', updatedDocument);

      const updatedPreviews = [...previewDocument];
      updatedPreviews.splice(index, 1);
      setPreviewDocument(updatedPreviews);
    }
  };

  const toggleAccordion = (section) => {
    setAccordionStates(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  return (
    <>
      <div>
        <h5 title="Evidence for Criteria Name 1">Evidence for Criteria Name 1</h5>
        <p title="This is the description of the criteria if there is one.">This is the description of the criteria if there is one.</p>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* Photo Evidence Accordion */}
          <div className="col-12">
            <div className="accordion ac_over_none" id="accordionExample">
              <div className="card-main">
                <div className="card-top">
                  <div className="card-header1" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link align-items-center"
                        type="button"
                      >
                        <div className='evidence_Type' title="Photo Evidence">
                          <i className="fal fa-camera mr-1"></i> Photo Evidence <i className="fal fa-check-circle" style={{ color: "#5CB85C" }}></i>
                        </div>

                        <div className="d-flex">
                          {openTabs == "assessmentMarking" && <div className="form-group atttach-file mr-1">
                            <label
                              className={formik.errors.photos && formik.touched.photos && " file-req is-invalid"}
                              title="Upload Files"
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Upload Files</span>
                              <input
                                type="file"
                                id="photos"
                                className="form-control form-control-aatch-file"
                                name="photos"
                                accept="image/*"
                                multiple
                                onChange={(event) => handleUpload(event, 'photo')}
                              />
                            </label>
                          </div>}

                          <div className="header-status" onClick={() => toggleAccordion('photo')}>
                            <div title={accordionStates.photo ? 'up' : 'down'}>
                              <i className={`fal fa-chevron-${accordionStates.photo ? 'up' : 'down'} rotate-icon`} style={{ color: "#467FCF" }}></i>
                            </div>
                          </div>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className={`collapse mt-2 ${accordionStates.photo ? 'show' : ''}`}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="row">
                      {previewPhotos.map((fileURL, index) => (
                        <div key={index} className="col-md-6">
                          <div className="card card-body border-0">
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li
                                  title={formik.values.photos[index]?.name || fileURL.split("/").pop()}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  <div className="file-info">
                                    <a href={fileURL} target="_blank" rel="noopener noreferrer">
                                      <i className="fal fa-file"></i>
                                      &nbsp;
                                      {formik.values.photos[index]?.name || TrimText(fileURL.split("/").pop(), 40)}
                                    </a>
                                  </div>

                                  <div>
                                    <a
                                      href={fileURL}
                                      download
                                      className="btn btn-primary rounded-circle btn-dropdown-item"
                                      title="Download"
                                      style={{ marginRight: 42 }}
                                    >
                                      <i className="fal fa-download"></i>
                                    </a>

                                    <button
                                      type="button"
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleDelete(index, 'photo')}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <div className="frm-group">
                              <img src={fileURL} alt="Uploaded" className="w-100" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          {/* Video Evidence Accordion */}
          <div className="col-12">
            <div className="accordion ac_over_none" id="accordionExample">
              <div className="card-main">
                <div className="card-top">
                  <div className="card-header1" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link"
                        type="button"
                      >
                        <div className='evidence_Type' title="Video Evidence">
                          <i className="fal fa-video mr-1"></i> Video Evidence <i className="fal fa-check-circle" style={{ color: "#5CB85C" }}></i>
                        </div>

                        <div className="d-flex">
                          {openTabs == "assessmentMarking" && <div className="form-group atttach-file mr-1">
                            <label
                              className={formik.errors.videos && formik.touched.videos && " file-req is-invalid"}
                              title="Upload Files"
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Upload Files</span>
                              <input
                                type="file"
                                id="videos"
                                className="form-control form-control-aatch-file"
                                name="videos"
                                accept="video/*"
                                multiple
                                onChange={(event) => handleUpload(event, 'video')}
                              />
                            </label>
                          </div>}

                          <div className="header-status" onClick={() => toggleAccordion('video')}>
                            <div title={accordionStates.video ? 'up' : 'down'}>
                              <i className={`fal fa-chevron-${accordionStates.video ? 'up' : 'down'} rotate-icon`} style={{ color: "#467FCF" }}></i>
                            </div>
                          </div>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className={`collapse mt-2 ${accordionStates.video ? 'show' : ''}`}
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="row">
                      {previewVideos.map((fileURL, index) => (
                        <div key={index} className="col-md-4">
                          <div className="card card-body border-0">
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li
                                  title={formik.values.videos[index]?.name || fileURL.split("/").pop()}
                                >
                                  <a href={fileURL} target="_blank" rel="noopener noreferrer">
                                    <i className="fal fa-file"></i>
                                    &nbsp;
                                    {formik.values.videos[index]?.name || TrimText(fileURL.split("/").pop(), 40)}
                                  </a>

                                  <div>
                                    <a
                                      href={fileURL}
                                      download
                                      className="btn btn-primary rounded-circle btn-dropdown-item"
                                      title="Download"
                                    >
                                      <i className="fal fa-download"></i>
                                    </a>

                                    <button
                                      type="button"
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleDelete(index, 'video')}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <div className="frm-group">
                              <video controls className="w-100">
                                <source src={fileURL} type="video/*" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          {/* Document Evidence Accordion */}
          <div className="col-12">
            <div className="accordion ac_over_none" id="accordionExample">
              <div className="card-main">
                <div className="card-top">
                  <div className="card-header1" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link"
                        type="button"
                      >
                        <div className='evidence_Type' title="Document Evidence">
                          <i className="fal fa-file-alt mr-1"></i> Document Evidence <i className="fal fa-check-circle" style={{ color: "#5CB85C" }}></i>
                        </div>

                        <div className="d-flex">
                          {openTabs == "assessmentMarking" && <div className="form-group atttach-file mr-1">
                            <label
                              className={formik.errors.document && formik.touched.document && " file-req is-invalid"}
                              title="Upload Files"
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Upload Files</span>
                              <input
                                type="file"
                                id="document"
                                className="form-control form-control-aatch-file"
                                name="document"
                                accept=".pdf"
                                multiple
                                onChange={(event) => handleUpload(event, 'document')}
                              />
                            </label>
                          </div>}

                          <div className="header-status" onClick={() => toggleAccordion('document')}>
                            <div title={accordionStates.document ? 'up' : 'down'}>
                              <i className={`fal fa-chevron-${accordionStates.document ? 'up' : 'down'} rotate-icon`} style={{ color: "#467FCF" }}></i>
                            </div>
                          </div>
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className={`collapse mt-2 ${accordionStates.document ? 'show' : ''}`}
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="row">
                      {previewDocument.map((fileURL, index) => (
                        <div key={index} className="col-md-4">
                          <div className="card card-body border-0">
                            <div className="frm-group">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li
                                  title={formik.values.document[index]?.name || fileURL.split("/").pop()}
                                >
                                  <a href={fileURL} target="_blank" rel="noopener noreferrer">
                                    <i className="fal fa-file"></i>
                                    &nbsp;
                                    {formik.values.document[index]?.name || TrimText(fileURL.split("/").pop(), 40)}
                                  </a>

                                  <div>
                                    <a
                                      href={fileURL}
                                      download
                                      className="btn btn-primary rounded-circle btn-dropdown-item"
                                      title="Download"
                                    >
                                      <i className="fal fa-download"></i>
                                    </a>

                                    <button
                                      type="button"
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleDelete(index, 'document')}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div className="col-md-6">
            <div className="d-flex" title="Confirm Lecturer Observation for Criteria">
              <label className={formik.values.lecturer == 1 ? "mb-0" : "mb-3"}>
                Confirm Lecturer Observation for Criteria
              </label>
              <div className="toggle-switch ml-2">
                <label className="switch">
                  <input
                    name='lecturer'
                    id='lecturer'
                    type="checkbox"
                    checked={formik.values?.lecturer == 1}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "lecturer",
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                  <span className="slider slider-round"></span>
                </label>
              </div>
            </div>
            {formik.values.lecturer == 1 && <p style={{ marginTop: 15 }} title="Confirmed on 29 Jan 24 at 13:05 by Portal Developers"><i className="fal fa-history" style={{ color: "var(--topbar-color)" }}></i> Confirmed on 29 Jan 24 at 13:05 by Portal Developers</p>}
          </div>

          {/* Feedback Section */}
          <div className="col-12">
            <div className="form-group-blk mb-3" title="Feedback">
              <label htmlFor="feedback">Feedback</label>
              <input
                type="text"
                className="form-control"
                placeholder="Criteria Feedback"
                name="feedback"
                id="feedback"
                onChange={formik.handleChange}
                value={formik.values.feedback}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {openTabs != "assessmentMarking" && <div className="col-12 mb-3">
            <div className="form-group atttach-file mr-1">
              <label className={formik.errors.uploadFeedback && formik.touched.uploadFeedback && " file-req is-invalid"} title="Upload Feedback">
                <i className="fal fa-paperclip"></i>
                <span>Upload Feedback</span>
                <input
                  type="file"
                  id="uploadFeedback"
                  className="form-control form-control-aatch-file"
                  name="uploadFeedback"
                  accept="image/*"
                  multiple
                  onChange={(event) => handleUpload(event, 'Feedback')}
                />
              </label>
            </div>
          </div>}
        </div>

        <div className="form-group form-group-save-cancel">
          {/* <PermissionsGate RenderError={() => (
            <button className="btn btn-save btn-success" disabled>
              <i className="fal fa-save"></i> Save
            </button>
          )} scopes={[type == "create" ? "wbssitewebsiteadd" : "wbssitewebsiteedit"]}> */}

          <button className="btn btn-save btn-success" type="submit" title="Save">
            {/* {loading ? <i className="fas fa-cog fa-spin"></i> :  */}
            <i className="fal fa-save"></i>
            {/* } */}
            Save
          </button>
          {/* </PermissionsGate> */}

          {/* {type === "create" && */}
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {/* } */}
        </div>
      </form>
    </>
  )
}

export default CriteriaSubmission;
