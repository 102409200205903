import { values } from "underscore";
import axiosInstance from "../utils/axios";

export const GetListOfCourses = async (data) => {
  return await axiosInstance().post("/getCourseSubjectList", data);
};

export const CopyCourses = async (data) => {
  return await axiosInstance().post("/copyCourses", data);
};

export const getBookedCoursesList = async (data) => {
  return await axiosInstance().post("/getBookedCoursesList", data);
};

export const getNetsuiteRooms = async () => {
  return await axiosInstance().get("/getNetsuiteRooms");
};

export const getBookedCoursesListCancelToken = async (data, cancelToken) => {
  return await axiosInstance().post("/getBookedCoursesList", {data: data}, {cancelToken: cancelToken});
};

export const GetOnlineClassGraphData = async (id) => {
  return await axiosInstance().get("/intakes-list-statistics?uid=" + id);
};

export const GetOnlineClassGraphDataCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get("/intakes-list-statistics?uid=" + id, {cancelToken: cancelToken});
};

export const GetCourseOnlineClassesList = async (id) => {
  return await axiosInstance().get("/getCourseOnlineClassesList?intake=" + id);
};

export const UpdateClassRecordStatus = async (id, status) => {

  // https://aiesuperadmin.devtechnosys.tech:17278/api/v1/toggleRecording?meetingId=615ab6d347480-1633334995&action=true
  return await axiosInstance().get("/toggleRecording?meetingId=" + id + "&action=" + status);
};
export const updateBookedCourseDetails = async (data) => {
  return await axiosInstance().post("/updateBookedCourseDetails", {data: data});
};
// export const UpdateClassRecordStatus = async (values) => {
//   return await axiosInstance().post("/update_class_record_status", values);
// };

export const GetNotificationsList = async (id) => {
  // https://aiesuperadmin.devtechnosys.tech:17278/api/v1/getProgramsNotificationList?intake=12002&type=course_notification
  return await axiosInstance().get(`/getProgramsNotificationList?intake=${id}&type=course_notification`);
};

export const GetCourseDetails = async (id) => {
  return await axiosInstance().get("/intakes-list-edit?uid=" + id);
};

export const GetCourseDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get("/intakes-list-edit?uid=" + id, {cancelToken});
};

export const GetList = async () => {
  return await axiosInstance().get("/intakes-list-edit");
};

export const GetListCancelToken = async (cancelToken) => {
  return await axiosInstance().get("/intakes-list-edit", { cancelToken });
};

export const GetOnlineClassesData = async (data) => {
  return await axiosInstance().get(`/getOnlineClassesData?class_id=${data.class_id}&intake=${data.intake}&isRecurring=${data.isRecurring}`);
};

export const GetListOfAssemblies = async (data) => {
  return await axiosInstance().post("/getAssembliesList",data);
};

export const GetListOfAssembliesCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getAssembliesList`, values, {
    cancelToken: cancelToken
  });
};

export const GetListOfAssembliesFilters = async () => {
  return await axiosInstance().get("/getAssembliesCommonContent");
};

export const GetListOfAssembliesFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get("/getAssembliesCommonContent", {cancelToken: cancelToken});
};

export const GetDetailOfAssemblies = async (id) => {
  return await axiosInstance().get("/getAssembliesDetail", { params: { id } });
};

export const GetDetailOfAssembliesCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get("/getAssembliesDetail", { params: { id } , cancelToken: cancelToken });
};

export const AddAssemblies = async (values) => {
  return await axiosInstance().post("/addAssemblies", values);
};

export const AddAssembliesResources = async (values) => {
  return await axiosInstance().post("/addAssembliesResource", values);
};

export const DeleteAssemblies = async (id) => {
  return await axiosInstance().post("/deleteAssemblies", id);
};

export const AddAndUpdateAssembliesItems = async (data) =>{
  return await axiosInstance().post("/addAndUpdateAssembliesItems",data);
}

export const GetAssembliesLists = async (id) =>{
  return await axiosInstance().get("/getAssembliesLists?id_assembly="+id);
}

export const GetAssembliesListsCancelToken = async (id, cancelToken) =>{
  return await axiosInstance().get("/getAssembliesLists?id_assembly="+id, {cancelToken: cancelToken});
}

export const GetAvailableAssemblies = async (id) => {
  return await axiosInstance().post("/getAssembliesListData", id);
};

export const GetAssignedAssemblies = async (id,data) => {
  return await axiosInstance().get("/getAssignedAssemblies?id_subject=" + id + "&dynamicFilter=" + data);
}

export const DeleteAssignedAssemblies = async (id) => {
  return await axiosInstance().post("/deleteAssignedAssemblies?ref_id=" + id);
}

export const DeleteNotifications = async (values) => {
  return await axiosInstance().post("/delete_notification", values);
}

export const AssignAssembly = async (id, aId) => {
  return await axiosInstance().post("/assignAssemblies?id_subject=" + id + "&id_assembly=" + aId);
}

export const GetStudentList = async (id,data) => {
  return await axiosInstance().get("/get-studen-list?intake=" + id + "&dynamicFilter=" + data);
}

export const StatmentIndividual = async (values) => {
  return await axiosInstance().post("/statmentIndividual", values);
};

// export const GetAssessmentList = async (id) => {
//   return await axiosInstance().get("/get-assigned-assessment-list?intake="+id);
// }  get-assessment?assessment_id=14617

export const GetAssessmentList = async (id = null,data) => {
  let param = id !== null ? "?uid=" + id + "&dynamicFilter=" + data : ""
  return await axiosInstance().get("/get-assesments-list-data" + param );
}

export const GetAssessmentListCancelToken = async (id = null,data, cancelToken) => {
  let param = id !== null ? "?uid=" + id + "&dynamicFilter=" + data : ""
  return await axiosInstance().get("/get-assesments-list-data" + param, {cancelToken} );
}

export const GetListOfResources = async (values = {}) => {
  return await axiosInstance().get("/getAssembliesItemsList", { params: values });
};

export const GetListOfItemsResources = async (values) => {
  return await axiosInstance().get(`/getAssembliesItemsList?parent_id=${values}`);
};

export const GetListOfAssignedResources = async (values) => {
  return await axiosInstance().post("/getAssignedResources", values);
};

export const GetListOfAssignedResourcesCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/getAssignedResources", values, {cancelToken: cancelToken});
};

export const GetListOfAvailableResources = async (values) => {
  return await axiosInstance().post("/getAvailableResources", values);
};

export const GetListOfAvailableResourcesCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/getAvailableResources", values, {cancelToken: cancelToken});
};

export const AddCourse = async (data) => {
  return await axiosInstance().post("/add-new-subject/", data);
}

export const GetAssemblyResourceDetail = async (values) => {
  return await axiosInstance().post("/getAssemblyResourceDetail", values);
};

export const GetAssemblyResourceDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/getAssemblyResourceDetail", values, {cancelToken: cancelToken});
};

export const EditAssemblyResourceDetail = async (values) => {
  return await axiosInstance().post("/editAssembliesItem", values);
};

export const EditAssemblies = async (values) => {
  return await axiosInstance().post("/editAssemblies", values);
};

export const AddAssignedAssemblyResource = async (values) => {
  return await axiosInstance().post("/addAssignedAssemblyResource", values);
};

export const DeleteAssemblyResource = async (values) => {
  return await axiosInstance().post("/deleteAssemblyItem", values);
};

export const GetLinkedCourses = async (values) => {
  return await axiosInstance().post("/getLinkedCourses", values);
};

export const GetLinkedCoursesCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post("/getLinkedCourses", values, {cancelToken: cancelToken});
};

export const AddOnlineClass = async (data) => {
  return await axiosInstance().post("/createNewOnlineClasses", data);
}

export const EditOnlineClass = async (data) => {
  return await axiosInstance().post("/updateOnlineClasses", data);
}

export const AddAssessment = async (data) => {
  return await axiosInstance().post("/add-assessment/", data);
}

export const GetAssessmentsData = async (intake, assId) => {
  return await axiosInstance().get("get-assessments-data", {params: {intake, assId}});
}

export const GetClassRecordings = async (id) => {
  return await axiosInstance().get("getClassRecordingList?intake=" + id);
}

export const UploadClassRec = async (data) => {
  return await axiosInstance().post("/uploadClassRecording/", data);
}

export const GetClassTypeOptionList = async (id) => {
  return await axiosInstance().get("classTypeOptionList");
}

export const GetAssessmentDetails = async (id) => {
  return await axiosInstance().get(`get-assessment?assessment_id=${id}`);
}

export const GetAssessmentSubmission = async (id, intake) => {
  return await axiosInstance().get(`get_assessment_submission`, {params: {assessment_id: id, intake_id: intake}});
}

export const SendQuickNotification = async (data) => {
  return await axiosInstance().post("/sendQuickNotification/", data);
}

export const DeleteOnlineClass = async (data) => {
  return await axiosInstance().post("/b_remove_online_class/", data);
}

export const SendAssessmentInstruction = async (data) => {
  return await axiosInstance().post("sendAssignmentInstuction", data);
}

export const UpdateSubmission = async (data) => {
  return await axiosInstance().post("update_assessment_submission_upload", data);
}

export const GetAssessmentSubmissionUpload = async (data) => {
  return await axiosInstance().post("get_assessment_submission_upload", data);
}

export const AddAndGetTicketComment = async (data) => {
  return await axiosInstance().post("addAndGetTicketComment", data);
}

export const GetCrmProductList = async () => {
  return await axiosInstance().get(`crmProducts`);
}

export const GetCrmProductListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`crmProducts`, { cancelToken });
}

export const DeleteAssessmentSubmissionUpload = async (data) => {
  return await axiosInstance().post("delete_assessment_submission_upload", data);
}

export const UploadMarkAssessment = async (data) => {
  return await axiosInstance().post("upload_attachment", data);
}

export const LaunchVirtualClass = async (data, isStart = true) => {
  return await axiosInstance().post(isStart ? "moderatorRedirect" : "endmeeting", data)
}

export const GetLuanchButtonData = async (data) => {
  return await axiosInstance().post("getLaunchButtonData", data);
}

export const DeleteAssignedAssemblyResource = async (values) => {
  return await axiosInstance().post("/deleteAssignedResource", values);
};

// export const DeleteAssignedAssemblies = async (values) => {
//   return await axiosInstance().post("/deleteAssignedAssemblies?ref_id="+values);
// };

export const GetAssignedAssembliesToResource = async (values) => {
  return await axiosInstance().get("/getAssignedAssembliesToResource?item_id=" + values);
};

export const GetAuditTrailData = async (id = null, resourcetype = "", action_id = "") => {
  let url = id === null ? "getCourseDetailsAuditTrails" : "getCourseDetailsAuditTrails?course_id=" + id + "&resourcetype=" + resourcetype + "&action_id=" + action_id;
  return await axiosInstance().get(url);
}

export const GetAssemblyResourceAuditTrail = async (param) => {
  return await axiosInstance().get(`/getAssemblyResourceAuditTrail`, {params:param});
};

export const GetAssemblyResourceAuditTrailCancelToken = async (param,cancelToken) => {
  return await axiosInstance().get(`/getAssemblyResourceAuditTrail`, { params:param, cancelToken: cancelToken });
};

export const GetNetsuiteProductList = async (serviceType = "Service") => {
  return await axiosInstance().get("/getNetsuiteProductList?itemtype=" + serviceType);
}

export const GetNetsuiteProductListCancelToken = async (serviceType = "Service", cancelToken) => {
  return await axiosInstance().get("/getNetsuiteProductList?itemtype=" + serviceType, { cancelToken });
}

export const CreateNotification = async (params) => {
  return await axiosInstance().post("/addSubjectNotification", params);
}

export const GetNotificationDetails = async (params) => {
  return await axiosInstance().get(`/getProgramsNotificationDetails?id=${params.id}?type=${params.type}`)
}

export const JoinClassAsAdmin = async (params) => {
  return await axiosInstance().post("/joinAsGuest", params);
}

export const GetClassParticipants = async (id) => {
  return await axiosInstance().get(`studentClassReport?class_id=${id}`);
}

export const GetStudentAttaendance = async (id) => {
  return await axiosInstance().get(`/studentsAttendanceClassSummary?class_id=${id}`);
}

export const AddTimer = async (data) => {
  return await axiosInstance().post(`/studentAddManualAttendance`, data);
};

export const GetCommonContent = async () => {
  return await axiosInstance().get(`/getCommonContent`)
}

export const GetSelectClass = async () => {
  return await axiosInstance().get(`/getOnlineClassesSelectList`)
}

export const GetSelectClassFilter = async () => {
  return await axiosInstance().get(`/getOnlineClassesSelectListFilters`)
}

export const AddSelectClass = async (data) => {
  return await axiosInstance().post("/onlineSelectClasses", data);
}

export const AddOrUpdateStudentNote = async (values) => {
  return await axiosInstance().post(`/addOrUpdateStudentNote`, values);
};

export const OnCampusCheckOutIn = async (data) => {
  return await axiosInstance().post("/onCampusCheckOutIn", data);
}

export const classLaunch = async (data) => {
  return await axiosInstance().post("/classLaunch", data);
}

export const AssignOrNotCourseAssignmentsList = async (id, type) => {
  return await axiosInstance().get(`/assignOrNotCourseAssignmentsList?intake=${id}?type=${type}`)
}

export const AddAssignmentToCourse = async (data) => {
  return await axiosInstance().post(`/addAssignmentToCourse`, data)
}

// updatemarks start my code
export const UpDateMarkssubmit = async (data) => {
  return await axiosInstance().post('/updateMarks', data)
}

export const UpdateHybridMarks = async (data) => {
  return await axiosInstance().post('/updateAssementHybridMarks', data)
}

export const DownloadAssessmentsStudentResults = async (param) => {
  return await axiosInstance().get('/downloadAssementsStudentResults', {params:param})
}

export const GetMarkasCompelete = async (data) => {
  return await axiosInstance().post('/markAsComplete', data)
}

export const GetdownloadAssements = async (val) => {
  return await axiosInstance().get(`/downloadAssements?assignment_id=${val}`)
}

export const GetdownloadAssementsCancelToken = async (val, cancelToken) => {
  return await axiosInstance().get(`/downloadAssements?assignment_id=${val}`, {cancelToken: cancelToken})
}

export const GetimportMarks = async (file) => {
  return await axiosInstance().post('/importMarks', file)
}

export const getAve = async () => {
  return await axiosInstance().get("/durationCalculation");
};

export const GetAssessmentDropdown = async () => {
  return await axiosInstance().get("/getAssessmentlistDropdown");
}

export const GetAvailableCourselist = async (file) => {
  return await axiosInstance().post('/getAvailableCourselist', file)
}
export const GetQuiz = async (data) => {
  return await axiosInstance().post("/getQuiz",data);
};
export const GetQuestionsByQuiz = async (val) => {
  return await axiosInstance().get(`/getQuestionsByQuiz?quiz_id=${val}`)
}

export const GetModuleResourceAudit = async (data) => {
  return await axiosInstance().post(`/getModuleResourceAudit`, data);
};


export const getModuleResourceAuditFilters = async (values,cancelToken) => {
  return await axiosInstance().get(`/getModuleResourceAuditFilters`, { params: values, cancelToken: cancelToken });
};

export const GetModuleResourceAuditCancelToken = async (data,cancelToken) => {
  return await axiosInstance().get(`/getModuleResourceAudit?action_id=${data}`, {
    cancelToken: cancelToken
  });
};

export const CourseAssignedProgramme = async (data) => {
  return await axiosInstance().post(`/course_assigned_programme`, data);
};

export const SendAssignmentInstruction = async (data) => {
  return await axiosInstance().post(`/sendAssignmentInstuction`, data);
};

export const GetAvailableCourselistFilters = async () => {
  return await axiosInstance().get(`/getAvailableCourselistFilters`)
}

export const DeleteAssessment = async (data) => {
  return  await axiosInstance().post(`/delete-assessment`,data);
}
export const GetLoadBalancing = async () => {
  return await axiosInstance().get(`activeServerList`);
}


export const AssignAssemblies = async (values) => {
  return await axiosInstance().post("/assembliesRearrange", values);
};

export const readAllCommentMessages = async (values) => {
  return await axiosInstance().post("/readAllCommentMessages", values);
};


export const GradeLetterTemplateList = async (values) => {
  return await axiosInstance().get(`/getGradeLetterTemplateList`, {params: values});
};

export const UpcomingImportantDates = async (values) => {
  return await axiosInstance().get(`/upcomingImportantDates`, {params: values});
};

export const UpcomingImportantDatesCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/upcomingImportantDates`, {params: values, cancelToken: cancelToken});
};

export const onlineClassesStatistics = async (values) => {
  return await axiosInstance().get(`/onlineClassesStatistics`, {params: values});
};

export const onlineClassesStatisticsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/onlineClassesStatistics`, {params: values, cancelToken: cancelToken});
};

export const getCourseClassAttend = async (values) => {
  return await axiosInstance().get(`/getCourseClassAttend`, {params: values});
};

export const getCourseClassAttendCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/getCourseClassAttend`, {params: values, cancelToken: cancelToken});
};

export const getCourseAvgRating = async (values) => {
  return await axiosInstance().get(`/getCourseAvgRating`, {params: values});
}

export const getCourseAvgRatingCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/getCourseAvgRating`, {params: values, cancelToken: cancelToken});
}

export const getCourseAuditTrial = async (values) => {
  return await axiosInstance().post(`/getCourseAuditTrial`, values);
};

export const GetCourseAuditTrialCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getCourseAuditTrial`, values, {
    cancelToken: cancelToken
  });
};

export const getCourseAuditTrialDropdown = async (values) => {
  return await axiosInstance().get(`/getCourseAuditTrialDropdown`, {params: values});
};
export const getBookedCourseAuditFilters = async (values) => {
  return await axiosInstance().get(`/getBookedCourseAuditFilters`, {params: values});
};
export const getBookedCourseAuditTrail = async (values) => {
  return await axiosInstance().post(`/getBookedCourseAuditTrail`, values);
};

export const GetCourseAuditTrialDropdownCancelToken = async (values,cancelToken) => {
  return await axiosInstance().get(`/getCourseAuditTrialDropdown`, { params: values, cancelToken: cancelToken });
};



export const getAssessemntAuditTrial = async (values) => {
  return await axiosInstance().post(`/getAssessementAuditTrial`, values);
};

export const GetAssessemntAuditTrialCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getAssessementAuditTrial`, values, {
    cancelToken: cancelToken
  });
};

export const getAssessmentAuditTrialDropdown = async (values) => {
  return await axiosInstance().get(`/getAssessmentAuditTrialDropdown`, {params: values});
};

export const GetAssessmentAuditTrialDropdownCancelToken = async (values,cancelToken) => {
  return await axiosInstance().get(`/getAssessmentAuditTrialDropdown`, { params: values, cancelToken: cancelToken });
};
export const getSubmissionSummary = async (values) => {
  return await axiosInstance().get(`/getSubmissionSummary`, {params: values});
};

export const getSubmissionSummaryCancelToken = async (values, cancelToken) => {
  return await axiosInstance().get(`/getSubmissionSummary`, {params: values, cancelToken: cancelToken});
};

export const GetClassFiles = async (values) => {
  return await axiosInstance().get(`/getClassFiles`, { params: values });
};
 
export const getSupplementaryStdList = async (data) => {
  return await axiosInstance().post(`/getSupplementaryStdList`, data );
};

export const getPracticalAssessmentStudentList = async (data) => {
  return await axiosInstance().post(`/getPracticalAssessmentStudentList`, data );
};

export const GetPracticalAssessmentQuizQuestions = async (values) => {
  return await axiosInstance().post(`/getPracticalAssessmentQuizQuestions`, values);
};

export const GetQuizQuestion = async (values) => {
  return await axiosInstance().post(`/getQuizQuestion`, values);
};

export const GetPracticalAssessmentStudentListSubmission = async (values) => {
  return await axiosInstance().post(`/getPracticalAssessmentStudentListSubmission`, values);
};

export const getSickAssessmentStudentList = async (data) => {
  return await axiosInstance().post(`/getSickAssessmentStudentList`, data );
};

export const GetPastAssessments = async (data) => {
  return await axiosInstance().post(`/getPastAssessments`, data );
};

export const getAssignedGrademixViaAssessment = async (values) => {
  return await axiosInstance().get(`/getAssignedGrademixViaAssessment`, {params: values});
};
export const removeClassRecording = async (values) => {
  return await axiosInstance().get(`/removeClassRecording`, { params: values });
};

export const updateGrademixMarkSubmission = async (data) => {
  return await axiosInstance().post(`/updateMarkSubmission`, data);
}
export const deleteGenerateCertificate = async (values) => {
  return await axiosInstance().post(`/deleteGenerateCertificate`, values);
};
export const GetNetsuiteItemsCancelToken = async (serviceType = "Service", cancelToken) => {
  return await axiosInstance().get(`/getNetsuiteItems?itemtype=${serviceType}`, { cancelToken });
}

// end