import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CourseAssignedProgramme, GetAvailableCourselist, GetAvailableCourselistFilters, GetCourseDetails, GetCourseDetailsCancelToken } from '../../../services/CourseService';
import { GetList } from '../../../services/DashBoardServices';
import { RenderLearningMethod } from '../../../utils/CommonGroupingItem';
import DataTableComponent from '../../common/DataTableComponent'
import DataTableComponentFrontPagination from '../../common/DataTableComponentFrontPagination';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TABLE_DATE_FORMAT, TABLE_TIME_FORMAT } from '../../../utils/Constants';
import axios from 'axios';
import {GetNetsuiteItemsCancelToken} from '../../../services/CourseService'
function Programs(props) {

    const [loading, setLoading] = useState(false);
    const [courseDetails, setCourseDetails] = useState({});
    const [assignloading, setAssignLoading] = useState(false);
    const [availableLoading, setAvailableLoading] = useState(false);
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "start",
        sortOrder: "asc",
    })
    const [totalRows, setTotalRows] = useState(0);

    const [deletedRec, setDeletedRec] = useState([]);
    const [addedRec, setAddedRec] = useState([]);
    const [assignedRec, setAssignedRec] = useState([]);
    const [availableRec, setAvailableRec] = useState([]);

    const [searchAvailable, setSearchAvailable] = useState("");
    const [searchAssigned, setSearchAssigned] = useState("");
    const [assingIntake, setAssingIntake] = useState({ arr: [], checkObj: {} })
    const [assingStudyType, setAssingStudyType] = useState({ arr: [], checkObj: {} })
    const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })
    const [availableStudyType, setAvailableStudyType] = useState({ arr: [], checkObj: {} })
    const [availableGradingStandard, setAvailableGradingStandard] = useState({ arr: [], checkObj: {} })
    const [filterData, setFilterData] = useState({ intake: [], learning: [] })
    const [AvailablefilterData, setAvailableFilterData] = useState({ intake: [], learning: [], gradingStandard: [] })
    const [netsuiteList, setNetsuiteList] = useState([]);
    const [loadingNS, setLoadingNS] = useState(false);
  
    useEffect(() => {
      setLoadingNS(true);
      const cancelTokenSources = [];
      const getData = async () => {
        cancelTokenSources.forEach((source) => {
          source.cancel('New request made');
        });
  
        const source = axios.CancelToken?.source();
        cancelTokenSources.push(source);
        try { 
              const data = await GetNetsuiteItemsCancelToken(
                'All',
                source.token
              );
              if (data.status === 200) {
                setNetsuiteList(
                  data && data.data && data.data.data && data.data.data.items
                  ? data.data.data.items
                  : []
                );
              }
              setLoadingNS(false);
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.log('error :', error);
          }
          setLoadingNS(false);
        }
      };
  
      getData();
  
      return () => {
        cancelTokenSources.forEach((source) => {
          source.cancel('Component unmounted');
        });
      };
    }, []);
    useEffect(() => {
        setAssignLoading(true)
        GetAvailableCourselistFilters().then((res) => {
            setAvailableFilterData({ ...res.data, intake: res.data.yearsAndIntaksFilter, learning: res.data.intakeTypeFilter, gradingStandard: res.data.gradingStandardFilter });
        })
    }, [])

    useEffect(() => {
        const cancelTokenSources = [];
        const getProgramsList = async () => {
            setAssignLoading(true);
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            try {
                let res = await GetCourseDetailsCancelToken(props.userId, source.token);
                if (res.status === 200) {
                    setCourseDetails(res.data);
                    setFilterData({ ...res.data, intake: res.data.yearsAndIntaksFilter, learning: res.data.intakeTypeFilter })
                    setAssignedRec(res.data && res.data.active ? res.data.active : []);
                    setAssignLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAssignLoading(false)
                }
            }

        }
        getProgramsList()
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [])

    useEffect(() => {
        const cancelTokenSources = [];
        const getProgramsList = async () => {
            setAvailableLoading(true);
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            const data = {
                page: tableState.page,
                limit: tableState.perPage,
                key: tableState.sortKey,
                sort: tableState.sortOrder,
                intake: availableIntake.arr,
                viaStudyType: availableStudyType.arr,
                search: searchAvailable,
                viaGradingStandard: props.gradingStandard == 2 ? "2" : props.gradingStandard == 3 ? "1" : availableGradingStandard.arr[0]
            };
            try {
                let res = await GetAvailableCourselist(data, source.token);
                if (res.status === 200) {
                    setAvailableRec(res.data?.data.data);
                    setTotalRows(res.data?.data?.total);
                    setAvailableLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAvailableLoading(false)
                }
            }

        }
        getProgramsList()
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [tableState, searchAvailable, availableIntake, availableStudyType, availableGradingStandard, props.gradingStandard])


    const columns = useMemo(() => [
        {
            name: "Programme Name",
            selector: "course",
            sortable: true,
            wrap: true,
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={{
                        pathname: `/courseAdministration/Programmes/programme/open/${row.qualification}/details`,
                    }}
                    title={row.course}
                >
                    <span className="textLimit100">
                        {row.course ? row.course : "-"}
                    </span>
                </Link>
            ),
        },
        {
            name: "Intake",
            selector: "year",
            // minWidth: "250px",
            sortable: true,
            cell: (row) => (row.number && row.year ? row.year + "-" + row.number : "-"),
        },
        {
            name: "Study Type",
            selector: "type",
            // minWidth: "250px",
            sortable: true,
            cell: (row) =>
                row.type ? RenderLearningMethod(row.type).html : "-",
        },
        {
            name: "Start",
            selector: "start",
            // minWidth: "250px",
            sortable: true,
            cell: (row) =>
                row.start ? (
                    // <p>{row.start}</p>
                    <div className="dateTime">
                        <p className="right-space">
                            {moment.unix(row.start).format(TABLE_DATE_FORMAT)}
                        </p>
                        {/* <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p> */}
                    </div>
                ) : (
                    <p>-</p>
                ),
        },
        {
            name: "End",
            selector: "end",
            // minWidth: "250px",
            sortable: true,
            cell: (row) =>
                row.end ? (
                    // <p>{row.end}</p>
                    <div className="dateTime">
                        <p className="right-space">
                            {moment.unix(row.end).format(TABLE_DATE_FORMAT)}
                        </p>
                        {/* <p>{moment.unix(row.end).format(TABLE_TIME_FORMAT)}</p> */}
                    </div>
                ) : (
                    <p>-</p>
                ),
        },
        {
            name: "Netsuite Product",
            selector: "netsuite_product",
            sortable: true,
            cell: (row) => (<><span className = "textLimit100">{loadingNS ? <i className="fa fa-cog fa-spin"></i> : netsuiteList && netsuiteList.filter((data) => data.id == row.netsuite_id)?.[0]?.displayname || "-"  }</span></>),
        }, 
        {
            name: "Action",
            selector: "",
            maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <Link
                            className="btn btn-primary rounded-circle"
                            to={`/courseAdministration/Programmes/programme/open/${row.qualification}/details`}
                            title="open"
                        >
                            <i className="fal fa-folder-open"></i>
                        </Link>
                        <button
                            type="button"
                            className="btn btn-danger rounded-circle"
                            onClick={() => handleDelete(row)}
                            title="Delete"
                        >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const availableColumns = useMemo(() => [
        {
            name: "Programme Name",
            selector: "course",
            sortable: true,
            sortField: "course",
            wrap: true,
            // maxWidth: "250px",
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={{
                        pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details`,
                    }}
                    title={row.course}
                >
                    <span className="textLimit100">
                        {row.course ? row.course : "-"}
                    </span>
                </Link>
            ),
        },
        {
            name: "Intake",
            selector: "intake",
            sortField: "intake",
            sortable: true,
            cell: (row) => (row.number && row.year ? row.year + "-" + row.number : "-"),
        },
        {
            name: "Study Type",
            selector: "type",
            sortField: "type",
            sortable: true,
            cell: (row) =>
                row.type ? RenderLearningMethod(row.type).html : "-",
        },
        {
            name: "Start",
            selector: "start",
            sortField: "start",
            // minWidth: "250px",
            sortable: true,
            cell: (row) =>
                row.start ? (
                    <div className="dateTime">
                        <p className="right-space">
                            {moment.unix(row.start).format(TABLE_DATE_FORMAT)}
                        </p>
                        {/* <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p> */}
                    </div>
                ) : (
                    <p>-</p>
                ),
        },
        {
            name: "End",
            selector: "end",
            sortField: "end",
            // minWidth: "250px",
            sortable: true,
            cell: (row) =>
                row.end ? (
                    // <p>{row.end}</p>
                    <div className="dateTime">
                        <p className="right-space">
                            {moment.unix(row.end).format(TABLE_DATE_FORMAT)}
                        </p>
                        {/* <p>{moment.unix(row.end).format(TABLE_TIME_FORMAT)}</p> */}
                    </div>
                ) : (
                    <p>-</p>
                ),
        },
        {
            name: "Netsuite Product",
            selector: "netsuite_product",
            sortable: true,
            cell: (row) => (<><span className = "textLimit100">{loadingNS ? <i className="fa fa-cog fa-spin"></i> : netsuiteList && netsuiteList.filter((data) => data.id == row.netsuite_id)?.[0]?.displayname || "-"  }</span></>),
        },
        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            className: "permission-tabe-last-cell",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary rounded-circle"
                            onClick={() => handleAdd(row)}
                            title="Add"
                        >
                            <i className="fal fa-plus"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const handleDelete = (rec) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (deletedRec.length) {
                    let recFound = false;
                    addedRec.map((e, i) => {
                        if (e === rec.id) {
                            recFound = true;
                        }
                        if (i === addedRec.length - 1) {
                            if (recFound === false) {
                                deletedRec.map((e, i) => {
                                    if (e == rec.id) {
                                        deletedRec.splice(i, 1);
                                        setDeletedRec([...deletedRec]);
                                    } else {
                                        setDeletedRec([...deletedRec, rec.id]);
                                    }
                                });
                            }
                        }
                    });
                } else {
                    let recFound = false;
                    addedRec.map((e, i) => {
                        if (e === rec.id) {
                            addedRec.splice(i, 1);
                            setAddedRec([...addedRec]);
                            recFound = true;
                        }
                        if (i === addedRec.length - 1) {
                            if (recFound === false) {
                                setDeletedRec([...deletedRec, rec.id]);
                            }
                        }
                    });
                }

                assignedRec.map((elem, ind) => {
                    if (elem.id == rec.id) {
                        assignedRec.splice(ind, 1);
                        setAvailableRec([...availableRec, rec]);
                    }
                });
                Swal.fire("Deleted!", "Your record has been deleted.", "success");
                if(props.gradingStandard == 1 && assignedRec.length < 1){
                    setAvailableGradingStandard({ arr: [], checkObj: {} })
                }
            }
        });
    };

    const handleAdd = (rec) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't be able to revert this!",
            icon: "confirm",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Add it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // DeleteUser({ UserID: ID }).then((res) => setDeleteRow(!deleterow));
                setAvailableGradingStandard({ arr: [rec.grading_standard], checkObj: {[`grading${rec.grading_standard}`]: true}})
                availableRec.map((elem, ind) => {
                    if (elem.id == rec.id) {
                        availableRec.splice(ind, 1);
                        setAssignedRec([...assignedRec, rec]);
                        if (addedRec.length) {
                            addedRec.map((e, i) => {
                                if (e == rec.id) {
                                    addedRec.splice(i, 1);
                                    setAddedRec([...addedRec]);
                                } else {
                                    setAddedRec([...addedRec, rec.id]);
                                }
                            });
                        } else {
                            setAddedRec([...addedRec, rec.id]);
                        }
                    }
                });
                Swal.fire("Success!", "Assigned Successfully.", "success");
            }
        });
    };

    const dataToRender = () => {
        let updatedData = [];
        let allData = assignedRec;
        if (searchAssigned.length) {
            let name = allData.filter((item) => {
                let includes = item.course
                    .toString()
                    .toLowerCase()
                    .includes(searchAssigned.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let startDate = allData.filter((item) => {
                let date =
                    item.startDate &&
                    moment(item.startDate).format(TABLE_DATE_FORMAT).toString() +
                    moment(item.startDate).format(TABLE_TIME_FORMAT).toString();
                let includes = date
                    .toString()
                    .toLowerCase()
                    .includes(searchAssigned.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let endDate = allData.filter((item) => {
                let date =
                    item.endDate &&
                    moment(item.endDate).format(TABLE_DATE_FORMAT).toString() +
                    moment(item.endDate).format(TABLE_TIME_FORMAT).toString();
                let includes = date
                    .toString()
                    .toLowerCase()
                    .includes(searchAssigned.toLowerCase());
                if (includes) {
                } else return null;
            });

            let data = [...name, ...startDate, ...endDate];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }
        if (assingIntake.arr.length) {
            let tempIntake = updatedData;
            let tempIntakeHash = tempIntake.filter((item) => {
                const startsMarking = assingIntake.arr.find(function (post) {
                    if (post.toLowerCase() === (item.number && item.year
                        ? item.year + "/" + item.number
                        : "")
                    )
                        return item;
                });
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }
        if (assingStudyType.arr.length) {
            let tempAssignStudy = updatedData;
            let tempIntakeHash = tempAssignStudy.filter((item) => {
                const startsMarking = assingStudyType.arr.find(function (post, index) {
                    if (post.toLowerCase() === item.type ? item.type : "")
                        return item;
                });
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }

        if (
            searchAssigned.length ||
            assingStudyType.arr.length ||
            assingIntake.arr.length
        ) {
            return updatedData;
        } else {
            return assignedRec;
        }
    };

    const exportData = () => {
        let data = dataToRender();
        const header = [
            "Programme Name",
            "Intake",
            "Study Type",
            "Start",
            "End"
        ];
        data = data.map((row) => ({
            "Programme Name": row.course ? row.course : "-",
            "Intake": row.number && row.year ? row.year + "-" + row.number : "-",
            "Study Type": row.type ? RenderLearningMethod(row.type).text : "-",
            "Start": row.startDate ? moment(row.startDate).format(TABLE_DATE_FORMAT) : "-",
            "End": row.endDate ? moment(row.endDate).format(TABLE_DATE_FORMAT) : "-",

        }));
        const pdfData = data.map((row) => {
            return [
                row["Programme Name"],
                row["Intake"],
                row["Study Type"],
                row["Start"],
                row["End"]
            ];
        });
        return {
            header,
            data,
            pdfData,
            columnStyles: {}
        }
    };

    const exportAvailableData = (fileType, fileName) => {
        let data = [];
        const header = [
            "Programme Name",
            "Intake",
            "Study Type",
            "Start",
            "End"
        ];

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        const details = {
            page: tableState.page,
            limit: tableState.perPage,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            intake: availableIntake.arr,
            viaStudyType: availableStudyType.arr,
            search: searchAvailable,
            exportStatus: "true"
        };
        GetAvailableCourselist(details)
            .then((res) => {
                data = res.data.data;

                data = data.map((row) => ({
                    "Programme Name": row.course ? row.course : "-",
                    "Intake": row.number && row.year ? row.year + " - " + row.number : "-",
                    "Study Type": row.type ? RenderLearningMethod(row.type).text : "-",
                    "Start": row.startDate ? moment(row.startDate).format(TABLE_DATE_FORMAT) : "-",
                    "End": row.endDate ? moment(row.endDate).format(TABLE_DATE_FORMAT) : "-",

                }));
                // exportFiles(fileType, data, header, fileName);
                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row["Programme Name"],
                            row["Intake"],
                            row["Study Type"],
                            row["Start"],
                            row["End"]
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }

            })
            .catch((error) => {
                console.log("error >>>>>> ", error);
            });
    };

    const handleSubmit = () => {
        let rec = assignedRec.map((e) => e.qualification);
        let obj = {
            intake: props.userId,
            assigned_program: rec
        }
        CourseAssignedProgramme(obj)
            .then((data) => {
                setLoading(false);
                props.setSaveData(!props.saveData)
                Swal.fire("Updated!", "Your record has been updated.", "success");
            })
            .catch((error) => {
                setLoading(false);
                console.log("error ---->", error);
            });
    }
    return (
        <>
            <DataTableComponentFrontPagination
                data={dataToRender()}
                columns={columns}
                loading={assignloading}
                search={searchAssigned}
                setSearch={setSearchAssigned}
                exportData={exportData}
                exportFileName={"Assigned_Programme"}
                defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
                filters={[
                    {
                        filterName: "Intake",
                        optionArr: filterData.intake,
                        state: assingIntake,
                        setState: setAssingIntake,
                        uniqueId: "assignIntake",
                        isOptionReversed: true,
                    },
                    {
                        filterName: "Study Type",
                        optionArr: filterData.learning,
                        state: assingStudyType,
                        setState: setAssingStudyType,
                        uniqueId: "assignStudyType",
                        renderLabelFunction: RenderLearningMethod,
                    }
                ]}
                tableButton={
                    [
                        <button className="btn btn-primary" data-toggle="modal" data-target="#availableProgramme" title="Assign Programme" type="button">
                            <i className="fal fa-plus"></i>Assign Programme
                        </button>
                    ]
                }
                bottomButton={
                    [
                        <div className="form-group form-group-save-cancel">
                            <button
                                className="btn btn-save btn-success"
                                type="button"
                                title="Save"
                                onClick={handleSubmit}
                                disabled={loading ? true : false}
                            >
                                {loading === false ? (
                                    <i className="fal fa-save"></i>
                                ) : (
                                    <i className="fas fa-cog fa-spin"></i>
                                )}
                                Save
                            </button>
                            {/* <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => {
                        handleReset(formik.resetForm);
                    }}
                >
                    <i className="fal fa-times"></i>Cancel
                </button> */}
                        </div>
                    ]
                }
            />
            <div
                className="topic-add-modal modal able_modal01 fade"
                id="availableProgramme"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered available-assessment-modal"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                        <div className="modal-body">
                            <DataTableComponent
                                data={availableRec}
                                loading={availableLoading}
                                state={tableState}
                                setState={setTableState}
                                setSearch={setSearchAvailable}
                                totalRows={totalRows}
                                columns={availableColumns}
                                exportFunction={exportAvailableData}
                                exportFileName={"Available_Programme"}
                                isInsidePopUp={true}
                                filters={[
                                    {
                                        filterName: "Intake",
                                        optionArr: AvailablefilterData.intake,
                                        state: availableIntake,
                                        setState: setAvailableIntake,
                                        uniqueId: "availableIntake",
                                        isOptionReversed: true,
                                    },
                                    {
                                        filterName: "Study Type",
                                        optionArr: AvailablefilterData.learning,
                                        state: availableStudyType,
                                        setState: setAvailableStudyType,
                                        uniqueId: "availableStudyType",
                                        renderLabelFunction: RenderLearningMethod,
                                    },
                                    {
                                        filterName: "Grading Standard",
                                        optionArr: AvailablefilterData.gradingStandard,
                                        state: availableGradingStandard,
                                        setState: setAvailableGradingStandard,
                                        // uniqueId: "availableStudyType",
                                        // renderLabelFunction: RenderLearningMethod,
                                        singleSelect: true,
                                        isDisabled: props.gradingStandard == 2 || props.gradingStandard == 3 || (assignedRec.length && availableGradingStandard.arr[0]),
                                        notReset : assignedRec.length > 0
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Programs