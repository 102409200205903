import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { RenderLearningMethod } from '../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../common/DataTableComponentFrontPagination';
import { RenderCourseAssignedlistStatus } from '../../../utils/CommonStatusItems';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import $ from "jquery";

function EmailAvailableProgrames({AvailablefilterData , availableRec , availableLoading , handleAdd ,assignedRec,setAssignedRec }) {
    const [searchAssigned, setSearchAssigned] = useState("");
    const [availableStudyType, setAvailableStudyType] = useState({ arr: [], checkObj: {} })
    const [linkedProgramme,setLinkedProgramme] = useState({ arr: [], checkObj:{}})
    const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} })

    const availableColumns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortField: "name",
            sortable: true,
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={`/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}`}
                    onClick={() => $("#availableProgramme").modal("hide")}
                    title={row.name}
                >
                    <span className="textLimit100">{row.name}</span>
                </Link>
            ),
        },
        {
            name: "Status",
            selector: "publish_status",
            sortField: "publish_status",
            sortable: true,
            cell: (row) => (row.publish_status ? RenderCourseAssignedlistStatus(row.publish_status).html : "-"),
        },
        {
            name: "Code",
            selector: "Code",
            sortField: "Code",
            sortable: true,
            cell: (row) => (row.Code ? row.Code : "-"),
        },
        {
            name: "Linked PMC Programme",
            selector: "LinkedProgrammeName",
            sortable: true,
            cell: (row) => (
                <Link
                    className="as-text-blue curser feature-name"
                    to={`/program-management-center/programmes/programme/open/${row.pmc_id}`}
                    onClick={() => $("#availableProgramme").modal("hide")}
                    title={row.LinkedProgrammeName}
                >
                    <span className="textLimit100">{row.LinkedProgrammeName}</span>
                </Link>
                
                // <span className="as-text-blue curser feature-name" title={row.LinkedProgrammeName}>{row.LinkedProgrammeName}</span>
                
            )
        },
        {
            name: "Programme Type",
            selector: "qualification_type_name",
            sortable: true,
            sortField: "qualification_type_name",
            cell: (row) =>
                row?.qualification_type_name
                    ? RenderLearningMethod(row?.qualification_type_name, "programme").html
                    : "-",
        },
        {
            name: "Actions",
            selector: "",
            className: "permission-tabe-last-cell",
            cell: (row) => {
              const isAssigned = assignedRec.some(item => {
                return item.id == row.id
            });
              return (
                <div className="assessment-08">
                  <div className="as-buttons">
                            
                    {isAssigned ? (
                      // Show the "Unassign" button if the item is assigned
                      <button
                        type="button"
                        className="btn btn-danger rounded-circle"
                        onClick={() => {
                            const updateData = assignedRec.filter((elem, ind) => {
                                return elem.id !== row.id
                            });
                            setAssignedRec(updateData)
                        }
                        }
                        title="Unassign Page"
                      >
                        <i className="fal fa-minus"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary rounded-circle"
                        onClick={() => handleAdd(row, "add","Programme")}
                        title="Assign Page"
                      >
                        <i className="fal fa-plus"></i>
                      </button>
                )} 
                  </div>
                </div>
              );
            },
          }
          
    ]);

    const dataToRenderAvailable = () => {
        let updatedData = [];
        let allData = availableRec;

        if (searchAssigned.length) {
            let name = allData?.filter((item) => {
                let includes = item?.name?.toString()
                    .toLowerCase()
                    .includes(searchAssigned.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let data = [...name];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (availableStudyType.arr.length) {
            let tempAssignStudy = updatedData;
            let tempIntakeHash = tempAssignStudy.filter((item) => {
                const startsMarking = availableStudyType.arr.find((it) => it == item.qualification_type)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }
        if (availableIntake.arr.length) {
            let tempIntake = updatedData;
            let tempIntakeHash = tempIntake.filter((item) => {
                const startsMarking = availableIntake.arr.find((it) => it == item.publish_status)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }if(linkedProgramme.arr.length) {
            let templinked = updatedData;
            let tempIntakeHash = templinked.filter((item) => {
                const startsMarking = linkedProgramme.arr.find((it) => it == item.pmc_id)
                if (startsMarking) {
                    return startsMarking;
                } else return null;
            });

            updatedData = tempIntakeHash;
        }

        if (
            searchAssigned.length ||
            linkedProgramme.arr.length ||
            availableIntake.arr.length ||
            availableStudyType.arr.length
        ) {
            return updatedData;
        } else {
            return availableRec;
        }
    };

    const exportAvailableData = () => {
        let data = dataToRenderAvailable();
        const header = [
            "Name",
            "Status",
            "Code",
            "Linked PMC Programme",
            "Programme Type"
        ];
        data = data.map((row) => ({
            "Name": row.name ? row.name : "-",
            "Status": row.publish_status ? RenderCourseAssignedlistStatus(row.publish_status).text : "-",
            "Code": row.Code ? row.Code : "-",
            "Linked PMC Programme": row?.LinkedProgrammeName ? row.LinkedProgrammeName : "-",
            "Programme Type":  row?.qualification_type_name
            ? RenderLearningMethod(row?.qualification_type_name, "programme").text
            : "-",
        }));
        
        const pdfData = data.map((row) => {
            return [
                row["Name"],
                row["Status"],
                row["Code"],
                row["Linked PMC Programme"],
                row["Programme Type"]
            ];
        });
        return {
            header,
            data,
            pdfData,
            columnStyles: {}
        }
    };

    return (
        <>
            <div className="modal-content">
                <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                <div className="modal-body">
                    <DataTableComponentFrontPagination
                        data={dataToRenderAvailable()}
                        columns={availableColumns}
                        loading={availableLoading}
                        exportData={exportAvailableData}
                        exportFileName={"Assigned_Programme"}
                        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
                        custumcss="border-0"
                        search={searchAssigned}
                        progressComponent={<SkeletonTicketList />}
                        setSearch={setSearchAssigned}
                        filters={[
                            {
                                filterName: "Status",
                                optionArr: AvailablefilterData.intake || [],
                                state: availableIntake,
                                setState: setAvailableIntake,
                                uniqueId: "availableIntake",
                                isOptionReversed: true,
                                renderLabelFunction: RenderCourseAssignedlistStatus,
                            },
                            {
                                filterName: "Linked PMC Programme",
                                optionArr: AvailablefilterData.linked || [],
                                state: linkedProgramme,
                                setState: setLinkedProgramme,
                                uniqueId: "availableStudyType",
                                // renderLabelFunction: RenderLearningMethod,
                            },
                            {
                                filterName: "Programme Type",
                                optionArr: AvailablefilterData.learning || [],
                                state: availableStudyType,
                                setState: setAvailableStudyType,
                                uniqueId: "availableStudyType",
                                renderLabelFunction: RenderLearningMethod,
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

export default EmailAvailableProgrames