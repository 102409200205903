import React, { useEffect, useRef } from "react";

const DisplayCard = ({
  videoUrl,
  scrollingText,
  status, // "Online" or "Offline"
  name,
  campusName,
  location,
  displayGroupName,
  displayGroupId,
}) => {
  const marqueeRef = useRef();

  // Map displayGroupId to a color (use consistent colors for identical IDs)
  const getColorFromId = (id) => {
    const colors = [
        "#FFC107", // Amber
        "#03A9F4", // Light Blue
        "#4CAF50", // Green
        "#E91E63", // Pink
        "#9C27B0", // Purple
        "#FF5722", // Deep Orange
        "#009688", // Teal
        "#3F51B5", // Indigo
        "#FF9800", // Orange
        "#2196F3", // Blue
        "#8BC34A", // Light Green
        "#FFEB3B", // Yellow
        "#673AB7", // Deep Purple
        "#795548", // Brown
        "#607D8B", // Blue Grey
        "#00BCD4", // Cyan
        "#9E9E9E", // Grey
        "#CDDC39", // Lime
        "#FF4081", // Pink Accent
        "#00BFAE", // Light Teal
      ];
      
    return colors[id % colors.length]; // Cycle through predefined colors
  };

  useEffect(() => {
    if (marqueeRef.current && scrollingText) {
      // Duplicate the text to ensure smooth scrolling
      marqueeRef.current.innerHTML = scrollingText + scrollingText 


      // Animate the scrolling text
      const keyframes = [
        { transform: "translateX(0%)" },
        { transform: "translateX(-200%)" },
      ];

      marqueeRef.current.animate(keyframes, {
        duration: 10000, // Adjust speed as needed
        iterations: Infinity,
      });
    }
  }, [scrollingText]);

  const displayGroupColor = getColorFromId(displayGroupId);

  return (
    <div className="display-card">
      <div className="video-container">
        {videoUrl ? (
          <video
            src={videoUrl}
            autoPlay
            muted
            loop
            className="video-element"
          />
        ) : (
          <div className="no-video">No video added</div>
        )}
        {scrollingText && (
          <div className="scrolling-text-bg">
          <div ref={marqueeRef} className="scrolling-text">
            {scrollingText}
          </div>
          </div>
        )}
      </div>
      <div className='display-card-status-group-container'>
      <div className={status === 1 ? "displaycard as-green-bg-smooth" : "displaycard as-red-bg-smooth"}>
        <i className="fas fa-circle mr-1"></i>{status === 1 ? "ONLINE" : "OFFLINE"}
      </div>
      <div
        className="display-group-name"
        style={{ backgroundColor: displayGroupColor }}
      >
        {displayGroupName}
      </div>
      </div>
      <div className="display-name">{name}</div>
      <div className="display-card-campus">
        <i className="fa fa-university mr-1"></i>{campusName}, {location}
      </div>      
    </div>
  );
};

export default DisplayCard;
