import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { GetStudentEditData, SendMenualNotification, SendStudentLoginEmails, UpdateStudentGeneralDetails } from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../../utils/permissionGate";


export default function StudentLoginTab({ studentData, smsLog, emailLog, statusLog, loading, updateData, setUpdateData, brandError, setBrandError , brandValue}) {
    const history = useHistory();

    const { id } = useParams();
    const [disabled, setDisabled] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [brandArr, setBrandArr] = useState([]);
    const [statusArr, setStatusArr] = useState([]);

    const fixBrandArr = (arr) => {
        let tempArr = [];
        arr.map((item) => tempArr.push({ value: item.id, label: item.brand_name }));
        // console.log("tempArr", tempArr);
        setBrandArr(tempArr);
    };

    const fixStatusArr = (obj) => {
        let tempArr = [];
        Object.keys(obj).map(key => {
            tempArr.push({ label: obj[key], value: key })
        })
        setStatusArr(tempArr)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            StudentNumber: studentData && studentData.getStudent && studentData.getStudent.student_crm_id ? studentData.getStudent.student_crm_id : "",
            StudentStatus: studentData && studentData.getStudent && studentData.getStudent.status ? studentData.getStudent.status : "",
            Brand: studentData && studentData.currentBrand ? studentData.currentBrand : "",
            Message: "",
            EmailNotification: false,
            emailNotification: false,
            SMSNotification: "",
        },
        validationSchema: Yup.object({
            StudentStatus: Yup.string().required("Student Status is required"),
            Message: Yup.string().trim(),
            Brand: Yup.string().required("Brand is required"),
        }),
        onSubmit: (values) => {
            setDisabled(true);
            let formData = new FormData();
            formData.append("student_id", id);
            formData.append("student_no", values.StudentNumber);
            formData.append("student_status", values.StudentStatus);
            formData.append("brand", values.Brand);
            formData.append("status_message", selectedStatus ? selectedStatus == "Active" ? "Your Student Portal account has been set to Active" : selectedStatus == "On Hold" ? `Your Student Portal account has been placed "On-Hold"` : "" : "");
            UpdateStudentGeneralDetails(formData)
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                    });
                    setDisabled(false);
                    setUpdateData(!updateData);
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    setDisabled(false);
                    setUpdateData(!updateData);
                    console.log("err", err);
                });
        },
    });

    useEffect(() => {
        studentData && studentData.brand && fixBrandArr(studentData.brand);
        studentData && studentData.student_current_status && fixStatusArr(studentData.student_current_status);
        studentData && studentData.getStudent && studentData.getStudent.status && setSelectedStatus(studentData.getStudent && studentData.getStudent.status);
        // if (brandError) {
        //   console.log("consosos", brandError)
        //   formik.setErrors({
        //     Brand: "Brand is requireddddd",
        //   });
        // }
    }, [studentData, brandError]);

    const handleSendLogin = () => {
        const formData = new FormData();
        formData.append("userid", id);
        formData.append("brand_id", brandValue);
        SendStudentLoginEmails(formData)
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Send successfully",
                });
                // setUpdateData(!updateData);
            })
            .catch((err) => console.log("err", err));
    }

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
                <div className="card-header">Student Login</div>
                {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                            <label>Send Student Login</label>
                            <div className="custom-check custom-control custom-checkbox" title="Email Notification">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="emailNotification"
                                    name="select"
                                    checked={formik.values.emailNotification}
                                    onChange={(e) => formik.setFieldValue("emailNotification", e.target.checked)}
                                />
                                <label className="custom-control-label" htmlFor="emailNotification">
                                    Email Notification
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        {/* <label>&nbsp;</label> */}
                        <div className="form-icon-group">
                            <button
                                className="btn btn-save btn-primary"
                                type="button"
                                title="Send Student Login"
                                disabled={!formik.values.emailNotification}
                                onClick={() => {
                                    handleSendLogin();
                                }}
                            >
                                <i className="fa fa-paper-plane"></i>
                                Send Student Login
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
