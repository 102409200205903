import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { AddCampusPages, AddCampusResourse } from "../../../../services/CampusPagesServer";
import $ from 'jquery';
import { IMAGE_URL } from "../../../../utils/Constants";


const CreateResourses = ({ resourceFormik,resourceType, setSelectedData, row, id }) => {
    const history = useHistory();
    const [iconName, setIconName] = useState("");
    const [data, setData] = useState("")

    const givenPermissions = useSelector((state) => state.givenPermission);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: data.id || "",
            resourceName: data.resourceName || "",
            uploadResource: data.uploadResource || ""
        },
        validationSchema: Yup.object({
            resourceName: Yup.string().required("Resource Name is required"),
        }),
        onSubmit: async (values, actions) => {
            const formData = new FormData()
            formData.append("id",values.id)
            formData.append("resourceName",values.resourceName)
            formData.append("uploadResource",values.uploadResource)
            const res = await AddCampusResourse(formData);
            const message = row ? "Updated Successfully" : "Added Successfully";

            Swal.fire({
                icon: "success",
                title: "Success",
                text: message,
            });

            if (row) {
                const filteredData = resourceFormik.values.resource_id?.map((item) => {
                    if (item.id == formik.values.id) {
                        return { ...values, id: values.id }
                    } else {
                        return item
                    }
                })
                resourceFormik.setFieldValue("resource_id", filteredData)
            } else {

                resourceFormik.setFieldValue("resource_id", [...resourceFormik.values.resource_id, res?.data?.getData])
            }
            $(`#${id}`).modal('hide');
            actions.resetForm()
            setIconName('')

        }
    });

    useEffect(() => {
        const modalElement = $(`#${id}`);

        const handleModalClose = () => {
            formik.resetForm();
            setSelectedData({})
            setIconName('')
            
        };

        modalElement.on('hidden.bs.modal', handleModalClose);

        return () => {
            modalElement.off('hidden.bs.modal', handleModalClose);
        };
    }, [formik]);




    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);


    const handleFileChange = (e) => {
        const file = e.currentTarget.files[0];
        if (file) {
            formik.setFieldValue("uploadResource", file);
            setIconName(file.name);
        }
        e.target.value = null
    };

    useEffect(() => {
        if (row) {
            setData(row)
            setIconName(typeof row?.uploadResource === 'string' ? row?.uploadResource.split('/').pop() : typeof row?.uploadResource == 'object' ? row?.uploadResource.name : "")
        }

    }, [row])
    return (
        <div className="card card-profile-info-card border-0">
            <div className="card-body-inr card-body-info">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group-blk mb-3">
                                <label>Resource Name*</label>
                                <input
                                    type="text"
                                    className={
                                        "form-control" +
                                        (formik.errors.resourceName && formik.touched.resourceName
                                            ? " is-invalid"
                                            : "")
                                    }
                                    placeholder="Enter Resource Name"
                                    name="resourceName"
                                    id="resourceName"
                                    title="Resource Name"
                                    value={formik.values.resourceName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group-blk mb-3">
                                <label>
                                    Upload Resource <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="d-flex align-items-start flex-column">
                                    <div className="form-group atttach-file">
                                        <label
                                            className={
                                                formik.errors.uploadResource && formik.touched.uploadResource && "file-req is-invalid"
                                            }
                                        >
                                            <i className="fal fa-upload"></i>
                                            <span>Upload</span>
                                            <input
                                                type="file"
                                                className="form-control form-control-aatch-file"
                                                onChange={(e) => handleFileChange(e)}
                                                name="uploadResource"
                                                id="uploadResource"
                                                accept=".png,.jpg,.jpeg"
                                            />
                                        </label>
                                    </div>
                                    {iconName && (
                                        <div className="frm-group d-flex align-items-center" >
                                            <ul className="list-unstyled attached-file-ul m-0">
                                                <li>
                                                    <a href={IMAGE_URL + formik.values.uploadResource} target="_blank" style={{ maxWidth: '250px' }}>
                                                        <i className="fal fa-file"></i>
                                                        &nbsp;{iconName}
                                                    </a>
                                                </li>
                                            </ul>

                                            <button
                                                type="button"
                                                className="btn btn-danger rounded-circle"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    formik.setFieldValue("uploadResource", "");
                                                    setIconName("");
                                                }}
                                            >
                                                <i className="fal fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-group-save-cancel mt-4">
                        <button
                            className="btn btn-save btn-success"
                            type="submit"
                            title="Save"
                        >
                            <i className="fal fa-save"></i>
                            Save
                        </button>

                        <button className="btn btn-close btn-danger" type="button" data-dismiss="modal" title="Close">
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    </div>
                    {Object.keys(formik.errors).map((key) => {
                        if (formik.touched[key]) {
                            return (
                                <div className="invalid-feedback d-block" key={key}>
                                    {formik.errors[key]}
                                </div>
                            );
                        }
                        return null;
                    })}
                </form>
            </div>
        </div>
    );
};

export default CreateResourses;
