import React from 'react'
import DataTableComponentFrontPagination from './DataTableComponentFrontPagination'
import DataTableComponent from './DataTableComponent'


function PopupComponent({ bottomButton = [], inputs = [], type = "table", id = "", header = {}, detailsBlocks = [], validationErrorMessage = [], modalBody, componentToRender, renderCondition, setRenderCondition, otherProps, tableData, closeFunction = () => {}, args, size, tableButton }) {
    if (type == "form") {
        return (
            <div className={`topic-add-modal modal fade ${otherProps?.secondPopupCss ? otherProps?.secondPopupCss : ""}`} id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitlee" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title">
                                <i className={`fal ${header.iconName}`}></i> {header.heading}
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { closeFunction() }}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="t p-30px">
                            <div className="topic-add-modal-content">
                                <div className="row center-label">
                                    {inputs.map((input, index) => <React.Fragment key={index}>{input}</React.Fragment>)}
                                </div>
                            </div>
                            <div className="form-group form-group-save-cancel mt-3">
                                {bottomButton.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    } else if (type == "multiple_details_blocks") {
        return <div
            className={`topic-add-modal modal fade ${otherProps?.secondPopupCss ? otherProps?.secondPopupCss : ""}`}
            id={id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
            >
                <div className="modal-content modal-border-update">

                    <div className="modal-body p-0">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title">
                                <i className="fal fa-comment"></i> Appeal
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeFunction}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="t p-30px">
                            <div className="topic-add-modal-content">
                                {detailsBlocks.map((item, index) => {
                                    return (
                                        <div className="row center-label no-left-margin" key={index}>
                                            <div className='col-md-12'>
                                                <h5 className="font-weight-bold">{item.heading}</h5>
                                            </div>
                                            <div className="row">
                                                {item.columns && item.columns.length && item.columns.map((col, index) => <React.Fragment key={index}>{col}</React.Fragment>)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {bottomButton.length ? <div className="form-group form-group-save-cancel mt-3">
                                {bottomButton.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
                            </div> : ""}
                            {validationErrorMessage.length ? <div className="form-group form-group-save-cancel">
                                <div className="invalid-feedback d-block">
                                    {validationErrorMessage.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } else if (type == "previewQuestion") {
        return <div
            className="topic-add-modal modal fade"
            id={id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content modal-border-update">
                    <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                            <i className={`fal ${header.iconName}`}></i> {header.heading}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {modalBody}
                    </div>
                </div>
            </div>
        </div>
    } else if (type == "customModalBody") {
        return <div
            className="topic-add-modal modal fade zindexadd"
            id={id}
            tabIndex="-1"
            role="dialog"
            data-backdrop="false"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-xl drag-modal"
                role="document"
            >
                <div className="modal-content drag-handler ui-draggable-handle">
                    <div className="modal-header modal-header-custom">
                        <h6 className="modal-title">
                            <i className={`fal ${header.iconName}`}></i> {header.heading}
                        </h6>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {modalBody}
                </div>
            </div>
        </div>
    } else if (type === "stateBasedCondition") {
        return renderCondition ? <div className="topic-add-modal modal fade show no-form-icon-group" style={{ display: "block" }} id={"feature-popup"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${size ??"available-assessment-modal"}`} role="document">
                <div className="modal-content">
                    <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                            <i className={`fal ${header.iconName}`}></i> {header.heading}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setRenderCondition(true)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="t p-30px">
                        {componentToRender}
                    </div>

                </div>
            </div>
        </div> : ""
    } else if (type === "backendPagingationTable") {
        return <div
            className="topic-add-modal modal fade"
            id={id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered available-assessment-modal"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                            <i className={`fal ${header.iconName}`}></i> {header.heading}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        {tableData.data ? <DataTableComponent
                            {...tableData}
                            isInsidePopUp
                        /> : ""}
                    </div>
                </div>
            </div>
        </div>
    } else if (type === "assessment") {
        return <div
            className="topic-add-modal modal fade"
            id={id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered available-assessment-modal"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                            <i className={`fal ${header.iconName}`}></i> {header.heading}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        {/* Before making any changes please check in grading template table delete button popup table */}
                        {tableData.data ? <DataTableComponentFrontPagination
                            data={tableData.data}
                            columns={tableData.columns}
                            isInsidePopUp
                            // loading={assignloading}
                            search={tableData.search}
                            setSearch={tableData.setSearch}
                            filters={tableData.filters}
                            tableButton={tableButton}
                            {...tableData}
                        // exportData={exportData}
                        // exportFileName={"Assigned_Programme"}
                        // defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
                        /> : ""}
                    </div>
                </div>
            </div>
        </div>
    } else {
        return <div
            className="topic-add-modal modal fade"
            id={id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered available-assessment-modal"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                            <i className={`fal ${header.iconName}`}></i> {header.heading}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        {/* Before making any changes please check in grading template table delete button popup table */}
                        {tableData.data ? <DataTableComponentFrontPagination
                            data={tableData.data}
                            columns={tableData.columns}
                            isInsidePopUp
                            // loading={assignloading}
                            search={tableData.search}
                            setSearch={tableData.setSearch}
                            filters={tableData.filters}
                            {...tableData}
                        // exportData={exportData}
                        // exportFileName={"Assigned_Programme"}
                        // defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
                        /> : ""}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default PopupComponent
