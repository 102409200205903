import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RenderCategoriesType } from '../../../../utils/CommonGroupingItem'
import Select from "react-select";
import { Link, useParams } from 'react-router-dom';
import CriteriaSubmission from './CriteriaSubmission';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import { GetAssessmentDetails, GetPracticalAssessmentQuizQuestions, GetPracticalAssessmentStudentListSubmission, GetQuizQuestion } from '../../../../services/CourseService';
import { IMAGE_URL } from '../../../../utils/Constants';
import Hover from '../../../common/Hover';
import { InitialRender } from '../../../common/Helper';



const AssessmentMarking = () => {
  const { openTabs , subId } = useParams();
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [mark, setMark] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [searchStudentList, setSearchStudentList] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const initialTime = 11 * 60 * 60 + 59 * 60 + 22;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [qid, setQid] = useState(null);
  const descriptionRef = useRef(null);

  const description = mark == false ? "Description of criteria to be assessed. Make the t...Description of criteria to be assessed. Make the t...Description of criteria to be assessed. Make the t...Description of criteria to be assessed. Make the t...Description of criteria to be assessed. Make the t...Description of criteria to be assessed. Make the t..." : quizQuestion?.description;

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current) {
        setShowSeeMore(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [description]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchAssessmentDetails = () => {
      GetAssessmentDetails(subId)
        .then((response) => {
          setAssessmentDetails(response?.data);
        })
        .catch((err) => console.log(err))
    }


    fetchAssessmentDetails();
  }, []);

  useEffect(() => {
    const fetchQuizQuestions = () => {
      const data = {
        type: "assigned",
        quizId: assessmentDetails.quiz_id,
        viaType : ["practical criteria"],
      }

      GetPracticalAssessmentQuizQuestions(data)
        .then((res) => {
          setQuizQuestions(res?.data?.res?.data);
        })
        .catch((err) => console.log(err))
    }

    if (assessmentDetails?.quiz_id) {
      fetchQuizQuestions();
    }
  }, [assessmentDetails]);

  useEffect(() => {
    const fetchQuizQuestion = () => {
      const data = {
        quizId: assessmentDetails.quiz_id,
        questionId: qid,
      }

      GetQuizQuestion(data)
        .then((res) => {
          setQuizQuestion(res?.data?.res[0]);
        })
        .catch((err) => console.log(err))
    }

    if (assessmentDetails?.quiz_id && qid) {
      fetchQuizQuestion();
    }
  }, [qid]);

  useEffect(() => {
    const fetchQuizQuestion = () => {
      setLoading(true);
      const data = {
        assessment_id:assessmentDetails.id,
        intake_id:assessmentDetails.intake
      }

      GetPracticalAssessmentStudentListSubmission(data)
        .then((res) => {
          setStudentList(res?.data?.list);
          setTotalRows(res?.data?.total);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }

    if (assessmentDetails?.id && assessmentDetails?.intake) {
      fetchQuizQuestion();
    }
  }, [assessmentDetails]);

  useEffect(() => {
    if (openTabs !== "assessmentMarking") return;

    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [openTabs]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleMarkCriteria = (qid) => {
    setMark(true);
    setQid(qid);
  }

  const studentColumns = useMemo(() => [
    {
      name: "Student",
      selector: "full_name",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk name-icon cat-dark-red"}>
              {row?.photo ? (
                <img
                  src={`${IMAGE_URL}/${row?.picture_me
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.first_name,
                  row.last_name,
                ])
              )}
              <span
                className={`profile-box-2-status ${
                  row.activity_status
                    ? row.activity_status.toLowerCase() == "online"
                      ? "Online"
                      : row.activity_status.toLowerCase() == "away"
                        ? "Away"
                        : "Offline"
                    : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.picture_me}
                email={row.email}
                mobile={row.mobile}
                right={true}
                // role="Sales"
                // status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
              />
            </span>
            <Link
              to={`/studentAdministration/students/open/${row.student_id}/general`}
              target="_blank"
              className="as-text-blue curser feature-name width-155"
            >
              <span className="textLimit100">{row.full_name}</span>
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Photo",
      selector: "Photo",
      sortable: false,
      cell: (row) =>
        row.student_crm_id ? (
          <span className="feature-name">
            <span className="textLimit100">{row.student_crm_id}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Video",
      selector: "Video",
      sortable: false,
      cell: (row) => row.status ? row.status : "-",
    },
    {
      name: "Document",
      selector: "Document",
      sortable: false,
      cell: (row) => row.course_grade ? row.course_grade : "-",
    },
    {
      name: "Lecturer Observation",
      selector: "Lecturer Observation",
      sortable: false,
      cell: (row) => row.pass_status ? row.pass_status : "-",
    },
    {
      name: "Result",
      selector: "Result",
      sortable: false,
      cell: (row) => row.pass_status ? row.pass_status : "-",
    },
    // {
    //   name: "Actions",
    //   selector: "Action",
    //   cell: (row) => (
    //     <div className="assessment-08 btn-dropdown-grp">
    //       <div className="as-buttons d-flex">
    //         <Link
    //           title="Open"
    //           className="btn btn-primary rounded-circle"
    //           to={`/studentAdministration/students/open/${row.student_id}/general`}
    //           target="_blank"
    //         >
    //           <i className="fal fa-folder-open"></i>
    //         </Link>
    //         {assessmentTypeSelected == 8 || assessmentTypeSelected == 14 ? row.isAssigned ? <button
    //           title="Remove"
    //           type="button"
    //           className="btn btn-danger rounded-circle"
    //           // onClick={() => handleRemoveStd(row)}
    //         >
    //           <i className="fal fa-user-minus"></i>
    //         </button> :
    //           <button
    //             title="Add"
    //             type="button"
    //             className="btn btn-primary rounded-circle"
    //             // onClick={() => handleAddStd(row)}
    //           >
    //             <i className="fal fa-user-plus"></i>
    //           </button> : assessmentTypeSelected == 9 && !row.isAssigned ? <button
    //             title="Add"
    //             type="button"
    //             className="btn btn-primary rounded-circle"
    //             data-toggle="modal"
    //             data-target="#assignStudent"
    //             // onClick={() => setSelectedStudentId(row.student_id)}
    //           >
    //             <i className="fal fa-user-plus"></i>
    //           </button> : ""}
    //       </div>
    //     </div>
    //   ),
    // },
  ]);

  const dataToRenderStudentList = () => {
    let updatedData = [];
    let allData = studentList;
    if (searchStudentList.length) {
      // {console.log("assessmentDetails", assessmentDetails)}
      let tempStudent = allData.filter((item) => {
        let includes = item.full_name?.toLowerCase().toString().includes(searchStudentList.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id?.toString().toLowerCase().includes(searchStudentList.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempStudent, ...tempStudentNo];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    // if (statusAssigned.arr?.length) {
    //   let tempAction = updatedData;
    //   let tempResult = tempAction.filter((item) => {
    //     const startsWith = statusAssigned.arr?.find((post, index) => {
    //       if (post?.toString() === item?.status.toString()) return item;
    //     });
    //     if (startsWith) {
    //       return startsWith;
    //     } else return null;
    //   });
    //   updatedData = tempResult;
    // }

    // if (courseResultAssigned.arr?.length) {
    //   let tempMonth = updatedData;
    //   let tempResult = tempMonth.filter((item) => {
    //     const startsWith = courseResultAssigned.arr.find((post, index) => {
    //       let courseResult = item.pass_status
    //       if (post.toString() === courseResult.toString()) return item;
    //     });
    //     if (startsWith) {
    //       return startsWith;
    //     } else return null;
    //   });
    //   updatedData = tempResult;
    // }

    if (searchStudentList?.length) {
      return updatedData;
    } else {
      return studentList;
    }
  };

  const exportDataStudentList = () => {
    let data = dataToRenderStudentList();
    const header = ["Student", "Student No", "Status", "Course Grade", "Course Result"];

    data = data?.map((row) => {
      return ({
        ...row,
        "Student": row.full_name ? row.full_name : "-",
        // "Student No": row.student_crm_id ? row.student_crm_id : "-",
        // "Status": row.status ? RenderStudentStatus(row.status).text : "-",
        // "Course Grade": row.course_grade ? row.course_grade : "-",
        // "Course Result":row.pass_status ? RenderQuizAttemptResultStatus(row.pass_status).text : "-"
      })
    });
    const pdfData = data.map((row) => {
      return [row["Student"], row["Student No"], row["Status"], row["Course Grade"], row["Course Result"]];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      {mark === true && <div className='card-new card card-profile-info-card' style={{ marginBottom: 20 }}>
        <div className='row align-items-center'>
          <div className="col-md-6">
            <div className='row'>
              <div className='col-lg-6'>
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentType" title='Criteria'>Criteria</label>
                  <Select
                    // isDisabled={(!isEditable && type === "open") || (type === "open" && formik.values.AssessmentType === 9)}
                    className={
                      "form-control custom-select-box"
                      // (formik.errors.AssessmentType &&
                      //   formik.touched.AssessmentType
                      //   ? " is-invalid"
                      //   : "")
                    }
                    // name="AssessmentType"
                    // id="AssessmentType"
                    title="Criteria"
                    // value={assessmentType.filter(function (option) {
                    //   return option.value == formik.values.AssessmentType;
                    // })}
                    // onChange={(value) => {
                    //   if (value) {
                    //     formik.setFieldValue("AssessmentType", value.value);
                    //     formik.setFieldValue("override_mark_ass_id", "");
                    //     setassessmentTypeSelected(value.value);
                    //     if (value.value == 9 || (value.value != 9 && formik.values.AssessmentType == 9)) {
                    //       setCheckedAllRows([]);
                    //       setTempCheckedAllData([]);
                    //     }
                    //   } else {
                    //     formik.setFieldValue("AssessmentType", "");
                    //     formik.setFieldValue("override_mark_ass_id", "");
                    //     setCheckedAllRows([]);
                    //     setTempCheckedAllData([]);
                    //     setassessmentTypeSelected("");
                    //   }
                    // }}
                    // placeholder={
                    //   formik.values.AssessmentType
                    //     ? formik.values.AssessmentType
                    //     : "Select Type"
                    // }
                    // options={assessmentType.sort(
                    //   sort_by("label", false, (a) => a.toUpperCase())
                    // )}
                    isClearable
                  // onBlur={formik.handleBlur}
                  />
                  {/* {formik.errors.AssessmentType &&
                formik.touched.AssessmentType ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null} */}
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex justify-content-end'>
              <button type="button" className="btn btn-save btn-success mr-3" title="Pause Session">
                <i className="fal fa-times"></i>Pause Session
              </button>
              <button type="button" className="btn btn-save btn-success mr-3" data-toggle="modal" data-target="#criteriaSubmission" title="Go to Criteria List">
                <i className="fal fa-clipboard-check"></i>Go to Criteria List
              </button>
              <button type="button" className="btn btn-save btn-success mr-3" title="Previous Criteria">
                <i className="fal fa-chevron-left"></i>Previous Criteria
              </button>
              <button type="button" className="btn btn-save btn-success" title=" Next Criteria">
                Next Criteria <i className="fal fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div >}

      {openTabs == "assessmentMarking" && <div className='card-new card card-profile-info-card d-flex flex-row align-items-center assessment mt-0' >
        <div style={{ marginRight: 10 }}>
          <i className="fal fa-clock" style={{ fontSize: 28, color: "#00A9FF" }} ></i>
        </div>
        <div className='assessment_text'>
          Time Limit: <strong style={{ color: "#EF0000" }}>{formatTime(timeLeft)}</strong>
        </div>
      </div >}

      <div className="card-new card card-profile-info-card mb-30 mt-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <div>
          {/* <div className="card-header">{`${headerData?.ass_type == 13 || headerData?.ass_type == 14 ? "Assessment" : "Marking"} Management`} {loading && <i className="fas fa-cog fa-spin ml-2"></i>}</div> */}
          <div className="card-header">{mark === false ? "Practical Assessment Details" : quizQuestion?.question_name}</div>
          <div className="card-body-inr card-body-info">
            <div className="basic-info-box">
              <div className="row">
                {mark === false &&
                  <>
                    <div className="col-md-6">
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Assessment Status</span>
                              </li>
                              <li>
                                <span className="title" style={{ lineHeight: "18.62px" }}>{RenderCategoriesType("Pending Assessment", "programme")?.html}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Date Completed</span>
                              </li>
                              <li>
                                <span className="title">{false ? "" : "-"}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Time</span>
                              </li>
                              <li>
                                <span className="title">12h</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title" title="Assessment Method">Assessment Method</span>
                              </li>
                              <li>
                                <span className="title" style={{ lineHeight: "18.62px" }} title={openTabs == "assessmentMarking" ? "Lecturer Assessed" : "Student Assessed"}>{RenderCategoriesType(openTabs == "assessmentMarking" ? "Lecturer Assessed" : "Student Assessed", "programme")?.html}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Criteria</span>
                              </li>
                              <li>
                                <span className="title">4</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Students</span>
                              </li>
                              <li>
                                <span className="title">30</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>}
              </div>
            </div>
          </div>

          <div className={`description-container ${mark == true && "mb-3"}`}>
            {mark == false ? <p
              ref={descriptionRef}
              style={{ paddingTop: 10 }}
              className={`description-p ${expanded ? 'expanded' : 'collapsed'}`}
            >
              {description}
            </p>
              :
              <p
                ref={descriptionRef}
                style={{ paddingTop: 10 }}
                className={`description-p ${expanded ? 'expanded' : 'collapsed'}`}
                dangerouslySetInnerHTML={{ __html: description }}
              />}

            {showSeeMore && (
              <span onClick={handleToggle} className="toggle-button">
                {expanded ? 'See Less' : 'See More'}
              </span>
            )}
          </div>

          {mark === true && <div className="row">
            {(quizQuestion?.evidence_photo == 1 || quizQuestion?.evidence_video == 1 || quizQuestion?.evidence_document == 1 || quizQuestion?.evidence_lecturer_observation == 1) && <div className='col-md-5'>
              <h6 style={{ marginBottom: 20 }} title="Evidence Required">Evidence Required</h6>
              <div className='d-flex'>
                {quizQuestion?.evidence_photo == 1 && <div className='mr-3'>
                  <span><i className="fal fa-camera" title="Photo"></i> Photo</span>
                </div>}
                {quizQuestion?.evidence_video == 1 && <div className='mr-3'>
                  <span><i className="fal fa-video" title="Video"></i> Video</span>
                </div>}
                {quizQuestion?.evidence_document == 1 && <div className='mr-3'>
                  <span><i className="fal fa-file-alt" title="Document"></i> Document</span>
                </div>}
                {quizQuestion?.evidence_lecturer_observation == 1 && <div>
                  <span><i className="fal fa-user-check" title="Lecturer Observation"></i> Lecturer Observation</span>
                </div>}
              </div>
            </div>}

            <div className='col-md-4'>
              <h6 style={{ marginBottom: 20 }} title="Students">Students</h6>
              <div className='d-flex'>
                <div className='mr-3' title={`Not Yet Marked: ${quizQuestion?.not_marked ? quizQuestion?.not_marked : 0}`}>
                  <span>{`Not Yet Marked: ${quizQuestion?.not_marked ? quizQuestion?.not_marked : 0}`}</span>
                </div>
                <div className='mr-3' title={`Marked: ${quizQuestion?.marked ? quizQuestion?.marked : 0}`}>
                  <span>{`Marked: ${quizQuestion?.marked ? quizQuestion?.marked : 0}`}</span>
                </div>
                <div title={`Absent: ${quizQuestion?.absent ? quizQuestion?.absent : 0}`}>
                  <span>{`Absent: ${quizQuestion?.absent ? quizQuestion?.absent : 0}`}</span>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <h6 style={{ marginBottom: 20 }} title="Criteria Score">Criteria Score</h6>
              <p className='mb-0' title={quizQuestion?.correct_score ? quizQuestion?.correct_score : 0}>{quizQuestion?.correct_score ? quizQuestion?.correct_score : 0}</p>
            </div>
          </div>
          }
        </div>
      </div>

      {mark === false && quizQuestions && quizQuestions.length > 0 && <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          {/* <div className="card-header">{`${headerData?.ass_type == 13 || headerData?.ass_type == 14 ? "Assessment" : "Marking"} Management`} {loading && <i className="fas fa-cog fa-spin ml-2"></i>}</div> */}
          <div className="card-header" title="Assessment Criteria">Assessment Criteria</div>
          {quizQuestions.map((item, index) => (
            <div className="card-body-inr card-body-info" style={{ border: '1px solid #D7DEE8', padding: "7px 10px", marginBottom: 20 }} key={index}>
              <div className="basic-info-box">
                <div className="row align-items-center">
                  <div className='col-12'>
                    <h6 className='criteria' title={item?.question_name}>{item?.question_name}</h6>
                  </div>

                  <div className="col-md-1">
                    <h6 style={{ marginBottom: 20 }} title="Score">Score</h6>
                    <p className='mb-0' title={item?.correct_score}>{item?.correct_score}</p>
                  </div>

                  <div className='col-md-4'>
                    <h6 style={{ marginBottom: 20 }} title="Marking Status">Marking Status</h6>
                    <div className='d-flex'>
                      {item?.marked ? <div className='mr-3'>
                        <span title={`Marked: ${item?.marked}`}>Marked: {item?.marked}</span>
                      </div> : <div className='mr-3' title={`Marked: 0`}>Marked: 0</div>}
                      {item?.not_marked ? <div className='mr-3'>
                        <span title={`Not Yet Marked: ${item?.not_marked}`}>Not Yet Marked: {item?.not_marked}</span>
                      </div> : <div className='mr-3' title={`Not Yet Marked: 0`}>Not Yet Marked: 0</div>}
                      {item?.absent ? <div>
                        <span title={`Marked Absent: ${item?.absent}`}>Marked Absent: {item?.absent}</span>
                      </div> : <div title={`Marked Absent: 0`}>Marked Absent: 0</div>}
                    </div>
                  </div>

                  {(item?.evidence_photo == 1 || item?.evidence_video == 1 || item?.evidence_document == 1 || item?.evidence_lecturer_observation == 1) && <div className='col-md-5'>
                    <h6 style={{ marginBottom: 20 }} title="Evidence Required">Evidence Required</h6>
                    <div className='d-flex'>
                      {item?.evidence_photo == 1 && <div className='mr-3' title="Photo">
                        <span><i className="fal fa-camera"></i> Photo</span>
                      </div>}
                      {item?.evidence_video == 1 && <div className='mr-3' title="Video">
                        <span><i className="fal fa-video"></i> Video</span>
                      </div>}
                      {item?.evidence_document == 1 && <div className='mr-3' title="Document">
                        <span><i className="fal fa-file-alt"></i> Document</span>
                      </div>}
                      {item?.evidence_lecturer_observation == 1 && <div title="Lecturer Observation">
                        <span><i className="fal fa-user-check"></i> Lecturer Observation</span>
                      </div>}
                    </div>
                  </div>}

                  <div className="col-md-2 ml-auto">
                    <button type="button" className="btn btn-save btn-success" onClick={() => handleMarkCriteria(item?.qid)} title={openTabs == "assessmentMarking" ? "Mark Criteria" : "Mark Submissions"}>
                      <i className="fal fa-pen"></i>{openTabs == "assessmentMarking" ? "Mark Criteria" : "Mark Submissions"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className='row'>
            <div className='col-md-6'>
              <div className='d-flex'>
                <button type="submit" className="btn btn-save btn-success mr-3" title="Save">
                  <i className="fal fa-save"></i>Save
                </button>
                {/* {openTabs == "assessmentMarking" && <button type="button" className="btn btn-save btn-success mr-3" title="Resume Marking">
                  <i className="fal fa-pen"></i>Resume Marking
                </button>} */}
                <button type="button" className="btn btn-save btn-success mr-3" title="Finalize Marking">
                  <i className="fal fa-clipboard-check"></i>Finalize Marking
                </button>
                <button type="reset" className="btn btn-close btn-danger" title="Cancel">
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div >}

      {mark === true && <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <div className="card-header" title="Student List">Student List</div>

          <DataTableComponentFrontPagination
                isInsidePopUp
                data={dataToRenderStudentList()}
                columns={studentColumns}
                loading={loading}
                totalRows={totalRows}
                exportData={exportDataStudentList}
                search={searchStudentList}
                setSearch={setSearchStudentList}
                exportFileName="Student List"
                // filters={[
                //   {
                //     filterName: "Status",
                //     optionArr: statusAssignedList,
                //     state: statusAssigned,
                //     setState: setStatusAssigned,
                //     renderLabelFunction: RenderStudentStatus,
                //     uniqueId: "assignedStatus"
                //   },
                //   {
                //     filterName: "Course Result",
                //     optionArr: courseResultAssignedList,
                //     state: courseResultAssigned,
                //     setState: setCourseResultAssigned,
                //     renderLabelFunction: RenderQuizAttemptResultStatus,
                //     uniqueId: "assigned"
                //   }
                // ]}
              />

          <div className='row'>
            <div className='col-md-6'>
              <div className='d-flex'>
                <button type="submit" className="btn btn-save btn-success mr-3" title="Save">
                  <i className="fal fa-save"></i>Save
                </button>
                <button type="reset" className="btn btn-close btn-danger" title="Cancel">
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div >}

      <div className="topic-add-modal modal fade able_modal01" id="criteriaSubmission" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

          <div className="modal-content">
            {/* <div className="modal-body"> */}
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title" title="Criteria Evidence"><i className="fal fa-clipboard-list-check"></i> Criteria Evidence</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" title="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <CriteriaSubmission openTabs={openTabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssessmentMarking