import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import Program from "./program/Program.js";
import { useParams, Link, useHistory } from "react-router-dom";
import ProgramDetail from "./program/ProgramDetail";
import AuditTrail from "./program/AuditTrail";
import AssignedTable from "./program/ProgramDetailBlock/AssignedTable";
import FeaturesBenefits from "./program/FeaturesBenefits";
import { GetProgramDetailCancelToken, GetShortCourseData } from "../../services/ProgramService";
import { TrimText } from "../common/TrimText";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import axios from "axios";
import ShortCourse from "./ShortCourses/ShortCourse.js";
import ShortCourseDetails from "./ShortCourses/ShortCourseDetails.js";
import ProgrammeDate from "./program/ProgrammeDate";
import CourseDates from "./ShortCourses/CourseDates.js";
import ShortCourseOutcomes from "./ShortCourses/ShortCourseOutcomes";
import ShortCourseTopics from "./ShortCourses/ShortCourseTopics";
import ShortCourseFeaturesBenefits from "./ShortCourses/ShortCourseFeaturesBenefits";

import StudyPath from "./program/StudyPath.js";
import StudyPathRearrange from "./program/StudyPathRearrange.js";
import LinkedProgramme from "./program/LInkedProgrammes.js";
import CreateStudyPath from "./program/CreateStudyPath"
import GraduatesTable from "./program/GraduatesTable.js";
import PermissionsGate from "../../utils/permissionGate.js";
import Swal from "sweetalert2";
import SoftwareUsed from "./program/SoftwareUsed.js";
import Resources from "./program/Resources.js";
import ShortCourseResources from "./ShortCourses/ShortCourseResources.js";
const Programs = () => {
  const history = useHistory();
  const { tab, type, id } = useParams();
  const [programId] = useState(id);
  const [programName, setProgrammeName] = useState("");
  const [detail, setDetail] = useState([]);


  useEffect(() => {
    const cancelTokenSources = [];
    setDetail([])
    const programmeDetails = async () => {

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetProgramDetailCancelToken({ PMC_ID: id }, source.token);
        if (res.status === 200) {
          setProgrammeName(res.data[0].name)
          setDetail(res.data[0]);

          if (!res.data[0]?.Id) {
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push(`/program-management-center/programmes/programme/table`)
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const shortCourseDetail = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetShortCourseData({ id: id }, source.token);
        if (res.status === 200) {
          const respData = res.data?.result;
          setProgrammeName(respData?.name)
          setDetail(respData);
        }
       
        
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
        if(error?.response?.data?.message == "No data found"){
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push(`/program-management-center/programmes/shortCourse/table`)
          
        }
      }
    }
    

    if((tab === "shortCourse" && (type=="open" || type == "duplicate")) || ((tab == "shortCourseFeatures-benefits" || tab === "shortCourse-resources" || tab == "shortCoursetopics" || tab == "shortCourseOutcomes"  || tab == "courseDates") && type == "table") || (tab == "shortCourseAuditTrail" && type == "open")) {
      shortCourseDetail();
    }

    if((tab === "programme" && (type=="open" || type == "duplicate")) || ((tab == "features-benefits" || tab == "" || tab == "linked_programmes" || tab == "study_path" || tab == "graduates" || tab == "assigned-modules" || tab == "programmeDates" && type == "table") || tab === "resources" || (tab == "auditTrail" && type == "open"))){
      programmeDetails();
    }
    // if(tab == "programme" && type == "add"){
    //   setDetail()
    // }

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [type, tab, id])
  
  // useEffect(() =>{
  //   if(((tab == "shortCourse" || tab == "shortCourseAuditTrail") && type == "open") || (tab == "courseDates" && type == "table")){
  //     GetShortCourseData({id : id}).then((res) =>{
  //       if(res.status === 200){
  //         const respData = res.data?.result;
  //         setProgrammeName(respData?.name)
  //       }

  //     }).catch((err)=>{
  //       console.log(err)
  //     })
  //   }
  // },[])


  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap new-standard-box">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {(tab == "programme" ||
                          tab == "shortCourse" ||
                          tab == "auditTrail") &&
                        type == "table" ? (
                          <>
                            Programmes & <span>Courses</span>
                          </>
                        ) : tab == "programme" ? (
                          "Programme: "
                        ) : tab == "shortCourse" ||
                          tab == "courseDates" ||   
                          tab == "shortCoursetopics" ||
                          tab == "shortCourseOutcomes" ||
                          tab == "shortCourseAuditTrail" ||
                          tab == "shortCourse-resources" ||
                          tab == "shortCourseFeatures-benefits" ? (
                          "Course: "
                        ) : (
                          "Programme: "
                        )}
                        {(tab == "programme" && type !== "table") ||
                        (tab == "shortCourse" && type !== "table") ||
                        (tab == "auditTrail" && type == "open") ||
                        tab == "shortCourseFeatures-benefits" || 
                        tab == "programme-structure" ||
                        tab == "assigned-modules" ||
                        tab == "programmeDates" ||
                        tab == "courseDates" ||
                        tab == "study_path" ||
                        tab == "linked_programmes" ||
                        tab == "graduates" ||
                        tab == "softwareused" ||
                        tab == "shortCoursetopics" ||
                        tab == "softwareused" ||
                        tab == "features-benefits" ||
                        tab == "resources" ||
                        tab == "shortCourse-resources" ||
                        tab == "shortCourseOutcomes" ||
                        tab == "shortCourseAuditTrail" ? (
                          <span title={programName}>
                            {(tab == "programme" || tab == "shortCourse") &&
                            type == "add"
                              ? "Create New"
                              : TrimText(programName, 60)}
                          </span>
                        ) : (
                          ""
                        )}
                        {/* Programmes { tab != "programme" || tab != "auditTrail" ? ":" : ""}  {tab != "programme" && <span>{programName}</span>} */}
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {type !== "add" && type !== "duplicate" && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            type == "table" &&
                              (tab == "programme" ||
                                tab == "auditTrail" ||
                                tab == "shortCourse")
                              ? "/program-management-center/programmes/programme/table"
                              : tab == "programme" || tab === "assigned-modules" || tab === "features-benefits" || tab == 'softwareused' || tab == "study_path" || tab == "graduates" || tab == "linked_programmes" || tab == "programmeDates" || tab === "resources" || (tab == "auditTrail" && type== "open")
                              ? `/program-management-center/programmes/programme/open/${id}`
                              : `/program-management-center/programmes/shortCourse/open/${id}`
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${
                            ((tab == "programme" || tab == "shortCourse") &&
                              type == "open") ||
                            (tab == "programme" && type == "table")
                              ? "active"
                              : ""
                          }`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={
                            type == "table" &&
                            (tab == "programme" ||
                              tab == "auditTrail" ||
                              tab == "shortCourse")
                              ? "/program-management-center/programmes/programme/table"
                              : tab == "programme" || tab === "assigned-modules" || tab === "features-benefits" || tab == "study_path" || tab == "graduates" || tab == "linked_programmes" || tab == "programmeDates" || tab === "resources" || (tab == "auditTrail" && type== "open")
                              ? `/program-management-center/programmes/programme/open/${id}`
                              : `/program-management-center/programmes/shortCourse/open/${id}`
                          }
                          href="#pills-tab11"
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          {(tab == "programme" ||
                            tab == "auditTrail" ||
                            tab == "shortCourse") &&
                          type == "table"
                            ? GlobalIconNames("Programmes").html
                            : GlobalIconNames("details").html}
                        </Link>
                      </li>

                      {(tab == "programme" ||
                        tab == "shortCourse" ||
                        // tab == "softwareused" ||
                        tab == "auditTrail") &&
                        type == "table" && (
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/program-management-center/programmes/shortCourse/table`
                              )
                            }
                          >
                            <Link
                              className={`nav-link ${
                                tab == "shortCourse" ? "active" : ""
                              }`}
                              id="pills-tab1"
                              data-toggle="pill"
                              to="/program-management-center/programmes/shortCourse/table"
                              href="#pills-tab11"
                              role="tab"
                              aria-controls="pills-tab11"
                              aria-selected="true"
                            >
                              {GlobalIconNames("shortcourse").html}
                            </Link>
                          </li>
                        )}                      

                        {/* {((tab == "programme" || tab === "auditTrail") && type == "open") || 
                        ((tab === "assigned-modules" || tab === "features-benefits" || tab == "programmeDates" || tab === 'linked_programmes') && type == "table") || 
                        (tab === 'study_path' || type == "re-arrange" || type == "create") && (  */}
                        {((((tab == "features-benefits" || tab == "graduates" || tab === 'resources' || tab == "softwareused" || tab == "assigned-modules" || tab == "study_path" || tab == "programmeDates" || tab == "linked_programmes") && type == "table") || (tab === "features-benefits" && type == "re-arrange" ) || ((tab == "programme" || tab == "auditTrail") && type == "open") || ( tab == "study_path" && (type == "re-arrange" || type == "open" || type == "create"))) && 
                          <>
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/program-management-center/programmes/assigned-modules/table/${id}`
                              )
                            }
                          >
                            <Link
                              className={`nav-link ${
                                tab == "assigned-modules" ? "active" : ""
                              }`}
                              id="pills-tab3"
                              data-toggle="pill"
                              to={`/program-management-center/programmes/assigned-modules/table/${id}`}
                              href="#pills-tab13"
                              role="tab"
                              aria-controls="pills-tab13"
                              aria-selected="true"
                            >
                              {GlobalIconNames("Modules").html}
                            </Link>
                          </li>
                          <PermissionsGate scopes={['pmcstudypathview']} RenderError={() => null}>

                          {/* {((tab == "programme" && type == "open"))
                          // || (tab == "softwareused" || tab === 'resources' || tab == "graduates" ||  tab == "linked_programmes" || tab === "programmeDates"  || tab == "assigned-modules" || tab == "features-benefits" || (tab == "study_path" || type == "re-arrange" || type == "create" || type == "open") || tab == "auditTrail" && type == "open" )) || (tab == "features-benefits" && (type == "re-arrange" || type == "table") ) 
                          &&   */}
                          
                          {(tab == "programme" || tab == "study_path" || tab == "features-benefits" || tab == "assigned-modules" || tab  == "resources" || tab == "linked_programmes" || tab == "graduates" || tab == "softwareused" || tab  == "auditTrail") && 
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(`/program-management-center/programmes/study_path/table/${id}`
                              )
                            }
                            >
                            <Link
                              className={`nav-link ${tab == "study_path" ? "active" : ""}`}
                              id="pills-tab4"
                              data-toggle="pill"
                              to="/program-management-center/programmes/study_path/table"
                              href="#pills-tab14"
                              role="tab"
                              aria-controls="pills-tab14"
                              aria-selected="true"
                              >
                              {GlobalIconNames("Study Path", "fa-route", "Study Path").html}
                            </Link>
                          </li>
                        }
                          </PermissionsGate>
                          {(((tab == "programme" || tab === "auditTrail" || tab == "shortCourse") &&
                        type == "open") ||
                        ((tab === "assigned-modules" || tab == "graduates" || tab === 'resources' || tab == "shortCourse-resources" || tab === 'linked_programmes' || tab == "softwareused" || (tab === 'study_path' || type == "create" || type == "open") || tab === "features-benefits" || tab == "programmeDates" || tab == "shortCourseFeatures-benefits" || tab == "shortCoursetopics" || tab == "shortCourseOutcomes" || tab == "courseDates") && type == "table") || (tab === "features-benefits" || tab === 'shortCourseFeatures-benefits' && type == "re-arrange" ) || (tab == "shortCourseAuditTrail" && type == "open") || (tab == "study_path" && (type == "re-arrange" || type == "create" || type == "open"))) && (
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push( 
                                ((tab == "shortCourse" || tab == "shortCourseAuditTrail") && type== "open" || (tab == "shortCoursetopics" || tab == "shortCourse-resources" || tab == "shortCourseOutcomes" || tab == "courseDates" || tab =="shortCourseFeatures-benefits") && type == "table")  ? `/program-management-center/programmes/shortCourseFeatures-benefits/table/${id}` : `/program-management-center/programmes/features-benefits/table/${id}`
                              )
                            }
                          >
                            <Link
                              className={`nav-link ${tab == "features-benefits" || tab == "shortCourseFeatures-benefits" ? "active" : ""
                                }`}
                              id="pills-tab4"
                              data-toggle="pill"
                              to={((tab == "shortCourse" || tab == "shortCourseAuditTrail") && type== "open" || (tab == "shortCoursetopics" || tab == "shortCourseOutcomes" || tab == "courseDates") && type == "table" || tab =="shortCourseFeatures-benefits") ? `/program-management-center/programmes/shortCourseFeatures-benefits/table/${id}` : `/program-management-center/programmes/features-benefits/table/${id}`}
                              href="#pills-tab14"
                              role="tab"
                              aria-controls="pills-tab14"
                              aria-selected="true"
                            >
                              {
                                GlobalIconNames(  
                                  "Features & Benefits",
                                  "fa-bars",
                                  "Features & Benefits"
                                ).html
                              }
                            </Link>
                          </li>
                        )}
                        {(((tab == "programme" || tab === "auditTrail") &&
                        type == "open") ||
                        ((tab === "assigned-modules" || tab == "graduates" || tab === 'resources' || tab == "softwareused"|| tab === 'linked_programmes' || (tab === 'study_path' || type == "create" || type == "open") || tab === "features-benefits" || tab == "programmeDates" || tab == "courseDates") && type == "table") || (tab === "features-benefits" && type == "re-arrange" ) || (tab == "study_path" && (type == "re-arrange" || type == "create" || type == "open"))) && (
                      <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          (tab == "programme" || tab == "auditTrail") && type == "table"
                            ? `/program-management-center/programmes/softwareused/table/${id}`
                            : ((tab == "programme" || tab == "auditTrail") && type == "open") ||
                              (((tab == "features-benefits" || tab == "assigned-modules" || tab == "programmeDates" || tab == "linked_programmes" || tab == "graduates") && type == "table") || (tab == "study_path" &&  (type == "re-arrange" || type == "create" || type == "open" || type == "table")))
                              ? `/program-management-center/programmes/softwareused/table/${id}`
                              : `/program-management-center/programmes/softwareused/table/${id}`
                        )
                      }>
                        <Link
                          className={`nav-link ${tab == "programmes" || tab == "softwareused" ? "active" : ""
                            }`}
                          id="pills-tab8"
                          data-toggle="pill"
                          to= {`/program-management-center/programmes/softwareused/table/${id}`}
                          // {
                          //   (tab == "programme" || tab == "auditTrail" || tab == "shortCourse") && type == "table"
                          //   ? `/program-management-center/programmes/softwareused/table/${id}`
                          //   : ((tab == "programme" || tab == "auditTrail") && type == "open") ||
                          //     (((tab == "features-benefits" || tab == "assigned-modules" || tab == "programmeDates" || tab == "linked_programmes" || tab == "graduates") && type == "table") || (tab == "study_path" && (type == "re-arrange" || type == "create" || type == "open" || type == "table")))
                          //     ? `/program-management-center/programmes/softwareused/table/${id}`
                          //     : `/program-management-center/programmes/softwareused/table/${id}`
                          // }

                          href="#pills-tab8"
                          role="tab"
                          aria-controls="pills-tab8"
                          aria-selected="true"
                        >
                          {GlobalIconNames("software").html}
                        </Link>

                      </li>
                        )}
                          {/* {  (tab != "shortCourseFeatures-benefits" && tab != "shortCoursetopics" && tab != "shortCourseOutcomes" && tab != "courseDates" || tab == "graduates" || (tab == "programme" && type == "open")) && (
                            <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/program-management-center/programmes/programmeDates/table/${id}`
                              )
                            }
                          >
                            {/* <Link
                              className={`nav-link ${
                                tab == "programmeDates" ? "active" : ""
                              }`}
                              id="pills-tab1"
                              data-toggle="pill"
                              to={`/program-management-center/programmes/programmeDates/table/${id}`}
                              href="#pills-tab11"
                              role="tab"
                              aria-controls="pills-tab11"
                              aria-selected="true"
                              >
                              {
                                GlobalIconNames(
                                  "Programme Dates",
                                  "fa-calendar-alt",
                                  "Programme Dates"
                                ).html
                              }
                            </Link>
                          </li>
                            )
                              } */}
                        </>
                      )}

                      {(((tab == "shortCourse" ||
                        tab === "shortCourseAuditTrail") &&
                        type == "open") ||
                        (tab == "courseDates" && type == "table") || 
                        (tab === "shortCourseFeatures-benefits" && type == "re-arrange" ) ||
                        (tab == "shortCoursetopics" && type == "table") || 
                        (tab == "shortCourseOutcomes" && type == "table") || 
                        (tab == "shortCourse-resources" && type == "table") || 
                        (tab == "shortCourseFeatures-benefits" && type == "table")) && (
                        <li
                          className="nav-item"
                          onClick={() =>
                            history.push(
                              `/program-management-center/programmes/shortCoursetopics/table/${id}`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${
                              tab == "shortCoursetopics" ? "active" : ""
                            }`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={`/program-management-center/programmes/shortCoursetopics/table/${id}`}
                            href="#pills-tab11"
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                          >
                            {
                              GlobalIconNames("Topics", "fa-books", "Topics")
                                .html
                            }
                          </Link>
                        </li>
                      )}

                      {(((tab == "shortCourse" ||
                        tab === "shortCourseAuditTrail") &&
                        type == "open") ||
                        (tab == "courseDates" && type == "table") || 
                        (tab === "shortCourseFeatures-benefits" && type == "re-arrange" ) ||
                        (tab == "shortCoursetopics" && type == "table") || 
                        (tab == "shortCourseOutcomes" && type == "table") || 
                        (tab == "shortCourse-resources" && type == "table") || 
                        (tab == "shortCourseFeatures-benefits" && type == "table")) && (
                        <li
                          className="nav-item"
                          onClick={() =>
                            history.push(
                              `/program-management-center/programmes/shortCourseOutcomes/table/${id}`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${
                              tab == "shortCourseOutcomes" ? "active" : ""
                            }`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={`/program-management-center/programmes/shortCourseOutcomes/table/${id}`}
                            href="#pills-tab11"
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                          >
                            {
                              GlobalIconNames(
                                "Outcomes",
                                "fa-chart-pie-alt",
                                "Outcomes"
                              ).html
                            }
                          </Link>
                        </li>
                      )}

                      {/* {(((tab == "shortCourse" || tab === "shortCourseAuditTrail") &&
                        type == "open") ||
                        (tab == "courseDates" && type == "table") || 
                        (tab == "shortCoursetopics" && type == "table") || 
                        (tab == "shortCourseOutcomes" && type == "table") || 
                        (tab == "shortCourseFeatures-benefits" && type == "table")) && (
                        <li
                          className="nav-item"
                          onClick={() =>
                            history.push(
                              `/program-management-center/programmes/courseDates/table/${id}`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${
                              tab == "courseDates" ? "active" : ""
                            }`}
                            id="pills-tab1"
                            data-toggle="pill"
                            to={`/program-management-center/programmes/courseDates/table/${id}`}
                            href="#pills-tab11"
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                          >
                            {
                              GlobalIconNames(
                                "Course Dates",
                                "fa-calendar-alt",
                                "Course Dates"
                              ).html
                            }
                          </Link>
                        </li>
                       
                      )} */}
                      {(
                          (
                            (tab == "programme" || tab == "auditTrail" || tab == "shortCourse") ||
                            (tab == "shortCourseAuditTrail" && type == "open") ||
                            tab === "programmeDates" || 
                            tab == "linked_programmes" || tab== "softwareused" || tab === "resources" || tab == "graduates" || 
                            tab == "assigned-modules" || 
                            tab == "features-benefits" ||
                            tab == "shortCourseFeatures-benefits" ||
                            tab == "shortCoursetopics" ||
                            tab == 'softwareused' ||
                            tab == "shortCourseOutcomes" ||
                            tab == "shortCourse-resources" ||
                            tab == "study_path" || type == "re-arrange" || type == "create"
                          ) && 
                          !(tab == "shortCourse" && type == "table") && 
                          !(tab == "programme" && type == "table") &&
                          !(tab == "auditTrail" && type == "table")
                        ) && (
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(((tab == "shortCourse" || tab == "shortCourseAuditTrail") && type== "open" || ((tab == "shortCoursetopics" || tab == "shortCourseOutcomes" || tab == "courseDates" || tab == "shortCourseFeatures-benefits" || tab =="shortCourseFeatures-benefits" || tab == "shortCourse-resources") && type == "table" )) || tab == "shortCourseFeatures-benefits" ? `/program-management-center/programmes/shortCourse-resources/table/${id}` : `/program-management-center/programmes/resources/table/${id}`)
                            }
                          >
                            <Link
                              className={`nav-link ${tab == "resources" || tab == "shortCourse-resources" ? "active" : ""}`}
                              id="pills-tab4"
                              data-toggle="pill"
                              to={((tab == "shortCourse" || tab == "shortCourseAuditTrail") && type== "open" || (tab == "shortCoursetopics" || tab == "shortCourseOutcomes" || tab == "courseDates" || tab =="shortCourseFeatures-benefits" || tab == "shortCourse-resources") && type == "table") ? `/program-management-center/programmes/shortCourse-resources/table/${id}` : `/program-management-center/programmes/resources/table/${id}`}
                              href="#pills-tab14"
                              role="tab"
                              aria-controls="pills-tab14"
                              aria-selected="true"
                            >
                              {GlobalIconNames("Resources", "fa-user", "Resources").html}
                            </Link>
                          </li>
                        )}
                      {(
                          (
                            (tab == "programme" || tab == "auditTrail") ||
                            (tab !== "shortCourseAuditTrail" && tab !== "shortCourse" && type == "open") ||
                            tab === "programmeDates" || 
                            tab == "linked_programmes" || tab === "resources" || tab == "graduates" || 
                            tab == "assigned-modules" || 
                            tab == "features-benefits" || tab == "softwareused" ||
                            tab == "study_path" || type == "re-arrange" || type == "create"
                          ) && 
                          !(tab == "shortCourse" && type == "open") && 
                          !(tab == "programme" && type == "table") &&
                          !(tab == "auditTrail" && type == "table")
                        ) && (
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(`/program-management-center/programmes/linked_programmes/table/${id}`)
                            }
                          >
                            <Link
                              className={`nav-link ${tab == "linked_programmes" ? "active" : ""}`}
                              id="pills-tab4"
                              data-toggle="pill"
                              to={`/program-management-center/programmes/linked_programmes/table/${id}`}
                              href="#pills-tab14"
                              role="tab"
                              aria-controls="pills-tab14"
                              aria-selected="true"
                            >
                              {GlobalIconNames("Linked Programmes", "fa-link", "Linked Programmes").html}
                            </Link>
                          </li>
                        )}
                        {(((tab == "programme" || tab == "auditTrail") && type == "open") || 
                        (((tab == "graduates" || tab === "programmeDates" || tab === 'resources' || tab == "linked_programmes"  || tab == "softwareused" || tab == "assigned-modules" || tab == "features-benefits") && type == "table") || (tab === "features-benefits" && type == "re-arrange" ) || (tab == "study_path" && (type == "re-arrange" || type == "create" || type == "table" || type == "open"))) && tab != "shortCourse") && <li
                          className="nav-item"
                          onClick={() =>
                            history.push(`/program-management-center/programmes/graduates/table/${id}`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${tab == "graduates" ? "active" : ""}`}
                            id="pills-tab4"
                            data-toggle="pill"
                            to="/program-management-center/programmes/graduates/table"
                            href="#pills-tab14"
                            role="tab"
                            aria-controls="pills-tab14"
                            aria-selected="true"
                          >
                            {GlobalIconNames("Graduates", "fa-graduation-cap", "Graduates").html}
                          </Link>
                        </li>
                        }
                                            
                      <li
                        className="nav-item"
                        onClick={() =>
                          history.push(
                            (tab == "programme" || tab == "auditTrail" || tab == "shortCourse") && type == "table"
                              ? "/program-management-center/programmes/auditTrail/table"
                              : ((tab == "programme" || tab == "auditTrail") && type == "open") ||
                                (((tab == "features-benefits" || tab=="softwareused" || tab == "assigned-modules" || tab == "programmeDates" || tab == "linked_programmes" || tab === "resources" || tab == "graduates") && type == "table") || (tab == "study_path" &&  (type == "re-arrange" || type == "create" || type == "open" || type == "table")))
                                ? `/program-management-center/programmes/auditTrail/open/${id}`
                                : `/program-management-center/programmes/shortCourseAuditTrail/open/${id}`
                          )
                        }
                      >
                        <Link
                          className={`nav-link ${tab == "auditTrail" || tab == "shortCourseAuditTrail" ? "active" : ""
                            }`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={
                            (tab == "programme" || tab == "auditTrail" || tab == "shortCourse") && type == "table"
                            ? "/program-management-center/programmes/auditTrail/table"
                            : ((tab == "programme" || tab == "auditTrail") && type == "open") ||
                              (((tab == "features-benefits" || tab=="softwareused" || tab == "assigned-modules" || tab == "programmeDates" || tab == "linked_programmes" || tab === "resources" || tab == "graduates") && type == "table") || (tab == "study_path" && (type == "re-arrange" || type == "create" || type == "open" || type == "table")))
                              ? `/program-management-center/programmes/auditTrail/open/${id}`
                              : `/program-management-center/programmes/shortCourseAuditTrail/open/${id}`
                          }
                          href="#pills-tab12"
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                        >
                          {GlobalIconNames("audittrail").html}
                        </Link>
                      </li>


                    </ul>
                  )}
                        {/* {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html} */}
                    {/* {(tab == "features-benefits" || tab == "study_path" || tab == "linked_programmes") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    } */}
                    {/* {(tab == "assigned-modules") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    } */}
                    {/* {(tab == "auditTrail" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    }
                    {(tab == "programme" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>} */}
                    {/* {(tab == "assigned-modules") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>} */}
                    {/* {(tab == "features-benefits" || tab == "study_path" || tab == "linked_programmes") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>} */}
                    {/* {(tab == "auditTrail" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>}
                    {((tab == "programme" && type == "open") || tab == "linked_programmes" || tab == "programmeDates"  || tab == "assigned-modules" || tab == "features-benefits" || tab == "study_path" || tab == "auditTrail" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/study_path/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "study_path" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/study_path/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Study Path", "fa-route", "Study Path").html}
                      </Link>
                    </li>
                    }
                    {((tab == "programme" && type == "open") || tab == "linked_programmes" || tab == "programmeDates" || tab == "assigned-modules" || tab == "features-benefits" || tab == "study_path" || tab == "auditTrail" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/linked_programmes/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "linked_programmes" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/study_path/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Linked Programmes", "fa-link", "Linked Programmes").html}
                      </Link>
                    </li>
                    } */}
                    {/* <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          tab == "programme" && type == "table" ?
                            "/program-management-center/programmes/auditTrail/table" : `/program-management-center/programmes/auditTrail/open/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "auditTrail" ? "active" : ""}`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to="/program-management-center/programmes/auditTrail/table"
                        href="#pills-tab12"
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="true"
                      >
                        {GlobalIconNames("audittrail").html}
                      </Link>
                    </li> */}
                  
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab == "programme" && type == "table" && <Program />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {(tab == "features-benefits" && (type == "table" || type == "re-arrange")) &&  (
                        <FeaturesBenefits />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {tab == "assigned-modules" && type == "table" && (
                        <AssignedTable
                          // data={assigned_data}
                          // refresh={handlerefresh}
                          id={programId}
                          // filterData={filterData}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "study_path") && type == "table" &&
                        <StudyPath
                          // data={assigned_data}
                          // refresh={handlerefresh}
                          id={programId}
                        // filterData={filterData}
                        />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "study_path") && type == "re-arrange" &&
                        <StudyPathRearrange
                          // data={assigned_data}
                          // refresh={handlerefresh}
                          id={programId}
                        // filterData={filterData}
                        />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "linked_programmes") && type == "table" &&
                        <LinkedProgramme
                          // data={assigned_data}
                          detail={detail}
                          // refresh={handlerefresh}
                          id={programId}
                        />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab8"
                      role="tabpanel"
                      aria-labelledby="pills-tab8"
                    >
                      {(tab == "softwareused") && type == "table" &&
                        <SoftwareUsed
                        />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "resources") && type == "table" &&
                        <Resources/>}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "shortCourse-resources") && type == "table" &&
                        <ShortCourseResources/>}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "graduates") && type == "table" &&
                        <GraduatesTable
                          // data={assigned_data}
                          detail={detail}
                          // refresh={handlerefresh}
                          id={programId}
                        // filterData={filterData}
                        />}
                    </div>
                    <div
                        className="tab-pane fade active show"
                        id="pills-tab13"
                        role="tabpanel"
                        aria-labelledby="pills-tab3"
                      >
                      {(tab == "study_path") && (type == "create" ||type=="open")&&
                        <CreateStudyPath
                          detail={detail}
                          id={programId}
                        />
                      }                     
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "programme" &&
                        (type == "open" ||
                          type == "add" ||
                          type == "duplicate") && (
                          <ProgramDetail detail={detail} />
                        )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab113"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab == "shortCourse" && type == "table" && (
                        <ShortCourse />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab122"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "shortCourse" &&
                        (type == "open" ||
                          type == "add" ||
                          type == "duplicate") && (
                          <ShortCourseDetails detail={detail} />
                        )}
                    </div>

                    <div
                      className="tab-pane fade active show"
                      id="pills-tab922"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "programmeDates" && type == "table" && (
                        <ProgrammeDate />
                      )}
                      {tab == "courseDates" && type == "table" && (
                        <CourseDates />
                      )}
                    </div>

                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "auditTrail" && <AuditTrail />}
                      {tab == "shortCourseAuditTrail" && <AuditTrail />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {(tab == "shortCourseFeatures-benefits" && (type == "table" || type == "re-arrange")) &&  (
                        <ShortCourseFeaturesBenefits />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {tab == "shortCoursetopics" && type == "table" && <ShortCourseTopics />}
                    </div>

                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab == "shortCourseOutcomes" && type == "table" && <ShortCourseOutcomes />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
