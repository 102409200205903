import React, { useMemo } from "react";
import { IMAGE_URL } from "../../../utils/Constants";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { useParams } from "react-router-dom";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
import { GetBlogAndNewsAuditTrailListFilters, GetBlogAndNewsTrailList } from "../../../services/BlogAndNewsPageServer";
import { GetLayoutNavigationAuditTrailList, getLayoutNavigationAuditTrailListFilters } from "../../../services/WebsiteListServer";
import { GetStudentAuditFiltersCancelToken, GetStudentAuditTrailDataCancelToken } from "../../../services/StudentsSettingServices";

function AuditTrail({ tabType, apiName,filterApiName,fileName  }) {
  const { subId, subType, subTab, type, tab, id } = useParams();

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  let apiParam = {
    // action_id: subId,
    // resource_type: ['Category']
  };
  if (tab == "categories" && subTab == "auditTrail" && type == "table") {
    apiParam.tab_type = 'Category'
    apiParam.action_id = subId
  }
  if (tab == "menus" && subTab == "auditTrail" && type == "table") {
    apiParam.tab_type='Menu'
    apiParam.action_id = subId
  }
  if (tab == "header" && subTab == "auditTrail" && type == "table") {
    apiParam.tab_type = 'Header'
    apiParam.action_id = subId
  }
  if (tab == "testimonials" && subTab == "auditTrail" && type == "table") {
    apiParam.tab_type = 'Testimonial'
    apiParam.action_id = subId
  }
  

  return (
    <AuditTrailsTable
      apiFunction={apiName ? apiName : GetLayoutNavigationAuditTrailList}
      filterArrApi={filterApiName ? filterApiName : getLayoutNavigationAuditTrailListFilters}
      columnsToShow={columnsToShow}
      exportFileName={fileName ? fileName : "Layout_&_Navigation_auditTrail"}
      isResourceNameShown={true}
      apiParams={apiParam}
      actionId={subId || id}
      isShowingResourceTypeFilter
      renderResourceTypeFunction={RenderStudentResourceType}
      tabType={tabType}
    />
  );
}

export default AuditTrail;
