import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import axios from "../../../utils/axios";
import { FileUploadField, FormField } from "../../common/FormFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import AssignedProgramme from "./AssignedProgramme";
import {
  addCategoryDetails,
  getCategoryDetails,
  getCategoryDropDownList,
  getCategoryTypeDropDownList,
  updateCategoryDetails,
} from "../../../services/LayoutCategoryServices";
import { getAccentColorBaseOnBrandTmpList } from "../../../services/LayoutHeaderServices";
import { getUpcomingEventListDropDown } from "../../../services/EventService";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

const CategoryInformation = ({ setHeaderName }) => {
  const { subId, subTab, type, tab } = useParams();
  const history = useHistory();
  const [statusArr, setStatusArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [relatedSchoolArr, setRelatedSchoolArr] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [colorArr, setColorArr] = useState([]);
  const [programmeData, setProgrammeData] = useState([]);
  const givenPermissions = useSelector((state) => state.givenPermission);
   
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCategoryDropDownList();
        setStatusArr(res.data?.headerStatus || []);
        setBrandArr(res.data?.brandList || []);
      } catch (error) {
        // if (!axios.isCancel(error)) {
        console.error(error);
        // }
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getUpcomingEventListDropDown();
        setRelatedSchoolArr(res.data?.relatedSchool || []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resCategories = await getCategoryTypeDropDownList();
        setCategoriesArr(resCategories.data?.categoryType || []);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const getAccentColor = async (value) => {
    try {
      let res = await getAccentColorBaseOnBrandTmpList({
        brandID: value ? value : [],
      });
      setColorArr(res?.data?.result || []);
    } catch (error) {
      // if (!axios?.isCancel(error)) {
      console.error(error);
      // }
    }
  };
  useEffect(() => {
    let response = hasPermission({
      scopes: ["wbslncategoriesview"],
      permissions: givenPermissions,
  });
  if (!response) {
      history.push("/noaccess");
  }

    const fetchData = async () => {
      try {
        let res = await getCategoryDetails(subId);
        res.data.Data.brandTemplete = res.data.Data?.brandTemplete?.map(
          (el) => el.brandID
        );
        getAccentColor([...res.data?.Data?.brandTemplete]);
        setCategoryData(res.data?.Data);
        setHeaderName(res.data?.Data?.CategoryName)
      } catch (error) {
        if (error.response.data?.message !== "") {
          Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
          })
          history.push(`/websiteManagement/layout_navigation/categories/table`)
        }
      }
    };
    if ((subId && (type == "open" || type == "update") && response)) {
      fetchData();
    }
  }, []);

  


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      CategoryName: categoryData?.CategoryName || "",
      Status: categoryData?.Status || 0,
      RelatedSchool: categoryData?.RelatedSchool || "",
      CategoryColor: categoryData?.CategoryColor || "",
      BrandTemplate: categoryData?.brandTemplete || [],
      OverrideColor: categoryData?.OverrideColor || "",
      CategoryBackground: categoryData?.CategoryBackground || "",
      assignProgrammes: programmeData?.map((item) => item.id) || [],
      categoryType: categoryData?.categoryType || "",
    },
    validationSchema: Yup.object().shape({
      CategoryName: Yup.string().required("Name is required").nullable().trim(),
      CategoryColor: Yup.string().required("Category Color is required"),
      BrandTemplate: Yup.string().required(
        "Assigned Brand Templates are required"
      ),
      CategoryBackground: Yup.string().required(
        "Category Background is required"
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
      Object.keys(trimmedValues).forEach((key) => {
        if (Array.isArray(trimmedValues[key])) {
          trimmedValues[key].forEach((val, idx) => {
            formData.append(`${key}[${idx}]`, val);
          });
        } else {
          formData.append(key, trimmedValues[key]);
        }
      });
      try {
        if (tab == "categories" && type === "create") {
          await addCategoryDetails(formData);
          Swal.fire("Success!", "Category created successfully!", "success");
          history.push("/websiteManagement/layout_navigation/categories/table")
        } else if (tab == "categories" && type === "open") {
          formData.append("categoryID", subId);
          await updateCategoryDetails(formData);
          Swal.fire("Success!", "Category updated successfully!", "success");
          // formik.handleReset()
        }
        // history.push("/websiteManagement/layout_navigation/categories/table");
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${subTab === "create" ? "create" : "update"} Category`,
          "error"
        );
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Category Information</h4>
            </div>
          </div>

          <FormField
            field={{ name: "CategoryName", label: "Category Name" }}
            formik={formik}
          />

          <FormField
            field={{
              name: "RelatedSchool",
              label: "Related School",
              required: false,
              info: false,
            }}
            required={false}
            formik={formik}
            selectOptions={relatedSchoolArr}
          />
          {/* <FormField
            field={{
              name: "Status",
              label: "Status",
              required: false,
              info: true,
            }}
            required={false}
            formik={formik}
            selectOptions={statusArr}
            isClearable={true}
          /> */}
          <div className="col-md-6 col-lg-3">
            <div className="form-group-blk mb-3" title="Status">
              <label>Status <i className="fal fa-info-circle"></i></label>
              <Select
                className={
                  "form-control custom-select-box" +
                  (formik.errors.Status && formik.touched.Status ? " is-invalid" : "")
                }
                name="status"
                value={
                  formik.values.Status !== ""
                    ? statusArr.find((val) => val?.value == formik.values.Status)
                    : ""
                }
                onChange={(option) => {
                  formik.setFieldValue("Status", option ? option.value : "");
                }}
                onBlur={formik.handleBlur}
                options={statusArr}
                maxMenuHeight={175}
                placeholder={"Select Status"}
                isClearable
              />

            </div>
          </div>

          <div className="col-md-6 col-lg-3">
            <label htmlFor="BrandTemplate">
              Assigned Brand Templates * <i className="fal fa-info-circle"></i>
            </label>
            <div className="form-icon-group mb-4">
              <Select
                className={`form-control custom-select-box ${formik.touched.BrandTemplate &&
                  formik.errors.BrandTemplate &&
                  "is-invalid"
                  }`}
                name="BrandTemplate"
                value={brandArr.filter((val) =>
                  formik.values?.BrandTemplate?.includes(val.value)
                )}
                onChange={(value) => {
                  formik.setFieldValue(
                    "BrandTemplate",
                    value ? value.map((v) => v.value) : []
                  );
                  getAccentColor(value ? value.map((v) => v.value) : []);
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={brandArr}
                maxMenuHeight={175}
                placeholder="Select"
                isMulti
              />
            </div>
          </div>
          <FormField
            field={{
              name: "categoryType",
              label: "Category Type",
              required: false,
              info: false,
            }}
            required={false}
            formik={formik}
            selectOptions={categoriesArr}
          />
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">
                <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" />{" "}
                Category Settings
              </h4>
            </div>
          </div>

          {
            formik.values?.OverrideColor ?
              <>
                {[
                  { label: "Category Colour", name: "CategoryColor", color: true, required: true, type: "color", },
                ].map((field, index) => (
                  <FormField key={index} field={field} formik={formik} />
                ))}
              </>
              :
              <FormField
                field={{ name: "CategoryColor", label: "Category Colour" }}
                formik={formik}
                selectOptions={colorArr}
              />
          }

          <div className="col-md-6 col-lg-3 d-flex " style={{ marginTop: 37 }}>
            <label className="mb-0">
              Override Colour Variable <i className="fal fa-info-circle"></i>
            </label>
            <div className="toggle-switch ml-2">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formik.values?.OverrideColor === 1}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "OverrideColor",
                      e.target.checked ? 1 : 0
                    )
                    formik.setFieldValue(
                      "CategoryColor",
                      e.target.checked ? "#000000" : ""
                    )
                  }
                  }
                />
                <span className="slider slider-round"></span>
              </label>
            </div>
          </div>

          <FileUploadField
            name="CategoryBackground"
            label="Category Image"
            formik={formik}
            accept=".png,.jpg,.jpeg"
            col_md={12}
            col_lg={6}
            required={true}
          />
          {subTab === "update" ? (
            <>
              <div className="col-md-12">
                <hr
                  className="border border-2"
                  style={{ borderColor: "#E6EBF1" }}
                />
              </div>
              <div className="col-md-12">
                <div className="edit-icon new-card-header">
                  <h4 className="card-header">Assigned Programmes</h4>
                </div>
              </div>
              <div className="col-md-12">
                <AssignedProgramme
                  subId={subId}
                  programmeData={programmeData}
                  setProgrammeData={setProgrammeData}
                />
              </div>
            </>
          ) : null}
          <div className="col-md-12 form-group form-group-save-cancel mt-4">
            <PermissionsGate scopes={[subTab == "create" ? 'wbslncategoriesadd' : 'wbslncategoriesedit']}>

              <button
                className="btn btn-save btn-success"
                disabled={formik.isSubmitting || !formik.dirty}
                type="submit"
                title="Save"
              >
                <i className="fal fa-save"></i> Save
              </button>
            </PermissionsGate>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              disabled={formik.isSubmitting}
              onClick={() =>
                formik.resetForm()
                // history.push("/websiteManagement/layout_navigation/categories/table")
              }
            >
              <i className="fal fa-times"></i>Cancel
            </button>
          </div>
        </div>
        {Object.keys(formik.errors).map((key) => {
          if (formik.touched[key]) {
            return (
              <div className="invalid-feedback d-block" key={key}>
                {formik.errors[key]}
              </div>
            );
          }
          return null;
        })}
      </form>
    </div>
  );
};

export default CategoryInformation;
