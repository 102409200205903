import React, { useEffect, useState } from 'react'
import { EditorFileList, UploadEditorFiles } from '../../services/EditorServices';
import { IMAGE_URL, STUDENT_FILE_DOWNLOAD, downloadURL } from '../../utils/Constants';
import Swal from 'sweetalert2';
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import SkeletonTicketList from '../../loaders/SkeletonTicketList';
import PopupComponent from './PopupComponent';
import $ from "jquery";


function FileSystem({setSelectedValue, setIsFMShowen, isFMultiMedia,isCKEditor=true, setTempEditorState,state,setter, tempEditorState , type , modal}) {
    const [data, setData] = useState([])
    const [allFiles, setAllFiles] = useState([])
    const [search, setSearch] = useState('')
    const [selectedFile, setSelectedFile] = useState();
    const [updateData, setUpdateData] = useState(true);
    const [layoutType, setLayoutType] = useState("list");
    const [dirName, setDirName] = useState("")
    const [isDirSelected, setIsDirSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blockSize, setBlockSize] = useState(2)
    const imageInputRef = React.useRef();
    const [folderName, setFolderName] = useState({value:"", error:""});

    let pathId = 1

    const handleUploadFile = (e) => {
        e.preventDefault();
        const data = new FormData()
        data.append("document", e.target.files[0])
        data.append("uploadPath", dirName ? dirName : "/")
        Swal.fire({
            title: "File uploading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        UploadEditorFiles(data).then(res => {
            setUpdateData(!updateData)
            Swal.close();
            Swal.fire("Success!", "Successfully Uploaded.", "success");
            imageInputRef.current.value = "";
        }).catch(err => {
            console.log("error :", err)
            Swal.close();
            Swal.fire("Error!", "File Not Uploaded.", "error");
        })
    }

    const handleCreateFolder = () => {
        if (!folderName.value) {
            setFolderName({...folderName, error:"Folder Name is Required"})
            return
        }
        setFolderName({...folderName, error:""})
        const data = new FormData()
        data.append("uploadPath", dirName ? dirName : "/")
        data.append("folderName", folderName.value)
        UploadEditorFiles(data).then(res => {
            setUpdateData(!updateData)
            Swal.fire("Success!", res.data.message, "success");
        }).catch(err => {
            console.log("error :", err)
            Swal.fire("Error!", err.response.data.message, "error");
        })
        $("#addFolderrr").modal("hide");
        setFolderName({value:"", error:""})
    }

    useEffect(() => {
        setLoading(true)
        let data = {
            fileReq: dirName,
            type: type
        }
        EditorFileList(data).then(res => {
            let tempData = []
            res.data.Folder.map((item) => {
                let isDir = !item.isDir
                if (isFMultiMedia) {
                    if (isDir || (item.path.endsWith("mp3") || item.path.endsWith("wav") || item.path.endsWith("aac") || item.path.endsWith("flac") || item.path.endsWith("ogg") || item.path.endsWith("wma") || item.path.endsWith("m4a") || item.path.endsWith("alac") || item.path.endsWith("aiff") || item.path.endsWith("ape") || item.path.endsWith("mp4") || item.path.endsWith("avi") || item.path.endsWith("mkv") || item.path.endsWith("mov") || item.path.endsWith("wmv") || item.path.endsWith("flv") || item.path.endsWith("mpeg") || item.path.endsWith("webm") || item.path.endsWith("rmvb") || item.path.endsWith("3gp") || item.path.endsWith("m4v"))) {
                        tempData.push({
                            id: (pathId).toString(),
                            path: item.path.replaceAll(
                                "/home/myaie/public_html/",
                                ""
                            ).replace("public/", ""),
                            name: item.name,
                            isDir: isDir,
                        })
                        ++pathId
                    }
                } else {
                    if (!isDir && (item.path.endsWith("jpg") || item.path.endsWith("png") || item.path.endsWith("gif") || item.path.endsWith("jpeg") || item.path.endsWith("svg"))) {
                        tempData.push({
                            id: (pathId).toString(),
                            path: item.path.replaceAll(
                                "/home/myaie/public_html/",
                                ""
                            ).replace("public/", ""),
                            name: item.name,
                            isDir: isDir
                        })
                        ++pathId
                    } else if (isDir && !item.name.startsWith(".")) {
                        tempData.push({
                            id: (pathId).toString(),
                            path: item.path.replaceAll(
                                "/home/myaie/public_html/",
                                ""
                            ).replace("public/", ""),
                            name: item.name,
                            isDir: isDir,
                        })
                        ++pathId
                    }
                }
            })
            setData(tempData)
            setAllFiles(tempData)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.error("error", err)
        })
    }, [updateData, dirName])

    return (
        <div className='content-wrapper'>
            <div className={`card fm-main-card no-form-icon-group ${isFMultiMedia ? "fm-no-border" : ""}`}>
                <div className="my-tickets-heading-blk flex-direction mb-2 shift-move fm-heading">
                    <div className="d-flex align-items-center ">
                        <div className="name-w-head d-flex align-items-center ">
                            <h4 className="text-left-align landing-heading heading_color_dynamic">Select <span className="ml-1" title={"Select File"}>File</span></h4>
                        </div>
                        {dirName && dirName?.split("/")?.length && dirName?.split("/")[1]?.length ? <div className="text-md-right action2-blk">
                            <div className="ticket-view-btn d-flex align-items-center">
                                <div className="add-new-ticket-bx">
                                    <button onClick={() => {
                                        setSearch("")
                                        let dir = dirName?.split("/")
                                        if (dir.length > 2) {
                                            dir = dir.slice(0, -1)
                                        } else {
                                            dir = [""]
                                        }
                                        setDirName(dir.join("/"))
                                    }} title="Back" className="btn btn-white-bordered" type='button'>
                                        {GlobalIconNames("leftarrow")}Back
                                    </button>
                                </div>
                            </div>
                        </div> : ""}
                    </div>
                </div>
                <div className='fm-container'>
                    <div className='fm-header'>
                        <div className='fm-search'>
                            <input
                                type="search"
                                className="fm-input-search"
                                placeholder="Search"
                                aria-controls="assessment-table-main"
                                onChange={e => {
                                    setSelectedFile({})
                                    setIsDirSelected(false)
                                    setSearch(e.target.value)
                                    let tempData = allFiles
                                    if (e.target.value) {
                                        let finalData = tempData.filter(item => item.name.toLowerCase().includes(e.target.value ? e.target.value.toLowerCase() : ""))
                                        setData([...finalData])
                                    } else {
                                        setData([...tempData])
                                    }
                                }}
                                value={search}
                            />
                        </div>
                        <div className='fm-buttons'>
                            {isFMultiMedia ? "" : <button
                                title={layoutType === "list" ? "List" : "Blocks"}
                                className={"btn rounded-circle btn-success mb-1 ml-1"}
                                onClick={() => setLayoutType(layoutType === "list" ? "block" : "list")}
                            >
                                {layoutType === "list" ? <i className='fal fa-list' /> : <i className='fal fa-th' />}
                            </button>}
                            <button
                                className="btn btn-save btn-primary ml-1 mb-1"
                                type="button"
                                title="Select"
                                disabled={!selectedFile?.data || (selectedFile?.data && isDirSelected)}
                                onClick={() => {
                                    if(isCKEditor){
                                        if (isFMultiMedia) {
                                            setSelectedValue(prevValue => (selectedFile?.data?.path))
                                            setIsFMShowen(false)
                                            setTempEditorState(!tempEditorState)
                                            // $("#editor-fs").modal("hide");
                                            // window.close()
                                            return;
                                        }
                                        const urlParams = new URLSearchParams(window.location.search);
                                        let CKEditorFuncNum = urlParams.get('CKEditorFuncNum')
                                        window.opener.CKEDITOR.tools.callFunction(CKEditorFuncNum, `${IMAGE_URL}/${selectedFile.data?.path}`)
                                        window.close()
                                    }else{
                                        setter(prevState => ({
                                            ...prevState,
                                            url: selectedFile?.data?.path,
                                            name: selectedFile?.data?.name,
                                        }))
                                        $(modal).modal('hide');
                                        Swal.close();
                                        Swal.fire("Success!", "The selected file has been added", "success");
                                    }
                                }}
                            >
                                Select
                            </button>
                            {selectedFile?.data && !isDirSelected ? <a
                                className="btn btn-save btn-success ml-1 mb-1"
                                target='_blank'
                                title="View"
                                href={`${IMAGE_URL}/${selectedFile.data?.path}`}
                            >
                                View
                            </a> : <button
                                className="btn btn-save btn-primary ml-1 mb-1"
                                title="View"
                                disabled
                            >
                                View
                            </button>}
                            {selectedFile?.data && !isDirSelected ? <a
                                className="btn btn-save btn-success ml-1 mb-1"
                                target='_blank'
                                title="Download"
                                download={`${downloadURL}${STUDENT_FILE_DOWNLOAD}/${selectedFile.data?.path}`}
                                href={`${downloadURL}${STUDENT_FILE_DOWNLOAD}/${selectedFile.data?.path}`}
                            >
                                Download
                            </a> : <button
                                className="btn btn-save btn-primary ml-1 mb-1"
                                title="Download"
                                disabled
                            >
                                Download
                            </button>}
                            <button
                                className="btn btn-save btn-primary ml-1 mb-1"
                                title="Add Folder"
                                data-toggle="modal"
                                data-target="#addFolderrr"
                                type='button'
                            >
                                Add Folder
                            </button>
                            <span className="form-group atttach-file ml-1 mb-1">
                                <label>
                                    <i className="fal fa-paperclip"></i>
                                    <span>Upload File</span>
                                    <input
                                        type="file"
                                        title="Upload File"
                                        name="document"
                                        className={"form-control form-control-aatch-file "}
                                        onChange={handleUploadFile}
                                        ref={imageInputRef}
                                        accept={isFMultiMedia ? "audio/*,video/*" : "image/png, image/jpeg, image/gif"}
                                    />
                                </label>
                            </span>
                        </div>
                    </div>
                    {loading ? <SkeletonTicketList /> : layoutType === "list" ? <div className='fm-ul-files'>
                        {data.map((row, index) => {
                            if (row.isDir) {
                                return (
                                    <div
                                        className={`title-border-box` + (index == selectedFile?.index ? " selected-div" : "")}
                                        onClick={(e) => {
                                            setSelectedFile({ data: row, index })
                                            setIsDirSelected(true)
                                        }}
                                        onDoubleClick={() => {
                                            setDirName(row.path.replace("/home/myaie/public_html/Library/Question Uploads", "").replace("Library/Question Uploads", ""))
                                            setSearch("")
                                        }}
                                        key={index}
                                    >
                                        <i className="fas fa-folder" ></i> {row.name}
                                    </div>
                                )
                            }
                            return (
                                <div
                                    className={`title-border-box` + (index == selectedFile?.index ? " selected-div" : "")}
                                    onClick={(e) => {
                                        setSelectedFile({ data: row, index })
                                        setIsDirSelected(false)
                                    }}
                                    onDoubleClick={() => {
                                        if (isFMultiMedia) {
                                            setSelectedValue(selectedFile?.data?.path)
                                            setIsFMShowen(false)
                                            setTempEditorState(!tempEditorState)
                                            return;
                                        }
                                        const urlParams = new URLSearchParams(window.location.search);
                                        let CKEditorFuncNum = urlParams.get('CKEditorFuncNum')
                                        window.opener.CKEDITOR.tools.callFunction(CKEditorFuncNum, `${IMAGE_URL}/${row?.path}`)
                                        window.close()
                                    }}
                                    key={index}
                                >
                                    {isFMultiMedia ? <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaLO2_EFFI-eVhUBi6BS6XKE54viRbADMBb-xvh41zS7Pzk5Ts-fkAHrwIHdfZ2_vYE7g&usqp=CAU' alt='.' className='fm-icon-img'/> : <i className="fal fa-image"></i>} {row.name}
                                </div>
                            )
                        })}
                    </div> : <div className='row'>
                        {data.map((row, index) => {
                            if (row.isDir) {
                                return (
                                    <div
                                        className={`fm-card col-md-${blockSize}`}
                                        onClick={(e) => {
                                            setSelectedFile({ data: row, index })
                                            setIsDirSelected(true)
                                        }}
                                        onDoubleClick={() => {
                                            setDirName(row.path.replace("/home/myaie/public_html/Library/Question Uploads", "").replace("Library/Question Uploads", ""))
                                            setSearch("")
                                        }}
                                        key={index}
                                        style={{ border: selectedFile?.index === index ? "1px var(--highlight-color) solid" : "" }}
                                    >
                                        <div className='fm-image-container'>
                                            <i className="fas fa-folder" style={{ fontSize: "1500%" }}></i>
                                        </div>
                                        <div className='fm-file-details'>
                                            <span className='fm-file-name' title={row.name}>{row.name}</span>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div
                                    className={`fm-card col-md-${blockSize}`}
                                    onClick={(e) => {
                                        setSelectedFile({ data: row, index })
                                        setIsDirSelected(false)
                                    }}
                                    onDoubleClick={() => {
                                        if (isFMultiMedia) {
                                            setSelectedValue(selectedFile?.data?.path)
                                            setIsFMShowen(false)
                                            return;
                                        }
                                        const urlParams = new URLSearchParams(window.location.search);
                                        let CKEditorFuncNum = urlParams.get('CKEditorFuncNum')
                                        window.opener.CKEDITOR.tools.callFunction(CKEditorFuncNum, `${IMAGE_URL}/${row?.path}`)
                                        window.close()
                                    }}
                                    key={index}
                                    style={{ border: selectedFile?.index === index ? "1px var(--highlight-color) solid" : "" }}
                                >
                                    <div className='fm-image-container'>
                                        {/* <iframe allow="autoplay; fullscreen" autoSave="false" autoPlay="false" frameborder="0" 
      sandbox="allow-same-origin allow-scripts" src={`${IMAGE_URL}/${row?.path}`}></iframe> */}
                                        <img className='fm-image' src={`${IMAGE_URL}/${row?.path}`} />
                                    </div>
                                    <div className='fm-file-details'>
                                        <span className='fm-file-name' title={row.name}>{row.name}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                </div>
                {/* input type range to adjust thumbnail size */}
                {/* {layoutType === "block" && <>
                    <hr />
                    <div className='fm-footer'>
                        <div></div>
                        <div className="range" title='Size'>
                            <input
                                type="range"
                                className="form-range"
                                id="customRange1"
                                min={2}
                                max={6}
                                step={2}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value > 1) {
                                        setBlockSize(value)
                                    }
                                }}
                                value={blockSize}
                            />
                        </div>
                    </div>
                </>} */}
            </div>
            <PopupComponent
                id="addFolderrr"
                type="form"
                header={{
                    iconName: "fa-folder",
                    heading: "Add Folder"
                }}
                inputs={[
                    <>
                        <div className="col-md-3">
                            <label className="font-weight-bold" htmlFor="folderName">
                                Folder Name *
                            </label>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group-blk mb-3">
                                <input
                                    name="folderName"
                                    id="folderName"
                                    type="text"
                                    className={"form-control" + (folderName.error ? " is-invalid" : "")}
                                    value={folderName.value}
                                    onChange={e => setFolderName({value:e.target.value, error:""})}
                                />
                            </div>
                        </div>
                    </>,
                ]}
                bottomButton={[
                    <button type="button" className="btn btn-primary" title='Save' onClick={handleCreateFolder}>
                        <i className="fal fa-save"></i>
                        Save
                    </button>,
                    <button type="button" className="btn btn-danger" data-dismiss="modal" title='Close'>
                        <i className="fal fa-times"></i>
                        Close
                    </button>,
                    folderName.error ? <div className="invalid-feedback d-block">{folderName.error}</div> : null
                ]}
                otherProps={
                    {
                        secondPopupCss: "zindexadd"
                    }
                }
            />
        </div>
    )
}

export default FileSystem