import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { TABLE_ROWS_PER_PAGE } from "../../utils/Constants";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import hasPermission from "../../utils/hasMultiplePermission";
import Tablefilter from "../common/Tablefilter";
import {
  LayoutHeaderStatus,
  RenderWebsiteBrandTemplate,
} from "../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../utils/commonFunction";
import $ from "jquery";
import moment from "moment";
import { DeleteLayhoutCategory, getCategoryFilters, getCategoryList, getCategoryListCancelToken } from "../../services/LayoutCategoryServices";
import { RenderCategoriesType } from "../../utils/CommonGroupingItem";
import PermissionsGate from "../../utils/permissionGate";

function LayoutCategory() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [deleterow, setDeleteRow] = useState(false);
  const [updateData, setUpdateData] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({
    status: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateCategory"));
    if (storedFilters) {
      setStatus(storedFilters.status);
      setFilterData(storedFilters.filterData);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      filterData,
    };
    localStorage.setItem("filterStateCategory", JSON.stringify(filterState));
  }, [status, filterData]);
  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const cancelTokenSources = [];
    const getHeadersList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);

      const headersData = {
        page: page,
        perPage: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: false,
        viaStatus: status.arr,
      };

      try {
        const res = await getCategoryListCancelToken(headersData, source.token);
        setCategoryData(res?.data?.Data?.data);
        setTotalRows(res.data?.Data?.total);
        setPerPage(res.data?.Data?.perPage);
        if (res.status == 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };

    getHeadersList();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    status,
    searchStatusCheck,
    updateData,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCategoryFilters();
        setFilterData({
          ...res.data,
          status: res.data?.getStatus ? res.data?.getStatus : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["wbslncategoriesview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearch("");
    setDebouncedTerm("");
  };

  useEffect(() => {
    return resetFilter();
  }, [])

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteLayhoutCategory(id)
          .then((res) => {
            Swal.fire("Deleted!", "Category has been deleted.", "success");
            setUpdateData(!updateData);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "CategoryName",
      sortField: "CategoryName",
      sortable: true,
      cell: (row) => (
        <PermissionsGate scopes={["wbslncategoriesedit"]} RenderError={() => <p>{row?.CategoryName}</p>}>

          <Link
            className="as-text-blue curser feature-name"
            to={`/websiteManagement/layout_navigation/categories/category_information/open/${row.categoryID}`}
            title={row.CategoryName}
          >
            <span className="textLimit100">{row.CategoryName}</span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "Status",
      sortable: true,
      cell: (row) =>
        row?.Status?.toString() ? LayoutHeaderStatus(row.Status)?.html : "-",
    },
    {
      name: "Category Type",
      selector: "categoryType",
      sortField: "categoryType",
      sortable: true,
      cell: (row) =>
        row.categoryType
          ? RenderCategoriesType(row.categoryType, "programme")?.html
          : "-",
    },
    {
      name: "Linked Brand Templates",
      selector: "brandTemplete",
      sortField: "brandTemplete",
      sortable: false,
      cell: (row) => {
        if (row.brandTemplete?.length) {
          return (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {row?.brandTemplete.map((d, i) => (
                  <React.Fragment key={i}>
                    {RenderWebsiteBrandTemplate(d?.templateName)?.html}
                  </React.Fragment>
                ))}
              </div>
            </>
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: "Last Updated",
      selector: "updated_at",
      sortField: "updated_at",
      sortable: true,
      cell: (row) => moment(row?.updated_at)?.format("DD MMM YY") || "-",
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={['wbslncategoriesedit']} RenderError={() => (
              <button className="btn btn-primary rounded-circle" disabled>
                <i className="fal fa-folder-open"></i>
              </button>
            )}>

              <Link
                className="btn btn-primary rounded-circle"
                to={`/websiteManagement/layout_navigation/categories/category_information/open/${row.categoryID}`}
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate scopes={['wbslncategoriesdelete']} RenderError={() => (
              <button className="btn btn-danger rounded-circle" disabled>
                <i className="fal fa-trash-alt"></i>
              </button>
            )}>

              <button
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row.categoryID)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = ["Name", "Status", "Category Type", "Linked Brand Templates", "Last Update"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const categoryData = {
      page: page,
      perPage: perPage,
      keyval: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: true,
      viaStatus: status.arr,
    };

    getCategoryList(categoryData)
      .then((res) => {
        data = res.data.Data;
        data = data?.map((row) => ({
          ...row,
          Name: row?.CategoryName || "",
          Status: row?.Status ? row?.Status : "-",
          "Category Type": row?.categoryType ? row?.categoryType : "-",
          "Linked Brand Templates": row.brandTemplete.length > 0 ? row.brandTemplete.map((item)=> item.templateName).join(", ") : "-",
          "Last Update": moment(row?.updated_at)?.format("DD MMM YY") || "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType == "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row?.CategoryName,
              row?.Status,
              row?.categoryType ? row?.categoryType : "-",
              row?.brandTemplete?.map((d, i) => d?.templateName).join(",") ||
              "-",
              moment(row?.updated_at)?.format("DD MMM YY") || "-",
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData?.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={LayoutHeaderStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Layout_Category_list");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Layout_Category_list");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Layout_Category_list");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={['wbslncategoriesadd']} RenderError={() => (
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled
                    title="Create new"
                  >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )}>

                  <Link
                    to={`/websiteManagement/layout_navigation/categories/category_information/create`}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      title="Create new"
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          data={categoryData}
          progressComponent={<SkeletonTicketList />}
          defaultSortField={sortkey}
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          // noDataComponent={Str.noRecord}
          onSort={handleSort}
          sortServer
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default LayoutCategory;
