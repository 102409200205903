import React, { useEffect, useMemo, useState } from "react";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import DataTableComponent from "../../common/DataTableComponent";
import {
  GetStatemantTmpList,
  deleteStatemantTmp,
} from "../../../services/GradeSettingService";
import PermissionsGate from "../../../utils/permissionGate";
import axios from "axios";
import striptags from "striptags";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

function ListOfSOR({ setSelectedSORTemplate }) {
  const [search, setSearch] = useState("");
  const [qdata, setQData] = useState();
  const [loading, setLoading] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "ASC",
  });
  const { tab } = useParams();
  const history = useHistory()
  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);

      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        page: tableState.page,
        limit: tableState.perPage,
        search: search,
        key: tableState.sortKey,
        sort: tableState.sortOrder,
        exportStatus: "false",
      };

      try {
        const res = await GetStatemantTmpList(
          data,
          source.token
        );
        setQData(res.data.result.data);
        setTotalRows(res.data.result.total);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [search, tableState, updateList]);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["coursessortview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return (
          <PermissionsGate scopes={["coursessortedit"]} RenderError={() => (
            <span className=" font-weight-bold textLimit100" title="Open" disabled>
                {row?.name ? row?.name : "-"}
            </span>
        )}>
          <Link
            className="as-text-blue curser textLimit100"
            title={row?.name ? striptags(row?.name) : "-"}
            to={`/courseAdministration/gradingTemplate/editsor/${row?.id}`}
            onClick={() => {
              $("#SORTemplatemodal").modal("hide");
            }}
          >
            {row?.name ? row?.name : "-"}
          </Link>
          </PermissionsGate>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          {tab === "sorTable" ? (
            <div className="as-buttons d-flex">
              <PermissionsGate scopes={["coursessortedit"]} RenderError={() => (
                            <button className="btn btn-primary rounded-circle" title="Open" disabled>
                                <i className="fal fa-folder-open"></i>
                            </button>
                        )}>
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/courseAdministration/gradingTemplate/editsor/${row?.id}`}
                  onClick={() => {
                    $("#SORTemplatemodal").modal("hide");
                  }}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
              </PermissionsGate>
              <PermissionsGate
                scopes={["coursessortdelete"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-danger rounded-circle"
                  title="Delete SoR"
                  onClick={() => handleDeleteSoR(row.id)}
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              </PermissionsGate>
            </div>
          ) : (
            <div className="as-buttons d-flex">
              <button
                className="btn btn-primary rounded-circle"
                title="Select Template"
                onClick={() => {
                  setSelectedSORTemplate(row);
                  $("#SORTemplatemodal").modal("hide");
                }}
              >
                <i className="fal fa-plus"></i>
              </button>
            </div>
          )}
        </div>
      ),
    },
  ]);

  const handleDeleteSoR = (sorId) => {


    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStatemantTmp(sorId).then(res => {
          Swal.fire("Deleted!", "SoR has been deleted", "success")
          setUpdateList(prev => !prev)
        }).catch((error) => {
          Swal.fire("Error", error.response.data.message, "error")
        })
      }
    });
    
  }

  const exportData = async (fileType, fileName) => {
    let data;

    const header = ["Name"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetStatemantTmpList({
      page: tableState.page,
      limit: tableState.perPage,
      search: search,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      exportStatus: "true",
    }).then((res) => {
      data = res.data.result;
      data = data?.map((row) => ({
        ...row,
        Name: row?.name ? row?.name : "-",
      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["Name"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

  return (
    <DataTableComponent
      exportFileName="Statement_of_Result_Template"
      exportFunction={exportData}
      columns={columns}
      data={qdata}
      loading={loading}
      isActionAlignRight
      isInsidePopUp={tab !== "sorTable"}
      setSearch={setSearch}
      setState={setTableState}
      state={tableState}
      totalRows={totalRows}
      tableButton={[
        <div className="search-filter-div-right">
          <div className=" filter-search-bar-blk">
            <div className="add-ticket-blk button-reset dropdown-comman">
              <PermissionsGate
                scopes={["coursessortadd"]}
                RenderError={() => (
                  <button
                    disabled
                    className="btn btn-primary"
                    title="Create New"
                  >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )}
              >
                <Link
                  className="btn btn-primary"
                  to={`/courseAdministration/gradingTemplate/addsor`}
                  title="Create New"
                >
                  <i className="fal fa-plus"></i>Create New
                </Link>
              </PermissionsGate>
            </div>
          </div>
        </div>,
      ]}
    />
  );
}

export default ListOfSOR;
