import axiosInstance from "../utils/axios";

export const GetRootFolders = async (queryParams = '') => {
    return await axiosInstance().get(`/getSharepointRoot${queryParams}`);
  };

  export const getSharepointFolderContents = async (folderID, queryParams = '') => {
    return await axiosInstance().get(`/getSharepointFolderContents?folderID=${folderID}${queryParams}`);
};

export const uploadToSharepoint = async (values, queryParams = '') => {
  return await axiosInstance().post(`/uploadToSharepoint${queryParams}`, values);
};

export const uploadLargeFilesToSharepoint = async (values,  queryParams = '') => {
    return await axiosInstance().post(`/UploadLargeFileToSharepoint${queryParams}`, values);
};

export const SharepointCreateFolder = async (values, queryParams = '') => {
    return await axiosInstance().post(`/SharepointCreateFolder${queryParams}`, values);
};
