import React,{ useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageComponent from "../components/common/PageComponent";
import SingleAuditTrail from "../components/systemadministration/BrandManagement/SingleAuditTrail";
import GradeMixDetails from "../components/courses/Programs/GradeMixDetails";
import GradeMixGradeBook from "../components/courses/Programs/GradeMixGradeBook";
import GradeMixAuditTrail from "../components/courses/Programs/GradeMixAuditTrail";

const GradeMix = () => {
    const [gradeName, setGradeName] = useState("")

    const { tab, id, subId } = useParams();

    const tableTabs = [
        {
            globalIconName: "gradeMixDetails",
            url: `/courseAdministration/gradeMix/${id}/details/${subId}/open`,
            condition: tab === "details"
        },
        {
            globalIconName: "gradeMixGradebook",
            url: `/courseAdministration/gradeMix/${id}/gradebook/${subId}/open`,
            condition: tab === "gradebook"
        },
        {
            globalIconName: "auditTrail",
            url: `/courseAdministration/gradeMix/${id}/auditTrail/${subId}/open`,
            condition: tab === "auditTrail"
        },
    ]

    return (
        <PageComponent heading={["Grade Mix", gradeName]}
            isBackButtonShown={true}
            // auditTrailUrl={`/courseAdministration/gradeMix/${id}/auditTrail/${subId}/open`}
            componentToRender={
                [
                    {
                        condition: tab === "details",
                        component: <GradeMixDetails setGradeName={setGradeName}/>
                    },
                    {
                        condition: tab === "gradebook",
                        component: <GradeMixGradeBook setGradeName={setGradeName}/>
                    },
                    {
                        condition: tab === "auditTrail",
                        component: <GradeMixAuditTrail/>
                    },
                ]
            }
            tabs={tableTabs}
        />
    );
};

export default GradeMix;
