import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getSubjects } from "../../../../services/RegistrationService";

function SubjectCheck(props) {

  const [studentData, setStudentData] = useState();
  const [reWriteData, setReWriteData] = useState();
  const [qualificationData, setQualificationData] = useState();
  const [subjectList1, setSubjectList1] = useState([]);
  const [subjectList2, setSubjectList2] = useState([]);
  const [subjectList3, setSubjectList3] = useState([]);
  const [subjectList4, setSubjectList4] = useState([]);
  const [subjectList5, setSubjectList5] = useState([]);
  const [reRegIntakesProgress, setReRegIntakesProgress] = useState();

  let dropdownValues = {
    registerFor: [
      {label: "First Year - Bachelor of Arts Degree in Interior Design",value: "1"},
      {label: "First Year - Bachelor of Arts Degree in Graphic Design",value: "2"},
      {label: "First Year - Bachelor of Arts Degree in Multimedia Design",value: "3"},
      {label: "Second Year - BA Graphic Design",value: "4"},
      {label: "Second Year - BA Multimedia Design",value: "5"},
      {label: "Second Year - BA Interior Design",value: "6"},
      {label: "Third year-Graphic Design",value: "7"},
      {label: "Third year-Interior Design",value: "8"},
      {label: "Third year Multimedia Design",value: "9"},
      {label: "BA Honours-Interior Design",value: "10"},
      {label: "BA Honours Multimedia Design",value: "11"},
      {label: "BA Honours-Visual Communication",value: "12"},
      {label: "Higher Certificate in Game Design and Development",value: "21"},
      {label: "Higher Certificate in Creative Music Technology",value: "22"},      
      {label: "Diploma in Music Technology (1st Year)",value: "23"},
      {label: "Diploma in Music Technology (2nd Year)",value: "24"},
      {label: "Diploma in Music Technology (3rd Year)",value: "25"}
    ],
    repeat: [
      {label: "Critical Studies",value: "4"},
      {label: "Design Studio, Visual Communication and Technology and Professional Practice",value: "5"},
      {label: "Minor Module",value: "6"},
      {label: "Major Module",value: "7"},
      {label: "Applied Digital Arts 101",value: "101"},             
      {label: "3D Modelling and Animation 101",value: "102"},             
      {label: "Game Design Practice 101",value: "103"},             
      {label: "Game Development and Scripting 101",value: "104"},             
      {label: "Contemporary Game Theory 101",value: "105"},             
      {label: "Audio for Game Design",value: "106"},             
      {label: "Audio Fundamentals 101",value: "107"},             
      {label: "Modern Music Theory 101",value: "108"},             
      {label: "Music Ear Training 101",value: "109"},             
      {label: "Music Production Technique 101",value: "110"},             
      {label: "Music Software Cubase 101",value: "111"},             
      {label: "Studio Sound Engineering 101",value: "112"},             
      {label: "Signal Processing 102",value: "113"},             
      {label: "Modern Music Theory 102",value: "114"},             
      {label: "Music Ear Training 102",value: "115"},             
      {label: "Music Production Technique 102",value: "116"},             
      {label: "Music Software FL Studio 101",value: "117"},             
      {label: "Recording Projects 102",value: "118"},             
      {label: "Music Production Software 101",value: "119"},             
      {label: "Music Production Software 110",value: "120"},             
      {label: "Music for Game Audio 102",value: "121"},             
      {label: "Music Production Technique 201",value: "122"},             
      {label: "Studio Sound Engineering 201",value: "123"},             
      {label: "Modern Music Theory 201",value: "124"},             
      {label: "Music Business 201",value: "125"},             
      {label: "Music Production Software 201",value: "126"},                       
      {label: "Live Sound Engineering 201",value: "128"},             
      {label: "Music Production Software 210",value: "129"},             
      {label: "Audio Post Production 301",value: "130"},             
      {label: "Music Business 301",value: "131"},             
      {label: "Music Industry Praxis 301",value: "132"},             
      {label: "Audio Mastering Techniques 302",value: "133"},           
      {label: "Audio Ear Training 201",value: "134"},         
      {label: "Recording Project 102",value: "135"},         
      {label: "Music Business Entrepreneurship 301",value: "136"}           
    ],
    progress : [
      {label : "Progress to SSD 2nd Year",value : "Progress to SSD 2nd Year"}
    ],
    elective :[
      {label: "Media Music Composition 301",value: "1001"},             
      {label: "Electronic Music Production 301",value: "1002"},             
      {label: "Sound Synthesis and Design 301",value: "1003"},             
      {label: "Studio Sound Engineering 301",value: "1004"}
    ]
  }

  useEffect(() => {
    setStudentData(props.studentData);
    let rewrite = props.studentData?.re_submission;
    let qualification = props.studentData?.progress_list;
    let modifiedArr = []
    let qualificationArr = []
    rewrite && rewrite.map(elem => {
      modifiedArr.push({ value: elem?.intakeID, label: elem.courseName + " - ( Intake-" + elem.number + "-" + elem.year + "-" + elem.type + ") " });
    })
    qualification && qualification.map(elem => {
      qualificationArr.push({ value: elem.id, label: elem.course + " - ( Intake-" + elem.number + "-" + elem.year + "-" + elem.type + ") " });
    })
    setReWriteData(modifiedArr)
    setQualificationData(qualificationArr)
    formik.setFieldValue("progress_intake_id", props.studentData?.reRegIntakesProgress[0]?.id);

    if (props && props.subjectCheckIds && props.subjectCheckIds.totalArr) {
      props.subjectCheckIds.totalArr.map((item, index) => {
        getSubjectsList(item.qualification, ++index)
      })
    }
  }, [props]);

  const formik = useFormikContext();


  const getSubjectsList = (id, num) => {
    if (id) {
      getSubjects(id).then(response => {
        let subjectList = response.data.SubjectsList
        let modifiedArr = []
        subjectList && subjectList.map(elem => {
          modifiedArr.push({ value: elem?.intakeID, label: elem.courseName + " - ( Intake-" + elem.number + "-" + elem.year + "-" + elem.type + ") " });
        })
        if (num == 1) {
          setSubjectList1(modifiedArr)
        }
        else if (num == 2) {
          setSubjectList2(modifiedArr)
        }
        else if (num == 3) {
          setSubjectList3(modifiedArr)
        }
        else if (num == 4) {
          setSubjectList4(modifiedArr)
        }
        else if (num == 5) {
          setSubjectList5(modifiedArr)
        }
      }).catch(error => {
        console.log("error >>>>> ", error);
      })
    }
  }

  const reWriteDiv = [1, 2, 3, 4, 5].map((item, index) => {
    return (
      <div className="row align-items-center mb-4" key={index}>
        <div className="col-md-3">
          <label htmlFor={`Rewrite${item}`}>Re-Write</label>
        </div>
        <div className="col-md-4">
          <div className="form-icon-group">
            <Select
              className={"form-control custom-select-box "}
              name={`Rewrite${item}`}
              value={reWriteData && reWriteData.filter((data) => {
                return (props.reWriteIds && props.reWriteIds[`reWrite${item}`]) == data.value
              })}
              onChange={(value) => {
                if (value) {
                  props.setReWriteIds(oldVal => ({ ...oldVal, [`reWrite${item}`]: value.value }))
                } else {
                  props.setReWriteIds(oldVal => ({ ...oldVal, [`reWrite${item}`]: "" }))
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={reWriteData}
              maxMenuHeight={175}
              placeholder={(props.reWriteIds && props.reWriteIds[`reWrite${item}`]) ? (props.reWriteIds[`reWrite${item}`]) : "Select"}
            />
          </div>
        </div>
      </div>
    )
  })

  const reSubmissionDiv = [1, 2, 3, 4, 5, 6, 7].map((item, index) => {
    return (
      <div className="row align-items-center mb-4" key={index}>
        <div className="col-md-6 col-lg-3">
          <label htmlFor={`ReSubmission${item}`}>Re-Submission</label>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="form-icon-group">
            <Select
              className={"form-control custom-select-box "}
              name={`ReSubmission${item}`}
              value={reWriteData && reWriteData.filter((data) => {
                return (props.reSubmissionIds && props.reSubmissionIds[`reSubmission${item}`]) == data.value
              })}
              onChange={(value) => {
                if (value) {
                  props.setReSubmissionIds(oldVal => ({ ...oldVal, [`reSubmission${item}`]: value.value }))
                } else {
                  props.setReSubmissionIds(oldVal => ({ ...oldVal, [`reSubmission${item}`]: "" }))
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={reWriteData}
              maxMenuHeight={175}
              placeholder={(props.reWriteIds && props.reWriteIds[`reSubmission${item}`]) ? (props.reWriteIds[`reSubmission${item}`]) : "Select"}
            />
          </div>
        </div>
      </div>
    )
  })

  const getRedoFieldCount = () =>{
    if(props.reRegData.academy == 'beauty' || props.reRegData.academy == 'IT' || props.reRegData.academy == 'business'){      
      if(props?.redoIds?.totalArr && props?.redoIds?.totalArr.length && props?.redoIds?.totalArr.length>5){        
        return Object.keys(props.redoIds.totalArr).map(key => parseInt(key)+1)
      }
      return [1, 2, 3, 4, 5]
    }

    return [1, 2, 3, 4, 5];
  }

  const redoDiv = getRedoFieldCount().map((item, index) => {
    return (
      <div className="row align-items-center mb-4" key={index}>
        <div className="col-md-3">
          <label htmlFor={`Redo${item}`}>Re-Do</label>
        </div>
        <div className="col-md-4">
          <div className="form-icon-group">
            <Select
              className={"form-control custom-select-box "}
              name={`Redo${item}`}
              value={reWriteData && reWriteData.filter((data) => {
                return (props.redoIds && props.redoIds[`redo${item}`]) == data.value
              })}
              onChange={(value) => {
                if (value) {
                  props.setRedoIds(oldVal => ({ ...oldVal, [`redo${item}`]: value.value }))
                } else {
                  props.setRedoIds(oldVal => ({ ...oldVal, [`redo${item}`]: "" }))
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={reWriteData}
              maxMenuHeight={175}
              placeholder={(props.redoIds && props.redoIds[`redo${item}`]) ? (props.redoIds[`redo${item}`]) : "Select"}
            />
          </div>
        </div>
      </div>)
  })

  const progressDiv = <>
    <p className="subjectcheck-title" >Progress</p>

    <div className="row align-items-center mb-4">
      <div className="col-md-3">
        <label htmlFor="Qualification1">Qualification </label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box "}
            name="Qualification1"
            value={qualificationData?.filter((data) => {
              return (props.subjectCheckIds && props.subjectCheckIds.qualification1) == data.value
            })}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification1: value.value, subject1: "", subjectName1: "" }))
                getSubjectsList(value.value, 1)
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification1: "", subject1: "", subjectName1: "" }))
                getSubjectsList("", 1)
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={qualificationData}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification1 ? props.subjectCheckIds.qualification1 : "Select"}
          />
        </div>
      </div>
      <div className="col-md-3">
        <label htmlFor="Subject1">Subject</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Subject1 && formik.touched.Subject1 ? " is-invalid" : "")}
            name="Subject1"
            value={subjectList1.filter(item => props.subjectCheckIds && props.subjectCheckIds.subject1 == item.value)}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject1: value.value }))
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject1: "" }))
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={subjectList1}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification1 && props.subjectCheckIds.qualification1 != "0" && props.subjectCheckIds && props.subjectCheckIds.subject1 ? props.subjectCheckIds.subject1 : props.subjectCheckIds.subjectName1 ? props.subjectCheckIds.subjectName1 : "Select"}
          />
        </div>
      </div>
    </div>

    <div className="row align-items-center mb-4">
      <div className="col-md-3">
        <label htmlFor="Qualification2">Qualification</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Qualification2 && formik.touched.Qualification2 ? " is-invalid" : "")}
            name="Qualification2"
            value={qualificationData?.filter((data) => {
              return (props.subjectCheckIds && props.subjectCheckIds.qualification2) == data.value
            })}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification2: value.value, subject2: "", subjectName2: "" }))
                getSubjectsList(value.value, 2)
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification2: "", subject2: "", subjectName2: "" }))
                getSubjectsList("", 2)
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={qualificationData}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification2 ? props.subjectCheckIds.qualification2 : "Select"}
          />
        </div>
      </div>
      <div className="col-md-3">
        <label htmlFor="Subject2">Subject</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box "}
            name="Subject2"
            value={subjectList2.filter(item => (props.subjectCheckIds && props.subjectCheckIds.subject2) == item.value)}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject2: value.value }))
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject2: "" }))
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={subjectList2}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification2 && props.subjectCheckIds.qualification2 != "0" && props.subjectCheckIds.subject2 ? props.subjectCheckIds.subject2 : props.subjectCheckIds.subjectName2 ? props.subjectCheckIds.subjectName2 : "Select"}
          />
        </div>
      </div>
    </div>

    <div className="row align-items-center mb-4">
      <div className="col-md-3">
        <label htmlFor="Qualification3">Qualification</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Qualification3 && formik.touched.Qualification3 ? " is-invalid" : "")}
            name="Qualification3"
            value={qualificationData?.filter((data) => (props.subjectCheckIds && props.subjectCheckIds.qualification3) == data.value)}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification3: value.value, subject3: "", subjectName3: "" }))
                getSubjectsList(value.value, 3)
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification3: "", subject3: "", subjectName3: "" }))
                getSubjectsList("", 3)
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={qualificationData}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification3 ? props.subjectCheckIds.qualification3 : "Select"}
          />
        </div>
      </div>
      <div className="col-md-3">
        <label htmlFor="Subject3">Subject</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Subject3 && formik.touched.Subject3 ? " is-invalid" : "")}
            name="Subject3"
            value={subjectList3.filter(item => (props.subjectCheckIds && props.subjectCheckIds.subject3) == item.value)}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject3: value.value }))
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject3: "" }))
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={subjectList3}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification3 && props.subjectCheckIds.qualification3 != "0" && props.subjectCheckIds.subject3 ? props.subjectCheckIds.subject3 : props.subjectCheckIds.subjectName3 ? props.subjectCheckIds.subjectName3 : "Select"}
          />
        </div>
      </div>
    </div>
    <div className="row align-items-center mb-4">
      <div className="col-md-3">
        <label htmlFor="Qualification4">Qualification</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Qualification4 && formik.touched.Qualification4 ? " is-invalid" : "")}
            name="Qualification4"
            value={qualificationData?.filter((data) => {
              return props.subjectCheckIds && props.subjectCheckIds.qualification4 == data.value
            })}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification4: value.value, subject4: "", subjectName4: "" }))
                getSubjectsList(value.value, 4)
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification4: "", subject4: "", subjectName4: "" }))
                getSubjectsList("", 4)
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={qualificationData}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification4 ? props.subjectCheckIds.qualification4 : "Select"}
          />
        </div>
      </div>
      <div className="col-md-3">
        <label htmlFor="Subject4">Subject</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Subject4 && formik.touched.Subject4 ? " is-invalid" : "")}
            name="Subject4"
            value={subjectList4.filter(item => (props.subjectCheckIds && props.subjectCheckIds.subject4) == item.value)}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject4: value.value }))
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject4: "" }))
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={subjectList4}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification4 && props.subjectCheckIds.qualification4 != "0" && props.subjectCheckIds.subject4 ? props.subjectCheckIds.subject4 : props.subjectCheckIds.subjectName4 ? props.subjectCheckIds.subjectName4 : "Select"}
          />
        </div>
      </div>
    </div>
    <div className="row align-items-center mb-4">
      <div className="col-md-3">
        <label htmlFor="Qualification5">Qualification</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Qualification5 && formik.touched.Qualification5 ? " is-invalid" : "")}
            name="Qualification5"
            value={qualificationData?.filter((data) => {
              return (props.subjectCheckIds && props.subjectCheckIds.qualification5) == data.value
            })}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification5: value.value, subject5: "", subjectName5: "" }))
                getSubjectsList(value.value, 5)
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, qualification5: "", subject5: "", subjectName5: "" }))
                getSubjectsList("", 5)
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={qualificationData}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification5 ? props.subjectCheckIds.qualification5 : studentData?.sp_students_progress_id[4]?.name ? studentData?.sp_students_progress_id[4]?.name : "Select"}
          />
        </div>
      </div>
      <div className="col-md-3">
        <label htmlFor="Subject5">Subject</label>
      </div>
      <div className="col-md-3">
        <div className="form-icon-group">
          <Select
            className={"form-control custom-select-box " + (formik.errors.Subject5 && formik.touched.Subject5 ? " is-invalid" : "")}
            name="Subject5"
            value={subjectList5.filter(item => (props.subjectCheckIds && props.subjectCheckIds.subject5) == item.value)}
            onChange={(value) => {
              if (value) {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject5: value.value }))
              } else {
                props.setSubjectCheckIds(oldVal => ({ ...oldVal, subject5: "" }))
              }
            }}
            isClearable
            onBlur={formik.handleBlur}
            options={subjectList5}
            maxMenuHeight={175}
            placeholder={props.subjectCheckIds && props.subjectCheckIds.qualification5 && props.subjectCheckIds.qualification5 != "0" && props.subjectCheckIds.subject5 ? props.subjectCheckIds.subject5 : props.subjectCheckIds.subjectName5 ? props.subjectCheckIds.subjectName5 : "Select"}
          />
        </div>
      </div>
    </div>
  </>

  const registerForDiv = [1].map((item, index) => {
    return (
      <div className="row align-items-center mb-4" key={index}>
        <div className="col-md-3">
          <label htmlFor={`registerFor${item}`}>Register For</label>
        </div>
        <div className="col-md-4">
          <div className="form-icon-group">
            <Select
              className={"form-control custom-select-box "}
              name={`registerFor${item}`}
              value={dropdownValues.registerFor && dropdownValues.registerFor.filter((data) => {
                return (props.registerFor && props.registerFor[`registerFor${item}`]) == data.value
              })}
              onChange={(value) => {
                if (value) {
                  props.setRegisterFor(oldVal => ({ ...oldVal, [`registerFor${item}`]: value.value }))
                } else {
                  props.setRegisterFor(oldVal => ({ ...oldVal, [`registerFor${item}`]: "" }))
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.registerFor}
              maxMenuHeight={175}
              placeholder={(props.registerFor && props.registerFor[`registerFor${item}`]) ? (props.registerFor[`registerFor${item}`]) : "Select"}
            />
          </div>
        </div>
      </div>)
  })

  const getRepeatField = () =>{
    if(props.reRegData.academy == 'gdc'){
      return [1,2,3,4]
    }
    return [1, 2, 3, 4, 5, 6, 7]
  }

  const repeatDiv = getRepeatField().map((item, index) => {
    return (
      <div className="row align-items-center mb-4" key={index}>
        <div className="col-md-3">
          <label htmlFor={`repeat${item}`}>Repeat</label>
        </div>
        <div className="col-md-4">
          <div className="form-icon-group">
            <Select
              className={"form-control custom-select-box "}
              name={`repeat${item}`}
              value={dropdownValues.repeat && dropdownValues.repeat.filter((data) => {
                return (props.repeat && props.repeat[`repeat${item}`]) == data.value
              })}
              onChange={(value) => {
                if (value) {
                  props.setRepeat(oldVal => ({ ...oldVal, [`repeat${item}`]: value.value }))
                } else {
                  props.setRepeat(oldVal => ({ ...oldVal, [`repeat${item}`]: "" }))
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.repeat}
              maxMenuHeight={175}
              placeholder={(props.repeat && props.repeat[`repeat${item}`]) ? (props.repeat[`repeat${item}`]) : "Select"}
            />
          </div>
        </div>
      </div>)
  })

  const electiveDiv = [1, 2].map((item, index) => {
    return (
      <div className="row align-items-center mb-4" key={index}>
        <div className="col-md-3">
          <label htmlFor={`elective${item}`}>Repeat Electives</label>
        </div>
        <div className="col-md-4">
          <div className="form-icon-group">
            <Select
              className={"form-control custom-select-box "}
              name={`elective${item}`}
              value={dropdownValues.elective && dropdownValues.elective.filter((data) => {
                return (props.elective && props.elective[`elective${item}`]) == data.value
              })}
              onChange={(value) => {
                if (value) {
                  props.setElective(oldVal => ({ ...oldVal, [`elective${item}`]: value.value }))
                } else {
                  props.setElective(oldVal => ({ ...oldVal, [`elective${item}`]: "" }))
                }
              }}
              isClearable
              onBlur={formik.handleBlur}
              options={dropdownValues.elective}
              maxMenuHeight={175}
              placeholder={(props.elective && props.elective[`elective${item}`]) ? (props.elective[`elective${item}`]) : "Select"}
            />
          </div>
        </div>
      </div>)
  })

  const divRenderCondition = () => {
    if (props.reRegData.academy == 'engineering') {

      return (
        <>
          {/* // $('#re_write_div').show(); */}
          {/* {reWriteDiv} */}
          {/* // $('#re_do_div').show(); */}
          {redoDiv}
          {/* // $('#progress_div').show(); */}
          {progressDiv}
        </>
      )
    } else if (props.reRegData.academy == 'draughting') {
      return <>
        {reWriteDiv}
        {redoDiv}
      </>
      // $('#re_write_div').hide();
      // reWriteDiv()
      // $('#re_do_div').hide();
      // redoDiv()
      // $('#progress_div').hide();
      // progressDiv()
    } else if (props.reRegData.academy == 'draughting' && props.reRegData['qualification'] !== 'draughting_NDSSD') {
      return ""
      // $('#subject_check_div').show();
      // $('#progress_div').hide();
      // $('#progress_div_for_draughting').show();


    } else if (props.reRegData.academy == 'architecture') {
      return reSubmissionDiv
      // $('#re_write_div').hide();
      // $('#re_do_div').hide();
      // $('#progress_div').hide();
    } else if (props.reRegData.academy == 'gdc') {
      return <>
        {registerForDiv}
        {repeatDiv}
      </>
    } else if (props.reRegData.academy == 'fst' || props.reRegData.academy == 'fg') {
      return <>
        {registerForDiv}
        {repeatDiv}
        {electiveDiv}
      </>
    }else if(props.reRegData.academy == 'beauty' || props.reRegData.academy == 'IT' || props.reRegData.academy == 'business'){
      return <>
      {redoDiv}
      </>
    }
  }


  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Subject Checks</div>
      </div>
      <div className="row align-items-center mb-4">
        <div className="col-md-12 col-lg-7">
          <label htmlFor="StudentSelection">Student Selection</label>
          <div className="form-icon-group">
            <textarea
              cols={5}
              rows={10}
              type="text"
              id="StudentSelection"
              className={"form-control" + (formik.errors.StudentSelection && formik.touched.StudentSelection ? " is-invalid" : "")}
              name="StudentSelection"
              placeholder="Student Selection"
              value={formik.values.StudentSelection}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="Student Selection"
            />
            {formik.errors.StudentSelection && formik.touched.StudentSelection ? (
              <span className="exclamation">
                <i className="fal fa-exclamation-circle"></i>
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {!props.toShowBachleor && divRenderCondition()}
      {/* {(props.reRegData.academy !== "architecture" && !(props.reRegData.academy == "draughting" && props.reRegData['qualification'] == "draughting_NDSSD") && (props.reRegData.academy == 'engineering' && props.status != 'Re-Registration') && props.reRegData.academy !== 'business') && (<>


    </>)}
    {(props.reRegData.academy !== "architecture" && !(props.reRegData.academy == "draughting" && props.reRegData['qualification'] == "draughting_NDSSD") && (props.reRegData.academy == 'engineering' && props.status != 'Re-Registration') && props.reRegData.academy !== 'business') && (<>

    </>)}
    {(props.reRegData.academy !== "architecture" && !(props.reRegData.academy == "draughting" && props.reRegData['qualification'] !== "draughting_NDSSD") && (props.reRegData.academy == 'engineering' && props.status != 'Re-Registration') || props.reRegData.academy == 'business') &&  */}
      {
        props.reRegData['academy'] == "architecture" || (props.reRegData['academy'] == "draughting" && props.reRegData['qualification'] == "draughting_NDSSD") || (props.reRegData['academy'] == "draughting") ?
          <div className="row align-items-center mb-4">
            <div className="col-md-6 col-lg-3">
              <label htmlFor="progress_q1">Progress </label>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-icon-group">
                <Select
                  className={"form-control custom-select-box " + (formik.errors.progress_q1 && formik.touched.progress_q1 ? " is-invalid" : "")}
                  name="progress_q1"
                  value={qualificationData?.filter((data) => {
                    return data.value == formik.values.progress_q1
                  })}
                  onChange={async (value) => {
                    if (value) {
                      formik.setFieldValue("progress_q1", value.value);
                      setReRegIntakesProgress(value.label)
                    } else {
                      formik.setFieldValue("progress_q1", "");
                      setReRegIntakesProgress("")
                    }
                  }}
                  isClearable
                  onBlur={formik.handleBlur}
                  options={qualificationData}
                  maxMenuHeight={175}
                  placeholder={reRegIntakesProgress ? reRegIntakesProgress : "Select"}
                />
              </div>
            </div>
          </div> :""
      }
    </div >
  );
}

export default SubjectCheck;
