import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { CKEditor } from "ckeditor4-react";
import HtmlParser from "react-html-parser";
import { addAndEditStatemantTmp } from "../../../services/GradeSettingService";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PermissionsGate from "../../../utils/permissionGate";

function SORForm({ templateData, setUpdateData,updateData, loading }) {
  const history = useHistory();
  const { id,tab } = useParams()
  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: templateData.name && id ? templateData.name : "",
      disclaimer: templateData.disclaimer && id ? templateData.disclaimer : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required ").trim(),
      disclaimer: Yup.string().test(
        "required",
        "Disclaimer is Required",
        (value) => {
          console.log("ASdfasd", value)
          const tempEditor = value;
          let isInvalid = !tempEditor;
          return !isInvalid;
        }
      ),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.set("name", values.name);
      formData.set("disclaimer", values.disclaimer);
      if (id) {
        formData.set("id", id);
      }
      
      addAndEditStatemantTmp(formData).then(res => {
        Swal.fire("success", `SoR ${tab == "addsor" ? "Added" : "Updated"} Successfully!`, "success");
        setUpdateData(prevVal => !prevVal)
        setDisabled(false);
        if(tab == "addsor") {

        history.push(`/courseAdministration/gradingTemplate/sorTable`);
        }
      }).catch(err => {
        console.error("error: ", err)
      })
    },
  });


  return (
    <div className="card card-body-inr">
      <div className="edit-icon new-card-header">
        <div className="card-header">
          Template Information{" "}
          {loading ? <i className="fas fa-cog fa-spin"></i> : ""}
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <label htmlFor="Name"> Name *</label>
            <div className="form-icon-group mb-4" title="Name">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.name && formik.touched.name
                    ? " is-invalid"
                    : "")
                }
                name="name"
                id="name"
                placeholder="Name"
                title="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <label>Disclaimer *</label>
            {loading ? "" : <CKEditor
              initData={HtmlParser(formik.values.disclaimer)}
              onChange={({ editor }) => {
                formik.setFieldValue("disclaimer", editor.getData());
              }}
              readOnly={false}
              config={{
                versionCheck : false,
                toolbar: [
                  { name: "styles", items: ["Format", "Heading"] },
                  {
                    name: "basicstyles",
                    items: [
                      "Bold",
                      "Italic",
                      "Underline",
                      "BulletedList",
                      "NumberedList",
                    ],
                  },
                  {
                    name: "paragraph",
                    items: ["JustifyLeft", "JustifyCenter", "JustifyRight"],
                  },
                  { name: "insert", items: ["Table"] },
                ],
                removeButtons: "PasteFromWord,Source,Undo,Redo,Anchor,Image,Link,Unlink,Subscript,Superscript,Indent,Outdent,CreateDiv,Styles,Font,FontSize,ShowBlocks",
              }}
            />}
          </div>
        </div>

        <div className="form-group form-group-save-cancel mt-4">
          <PermissionsGate scopes={['coursessortedit']} RenderError={
            () => 
              <button className="btn btn-save btn-success" type="button" disabled title="Save">
                {disabled === false? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                    Save
                  </button>
            
          }>
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            // onClick={() => history.goBack()}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => {
              if(id) {
                formik.resetForm();
                setUpdateData(!updateData)
              } else {
                history.goBack();
              }
            }}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {Object.keys(formik.values).map((key) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={key}>
                  {formik.errors[key]}
                </div>
              );
            }
          })}
        </div>
      </form>
    </div>
  );
}

export default SORForm;
