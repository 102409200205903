import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from "react-select";
import {
  CreateFeaturesBenefits,
  DeleteBenefitAndFeature,
  ListFeaturesBenefits,
  ListFeaturesBenefitsCancelToken,
} from "../../../services/ProgramService";
import { useFormik } from "formik";
import * as Yup from "yup";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import { formatQuestion } from "../../../utils/FormatQuestion";
import { RenderFeatureAndBenefits } from "../../../utils/CommonGroupingItem";
import PermissionsGate from "../../../utils/permissionGate";
import { TrimText } from "../../common/TrimText";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";
import HtmlParser from "react-html-parser";
import { RendeFBStatus } from "../../../utils/CommonStatusItems";
const statusArr = [
  {
    value: "1",
    label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `),
  },
  {
    value: "2",
    label: HtmlParser(`<span className="skyblue" title="New"> New </span>`),
  },
  {
    value: "3",
    label: HtmlParser(` <span className="green" title="Final">Final</span>`),
  },
  {
    value: "4",
    label: HtmlParser(
      `<span className="yellowshade" title="Approved">Approved</span>`
    ),
  },
  {
    value: "5",
    label: HtmlParser(
      `<span className="blue" title="Inactive">Inactive</span>`
    ),
  },
];

const ListOfFeatures = (props) => {
  const history = useHistory();
  // console.log("assigned_modules",data)
  const { id, type, tab } = useParams();

  const [search1, setSearch1] = useState("");
  const [featuresData, setFeaturesData] = useState({});
  const [topics, setTopics] = useState([]);
  const [loadtopics, setLoadTopics] = useState(true);
  const [dummy, setDummy] = useState();
  const [disabledTopic, setDisabledTopic] = useState(false);
  const [editorState1, setEditorState1] = useState("");
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [modalIcon, setModalIcon] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    handleTableScroll()
  }, [loadtopics])
  useEffect(() => {
    const cancelTokenSources = [];
    const listOfTopics = async() => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      let id="";
      try {
        const res = await ListFeaturesBenefitsCancelToken(id, undefined, source.token);
        setTopics(res.data.data);
        if (res.status === 200) {
          setLoadTopics(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoadTopics(false);
        }
      }
    };

    listOfTopics();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [dummy, type, reload]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pmcfandbview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const FeatureType = [
    { value: "program", label: "Program" },
    { value: "institute", label: "Institute" },
    { value: "faculty", label: "Faculty" },
  ];

  const resetFilter1 = () => {
    setSearch1("");
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = topics;
    if (search1.length) {
      let tempId = allData.filter((item) => {
        let includes = item.name
          .toString()
          .toLowerCase()
          .includes(search1.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [...tempId];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search1.length) {
      return updatedData;
    } else {
      return topics;
    }
  };
  const typeSort = (a, b) => {
    return a &&
      a.type.toString().toLowerCase() > b &&
      b.type.toString().toLowerCase()
      ? 1
      : -1;
  };

  const handleSearchFilter1 = (e) => {
    const value = e.target.value;
    setSearch1(value);
  };

  const exportData = (fileType, fileName) => {
    let data, header;
    data = dataToRender();
    header = ["Name", "Type"];
    data = data.map((row) => ({
      ...row,
      // ID: row.id ? row.id : "-",
      "Name": row.name ? row.name : "-",
      Type: row.type ? RenderFeatureAndBenefits(row.type).text : "-",
    }));
    // }

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      let compatibleData;
      compatibleData = data.map((row) => {
        return [row["Name"], row["Type"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const sortName = (a, b) => {
    let nameA = a.name.toString().trim().toLowerCase();
    let nameB = b.name.toString().trim().toLowerCase();
    return nameA.localeCompare(nameB);
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
      sortFunction:sortName,
      cell: (row) => (row.name ? 
        <PermissionsGate scopes={["pmcfandbedit"]} RenderError={() => (
          <p>{<span className="feature-name"> <span className="textLimit100">{row.name}</span> </span>}</p>
        )}>
       <span  title={row.name}  onClick={() => {
        setShowCkEditor(true);
        // $("#feature-popup").modal("show");
        setFeaturesData(row);
        setModalIcon(true)
        formik.setFieldValue("type", row.type);
        if (row.feature_benefit) {
          setEditorState1(row.feature_benefit);
        }
      }} className="as-text-blue curser feature-name">
        <span className="textLimit100">{row.name}</span>
      </span> 
      </PermissionsGate>
        : "-"),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      sortFunction: typeSort,
      wrap: true,
      cell: (row) => (row.type ? <span className={`as-${RenderFeatureAndBenefits(row.type).color}-bg as-widget`}> {RenderFeatureAndBenefits(row.type).text} </span> : "-"),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row?.status != null ? RendeFBStatus(row?.status).html : "-",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
          <PermissionsGate RenderError={() => (
              <button className="btn btn-primary rounded-circle" title="Open" disabled>
                <i className="fal fa-folder-open"></i>
              </button>
            )} scopes={["pmcfandbedit"]}>
            <button
              title="Open"
              className="btn btn-primary rounded-circle"
              onClick={() => {
                setShowCkEditor(true);
                // $("#feature-popup").modal("show");
                setFeaturesData(row);
                setModalIcon(true)
                formik.setFieldValue("type", row.type);
                if (row.feature_benefit) {
                  setEditorState1(row.feature_benefit);
                }
              }}
            >
              <i className="fal fa-folder-open"></i>
            </button>
            </PermissionsGate>
            <PermissionsGate scopes={["pmcfandbdelete"]} RenderError={() => (
                  <button className="btn btn-danger rounded-circle" title="Delete" disabled>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                )}>
            <button
              title="Delete"
              className="btn btn-danger rounded-circle"
              onClick={() => handleDeleteTopic(row.programme_id, row.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDeleteTopic = (ID, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteBenefitAndFeature({ id: id })
          .then((res) => {
            Swal.fire(
              "Deleted!",
              "Features and Benefits has been deleted.",
              "success"
            );
            setDummy(!dummy);
          })
          .catch((error) => console.log(error));
        // listOfTopics();
        setReload(!reload)
      }
    });
  };

  // console.log("featuresData1111",featuresData,"dsfdsf",featuresData.type);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      feature_benefit: "",
      type: featuresData?.type ? featuresData.type : "",
      name: featuresData?.name ? featuresData.name : "",
      status: featuresData?.status ? featuresData.status : "",
    },
    validationSchema: Yup.object({
      feature_benefit: Yup.string().test(
        "required",
        "Description is Required",
        (value) => {
          let feature = formatQuestion(editorState1);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      type: Yup.string().required("Type is required"),
      name: Yup.string().required("Name is required").max(255, "Name should not be greater than 255 Character"),
    }),
    onSubmit: (values) => {
      setDisabledTopic(true);
      // const feature_benefit = draftToHtml(
      //   convertToRaw(editorState1.getCurrentContent())
      // );

      // values = { ...values };
      !featuresData &&
        CreateFeaturesBenefits({
          ...values,
          programme_id: id,
          feature_benefit: editorState1,
        })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            setDisabledTopic(false);
            setLoadTopics(false);
            setDummy(!dummy);
            formik.resetForm();
            $("#feature-popup").modal("hide");
            setShowCkEditor(false);
          })
          .catch((err) => console.log(err));

      featuresData &&
        CreateFeaturesBenefits({
          ...values,
          programme_id: id,
          id: featuresData?.id,
          feature_benefit: editorState1,
        })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added Successfully",
            });
            setDisabledTopic(false);
            setLoadTopics(false);
            setDummy(!dummy);
            formik.resetForm();
            setFeaturesData({});
            $("#feature-popup").modal("hide");
            setShowCkEditor(false);
          })
          .catch((err) => console.log(err));
    },
  });

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div
                          id="assessment-table-main_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className=""
                              placeholder="Search"
                              aria-controls="assessment-table-main"
                              onChange={handleSearchFilter1}
                              value={search1}
                            />
                          </label>
                          <div className="filter-eff filter-data-btn">
                              <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button
                            className="btn btn-primary"
                            onClick={() => resetFilter1()}
                            title="Reset"
                          >
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData(
                                "xlsx",
                                "Features and Benefits",
                                "Features and Benefits"
                              );
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData(
                                "csv",
                                "Features and Benefits",
                                "Features and Benefits"
                              );
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData(
                                "pdf",
                                "Features and Benefits",
                                "Features and Benefits"
                              );
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                    </div>
                  </div>
              </div>
            </div>

            
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate RenderError={() => (
                        <button className="btn btn-primary" title="Create New" disabled>Create New
                        </button>
                      )} scopes={["pmcfandbadd"]}>
                    <button
                      className="btn btn-primary"
                      // data-toggle="modal"
                      // data-target="#feature-popup"
                      title="Create New"
                      onClick={() => {
                        setFeaturesData({});
                        setEditorState1("");
                        setShowCkEditor(true);
                        $("#feature-popup").modal("show");
                      }}
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                    </PermissionsGate>
                    
                </div>
              </div>
            </div>
          </div>


        <DataTable
          data={dataToRender()}
          columns={columns}
          pagination={true}
          progressComponent={<SkeletonTicketList />}
          progressPending={loadtopics}
          noDataComponent={Str.noRecord}
          defaultSortAsc={true}
          defaultSortField={"name"}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
        {showCkEditor ? (
          <>
            <div
              // ref={editorRef}
              className="topic-add-modal modal fade show"
              style={{ display: "block" }}
              id="feature-popup"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content modal-border-update">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="modal-body p-0">
                      <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                        {modalIcon ?<> <i className="fal fa-folder-open"></i> Edit </>:<><i className="fal fa-plus"></i> Add</>} Features &amp; Benefits
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            formik.resetForm()
                            $("#feature-popup").modal("hide");
                            setEditorState1("");
                            setShowCkEditor(false);

                            formik.setFieldValue("type", "");
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="t p-30px">
                      <div className="topic-add-modal-content">
                        <div className="row center-label">
                          <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                              <label>Name *</label>
                              <input
                                type="text"
                                className={
                                  "form-control" +
                                  (formik.errors.name && formik.touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Name"
                                name="name"
                                id="name"
                                title="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                              <label>Description *</label>
                              <HtmlInputEditor
                                editorState={editorState1}
                                setEditorState={setEditorState1}
                                hideSign={true}
                                isCKEditor={true}
                                isInvalid={
                                  formik.touched.feature_benefit &&
                                  formik.errors.feature_benefit
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group-blk mb-3" title="Type">
                              <label htmlFor="type">Type *</label>
                              <Select
                                className={
                                  "form-control custom-select-box" +
                                  (formik.errors.type && formik.touched.type
                                    ? " is-invalid"
                                    : "")
                                }
                                name="type"
                                id="type"
                                value={FeatureType.filter((data) => {
                                  return data.value == formik.values.type;
                                })}
                                onChange={(value) =>{
                                  if (value) {
                                    formik.setFieldValue("type", value.value)
                                  } else {
                                    formik.setFieldValue("type", "")
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                options={FeatureType}
                                maxMenuHeight={175}
                                placeholder={
                                  formik.values.type
                                    ? formik.values.type
                                    : "Select Type "
                                }
                                isClearable
                              />
                            </div>
                          </div>
                          <div className="form-group-blk col-md-5" title="Review Status">
                          <label>
                            Review Status *{" "}
                            <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <Select
                            // styles={colourStyles}
                            className={
                              "form-control custom-select-box" +
                              (formik.errors.status && formik.touched.status
                                ? " is-invalid"
                                : "")
                            }
                            name="status"
                            value={statusArr.filter(
                              (val) => val?.value == formik.values.status
                            )}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue("status", value.value);
                              } else {
                                formik.setFieldValue("status", "");
                              }
                            }}
                            // menuIsOpen={true}
                            onBlur={formik.handleBlur}
                            options={statusArr}
                            maxMenuHeight={175}
                            placeholder={
                              formik.values.status
                                ? formik.values.status
                                : "Select Review Status  "
                            }
                            isClearable
                          />
                        </div>
                        </div>
                      </div>
                      <div className="form-group form-group-save-cancel mt-3">
                        <button
                          className="btn btn-primary"
                          title="Save"
                          onClick={formik.handleSubmit}
                          disabled={disabledTopic}
                        >
                          {disabledTopic ? (
                            <i className="fas fa-cog fa-spin"></i>
                          ) : (
                            <i className="fal fa-save"></i>
                          )}{" "}
                          Save
                        </button>
                        <button
                          className="btn btn-close btn-danger"
                          type="button"
                          title="Cancel"
                          onClick={() => {
                            formik.resetForm()
                            $("#feature-popup").modal("hide");
                            setEditorState1("");
                            setShowCkEditor(false);

                            formik.setFieldValue("type", "");
                          }}
                        >
                          <i className="fal fa-times"></i>Cancel
                        </button>
                        {Object.keys(formik.values).map((key) => {
                          if (formik.touched[key] && formik.errors[key]) {
                            return (
                              <div className="invalid-feedback d-block">
                                {formik.errors[key]}
                              </div>
                            );
                          }
                        })}
                      </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ListOfFeatures;
