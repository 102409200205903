import axiosInstance from "../utils/axios";

export const AddCampusPages = async (data) => {
    return await axiosInstance().post('/insertCampusData', data)
}

export const AddCampusResourse = async (data) => {
    return await axiosInstance().post('/insertUploadResource', data)
}

export const updateCampusPages = async (data) => {
    return await axiosInstance().post(`/updateCampusData`, data)
}

export const getCampusLocationDropdown = async () => {
    return await axiosInstance().get(`/getCampusLocationDropdown`)
}

export const getCampusColorList = async (values) => {
    return await axiosInstance().post(`/getAccentColorBaseOnBrandTmpList`, values)
}

// export const getStaticPagesBrandList = async () => {
//     return await axiosInstance().get(`/getBrandTemplateList`)
// }

// export const getStaticPagesHeaderList = async () => {
//     return await axiosInstance().get(`/getHeaderDropDownListStatic`)
// }

export const getCampusPagesStatusList = async () => {
    return await axiosInstance().get(`/getPublishedStatusList`)
}

// export const getStaticPagesPopupList = async () => {
//     return await axiosInstance().get(`/getPopUpListStatic`)
// }

export const GetCampusPageListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getCampusList`, values, {
        cancelToken: cancelToken
    });
};

export const getCampusList = async (values) => {
    return await axiosInstance().post(`/getCampusList`, values);
};

// export const GetStaticPagesListsFilterCancelToken = async (cancelToken) => {
//     return await axiosInstance().get(`/getStaticFilter`, {
//         cancelToken: cancelToken
//     });
// };

export const DeleteCampusPages = async (id) => {
    return await axiosInstance().post(`/deleteCampusData?id=${id}`);
};

export const DeleteCampusResousePages = async (id) => {
    return await axiosInstance().get(`/deleteUploadResource?id=${id}`);
};

export const GetCampusResourseList = async (data) => {
    return await axiosInstance().post(`/getCampusResourceList`, data);
};

export const GetCampusPageResourseListCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getCampusResourceList`, values, {
        cancelToken: cancelToken
    });
};

export const GetCampusUpdateList = async (id) => {
    return await axiosInstance().get(`/getCampusData?id=${id}`);
};

export const GetCampusHeaderList = async () => {
    return await axiosInstance().get(`/getHeaderDropDownListStatic`);
};


export const GetCampusFiltersListCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getCampusFilter`, {
        cancelToken: cancelToken
    })
}