import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "../../StudentAdministration/Students/AuditTrail";
import CategoryLandingPagesOpt from "./CategoryLandingPagesOpt";
import { GetCategoryLandingUpdateList } from "../../../services/CategoryLandingPageServer";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
import { getCategoryPagesAuditTrailList, getCategoryPagesAuditTrailListFilters } from "../../../services/WebsiteListServer";
// This will be shown in merge-all branch

function CreateCategoryLandingPages() {
    const [updateData, setUpdateData] = useState(null);
    const history = useHistory();
    const { tab, type, id, subId } = useParams();
    const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
    const [categoryId, setCategoryId] = useState(null);
    const [previewDomain, setPreviewDomain] = useState("https://next.aie.ac");
    const apiParams = {
        action_id: subId || id,
    }
    useEffect(() => {
        GetCategoryLandingUpdateList(id)
        .then(res => {
            setUpdateData(res?.data?.Data)
            let brandTemplates = res?.data?.Data?.brandTemplete;
            setCategoryId(res?.data?.Data?.category);
            let brandIds  = brandTemplates.map(brand => brand.brandID);
            if (brandIds) {
                //check if brand_ids contain 1
                if (brandIds.includes(1)) {
                    setPreviewDomain('https://next.aie.ac');
                } else if (brandIds.includes(4)) {
                    setPreviewDomain('https://next.designcenter.co.za');
                } else if(brandIds.includes(3)) {
                    setPreviewDomain('https://next.emendy.co.za');
                } else if(brandIds.includes(2)) {
                    setPreviewDomain('https://next.learnfast.co.za');
                } else if(brandIds.includes(6)) {
                    setPreviewDomain("https://next.cadco.co.za");
                }
            }else{
                setPreviewDomain("https://next.aie.ac");
            }
        })
        .catch(err => console.log(err))
    }, [id])
    
    return (
        <>
        <Header />
        <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
        <div className="sub-menu-content-block">
        {/* <Tabs /> */}
        <div className="sub-menu-right-block">
        <div className="wrap-box-right">
        <div className="my-tickets-heading-blk flex-direction mb-2">
        <div className="d-flex align-items-center ">
        <div className="name-w-head d-flex align-items-center ">
        <h4 className="text-left-align landing-heading heading_color_dynamic">
        {/* Student <span>List</span> */}
        School Landing Pages: <span>{(type === "open" || type == "auditTrail") ? updateData?.PageTitle : "Create New"}</span>
        </h4>
        </div>
        <div className="text-md-right action2-blk">
        <div className="ticket-view-btn d-flex align-items-center">
        <div className="add-new-ticket-bx">
        <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
        <i className="fal fa-angle-left"></i>Back
        </button>
        </div>
        </div>
        </div>
        {id && categoryId && <div className="text-md-right action2-blk">
            <div className="ticket-view-btn d-flex align-items-center">
            <div className="add-new-ticket-bx">
            <a
            href={`${previewDomain}/schoolLanding?cid=${categoryId}`}
            title="review"
            target="_blank"
            className="btn btn-white-bordered"
            >
            <i className="fal fa-eye"></i>Preview
            </a>
            </div>
            </div>
            </div>
        }
        </div>
        </div>
        <div className="tabs-wrap">
        {(type === "auditTrail" || type === "open") && (
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li className="nav-item" onClick={() => history.push(`/website_management/pages/categorylandingpages/table/open/${id}`)}>
            <Link
            className={`nav-link ${type === "open" && "active"}`}
            id="pills-tab1"
            data-toggle="pill"
            to={`/website_management/pages/categorylandingpages/table/open/${id}`}
            role="tab"
            aria-controls="pills-tab11"
            aria-selected="true"
            title="Page Details"
            >
            <i className="fa fa-info-circle"></i>
            Page Details
            </Link>
            </li>
            <li className="nav-item" onClick={() => history.push(`/website_management/pages/categorylandingpages/table/auditTrail/${id}`)}>
            <Link
            className={`nav-link ${type === "auditTrail" && "active"}`}
            id="pills-tab2"
            data-toggle="pill"
            to={`/website_management/pages/categorylandingpages/table/auditTrail/${id}`}
            role="tab"
            aria-controls="pills-tab12"
            aria-selected="true"
            title="Audit trail"
            >
            <i className="fal fa-history"></i>Audit trail
            </Link>
            </li>
            </ul>
        )}
        <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
        {type == "create" && <CategoryLandingPagesOpt />}
        {(type == "open" || type =='re-arrange') && <CategoryLandingPagesOpt />}
        {type == "auditTrail" && <AuditTrailsTable
            apiFunction={getCategoryPagesAuditTrailList}
            filterArrApi={getCategoryPagesAuditTrailListFilters}
            columnsToShow={columnsToShow}
            exportFileName={"School landing pages audit trails"}
            isResourceNameShown={true}
            apiParams={apiParams}
            actionId={subId || id}
            isShowingResourceTypeFilter
            renderResourceTypeFunction={RenderStudentResourceType}
            />}
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </>
        );
    }
    
    export default CreateCategoryLandingPages;
    