import React, { useState, useEffect, useRef } from "react";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CoreModuleForm from "./CoreModuleForm";
import FoundationalModuleForm from "./FoundationalModuleForm";
import ElectiveModuleForm from "./ElectiveModuleForm";


function BachleorP2Form(props) {
  const { reRegSubject, registration, setReadOnly, setStudentNewRegId, lateReg,setElectiveArr,setFoundationArr,setCoreArr } = props;
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('user'))
  const [checked, setChecked] = useState(false);
  const signaturePadRef = useRef(null);
  const [continueChecked, setContinueChecked] = useState(false);
  const [signatureType,setSignatureType] = useState(0);
  const fileInputRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const [progressionData,setProgressionData] = useState([]);
  const [showModules,setShowModules] = useState({core : false,foundational : false,elective : false,core_data : [],foundational_data : [],elective_data : []});
  
  

  const [parentSubjectProgLength, setparentSubjectProgLength] = useState([
    { subject: "" },
  ]);

  const [parentSubjectReSubLengthCore, setparentSubjectReSubLengthCore] = useState({});
  const [parentSubjectReSubLengthFoundational, setparentSubjectReSubLengthFoundational] = useState({});
  const [parentSubjectReSubLengthElective, setparentSubjectReSubLengthElective] = useState({});

  const handleCheckContinue = (e) => {
    setContinueChecked(e.target.checked);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      core_arr: parentSubjectReSubLengthCore,
      foundation_arr: parentSubjectReSubLengthFoundational,
      elective_arr: parentSubjectReSubLengthElective,
      term_condn: checked,
      ip : "",
      student_signature : "",
      // term_continue: continueChecked,
      rereg_academy: props?.registration?.academy,
      latest_enrolment_netsuite_id: props?.bachleorData?.netsuite_id,
      rereg_id: props?.registration?.id,
      next_year_option: '',
      late_reg : lateReg
    },
    validationSchema: Yup.object({
      // subjectProgress_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      // subjectReSub_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Select at least one"),
      student_signature : Yup.mixed().required('Please sign'),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
      // term_continue: Yup.bool().oneOf([true], "Required"),
      // next_year_option: Yup.string().required("Please select option")
    }),
    onSubmit: (values) => {
      const formData = new FormData()
      function transformObject(input) {
        // If input is an empty object, return an empty array
        if (Object.keys(input).length === 0) {
          return [];
        }
    
        return Object.entries(input).reduce((acc, [key, value]) => {
          // Filter out entries with empty arrays or empty strings
          const filteredValue = Array.isArray(value) 
            ? value.filter(item => item !== "")
            : value;
          
          // Only add to accumulator if filtered value is not empty
          if (filteredValue.length > 0 || (typeof filteredValue === 'string' && filteredValue !== '')) {
            acc.push({ [key]: filteredValue });
          }
          
          return acc;
        }, []);
      }       
      let valuesKey = ''
      Object.keys(values).forEach((key) => {
        valuesKey = (key == 'core_arr' || key == 'foundation_arr' || key == 'elective_arr') ? JSON.stringify(transformObject(values[key])) : values[key]
        formData.append(key, valuesKey);
      })
      // submitReRegistrationService(formData).then((res) => {
      //   Swal.fire({
      //     icon: "success",
      //     title: "Successfully Submitted",
      //     text: "This does not confirm approval of your re-registration!!",
      //     timer: 3000,
      //     onOpen: function () {
      //       Swal.showLoading();
      //     },
      //   }).then(function (result) {
      //     setLoading(true);
      //     setStudentNewRegId(res.data.studentNewRegId);
      //     setReadOnly(true);
      //     props.updateStatus();
      //     if(isReReg.isReReg===true){
      //       dispatch(setReRegistrationsReload(!reload));
      //       history.push("/reregStatus");
      //     }
      //   });
      // });
      
    },
  });

  useEffect(() => {
    setCoreArr(formik.values.core_arr)
    setFoundationArr(formik.values.foundation_arr)
    setElectiveArr(formik.values.elective_arr)
  },[formik.values.elective_arr,formik.values.core_arr,formik.values.foundation_arr])
 

  useEffect(
    () => {
        
        if(!Array.isArray(props.bachleorData)){

          let array = [];
          let filteredKeys = Object.keys(props.bachleorData.responseData).filter(key => isNaN(key) == false)
          filteredKeys.forEach(key => array.push({data: props.bachleorData.responseData[key],realKey : key}))
          // array.forEach((data,key) => {
          //   if(data.data.core.modules.length){
          //     console.log("WWWWWWWWWWWWWWwww");
          //     let coreDataArray = []
          //     coreDataArray.push({name : data.data.name,key : data.realKey,modules : data.data.core})
          //     setShowModules({...showModules, core: true,core_data : coreDataArray})
          //   }
          //   if(data.data.foundation.modules.length){
          //     let foundationDataArray = []
          //     foundationDataArray.push({name : data.data.name,key : data.realKey,modules : data.data.foundation})
          //     setShowModules({...showModules, foundational: true,foundational_data : foundationDataArray})
          //   }
          //   if(data.data.elective.modules.length){
          //     let electiveDataArray = []
          //     electiveDataArray.push({name : data.data.name,key : data.realKey,modules : data.data.elective})
          //     setShowModules({...showModules, elective: true,elective_data : electiveDataArray})
          //   }
          // })
          let updates = {
            core: false,
            core_data: [],
            foundational: false,
            foundational_data: [],
            elective: false,
            elective_data: []
          };
          
          array.forEach((data, key) => {
          
            if (data.data.core.modules.length) {
              updates = {
                ...updates,
                core: true,
                core_data: [
                  ...updates.core_data,
                  {
                    name: data.data.name,
                    key: data.realKey,
                    modules: data.data.core
                  }
                ]
              };
            }
          
            if (data.data.foundation.modules.length) {
              updates = {
                ...updates,
                foundational: true,
                foundational_data: [
                  ...updates.foundational_data,
                  {
                    name: data.data.name,
                    key: data.realKey,
                    modules: data.data.foundation
                  }
                ]
              };
            }
          
            if (data.data.elective.modules.length) {
              updates = {
                ...updates,
                elective: true,
                elective_data: [
                  ...updates.elective_data,
                  {
                    name: data.data.name,
                    key: data.realKey,
                    modules: data.data.elective
                  }
                ]
              };
            }
          
          });
          setShowModules(prevState => ({
            ...prevState,
            ...updates
          }));
        }
    },[props.bachleorData]
  )

  const saveSignature = () => {
    if (signaturePadRef.current.isEmpty()) {
        alert("Please provide a signature first.");
    } else {
        const dataURL = signaturePadRef.current.toDataURL();
        let base64_to_imgsrc = Buffer.from(dataURL, "base64").toString()
        //add the string to the state
        return base64_to_imgsrc
        // You can also upload this data URL to your server
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
            <div className="form-group col-sm-12 inputfield">
                {showModules.core && <CoreModuleForm
                  formik={formik}
                  setparentSubjectReSubLength={setparentSubjectReSubLengthCore}
                  parentSubjectReSubLength={parentSubjectReSubLengthCore}
                  progressionData={progressionData}
                  selectedData={props.studentData?.re_reg_data?.core_arr}
                  modulesData={showModules.core_data}
                />}
                {showModules.foundational && <FoundationalModuleForm
                  formik={formik}
                  setparentSubjectReSubLength={setparentSubjectReSubLengthFoundational}
                  parentSubjectReSubLength={parentSubjectReSubLengthFoundational}
                  progressionData={progressionData}
                  selectedData={props.studentData?.re_reg_data?.foundation_arr}
                  modulesData={showModules.foundational_data}
                />}
                {showModules.elective && <ElectiveModuleForm
                  formik={formik}
                  setparentSubjectReSubLength={setparentSubjectReSubLengthElective}
                  selectedData={props.studentData?.re_reg_data?.elective_arr}
                  parentSubjectReSubLength={parentSubjectReSubLengthElective}
                  progressionData={progressionData}
                  modulesData={showModules.elective_data}
                />}
                
              </div>
              <div className="form-group col-sm-12 inputfield">
               { // no need for extra static in put field -------------------------------------------------------------------------->>
               }
               {/* <h5 className="textdanger heading-5">Progress:</h5>
                {/* <label>
                  <b>Architecture:</b> In order to be eligible to progress into
                  the second year of the National Diploma (NQF6), you need to
                  achieve 50% or more for the following core subjects:
                </label>

                <ul className="list-unstyled unstyledlist-custom">
                  <li>Architectural Design.</li>
                  <li>Construction, Technology and Detailing.</li>
                  <li>Architectural Representation and Draughting.</li>
                  <li>Computer Applications.</li>
                </ul> 

                <label>
                  <b>
                    SSD / Management of Civil Engineering Construction
                    Processes:
                  </b>{" "}
                  In order to be eligible to progress into the second year of
                  SSD or Management of Civil Engineering Construction Processes,
                  please ensure you have 65% across all your modules.
                </label>
              </div>

              <div className="col-12 form-group inputfield select-width">
                 <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_continue &&
                        formik.errors.term_continue
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_continue"
                    onChange={(e) => handleCheckContinue(e)}
                    onBlur={formik.handleBlur}
                    checked={continueChecked}
                    id="customCheck1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    I would like to progress to next level.
                  </label>
                  {formik.touched.term_continue &&
                    formik.errors.term_continue ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_continue}
                    </div>
                  ) : null}
                </div>
                <br /> 
                {/* <div className="">
                {/* <label>Please select an option*</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (formik.errors.next_year_option && formik.touched.next_year_option
                        ? " is-invalid"
                        : "")
                    }
                    name="next_year_option"
                    value={formik.values.next_year_option}
                    onChange={(value) => {
                      formik.setFieldValue("next_year_option", value.value)
                      formik.setFieldValue("next_year_option_label", value.label);
                    }}
                    onBlur={formik.handleBlur}
                    maxMenuHeight={175}
                    placeholder={formik.values.next_year_option_label ? formik.values.next_year_option_label : "Please select"}
                    options={[
                      { value: 'Progress to Civil Management Processes 2nd Year', label: 'Progress to Civil Management Processes 2nd Year' },
                      { value: 'Progress to SSD 2nd Year', label: 'Progress to SSD 2nd Year' },
                      // { value: 'Progress to Architectural Technology NQF6', label: 'Progress to Architectural Technology NQF6' },
                      // { value: 'Progress to (NEW) Higher Certificate in Architectural Technology', label: 'Progress to (NEW) Higher Certificate in Architectural Technology' }
                    ]}
                  />

                 
                </div> */}
                {(formik.errors.next_year_option &&
                  formik.touched.next_year_option) ? (
                  <span className="">
                    <p className="errorMsg">{formik.errors.next_year_option}</p>
                  </span>
                ) : null}

                
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default BachleorP2Form;
