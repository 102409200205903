import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {TABLE_ROWS_PER_PAGE} from "../../utils/Constants";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../utils/permissionGate";
import hasPermission from "../../utils/hasMultiplePermission";
import Tablefilter from "../common/Tablefilter";
import { RenderBranchList, RenderWebsitelistStatus } from "../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../utils/commonFunction";
import $ from "jquery";
import { DeleteWebsiteList, GetWebsiteList, GetWebsiteListCancelToken, GetWebsiteListsFilterCancelToken } from "../../services/WebsiteListServer";

function WebsiteList() {

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState("");
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [branch, setBranch] = useState({ arr: [], checkObj: {} });
    const [brandTemplate, setBrandTemplate] = useState({ arr: [], checkObj: {} });
    const [searchStatusCheck, setSearchStatusCheck] = useState({});
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [deleterow, setDeleteRow] = useState(false);
    const [websiteData, setWebsiteData] = useState([]);
    const [loading, setloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [updateData, setUpdateData] = useState(true);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("siteName");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [filterData, setFilterData] = useState({ status: [], branch: [], brandTemplate:[] });

    useEffect(
        () => {
            let response = hasPermission({
                scopes: ["wbssitewebsiteview"],
                permissions: givenPermsisions,
              });
              if (!response) {
                history.push("/noaccess");
              }
        },[]
    )

    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })
    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateWebsiteListMain"));
        if (storedFilters) {
            setStatus(storedFilters.status);
            setBranch(storedFilters.branch);
        }
    }, []);
    useEffect(() => {
        const filterState = {
            status,
            branch
        };
        localStorage.setItem("filterStateWebsiteListMain", JSON.stringify(filterState));
    }, [status, branch]);
    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })
    useEffect(() => {
        const cancelTokenSources = [];

        const getWebsiteList = async () => {
            setloading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            const websiteData = {
                page: page,
                perPage: perPage,
                key: sortkey,
                sort: sortOrder,
                search: search,
                viaStatus: status?.arr,
                viaBranch: branch?.arr,
                viaBrand : brandTemplate?.arr
            };

            try {
                const res = await GetWebsiteListCancelToken(websiteData, source.token);
                setWebsiteData(res?.data?.data?.data);
                setTotalRows(res.data?.data?.total);
                setPerPage(res.data?.data?.perPage);
                if (res.status == 200) {
                    setloading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setloading(false);
                }
            }
        }

        getWebsiteList();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [page, perPage, sortOrder, sortkey, search, status, branch,brandTemplate, searchStatusCheck , updateData]);

    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const res = await GetWebsiteListsFilterCancelToken(dropdownSource.token);

                setFilterData({
                    status : res?.data?.status,
                    branch : res?.data?.branch,
                    brandTemplate : res?.data?.brand
                })
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();

        return () => {
            dropdownSource.cancel('Component unmounted');
        };
    }, []);

    // useEffect(() => {
    //     let response = hasPermission({
    //         scopes: ["wbssitewebsiteview"],
    //         permissions: givenPermsisions,
    //     });
    //     if (!response) {
    //         history.push("/noaccess");
    //     }
    // }, [deleterow]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setDebouncedTerm(value);
    };

    const resetFilter = () => {
        setStatus({ arr: [], checkObj: {} });
        setBranch({ arr: [], checkObj: {} });
        setBrandTemplate({ arr: [], checkObj: {} });
        setSearchStatusCheck({});
        setSearch("");
        setDebouncedTerm("")
    };

    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    const handleRedirection = (ID) => {
        if (hasPermission({ scopes: ["sgiview"], permissions: givenPermsisions })) {
            return `/studentAdministration/students/open/${ID}/general`;
        } else if (
            hasPermission({ scopes: ["spiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/personal`;
        } else if (
            hasPermission({ scopes: ["sedview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/education`;
        } else if (
            hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/account`;
        } else if (
            hasPermission({ scopes: ["scpview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/coursesAndProgramme`;
        } else if (
            hasPermission({ scopes: ["sasview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/assessments`;
        } else if (
            hasPermission({ scopes: ["snview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notifications`;
        } else if (
            hasPermission({ scopes: ["scaview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/classAttendance`;
        } else if (
            hasPermission({ scopes: ["slview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/licenses`;
        } else if (
            hasPermission({ scopes: ["scview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/certificates`;
        } else if (
            hasPermission({ scopes: ["saview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/appeals`;
        } else if (
            hasPermission({ scopes: ["stview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/tickets`;
        } else if (
            hasPermission({ scopes: ["snoview"], permissions: givenPermsisions })
        ) {
            return `/studentAdministration/students/open/${ID}/notes`;
        } else {
            return `/studentAdministration/students/open/${ID}/auditTrail/employee`;
        }
        // history.push(redirectLink)
    };

    const sortingName = (rowA, rowB) => {
        const name1 = (rowA.first_name + " " + rowA.last_name).trim().toLowerCase();
        const name2 = (rowB.first_name + " " + rowB.last_name).trim().toLowerCase();
        return name1.localeCompare(name2);
    };

    const handleDelete = (id) => {  
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteWebsiteList({ id: id })
                    .then((res) => {
                        Swal.fire("Deleted!", "Website has been deleted.", "success");
                        setUpdateData(!updateData);
                    })
                    .catch((error) => console.log(error));
            }
        });
    }

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "siteName",
            sortField: "siteName",
            sortable: true,
            cell: (row) => (
                <div>
                    <PermissionsGate scopes={["wbssitewebsiteedit"]} RenderError={() => <p>{row?.siteName}</p>}>
                    <Link
                        className="as-text-blue curser feature-name"
                        to={`/websiteManagement/siteSetup/table/open/${row.id}`}
                        title={row.siteName}
                    >
                        <span className="textLimit100">{row.siteName}</span>
                    </Link>
                    </PermissionsGate>
                </div>
            ),
        },
        {
            name: "Status",
            selector: "status",
            sortField: "status",
            sortable: true,
            // maxWidth: "120px",
            // minWidth: "120px",
            cell: (row) => (row.status ? RenderWebsitelistStatus(row.status).html : "-"),
        },
        {
            name: "Brand Template",
            selector: "BrandTemplateName",
            sortField: "BrandTemplateName",
            sortable: true,
            cell: (row) => (
                <div>
                    {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                    <Link
                        className="as-text-blue curser feature-name"
                        to={`/websiteManagement/siteSetup/brand_Templates/brand_information/update/${row.brandTemplate}`}
                        // to={`/websiteManagement/siteSetup/table/open/${row.id}`}
                        title={row.BrandTemplateName}
                    >
                        <span className="textLimit100 font-weight-bold">{row.BrandTemplateName}</span>
                    </Link>
                    {/* </PermissionsGate> */}
                </div>
            ),
        },
        {
            name: "Branch",
            selector: "branch",
            sortField: "branch",
            sortable: true,
            cell: (row) => (row.branch ? RenderBranchList(row.branch).html : "-"),
        },
        {
            name: "Date Created",
            selector: "created_at",
            sortField: "created_at",
            sortable: true,
            cell: (row) => {
                let dateString = row.created_at;
                let dateObj = new Date(dateString);
                let day = ('0' + dateObj.getDate()).slice(-2);
                let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                let month = monthNames[dateObj.getMonth()];
                let year = ('' + dateObj.getFullYear()).slice(-2);
                let formattedDate = `${day} ${month} ${year}`;
                return <div title={formattedDate}>{formattedDate}</div>
            },
        },
        {
            name: "Domain",
            selector: "siteDomain",
            sortField: "siteDomain",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <Link
                            className="as-text-blue curser feature-name"
                            // to={row.siteDomain}
                            title={row.siteDomain}
                        >
                            <span className="textLimit100 font-weight-bold">{row.siteDomain}</span>
                        </Link>
                    </div>
                </div>
            ),
        },
        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <PermissionsGate scopes={['wbssitewebsiteedit']} RenderError={() => (
                            <>
                            <button className="btn btn-primary rounded-circle" disabled>
                                <i className="fal fa-folder-open"></i>
                            </button>
                            </>
                        )}>

                        <Link
                            className="btn btn-primary h-auto rounded-circle"
                            to={`/websiteManagement/siteSetup/table/open/${row.id}`}
                            title="Open"
                            >
                            <i className="fal fa-folder-open"></i>
                        </Link>
                        </PermissionsGate>
                        <PermissionsGate scopes={['wbssitewebsitedelete']} RenderError={() => (
                            <>
                            <button className="btn btn-danger rounded-circle" disabled>
                                <i className="fal fa-trash-alt"></i>
                            </button>
                            </>
                        )}>

                        <button
                            className="btn btn-danger rounded-circle"
                            title="Delete"
                            onClick={() => handleDelete(row.id)}
                            >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    // TODO Excel
    // TODO Csv
    // TODO Pdf

    const exportData = (fileType, fileName) => {
        let data = [];
        const header = ["Name", "Status", "Brand Template", "Branch", "Date Created", "Domain"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        const websiteData = {
            page: page,
            limit: perPage,
            key: sortkey,
            sort: sortOrder,
            search: search,
            viaStatus: status.arr,
            viaBranch: branch.arr,
            viaBrand : brandTemplate?.arr,
            exportStatus: "true",
        };

        GetWebsiteList(websiteData)
            .then((res) => {
                data = res?.data?.data;
                data = data?.map((row) => {
                    let dateString = row.created_at;
                    let dateObj = new Date(dateString);
                    let day = ('0' + dateObj.getDate()).slice(-2);
                    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    let month = monthNames[dateObj.getMonth()];
                    let year = ('' + dateObj.getFullYear()).slice(-2);
                    let formattedDate = `${day} ${month} ${year}`;

                    return ({
                        ...row,
                        "Name" :row.siteName,
                        "Status" :row.status,
                        "Brand Template" :row.BrandTemplateName,
                        "Branch" :row.branch,
                        "Date Created" :row.created_at && formattedDate,
                        "Domain" :row.siteDomain,
                    });
                });
                console.log("_____________________",data);

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    // Create new tag for download file
                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click(); 

                    // Remove URL.createObjectURL. The browser should not save the reference to the file.
                    setTimeout(() => {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    // Returning false as downloading of file is already taken care of
                    return false;
                }
                if (fileType === "pdf") {   
                    const compatibleData = data?.map((row) => {
                        let dateString = row.created_at;
                        let dateObj = new Date(dateString);
                        let day = ('0' + dateObj.getDate()).slice(-2);
                        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        let month = monthNames[dateObj.getMonth()];
                        let year = ('' + dateObj.getFullYear()).slice(-2);
                        let formattedDate = `${day} ${month} ${year}`;

                        return [
                            row.siteName,
                            row.status,
                            row.BrandTemplateName,
                            row.branch,
                            row.created_at && formattedDate,
                            row.siteDomain,
                            row.siteTitle,  
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            // valign: "center",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [debouncedTerm])

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={debouncedTerm}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Status"
                                            optionArr={filterData?.status}
                                            state={status}
                                            setState={setStatus}
                                            stopOptionSorting={true}
                                            isOptionReversed={false}
                                            renderLabelFunction={RenderWebsitelistStatus}
                                        />
                                    </div>
                                </div>
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Branch"
                                            optionArr={filterData?.branch}
                                            state={branch}
                                            setState={setBranch}
                                            stopOptionSorting={true}
                                            isOptionReversed={false}
                                            renderLabelFunction={RenderBranchList}
                                        />
                                    </div>
                                </div>
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Brand Template"
                                            optionArr={filterData?.brandTemplate}
                                            state={brandTemplate}
                                            setState={setBrandTemplate}
                                            stopOptionSorting={true}
                                            isOptionReversed={false}
                                            // renderLabelFunction={Render}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "Website list");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "Website list");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "Website list");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                                <PermissionsGate
                                    RenderError={() => (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                            disabled
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    )}
                                    scopes={["wbssitewebsiteadd"]}
                                >
                                    <Link to={`/websiteManagement/siteSetup/table/create`}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    </Link>
                                </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    paginationDefaultPage={page}
                    progressPending={loading}
                    data={websiteData}
                    progressComponent={<SkeletonTicketList />}
                    defaultSortField={sortkey}
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    // noDataComponent={Str.noRecord}
                    onSort={handleSort}
                    sortServer
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
        </div>
    );
}

export default WebsiteList;
