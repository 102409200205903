import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { AddBrandTemplate, getFromEmails, getBrandTemplate, getBrandTemplateBrand, getBrandTemplateCampus, getBrandTemplateStatus, getCurrencyDropDownList, updateBrandTemplate } from "../../../../services/BrandTemplateServices";
import axios from "../../../../utils/axios";
import { IMAGE_URL, URL_REGEX } from "../../../../utils/Constants";
import { AssignPageField, FileUploadField, FormField } from "../../../common/FormFields";
import AssignPagePopup from "./AssignPagePopup";
import { TrimText } from "../../../common/TrimText";
import PermissionsGate from "../../../../utils/permissionGate";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";



const BrandInformation = ({ setHeaderName }) => {
  const { subId, subType } = useParams();
  const history = useHistory();
  const [campusArr, setCampusArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);
  const [currencyArr, setCurrencyArr] = useState([]);
  const [brandData, setBrandData] = useState({});
  const [assignedPage, setAssignedPage] = useState({});
  const [assignedThankYou,setAssignedThankYou] = useState({});
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [emailsOptions, setEmailsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [statusRes, campusRes, brandRes, currencyRes, emailsRes] = await Promise.all([
          getBrandTemplateStatus(),
          getBrandTemplateCampus(),
          getBrandTemplateBrand(),
          getCurrencyDropDownList(),
          getFromEmails()
        ]);
        const statusFilters = statusRes?.data?.statusFilters || [];
        const campusFilters = campusRes?.data?.campusLocationFilters || [];
        const brandFilters = brandRes?.data?.BrandFilters || [];
        const currencyFilters = currencyRes?.data?.currency || [];
        const emailOptionsRes = emailsRes?.data || [];
        setStatusArr(statusFilters);
        setBrandArr(brandFilters);
        setCampusArr(campusFilters);
        setCurrencyArr(currencyFilters);
        setEmailsOptions(emailOptionsRes);
        setLoading(false);
      } catch (error) {
        console.log("error",error);
      }
    };
    
    fetchData();
  }, []);

  const givenPermsisions = useSelector((state) => state.givenPermission);


  useEffect(() => {
    let response = hasPermission({ scopes: ["wbssitebrandtmpview"], permissions: givenPermsisions, });
    if (!response) {
      history.push("/noaccess");
    }

    const fetchData = async () => {
      try {
        let res = await getBrandTemplate(subId);
        res.data.data.campuslocation = res.data.data?.campuslocation?.map((el) => el.campusID)
        setBrandData(res.data?.data);
        setHeaderName(res.data?.data?.templateName)
        setAssignedPage({ PageTitle: res.data?.data?.termsAndConditionsPageName, PageID: res.data?.data?.termsAndConditionsPages })
        setAssignedThankYou({ PageTitle: res.data?.data?.thank_you_page_name, PageID: res.data?.data?.thank_you_page_id })
        localStorage.setItem("headerName", res.data?.data?.templateName);
      } catch (error) {
        if (error.response.data?.message !== "") {
          Swal.fire({
            title: "Info",
            text: "No record found with this ID",
          });
          history.push(`/websiteManagement/siteSetup/brand_Templates`);
        }
      }
    };
    if ((subId && (subType == "update" || subType == "open")) && response) {
      fetchData();
    }
  }, [subId]);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      templateName: "",
      brandLinked: "",
      status: "",
      campusID: [],
      whatsappNo: "",
      termsAndConditionsPages: "",
      thank_you_page_id: "",
      flagTermsAndConditions: "",
      primaryContactNo: "",
      student_registration_fee: 0,
      administration_fee: 0,
      currency: "",
      primaryEmail: "",
      headerColor: "#007BFF",
      header_hover_color: "#007BFF",
      primaryColor: "#007BFF",
      secondaryColor: "#007BFF",
      highlightColor: "#007BFF",
      activeColor: "#007BFF",
      accentColorOne: "#007BFF",
      accentColorTwo: "#007BFF",
      accentColorThree: "#007BFF",
      accentColorFour: "#007BFF",
      textColorOne: "#007BFF",
      textColorTwo: "#007BFF",
      backgroundColorOne: "#007BFF",
      backgroundColorTwo: "#007BFF",
      logo: "",
      favicon: "",
      headerVideo: "",
      complaint_emails: [],
      defaultEmailTitle: "",
      facebook: "",
      twitter: "",
      youtube: "",
      linkedIn: "",
      instagram: "",
      facebookPixelId:'',
      whatsappTemplateApiUrl: "",
      whatsappMsgApiURL: "",
      WhatsappAccessToken: "",
      UseWhatsapp: 0
    },
    validationSchema: Yup.object().shape({
      templateName: Yup.string().required("Template Name is required").nullable().trim(),
      brandLinked: Yup.string().required("Linked Brand is required"),
      // status: Yup.string().required("Status is required"),
      campusID: Yup.array().min(1, "Campus Locations is required").required("Campus Locations is required"),
      whatsappNo: Yup.string().required("WhatsApp Number is required").nullable().trim(),
      primaryContactNo: Yup.string().required("Primary Contact Number is required").nullable().trim(),
      primaryEmail: Yup.string().email("Invalid email address").required("Primary Email Address is required"),
      logo: Yup.string().required("Logo is required"),
      headerVideo: Yup.string().required("Header Video is required"),
      status: Yup.string().required("Status is required"),
      whatsappTemplateApiUrl: Yup.string().when('UseWhatsapp', {
        is: '1',  // If UseWhatsapp is '1', which means checked
        then: Yup.string().required('Whatsapp Template API URL is required'),
        otherwise: Yup.string().nullable(),
      }),
      
      whatsappMsgApiURL: Yup.string().when('UseWhatsapp', {
        is: '1',
        then: Yup.string().required('Whatsapp Message API URL is required'),
        otherwise: Yup.string().nullable(),
      }),
      
      WhatsappAccessToken: Yup.string().when('UseWhatsapp', {
        is: '1',
        then: Yup.string().required('Whatsapp Access Token is required'),
        otherwise: Yup.string().nullable(),
      }),
    facebook: Yup.string().matches(/^(\/|https?:\/\/)/, "Please Enter valid URL for Facebook"),
    twitter: Yup.string().matches(/^(\/|https?:\/\/)/, "Please Enter valid URL for Twitter"),
    youtube: Yup.string().matches(/^(\/|https?:\/\/)/, "Please Enter valid URL for YouTube"),
    linkedIn: Yup.string().matches(/^(\/|https?:\/\/)/, "Please Enter valid URL for LinkedIn"),
    instagram: Yup.string().matches(/^(\/|https?:\/\/)/, "Please Enter valid URL for Instagram"),
    complaint_emails: Yup.array().of(Yup.string().email("Please enter a valid complaint email address")),
  }),
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
    
      const setDefaultFee = (fieldName) => {
        const feeValue = values[fieldName];
        formik.setFieldValue(fieldName, 0); 
        formData.append(fieldName, 0); 
    };
    
    if (formik.values.student_registration_fee == null || formik.values.student_registration_fee === "") {
      setDefaultFee("student_registration_fee");
    }
    if (formik.values.administration_fee == null || formik.values.administration_fee === "") {
      setDefaultFee("administration_fee");
    }

    
      let trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
    
      Object.keys(trimmedValues).forEach((key) => {
        if (Array.isArray(trimmedValues[key])) {
          trimmedValues[key].forEach((val, idx) => {
            if (val != null && val !== "") { 
              formData.append(`${key}[${idx}]`, val); 
            }
          });
        } else if (trimmedValues[key] != null && trimmedValues[key] !== "") {
          formData.append(key, trimmedValues[key]);
        }
      });

      try {
        if (subType === "create") {
          await AddBrandTemplate(formData);
          Swal.fire("Success!", "Brand Template created successfully!", "success");
          history.push("/websiteManagement/siteSetup/brand_Templates");
        } else if (subType === "update") {
          formData.append("brandID", subId)
          await updateBrandTemplate(formData);
          Swal.fire("Success!", "Brand Template updated successfully!", "success");
        }
      } catch (error) {
        Swal.fire("Error!", `Failed to ${subType === "create" ? "create" : "update"} Brand Template`, "error");
        console.log(error)
      }
      setSubmitting(false);
    },
  });

  const handleKeyDown1 = (event) => {
    let tags = selectKeywords;
    if (event.key === "Enter") {
      event.preventDefault();
      const email = event.target.value.trim();
  
      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if (email) {
        tags.push(email);
        event.target.value = "";
        formik.setFieldValue("complaint_emails", tags);
      } else {
        console.error("Invalid email format");
      }
    }
  };
  
  const removeKeywords = (index) => {
    let tags = selectKeywords;
    tags.splice(index, 1); // Remove the email at the specified index
    formik.setFieldValue("complaint_emails", tags); // Use "complaint_emails" to keep it consistent
  };
  

useEffect(() => {
  setSelectedKeywords((brandData?.complaint_emails && brandData?.complaint_emails.length > 0) ? brandData?.complaint_emails.map(item => item) : []);
}, [brandData])
  useEffect(() => {
    if (brandData) {
      formik.setFieldValue("templateName", brandData?.templateName || "");
      formik.setFieldValue("brandLinked", brandData?.brandLinked || "");
      formik.setFieldValue("status", brandData?.status !== null && brandData?.status !== undefined ? brandData.status : "");
      formik.setFieldValue("campusID", brandData?.campuslocation || []);
      formik.setFieldValue("whatsappNo", brandData?.whatsappNo || "");
      formik.setFieldValue("termsAndConditionsPages", brandData?.termsAndConditionsPages || "");
      formik.setFieldValue("thank_you_page_id",brandData?.thank_you_page_id || "")
      formik.setFieldValue("flagTermsAndConditions", brandData?.flagTermsAndConditions || "");
      formik.setFieldValue("primaryContactNo", brandData?.primaryContactNo || "");
      formik.setFieldValue("student_registration_fee", brandData?.student_registration_fee || 0);
      formik.setFieldValue("administration_fee", brandData?.administration_fee || 0);
      formik.setFieldValue("currency", brandData?.currency || "");
      formik.setFieldValue("primaryEmail", brandData?.primaryEmail || "");
      formik.setFieldValue("headerColor", brandData?.headerColor || "#007BFF");
      formik.setFieldValue("header_hover_color", brandData?.header_hover_color || "#007BFF");
      formik.setFieldValue("primaryColor", brandData?.primaryColor || "#007BFF");
      formik.setFieldValue("secondaryColor", brandData?.secondaryColor || "#007BFF");
      formik.setFieldValue("highlightColor", brandData?.highlightColor || "#007BFF");
      formik.setFieldValue("activeColor", brandData?.activeColor || "#007BFF");
      formik.setFieldValue("accentColorOne", brandData?.accentColorOne || "#007BFF");
      formik.setFieldValue("accentColorTwo", brandData?.accentColorTwo || "#007BFF");
      formik.setFieldValue("accentColorThree", brandData?.accentColorThree || "#007BFF");
      formik.setFieldValue("accentColorFour", brandData?.accentColorFour || "#007BFF");
      formik.setFieldValue("textColorOne", brandData?.textColorOne || "#007BFF");
      formik.setFieldValue("textColorTwo", brandData?.textColorTwo || "#007BFF");
      formik.setFieldValue("backgroundColorOne", brandData?.backgroundColorOne || "#007BFF");
      formik.setFieldValue("backgroundColorTwo", brandData?.backgroundColorTwo || "#007BFF");
      formik.setFieldValue("logo", brandData?.logo || "");
      formik.setFieldValue("favicon", brandData?.favicon || "");
      formik.setFieldValue("headerVideo", brandData?.headerVideo || "");
      formik.setFieldValue("complaint_emails", selectKeywords || []);
      formik.setFieldValue("defaultEmailTitle", brandData?.defaultEmailTitle || "");
      formik.setFieldValue("facebook", brandData?.facebook || "");
      formik.setFieldValue("facebookPixelId", brandData?.facebookPixelId || "");
      formik.setFieldValue("twitter", brandData?.twitter || "");
      formik.setFieldValue("youtube", brandData?.youtube || "");
      formik.setFieldValue("linkedIn", brandData?.linkedIn || "");
      formik.setFieldValue("instagram", brandData?.instagram || "");
      formik.setFieldValue("whatsappTemplateApiUrl", brandData?.whatsappTemplateApiUrl || "");
      formik.setFieldValue("whatsappMsgApiURL", brandData?.whatsappMsgApiURL || "");
      formik.setFieldValue("WhatsappAccessToken", brandData?.WhatsappAccessToken || "");
      formik.setFieldValue("UseWhatsapp", brandData?.UseWhatsapp || 0);
    }
  }, [brandData, selectKeywords]);
  return (
    <div className="card card-body-inr">
    <form onSubmit={formik.handleSubmit}>
    <div className="row">
    <div className="col-md-12">
    <div className="edit-icon new-card-header">
    <h4 className="card-header">Brand Information</h4> {loading ? <i className="fas fa-cog fa-spin"></i> : null}
    </div>
    </div>
    
    <FormField field={{ name: "templateName", label: "Template Name" }} formik={formik} />
    <FormField field={{ name: "brandLinked", label: "Linked Brand" }} formik={formik} selectOptions={brandArr} />

    <div className="col-md-6 col-lg-3">
      <div className="form-group-blk mb-3" title="Status">
        <label>Status * <i className="fal fa-info-circle"></i></label>
        <Select
          className={
            "form-control custom-select-box" +
            (formik.errors.status && formik.touched.status ? " is-invalid" : "")
          }
          name="status"
          value={
            formik.values.status !== "" 
              ? statusArr.find((val) => val?.value == formik.values.status) 
              : ""
          }
          onChange={(option) => {
            formik.setFieldValue("status", option ? option.value : "");
          }}
          onBlur={formik.handleBlur}
          options={statusArr}
          maxMenuHeight={175}
          placeholder={"Select Status"}
          isClearable
        />

      </div>
    </div>

    {/* <FormField field={{ name: "status", label: "Status", required: false, info: true }} required={true} formik={formik} selectOptions={statusArr} /> */}
    <div className="col-md-6 col-lg-3" title="Campus Locations">
    <label htmlFor="Campus_Locations">Campus Locations * <i className="fal fa-info-circle"></i></label>
    <div className="form-icon-group mb-4">
    <Select
    className={`form-control custom-select-box ${formik.touched.campusID && formik.errors.campusID && "is-invalid"}`}
    name="campusID"
    value={campusArr.filter((val) => formik.values.campusID.includes(val.value))}
    onChange={(value) => formik.setFieldValue("campusID", value ? value.map((v) => v.value) : [])}
    isClearable
    onBlur={formik.handleBlur}
    options={campusArr}
    maxMenuHeight={175}
    placeholder="Select"
    isMulti
    />
    </div>
    </div>
    <FormField field={{ name: "administration_fee", label: "Administration Fee" }} formik={formik} required={false}/>
    <FormField field={{ name: "student_registration_fee", label: "New Student Registration Fee" }} formik={formik} required={false}/>
    <FormField field={{ name: "currency", label: "Currency", required: false }} required={false} formik={formik} selectOptions={currencyArr} />
    <AssignPageField name="termsAndConditionsPages" label="Terms & Conditions Page" formik={formik} dataTarget="#assignPagePopupBrandTemp" assignedPage={assignedPage}
    setAssignedPage={setAssignedPage} />
    <AssignPageField name="thank_you_page_id" label="Assign Thank You Page" formik={formik} dataTarget="#assignThankYou" assignedPage={assignedThankYou}
    setAssignedPage={setAssignedThankYou} />
    <div className="col-md-12">
    <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
    </div>
    
    <div className="col-md-12">
    <div className="edit-icon new-card-header">
    <h4 className="card-header">Contact Information</h4>
    </div>
    </div>
    <FormField field={{ name: "primaryContactNo", label: "Primary Contact Number" }} formik={formik} />
    <FormField field={{ name: "whatsappNo", label: "WhatsApp Number" }} formik={formik} />
    <div className="w-100"></div>
    <FormField field={{ name: "primaryEmail", label: "Primary Email Address", type: "email" }} formik={formik} />
    {/* <FormField field={{ name: "defaultEmailTitle", label: "System Sent Emails From Address", required: false}} required={false} formik={formik} /> */}
    <div title='Complaint Email Addresses, press enter after entering an email address'className="col-md-6 col-lg-3 mb-3">
      <label>Complaint Email Addresses</label>
      <div className="form-icon-group">
        <div className="form-control custom-select-box">
          <input
            type="email"
            className={`form-control ${formik.errors.complaint_emails && formik.touched.complaint_emails ? "is-invalid" : ""}`}
            name="complaint_emails"
            placeholder="Add Complaint Emails Addresses"
            title="Complaint Emails Addresses"
            onBlur={formik.handleBlur}
            onKeyDown={handleKeyDown1}
          />
          {formik.errors.complaint_emails && formik.touched.complaint_emails && (
            <span className="exclamation"><i className="fal fa-exclamation-circle"></i></span>
          )}
        </div>
      </div>
      {selectKeywords.length > 0 && (
        <ul className="tag-list mb-0">
          {selectKeywords.map((email, index) => (
            <li key={index}>
              {email}
              <span onClick={() => removeKeywords(index)}>
                <i className="fal fa-times"></i>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
    <div className="col-md-6 col-lg-4">
      <div className="form-group-blk mb-4" title="Email address used to send emails from the system, ex. email used for email sentto user when filling in form">
        <label>System Sent Emails From Address * <i className="fal fa-info-circle"></i></label>
        <Select
          className={
            "form-control custom-select-box" +
            (formik.errors.defaultEmailTitle && formik.touched.defaultEmailTitle ? " is-invalid" : "")
          }
          name="defaultEmailTitle"
          value={
            formik.values.defaultEmailTitle !== ""
              ? emailsOptions.find((val) => val?.value == formik.values.defaultEmailTitle) 
              : ""
          }
          onChange={(option) => {
            formik.setFieldValue("defaultEmailTitle", option ? option.value : "");
          }}
          onBlur={formik.handleBlur}
          options={emailsOptions}
          maxMenuHeight={175}
          placeholder={"Select Email"}
          isClearable
        />

      </div>
    </div>   

    {/* <div className="col-md-6 col-lg-3 d-flex align-items-center">
  <label className="me-2">
    Use Whatsapp Info Packs 
    <i title="When enabled, info packs sent on enquiries will be sent to WhatsApp and not e-mail" className="fal fa-info-circle grade-icon"></i>
  </label>

  <div className="toggle-switch">
    <label className="switch">
      <input
        type="checkbox"
        checked={formik.values.UseWhatsapp == "1"}
        onChange={(e) => {
          formik.setFieldValue(
            "UseWhatsapp", formik.values.UseWhatsapp == "1" ? "0" : "1"
          );
        }}
      />
      <span className="slider slider-round"></span>
    </label>
  </div>
</div>
    
    {formik.values.UseWhatsapp == "1" && (
      <>
      <FormField field={{ name: "whatsappTemplateApiUrl", label: "Whatsapp Template API URL" }} formik={formik} />
      <FormField field={{ name: "whatsappMsgApiURL", label: "Whatsapp Message API URL" }} formik={formik} />
      <FormField field={{ name: "WhatsappAccessToken", label: "Whatsapp Access Token" }} formik={formik} />
      </>
    )} */}
    
    <div className="col-md-12">
    <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
    </div>
    
    <div className="col-md-12">
    <div className="edit-icon new-card-header">
    <h4 className="card-header">Brand Colours</h4>
    </div>
    </div>
    
    {[
      { label: "Header Colour", name: "headerColor", color: true, required: false, type: "color", },
      { label: "Header Hover Colour", name: "header_hover_color", color: true, required: false, type: "color", },
      { label: "Primary Colour", name: "primaryColor", color: true, required: false, type: "color" },
      { label: "Secondary Colour", name: "secondaryColor", color: true, required: false, type: "color" },
      // { label: "Button Colour", name: "buttonColor", color: true, required: false, type: "color" },
      // { label: "Button Hover Colour", name: "buttonHoverColor", color: true, required: false, type: "color" },
      // { label: "Button Active Colour", name: "buttonActiveColor", color: true, required: false, type: "color" },
      { label: "Highlight Colour", name: "highlightColor", color: true, required: false, type: "color" },
      { label: "Active Colour", name: "activeColor", color: true, required: false, type: "color" },
      { label: "Accent Colour 1", name: "accentColorOne", color: true, required: false, type: "color" },
      { label: "Accent Colour 2", name: "accentColorTwo", color: true, required: false, type: "color" },
      { label: "Accent Colour 3", name: "accentColorThree", color: true, required: false, type: "color" },
      { label: "Accent Colour 4", name: "accentColorFour", color: true, required: false, type: "color" },
      { label: "Text Colour 1", name: "textColorOne", color: true, required: false, type: "color" },
      { label: "Text Colour 2", name: "textColorTwo", color: true, required: false, type: "color" },
      { label: "Background Colour 1", name: "backgroundColorOne", color: true, required: false, type: "color" },
      { label: "Background Colour 2", name: "backgroundColorTwo", color: true, required: false, type: "color" },
    ].map((field, index) => (
      <FormField key={index} field={field} formik={formik} />
    ))}
    
    <div className="col-md-12">
    <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
    </div>
    
    <div className="col-md-12">
    <div className="edit-icon new-card-header">
    <h4 className="card-header">Brand Assets</h4>
    </div>
    </div>
    
    <FileUploadField name="logo" label="Logo" formik={formik} accept=".png,.jpg,.jpeg" required={true} col_md={6} col_lg={3} />
    <FileUploadField name="favicon" title={"This determines the bullet icon used for the category card component"} label="List Item Logo" formik={formik} accept=".png,.jpg,.jpeg,.svg" col_md={6} col_lg={3} />
    <FileUploadField name="headerVideo" label="Header Video" formik={formik} accept="video/*" required={true} col_md={6} col_lg={3} />
    <div className="col-md-12">
    <hr className="border border-2" style={{ borderColor: "#E6EBF1" }} />
    </div>
    <div className="col-md-12">
    <div className="edit-icon new-card-header">
    <h4 className="card-header">Social Media Links</h4>
    </div>
    </div>
    <FormField field={{ name: "facebook", label: "Facebook" }} required={false} formik={formik} />
    <FormField field={{ name: "twitter", label: "X (Twitter)" }} required={false} formik={formik} />
    <FormField field={{ name: "youtube", label: "YouTube" }} required={false} formik={formik} />
    <FormField field={{ name: "linkedIn", label: "LinkedIn" }} required={false} formik={formik} />
    <FormField field={{ name: "instagram", label: "Instagram" }} required={false} formik={formik} />
    <FormField field={{ name: "facebookPixelId", label: "Facebook Pixel Id" }} required={false} formik={formik} />
    
    
    </div>
    <div className="form-group form-group-save-cancel">
    <PermissionsGate scopes={[subType == "create" ? 'wbssitebrandtmpadd' : 'wbssitebrandtmpedit']} RenderError={() => (
      <button className="btn btn-save btn-primary" disabled>
      <i className="fal fa-save"></i> Save
      </button>
    )}>
    
    <button className="btn btn-save btn-primary" disabled={loading} type="submit" title="Save">
    {formik.isSubmitting ? (
      <i className="fas fa-cog fa-spin"></i>
    ) : (
      <i className="fal fa-save"></i>
    )} Save
    </button>
    </PermissionsGate>
    {Object.keys(formik.values).map((key) => {
      if (formik.touched[key] && formik.errors[key]) {
        return (
          <div className="invalid-feedback d-block">
          {formik.errors[key]}
          </div>
        );
      }
    })}
    </div>
    </form>
    <div
    className="topic-add-modal modal able_modal01 fade"
    id="assignPagePopupBrandTemp"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    >
    <div
    className="modal-dialog modal-dialog-centered available-assessment-modal"
    role="document"
    >
    <div className="modal-content">
    <div className="modal-header modal-header-custom">
    <h5 className="modal-title">
    <i className="fal fa-file"></i> Assign Terms & Conditions Page
    </h5>
    <button
    type="button"
    className="close"
    data-dismiss="modal"
    aria-label="Close"
    >
    <span aria-hidden="true">×</span>
    </button>
    </div>
    <div className="modal-body">
    <AssignPagePopup formik={formik} assignedPage={assignedPage} setAssignedPage={setAssignedPage} name={'termsAndConditionsPages'} />
    </div>
    </div>
    </div>
    </div>
    <div
    className="topic-add-modal modal able_modal01 fade"
    id="assignThankYou"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    >
    <div
    className="modal-dialog modal-dialog-centered available-assessment-modal"
    role="document"
    >
    <div className="modal-content">
    <div className="modal-header modal-header-custom">
    <h5 className="modal-title">
    <i className="fal fa-file"></i> Assign Thank You Page
    </h5>
    <button
    type="button"
    className="close"
    data-dismiss="modal"
    aria-label="Close"
    >
    <span aria-hidden="true">×</span>
    </button>
    </div>
    <div className="modal-body">
    <AssignPagePopup formik={formik} assignedPage={assignedThankYou} uniqueId="thank" setAssignedPage={setAssignedThankYou} name={'thank_you_page_id'}/>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default BrandInformation;

