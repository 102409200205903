import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { getBlockVariantTypeDropDown, getBrandTemplate, getBrandTemplateStatus, getHeaderDropDownListStatic, getHeaderDropdown, getPopUpListStatic, updateBrandTemplateHomePage } from "../../../services/BrandTemplateServices";
// import axios from "../../../utils/axios";
import axios from "axios";
import { FormField } from "../../common/FormFields";
import { AddAndUpdateProgrammePageContentAndCustomisation, GetProgrammePageDetailCancelToken } from "../../../services/ProgramService";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import FormImpl from "react-bootstrap/esm/Form";
import SectionColorOverrideComponent
from "../SectionColorOverrideComponent";
import PermissionsGate from "../../../utils/permissionGate";
function PageCustomisation() {
    const { subId, subType, id, type } = useParams();
    const history = useHistory();
    const [addCss, setAddCss] = useState(false)
    const [statusArr, setStatusArr] = useState([]);
    const [popUpArr, setPopUpArr] = useState([]);
    const [headerArr, setHeaderArr] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [use, setUse] = useState(false)
    const [css, setCss] = useState("")
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
    const [brandData, setBrandData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statusRes, headerRes, BlockVariantTypeRes, PopUpRes] = await Promise.all([
                    getBrandTemplateStatus(),
                    getHeaderDropDownListStatic(),
                    getBlockVariantTypeDropDown(),
                    getPopUpListStatic()
                ]);
                const statusFilters = statusRes.data.statusFilters || [];
                const popUpFilters = PopUpRes.data.Data || [];
                const headerFilters = headerRes.data.Data || [];
                const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
                const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
                setStatusArr(statusFilters);
                setHeaderArr(headerFilters);
                setPopUpArr(popUpFilters);
                setLearnignBlockArr(learnignBlockFilters);
                setTestimonialsBlockArr(testimonialsBlockFilters);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };
        
        fetchData();
    }, []);
    
    const handlereset = () => {
        history.push(`/websiteManagement/pages/programmes_and_short_courses/programme_pages/table`);
        formik.handleReset()
    }
    
    useEffect(() => {
        setDisabled(true);
        const cancelTokenSources = [];
        try{
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            
            const source = axios.CancelToken?.source();
            cancelTokenSources.push(source);
            setDisabled(true);
            const getData = async () => {        
                const res = await GetProgrammePageDetailCancelToken( {id:id},source.CancelToken);
                if (res.status == 200 && res.data?.data) {
                    setBrandData(res.data?.data);
                }
                setDisabled(false);
            }
            if(id){
                getData();
            }
        }catch(error){
            console.error(error);
            setDisabled(false);
        }
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
        setDisabled(false);
    }, [id]);
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siteTitle: brandData.siteTitle || '',
            header: brandData.header || '',
            popupID: brandData.popupID || '',
            analyticsCode: brandData.analyticsCode || '',
            toggle_show_button_form : brandData.toggle_show_button_form || 0,
            toggle_brand_template : brandData.toggle_brand_template || 0,
            toggle_custom_css : brandData.toggle_custom_css || 0,
            
            
            school_category_text: brandData.school_category_text || '',
            school_category_show_block: brandData.school_category_show_block || 0,
            school_category_search_bar: brandData.school_category_search_bar || 0,
            
            secondary_category_text: brandData.secondary_category_text || '',
            secondary_category_show_block: brandData.secondary_category_show_block || 0,
            
            upcoming_opendays_text: brandData.upcoming_opendays_text || '',
            upcoming_opendays_show_block: brandData.upcoming_opendays_show_block || 0,
            
            learning_method_block_variant: brandData.learning_method_block_variant || '',
            learning_method_block_text: brandData.learning_method_block_text || '',
            learning_method_show_block: brandData.learning_method_show_block || 0,
            
            testimonials_block_variant: brandData.testimonials_block_variant || '',
            testimonials_block_text: brandData.testimonials_block_text || '',
            testimonials_block_show: brandData.testimonials_block_show || 0,
            
            news_block_text: brandData.news_block_text || '',
            news_block_show: brandData.news_block_show || 0,
            
            meta_title: brandData.meta_title || "",
            meta_description: brandData.meta_description || "",
            meta_keywords: brandData.meta_keywords || "",
            custom_css: brandData.custom_css || "",
            page_type: "0",
            
            school_override_color: brandData?.school_override_color || 0,
            school_accentColor: brandData?.school_accentColor || "#007BFF",
            school_textColorOne: brandData?.school_textColorOne || "#007BFF",
            school_textColorTwo: brandData?.school_textColorTwo || "#007BFF",
            school_backgroundColor: brandData?.school_backgroundColor || "#007BFF",
            openday_override_color: brandData?.openday_override_color || 0,
            openday_accentColor: brandData?.openday_accentColor || "#007BFF",
            openday_textColorOne: brandData?.openday_textColorOne || "#007BFF",
            openday_textColorTwo: brandData?.openday_textColorTwo || "#007BFF",
            openday_backgroundColor: brandData?.openday_backgroundColor || "#007BFF",
            learning_method_override_color: brandData?.learning_method_override_color || 0,
            learning_method_accentColor: brandData?.learning_method_accentColor || "#007BFF",
            learning_method_textColorOne: brandData?.learning_method_textColorOne || "#007BFF",
            learning_method_textColorTwo: brandData?.learning_method_textColorTwo || "#007BFF",
            learning_method_backgroundColor: brandData?.learning_method_backgroundColor || "#007BFF",
            testimonials_override_color: brandData?.testimonials_override_color || 0,
            testimonials_accentColor: brandData?.testimonials_accentColor || "#007BFF",
            testimonials_textColorOne: brandData?.testimonials_textColorOne || "#007BFF",
            testimonials_textColorTwo: brandData?.testimonials_textColorTwo || "#007BFF",
            testimonials_backgroundColor: brandData?.testimonials_backgroundColor || "#007BFF",
            news_override_color: brandData?.news_override_color || 0,
            news_accentColor: brandData?.news_accentColor || "#007BFF",
            news_textColorOne: brandData?.news_textColorOne || "#007BFF",
            news_textColorTwo: brandData?.news_textColorTwo || "#007BFF",
            news_backgroundColor: brandData?.news_backgroundColor || "#007BFF",
            toggle_brand_template: brandData?.toggle_brand_template || 0,
            groupByCourseLandingPages: brandData?.groupByCourseLandingPages || 0,
        },
        validationSchema: Yup.object().shape({
            // school_category_text:  use ? Yup.string().required('Schools Category Section Title Text is required') : "",
            // secondary_category_text:  use ? Yup.string().required('Secondary Category Section Title Text is required') : "",
            // upcoming_opendays_text:  use ? Yup.string().required('Upcoming Open Days Section Title Text is required') : "",
            // learning_method_block_text:  use ? Yup.string().required('Learning Methods Section Title Text is required') : "",
            // testimonials_block_text:  use ? Yup.string().required('Testimonials Section Title Text is required') : "",
            // news_block_text:  use ? Yup.string().required('News Section Title Text is required') : "",
            // custom_css : addCss ? Yup.string().required('Custom Css is required') : ""
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const formData = new FormData()
            formData.append("popupID", values.popupID)
            formData.append("page_type", values.page_type)
            formData.append("toggle_show_button_form", values.toggle_show_button_form)
            formData.append("toggle_brand_template", values.toggle_brand_template)
            formData.append("school_category_show_block", values.school_category_show_block)
            formData.append("school_category_search_bar", values.school_category_search_bar)
            formData.append("school_category_text", values.school_category_text)
            formData.append("secondary_category_show_block", values.secondary_category_show_block)
            formData.append("secondary_category_text", values.secondary_category_text)
            formData.append("upcoming_opendays_show_block", values.upcoming_opendays_show_block)
            formData.append("upcoming_opendays_text", values.upcoming_opendays_text)
            formData.append("learning_method_show_block", values.learning_method_show_block)
            formData.append("learning_method_block_variant", values.learning_method_block_variant)
            formData.append("learning_method_block_text", values.learning_method_block_text)
            formData.append("testimonials_block_show", values.testimonials_block_show)
            formData.append("testimonials_block_variant", values.testimonials_block_variant)
            formData.append("testimonials_block_text", values.testimonials_block_text)
            formData.append("news_block_show", values.news_block_show)
            formData.append("news_block_text", values.news_block_text)
            formData.append("meta_title", values.meta_title)
            formData.append("meta_description", values.meta_description)
            formData.append("meta_keywords", values.meta_keywords)
            formData.append("toggle_custom_css", values.toggle_custom_css)
            formData.append("custom_css", values.custom_css)
            formData.append("school_override_color", values.school_override_color)
            formData.append("school_accentColor", values.school_accentColor)
            formData.append("school_textColorOne", values.school_textColorOne)
            formData.append("school_textColorTwo", values.school_textColorTwo)
            formData.append("school_backgroundColor", values.school_backgroundColor)
            formData.append("openday_override_color", values.openday_override_color)
            formData.append("openday_accentColor", values.openday_accentColor)
            formData.append("openday_textColorOne", values.openday_textColorOne)
            formData.append("openday_textColorTwo", values.openday_textColorTwo)
            formData.append("openday_backgroundColor", values.openday_backgroundColor)
            formData.append("learning_method_override_color", values.learning_method_override_color)
            formData.append("learning_method_accentColor", values.learning_method_accentColor)
            formData.append("learning_method_textColorOne", values.learning_method_textColorOne)
            formData.append("learning_method_textColorTwo", values.learning_method_textColorTwo)
            formData.append("learning_method_backgroundColor", values.learning_method_backgroundColor)
            formData.append("testimonials_override_color", values.testimonials_override_color)
            formData.append("testimonials_accentColor", values.testimonials_accentColor)
            formData.append("testimonials_textColorOne", values.testimonials_textColorOne)
            formData.append("testimonials_textColorTwo", values.testimonials_textColorTwo)
            formData.append("testimonials_backgroundColor", values.testimonials_backgroundColor)
            formData.append("news_override_color", values.news_override_color)
            formData.append("news_accentColor", values.news_accentColor)
            formData.append("news_textColorOne", values.news_textColorOne)
            formData.append("news_textColorTwo", values.news_textColorTwo)
            formData.append("news_backgroundColor", values.news_backgroundColor)
            if (type == "open") {
                formData.append("id", id)
            }
            AddAndUpdateProgrammePageContentAndCustomisation(formData)
            .then(() => {
                Swal.fire('Success!', `Page customization section ${type == "open" ? "updated" : "added"} successfully!`, 'success');
                // history.push("/websiteManagement/siteSetup/brand_templates");
            }).catch((err) => {
                Swal.fire('Error!', err?.response?.data?.message, 'error');
            })
            // if (subType === 'Create') {
            //     Swal.fire('Error!', 'Something went wrong', 'error');
            // } else if (subType === 'update') {
            //     await updateBrandTemplateHomePage({ ...values, brandID: subId })
            //         .then(response => {
                //             Swal.fire('Success!', 'Brand Template updated successfully!', 'success');
            //             history.push("/websiteManagement/siteSetup/brand_Templates");
            //         })
            //         .catch(error => {
                //             Swal.fire('Error!', 'Failed to update Brand Template', 'error');
            //         });
            // }
            // setSubmitting(false);
        },
    });
    const { handleSubmit,handleBlur,handleReset,values } = formik
    return (
        <div className="card card-body-inr">
        <form onSubmit={handleSubmit}>
        <div className="row">
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header">Page & Sections Settings</h4>
        </div>
        </div>
        
        <div className="col-md-6 col-lg-3 ">
        <div className="form-icon-group flex-column mb-4" title="Select the popup displayed when opening the page. Selecting the default option will display the popup assigned to the brand template, selecting none will show no popup when opening the page">
        <label>Popup</label>
        <Select
        className={
            "form-control custom-select-box" +
            (formik.errors.popupID &&
                formik.touched.popupID
                ? " is-invalid"
                : "")
            }
            name="popupID"
            value={popUpArr ? popUpArr.filter((val)=>{
                return val.value == formik.values.popupID
            })  : ""}
            onChange={(value) => {
                if (value) {
                    formik.setFieldValue("popupID", value.value);
                } else {
                    formik.setFieldValue("popupID", "");
                }                                    
            }}
            onBlur={formik.handleBlur}
            options={popUpArr}
            maxMenuHeight={175}
            
            isClearable
            />
            </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex align-items-center" title="This determines the visibility of the bottom form found above the footer">
            <div
            className="d-flex"
            title="This determines the visibility of the bottom form found above the footer"
            >
            <label className="mb-0">
            Show Bottom Form <i className="fal fa-info-circle grade-icon"></i>
            </label>
            <div className="toggle-switch ml-2">
            <label class="switch">
            <input
            type="checkbox"
            checked={formik.values.toggle_show_button_form == 1}
            onChange={(e) => {
                formik.setFieldValue(
                    "toggle_show_button_form",
                    e.target.checked ? 1 : 0
                );
            }}
            />
            <span class="slider slider-round"></span>
            </label>
            </div>
            </div>
            </div>
            </div>
            
            <div className="row mb-4">
            <div className="col-md-12">
            <div className="edit-icon new-card-header">
            <h4 className="card-header">Section Settings</h4>
            </div>
            </div>
            
            <div className="col-md-6 col-lg-3 d-flex align-items-center">
            <div
            className="d-flex"
            title="Overwrite Section Content and settings of page components, this overwrites the settings on the brand template and is only applicable to this page."
            >
            <label className="mb-0">
            Override Brand Template Section Settings <i className="fal fa-info-circle grade-icon"></i>
            </label>
            <div className="toggle-switch ml-2">
            <label class="switch">
            <input
            type="checkbox"
            checked={formik.values.toggle_brand_template == 1 }
            onChange={(e) => {
                setUse(e.target.checked)
                formik.setFieldValue(
                    "toggle_brand_template", 
                    e.target.checked ? 1 : 0
                );
            }}
            />
            <span class="slider slider-round"></span>
            </label>
            </div>
            </div>
            </div>
            </div>
            {
                formik.values.toggle_brand_template ? (
                    <>
                    <div className="row">
                    <div className="col-md-12">
                    
                    <div className="row pt-1">
                    <div className="col-md-12">
                    <div className="edit-icon new-card-header">
                    <h4 className="card-header">Learning Methods Section</h4>
                    </div>
                    </div>
                    
                    <div className={`col-md-6 col-lg-3`}>
                    <label htmlFor={"Section Settings"}>
                    Section Settings
                    </label>
                    <div className="form-icon-group mb-4 mt-3">
                    <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                    <input
                    type="checkbox"
                    className="custom-control-input"
                    id="learning_method_show_block"
                    name="learning_method_show_block"
                    checked={formik.values.learning_method_show_block == 1}
                    onChange={(e) => {
                        formik.setFieldValue(
                            "learning_method_show_block",
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <label
                    className="custom-control-label"
                    htmlFor="learning_method_show_block"
                    >
                    Show Section
                    </label>
                    </div>
                    </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title="Section Variant">
                    <label htmlFor="learning_method_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <Select
                    className={
                        "form-control custom-select-box " +
                        (formik.errors.learning_method_block_variant && formik.touched.learning_method_block_variant
                            ? " is-invalid"
                            : "")
                        }
                        name="learning_method_block_variant"
                        value={learnignBlockArr.filter(
                            (item) => item.value == formik.values.learning_method_block_variant
                        )}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue("learning_method_block_variant", value.value);
                            } else {
                                formik.setFieldValue("learning_method_block_variant", "");
                            }
                        }}
                        onBlur={formik.handleBlur}
                        options={learnignBlockArr}
                        maxMenuHeight={175}
                        placeholder={"Select Section Variant"}
                        />
                        </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-4">
                        <label htmlFor="learning_method_block_text" title="Site Name">Section Title Text</label>
                        <div className="form-icon-group" title="site_Name">
                        <input
                        type="text"
                        className={`form-control ${formik.touched.learning_method_block_text && formik.errors.learning_method_block_text && "is-invalid"
                        }`}
                        name="learning_method_block_text"
                        id="learning_method_block_text"
                        title="Section Title Text"
                        placeholder="Section Title Text"
                        value={formik.values?.learning_method_block_text}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        />
                        </div>
                        </div>
                        </div>
                        <SectionColorOverrideComponent 
                        prefix="learning_method_" 
                        formik={formik} 
                        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                        <hr/>
                    <div className="edit-icon new-card-header">
                    <h4 className="card-header">Schools Category Section </h4>
                    </div>
                    </div>
                    
                    <div className={`col-md-6 col-lg-3`}>
                    <label htmlFor={"Section Settings"}>
                    Section Settings
                    </label>
                    <div className="form-icon-group mt-3">
                    <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section">
                    <input
                    type="checkbox"
                    className="custom-control-input"
                    id="school_category_show_block"
                    name="school_category_show_block"
                    checked={formik.values.school_category_show_block == 1}
                    onChange={(e) => {
                        formik.setFieldValue(
                            "school_category_show_block",
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <label
                    className="custom-control-label"
                    htmlFor="school_category_show_block"
                    >
                    Show Section
                    </label>
                    </div>
                    <div className="custom-control custom-checkbox text-left" title="Show Course Search Bar">
                    <input
                    type="checkbox"
                    className="custom-control-input"
                    id="school_category_search_bar"
                    name="school_category_search_bar"
                    checked={formik.values.school_category_search_bar == 1}
                    onChange={(e) => {
                        formik.setFieldValue(
                            "school_category_search_bar",
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <label
                    className="custom-control-label"
                    htmlFor="school_category_search_bar"
                    >
                    Show Course Search Bar
                    </label>
                    </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-9">
                    <label htmlFor="school_category_text" title="Site Name">Section Title Text</label>
                    <div className="form-icon-group" title="site_Name">
                    <input
                    type="text"
                    className={`form-control ${formik.touched.school_category_text && formik.errors.school_category_text && "is-invalid"
                    }`}
                    name="school_category_text"
                    id="school_category_text"
                    title="Section Title Text"
                    placeholder="Section Title Text"
                    value={formik.values?.school_category_text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div>
                    </div>                   
                    <div
                    className="d-flex my-20"
                    title="Group Content by Course Landing Pages and not by schools"
                    >
                    <label className="mb-0">
                    Group Content by Course Landing Pages
                    <i className="fal fa-info-circle grade-icon ml-2"></i>
                    {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
                    </label>
                    <div className="toggle-switch ml-2">
                    <label className="switch">
                    <input
                    type="checkbox"
                    checked={formik.values.groupByCourseLandingPages == 1}
                    onChange={(e) => {
                        formik.setFieldValue(
                            'groupByCourseLandingPages',
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <span className="slider slider-round"></span>
                    </label>
                    </div>
                    </div>
                    <SectionColorOverrideComponent 
                    prefix="school_" 
                    formik={formik} 
                    fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                     <hr />
                    <div className="row pt-1">
                    <div className="col-md-12">
                    <div className="edit-icon new-card-header">
                    <h4 className="card-header">Secondary Category Section</h4>
                    </div>
                    </div>
                    
                    <div className={`col-md-6 col-lg-3`}>
                    <label htmlFor={"Section Settings"}>
                    Section Settings
                    </label>
                    <div className="form-icon-group mt-3">
                    <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section">
                    <input
                    type="checkbox"
                    className="custom-control-input"
                    id="secondary_category_show_block"
                    name="secondary_category_show_block"
                    checked={formik.values.secondary_category_show_block == 1}
                    onChange={(e) => {
                        formik.setFieldValue(
                            "secondary_category_show_block",
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <label
                    className="custom-control-label"
                    htmlFor="secondary_category_show_block"
                    >
                    Show Section
                    </label>
                    </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-9">
                    <label htmlFor="secondary_category_text" title="Site Name">Section Title Text</label>
                    <div className="form-icon-group" title="site_Name">
                    <input
                    type="text"
                    className={`form-control ${formik.touched.secondary_category_text && formik.errors.secondary_category_text && "is-invalid"
                    }`}
                    name="secondary_category_text"
                    id="secondary_category_text"
                    title="Section Title Text"
                    placeholder="Section Title Text"
                    value={formik.values?.secondary_category_text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div>
                    </div>
                    <hr />
                    
                    <div className="row pt-1">
                    <div className="col-md-12">
                    <div className="edit-icon new-card-header">
                    <h4 className="card-header">Upcoming Open Days Section</h4>
                    </div>
                    </div>
                    
                    <div className={`col-md-6 col-lg-3`}>
                    <label htmlFor={"Section Settings"}>
                    Section Settings
                    </label>
                    <div className="form-icon-group mb-4 mt-3">
                    <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                    <input
                    type="checkbox"
                    className="custom-control-input"
                    id="upcoming_opendays_show_block"
                    name="upcoming_opendays_show_block"
                    checked={formik.values.upcoming_opendays_show_block == 1}
                    onChange={(e) => {
                        formik.setFieldValue(
                            "upcoming_opendays_show_block",
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <label
                    className="custom-control-label"
                    htmlFor="upcoming_opendays_show_block"
                    >
                    Show Section
                    </label>
                    </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-9">
                    <label htmlFor="upcoming_opendays_text" title="Site Name">Section Title Text</label>
                    <div className="form-icon-group" title="site_Name">
                    <input
                    type="text"
                    className={`form-control ${formik.touched.upcoming_opendays_text && formik.errors.upcoming_opendays_text && "is-invalid"
                    }`}
                    name="upcoming_opendays_text"
                    id="upcoming_opendays_text"
                    title="Section Title Text"
                    placeholder="Section Title Text"
                    value={formik.values?.upcoming_opendays_text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div>
                    </div>
                    <SectionColorOverrideComponent 
                    prefix="openday_" 
                    formik={formik} 
                    fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />                   
                        <hr />
                        <div className="row pt-1">
                        <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                        <h4 className="card-header">Testimonials Section</h4>
                        </div>
                        </div>
                        
                        <div className={`col-md-6 col-lg-3`}>
                        <label htmlFor={"Section Settings"}>
                        Section Settings
                        </label>
                        <div className="form-icon-group mb-4 mt-3">
                        <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                        <input
                        type="checkbox"
                        className="custom-control-input"
                        id="testimonials_block_show"
                        name="testimonials_block_show"
                        checked={formik.values.testimonials_block_show == 1}
                        onChange={(e) => {
                            formik.setFieldValue(
                                "testimonials_block_show",
                                e.target.checked ? 1 : 0
                            );
                        }}
                        />
                        <label
                        className="custom-control-label"
                        htmlFor="testimonials_block_show"
                        >
                        Show Section
                        </label>
                        </div>
                        </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3" title="Section Variant">
                        <label htmlFor="testimonials_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
                        </label>
                        <Select
                        className={
                            "form-control custom-select-box " +
                            (formik.errors.testimonials_block_variant && formik.touched.testimonials_block_variant
                                ? " is-invalid"
                                : "")
                            }
                            name="Linked PMC Programme"
                            value={testimonialsBlockArr.filter(
                                (item) => item.value == formik.values.testimonials_block_variant
                            )}
                            onChange={(value) => {
                                if (value) {
                                    formik.setFieldValue("testimonials_block_variant", value.value);
                                } else {
                                    formik.setFieldValue("testimonials_block_variant", "");
                                }
                            }}
                            onBlur={formik.handleBlur}
                            options={testimonialsBlockArr}
                            maxMenuHeight={175}
                            placeholder={"Select Section Variant"}
                            />
                            </div>
                            </div>
                            
                            <div className="col-md-6 col-lg-4">
                            <label htmlFor="testimonials_block_text" title="Site Name">Section Title Text</label>
                            <div className="form-icon-group" title="site_Name">
                            <input
                            type="text"
                            className={`form-control ${formik.touched.testimonials_block_text && formik.errors.testimonials_block_text && "is-invalid"
                            }`}
                            name="testimonials_block_text"
                            id="testimonials_block_text"
                            title="Section Title Text"
                            placeholder="Section Title Text"
                            value={formik.values?.testimonials_block_text}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                            </div>
                            </div>
                            </div>
                            <SectionColorOverrideComponent 
                            prefix="testimonials_" 
                            formik={formik} 
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                            <hr />
                            <div className="row pt-1">
                            <div className="col-md-12">
                            <div className="edit-icon new-card-header">
                            <h4 className="card-header">News Section</h4>
                            </div>
                            </div>
                            
                            <div className={`col-md-6 col-lg-3`}>
                            <label htmlFor={"Section Settings"}>
                            Section Settings
                            </label>
                            <div className="form-icon-group mb-4 mt-3">
                            <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                            <input
                            type="checkbox"
                            className="custom-control-input"
                            id="news_block_show"
                            name="news_block_show"
                            checked={formik.values.news_block_show == 1}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "news_block_show",
                                    e.target.checked ? 1 : 0
                                );
                            }}
                            />
                            <label
                            className="custom-control-label"
                            htmlFor="news_block_show"
                            >
                            Show Section
                            </label>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-6 col-lg-9">
                            <label htmlFor="news_block_text" title="Site Name">Section Title Text</label>
                            <div className="form-icon-group" title="site_Name">
                            <input
                            type="text"
                            className={`form-control ${formik.touched.news_block_text && formik.errors.news_block_text && "is-invalid"
                            }`}
                            name="news_block_text"
                            id="news_block_text"
                            title="Section Title Text"
                            placeholder="Section Title Text"
                            value={formik.values?.news_block_text}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                            </div>
                            </div>
                            <SectionColorOverrideComponent 
                            prefix="news_" 
                            formik={formik} 
                            fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                            </div>
                            
                            <hr/>
                            </>
                        ) : ""
                    }
                    
                    
                    
                    <div className="row mb-3">
                    <div className="col-md-12">
                    <div className="edit-icon new-card-header">
                    <h4 className="card-header"><FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> SEO Settings</h4>
                    </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-12 mb-3">
                    <label htmlFor="meta_title" title="Meta Title">Meta Title</label>
                    <div className="form-icon-group" title="site_Name">
                    <input
                    type="text"
                    className={`form-control ${formik.touched.meta_title && formik.errors.meta_title && "is-invalid"
                    }`}
                    name="meta_title"
                    id="meta_title"
                    title="Meta Title"
                    placeholder="Meta Title"
                    value={formik.values?.meta_title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-12 mb-3">
                    <label htmlFor="meta_description" title="Site Name">Meta Description</label>
                    <div className="form-icon-group" title="site_Name">
                    <input
                    type="text"
                    className={`form-control ${formik.touched.meta_description && formik.errors.meta_description && "is-invalid"
                    }`}
                    name="meta_description"
                    id="meta_description"
                    title="Meta Description"
                    placeholder="Meta Description"
                    value={formik.values?.meta_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-12">
                    <label htmlFor="meta_keywords" title="Site Name">Meta Keywords</label>
                    <div className="form-icon-group" title="site_Name">
                    <input
                    type="text"
                    className={`form-control ${formik.touched.meta_keywords && formik.errors.meta_keywords && "is-invalid"
                    }`}
                    name="meta_keywords"
                    id="meta_keywords"
                    title="Meta Title"
                    placeholder="Meta Title"
                    value={formik.values?.meta_keywords}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                    </div>
                    </div>
                    </div>
                    
                    <div className="row mb-3">
                    <div className="col-md-12">
                    <div className="edit-icon new-card-header">
                    <h4 className="card-header"><FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> Style Settings</h4>
                    </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-3 d-flex align-items-center">
                    <div
                    className="d-flex"
                    title="Enabling this will allow you to add custom CSS styles in plain CSS"
                    >
                    <label className="mb-0">
                    Add Custom CSS <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                    <label class="switch">
                    <input
                    type="checkbox"
                    checked={formik.values.toggle_custom_css == 1}
                    onChange={(e) => {
                        setAddCss(e.target.checked)
                        formik.setFieldValue(
                            "toggle_custom_css",
                            e.target.checked ? 1 : 0
                        );
                    }}
                    />
                    <span class="slider slider-round"></span>
                    </label>
                    </div>
                    </div>
                    </div>
                    
                    </div>
                    <hr/>
                    {
                        formik.values.toggle_custom_css ? (
                            <>
                            <span className="my-2">Custom CSS *</span>
                            <div className="html-editor-input">
                            <textarea value={formik.values.custom_css} className={`textarea form-control ${formik.touched.custom_css && formik.errors.custom_css && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="custom_css"></textarea>
                            <div className="headerf">
                            <span className="title fw-bolds"> CSS</span>
                            </div>
                            </div>
                            </>
                        ) : ""
                    }
                    
                    
                    <div className="form-group form-group-save-cancel mt-4">
                    <PermissionsGate scopes={[type == "create" ? 'wbspagespmcpadd' : 'wbspagespmcpedit']} RenderError={() => (
                        <button className="btn btn-save btn-success" disabled>
                            <i className="fal fa-save"></i> Save
                        </button>
                    )}>

                        <button
                            className="btn btn-save btn-success"
                            type="submit"
                            title="Save"
                            disabled={disabled }
                            onClick={formik.handleSubmit}
                        >
                            {disabled ? (
                                <i className="fas fa-cog fa-spin"></i>
                            ) : (
                                <i className="fal fa-save"></i>
                            )}
                            Save
                        </button>
                    </PermissionsGate>
                    
                    <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() =>formik.resetForm()}
                    >
                    <i className="fal fa-times"></i>Cancel
                    </button>
                    </div>  
                    {Object.keys(formik.values).map(key => {
                        if (formik.touched[key] && formik.errors[key]) {
                            return (
                                <div className="invalid-feedback d-block">
                                {formik.errors[key]}
                                </div>
                            )
                        }
                    })}              
                    </form>
                    </div>
                )
            }
            
            export default PageCustomisation