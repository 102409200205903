import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useParams, Link} from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import SmartSignageList from "./SmartSignageList";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import SmartSignageUserCreate from "./SmartSignageUserCreate";
import SmartSignageUserList from "./SmartSignageUserList";
import PermissionsGate from "../../../utils/permissionGate";
import SmartSignageGroupList from "./SmartSignageGroupList";
import SmartSignageGroupCreate from "./SmartSignageGroupCreate";
import SmartSignageLayoutList from "./SmartSignageLayoutList";
import SmartSignageLayoutCreate from "./SmartSignageLayoutCreate";
import AuditTrail from "./AuditTrail";
function SmartSignage() {
  const [activeTab, setActiveTab] = useState('');
  const history = useHistory();
  const { tab, type, id } = useParams();
  const search = useLocation().search;
  const signageId = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (tab !== '') {
      setActiveTab(tab);
    } else {
      setActiveTab('list');
    }
  }, [tab]);
  
  return (
    <>
    <Header />
    <div className="content-wrapper d-flex flex-wrap">
    <SideBar />
    <div className="sidebar-right">
    <div className="sub-menu-content-block">
    <div className="sub-menu-right-block">
    <div className="wrap-box-right">
    <div className="my-tickets-heading-blk flex-direction mb-2">
    <div className="d-flex align-items-center ">
    <div className="name-w-head d-flex align-items-center ">
    <h4 className="text-left-align landing-heading heading_color_dynamic">
    Smart<span> Signage</span>
    </h4>
    </div>
    <div className="text-md-right action2-blk">
    <div className="ticket-view-btn d-flex align-items-center">
    <div
    className="add-new-ticket-bx"
    style={{ display: 'flex', alignItems: 'center' }}
    >
    <button
    onClick={() => history.goBack()}
    title="Back"
    className="btn btn-white-bordered"
    >
    <i className="fal fa-angle-left"></i>Back
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className="tabs-wrap">
    <ul className="nav nav-pills mb-2" id="tabs" role="tablist">
    <li className="nav-item" onClick={() => history.push("/campusAdministration/smartsignage/list")}>
    <Link to={"/campusAdministration/smartsignage/list"} className={`nav-link ${activeTab === 'list' || activeTab === 'list' ? 'active' : ''}`} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
    {signageId === null && <><i className="fal fa-chart-pie"></i>Dashboard</>}
    {signageId !== null && GlobalIconNames("Details").html}
    </Link>
    </li>
    <PermissionsGate scopes={["casmartsignuserview"]}>
    <li className="nav-item" onClick={() => history.push("/campusAdministration/smartsignage/displayusers")}>
    <Link to={"/campusAdministration/smartsignage/displayusers"} className={`nav-link ${activeTab == 'displayusers' ? 'active' : ''}`} id="pills-tab4" data-toggle="pill" href="#pills-tab2" role="tab" aria-controls="pills-tab2" aria-selected="true">
    {<><i className="fal fa-desktop"></i>Displays</>}
    </Link>
    </li>
    </PermissionsGate>
    <PermissionsGate scopes={["casmartsigngroupview"]}>
    <li className="nav-item" onClick={() => history.push("/campusAdministration/smartsignage/displaygroups")}>
    <Link to={"/campusAdministration/smartsignage/displaygroups"} className={`nav-link ${activeTab == 'displaygroups' ? 'active' : ''}`} id="pills-tab4" data-toggle="pill" href="#pills-tab3" role="tab" aria-controls="pills-tab3" aria-selected="true">
    {<><i className="fal fa-link"></i>Display Groups</>}
    </Link>
    </li>
    </PermissionsGate>
    <PermissionsGate scopes={["casmartsignlayoutview"]}>
    <li className="nav-item" onClick={() => history.push("/campusAdministration/smartsignage/layouts")}>
    <Link to={"/campusAdministration/smartsignage/layouts"} className={`nav-link ${activeTab == 'layouts' ? 'active' : ''}`} id="pills-tab4" data-toggle="pill" href="#pills-tab4" role="tab" aria-controls="pills-tab4" aria-selected="true">
    {<><i className="fal fa-shapes"></i>Layouts</>}
    </Link>
    </li>
    </PermissionsGate>
    <li className="nav-item" onClick={() => history.push("/campusAdministration/smartsignage/auditTrail")}>
    <Link to={"/campusAdministration/smartsignage/auditTrail"} className={`nav-link ${activeTab == 'auditTrail' ? 'active' : ''}`} id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
    <i className="fal fa-history"></i>Audit Trail
    </Link>
    </li>
    </ul>
    <div className="tab-content" id="pills-tabContent">
    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
    {tab == "list" && <SmartSignageList/>}
    {(tab === 'displayusers' && (type == 'add' || type == 'edit')) && <SmartSignageUserCreate />}    
    {(tab === 'displayusers' && !(type == 'add' || type == 'edit'))  && <SmartSignageUserList/>}
    {(tab === 'displaygroups' && (type == 'add' || type == 'edit')) && <SmartSignageGroupCreate />}    
    {(tab === 'displaygroups' && !(type == 'add' || type == 'edit'))  && <SmartSignageGroupList/>}
    {(tab === 'layouts' && (type == 'add' || type == 'edit')) && <SmartSignageLayoutCreate />}
    {(tab === 'layouts' && !(type == 'add' || type == 'edit'))  && <SmartSignageLayoutList/>}    
    {tab === 'auditTrail' && <AuditTrail/>}
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
  )
  
}


export default SmartSignage;