import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { convertIntoMb, showErrors } from '../../../common/Helper';
import { IconName } from './IconNames';

const UploadMultiFormatModal = ({ show, setShow, formik: parentFormik, documentTypesList,isLibraryDocument,libraryFiles, operation,fileIndex }) => {
    const [acceptFileName, setAcceptFileName] = useState("");
    const fileInputRef = useRef(null);

    // Validation schema
    const validationSchema = Yup.object().shape({
        type: Yup.string().required('File type is required'),
        files: Yup.array()
            .of(Yup.mixed())
            .min(1, 'Please provide a file')
    });
    

    const initialValues = {
        type: isLibraryDocument ? "Library" : "",
        files: []
    };

    const handleChangeDocumentType = (value, formikProps) => {
        const { setFieldValue } = formikProps;
        let acceptedFormat = "";
        
        switch(value.label) {
            case "Image":
                acceptedFormat = "image/*";
                break;
            case "Word":
                acceptedFormat = ".docx, .doc";
                break;
            case "Excel":
                acceptedFormat = ".xlsx";
                break;
            case "PDF":
                acceptedFormat = ".pdf";
                break;
            case "ZIP":
                acceptedFormat = ".zip";
                break;
            case "Powerpoint":
                acceptedFormat = ".pptx, .ppt";
                break;
            default:
                acceptedFormat = "";
        }
        
        setAcceptFileName(acceptedFormat);
        setFieldValue('type', value?.label);
    };

    const handleChangeDocument = (e, formikProps) => {
        const filesArray = Array.from(e.target.files);
        formikProps.setFieldValue("files",  filesArray.length > 0 ? filesArray : []);
    };
    
    const handleClose = (formikProps) => {
        formikProps.resetForm()
        setShow(false);
    };

    const handleRemove = (id, formikProps) => {
        const { setFieldValue, values } = formikProps;
        const updatedFiles = values.files.filter((_, index) => index != id);
        setFieldValue('files', updatedFiles);
    };

    const handleSubmit = async (values, { resetForm, setSubmitting }) => {
        try {
            setSubmitting(true);

            if(operation === "add"){

                const  temp = values?.files?.map((value) => {
                    return {
                        docs_name : "",
                        data: isLibraryDocument ? value.value : value,
                        type : values?.type,
                        fromLibrary : values?.type == "Library" ? 1 : 0
                    }
                })
                let newData =  parentFormik?.values?.file_data?.length > 0  ? [...parentFormik?.values?.file_data, ...temp] : temp
                console.log("final temp", temp)
                parentFormik.setFieldValue("file_data",newData)

            }else if(operation === "edit"){
                const  temp = values?.files?.map((value) => {
                    return {
                        docs_name : "",
                        data: isLibraryDocument ? value.value : value,
                        type : values?.type,
                        fromLibrary : values?.type == "Library" ? 1 : 0
                    }
                })
                console.log("final temp", temp)
                let newData = temp[0]
                if(parentFormik?.values?.file_data?.length > 0){
                    temp[0].docs_name = parentFormik.values.file_data[fileIndex]?.docs_name
                    parentFormik.values.file_data[fileIndex] = temp[0]                   
                }else{
                    parentFormik.setFieldValue("file_data",newData)
                }
                           
            }
            
            setShow(false);
            resetForm();

        } catch (error) {
            console.error('Error in file upload:', error);
        } finally {
            setSubmitting(false);
        }
    };
    
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formikProps) => (
                <Modal 
                    className='modal zindexadd content-assembly upload-multi-format fade' 
                    size='md' 
                    show={show} 
                    centered 
                    onHide={() => handleClose(formikProps)}
                >
                    {console.log("formikProps",formikProps.values)}
                    <Modal.Header className='modal-header-custom' closeButton>
                        <h5 className="modal-title">
                            <i className={isLibraryDocument ? "fal fa-plus" : "fal fa-upload"}></i> {isLibraryDocument  ? operation=="add" ? "Add File From Library": "Edit File From Library" : "File Upload"}
                        </h5>
                    </Modal.Header>
                    <ModalBody className='p-0'>
                        <Form>
                            <div className="t p-30px">
                                <div className="row form-group-blk align-items-center mb-3">
                                    <div className={isLibraryDocument ? "col-12" : "col-md-9"}>
                                        <label htmlFor="file_type">File*</label>
                                        {isLibraryDocument ?

                                            <Select
                                                className={"form-control custom-select-box " + (formikProps?.errors.type && formikProps?.touched.type ? " is-invalid" : "")}
                                                name="item_value"
                                                id="file_type"
                                                title="Select Document"
                                                placeholder="Select Document"
                                                options={libraryFiles}
                                                isMulti={operation == "add" ? true : false}
                                                value={formikProps?.values?.files}
                                                onChange={(selectedOptions) => {
                                                    selectedOptions = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
                                                    if(selectedOptions[0]==null){
                                                        selectedOptions = []
                                                    }
                                                    // console.log("selectedOptions", selectedOptions)
                                                   return formikProps?.setFieldValue("files", selectedOptions )
                                                   
                                                }
                                                }
                                                required
                                                isInvalid={formikProps.touched.type && formikProps.errors.type}
                                            />
                                            :
                                            <Select
                                                className={"form-control custom-select-box " + (formikProps?.errors.type && formikProps?.touched.type ? " is-invalid" : "")}
                                                name="item_value"
                                                id="file_type"
                                                title="Select Document"
                                                placeholder="Select Document"
                                                options={documentTypesList}
                                                onChange={(value) => handleChangeDocumentType(value, formikProps)}
                                                isInvalid={formikProps.touched.type && formikProps.errors.type}
                                            />
                                        }
                                        {console.log("errors",formikProps?.errors)}
                                    </div>

                                    <div className={`col-md-3 ${isLibraryDocument && "d-none"}`}>
                                        <button
                                            type="button"
                                            className="btn btn-light form-control upload_btn"
                                            onClick={() => fileInputRef.current.click()}
                                            disabled={!formikProps.values.type == null}
                                        >
                                            <i className="fal fa-upload" aria-hidden="true"></i>
                                            Upload
                                        </button>

                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            name="document"
                                            id="formFile"
                                            multiple={operation == "add" ? true : false}
                                            disabled={!formikProps.values.type}
                                            className="form-control form-control-aatch-file d-none"
                                            accept={acceptFileName}
                                            onChange={(e) => handleChangeDocument(e, formikProps)}
                                        />
                                    </div>
                                </div>

                                {!isLibraryDocument && formikProps.values.files.map((file, id) => (
                                    <div className="row form-group-blk" key={id}>
                                        <div className="col-8">
                                            <div className="border border-2 border-dashed rounded py-2 px-3 bg-light mb-3">
                                                <div className="position-relative">
                                                    <div className="uploaded-files-con">
                                                        <i className={IconName(formikProps.values.type)} aria-hidden="true"></i>
                                                        <div>
                                                            <h3>{file?.name}</h3>
                                                            <h6 className='mb-0'>
                                                                {convertIntoMb(file?.size)}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <i 
                                                        className="fal fa-times close_icon" 
                                                        aria-hidden="true" 
                                                        onClick={() => handleRemove(id, formikProps)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {showErrors(formikProps)}

                                <div className="form-group form-group-save-cancel mt-4">
                                    <button
                                        className="btn btn-save btn-success"
                                        type="submit"
                                        title="Save"
                                        disabled={formikProps.isSubmitting}
                                    >
                                        <i className="fal fa-save"></i>
                                        Save
                                    </button>

                                    <button
                                        className="btn btn-close btn-danger"
                                        type="button"
                                        title="Cancel"
                                        onClick={() => handleClose(formikProps)}
                                    >
                                        <i className="fal fa-times"></i>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            )}
        </Formik>
    );
};

export default UploadMultiFormatModal;