import React from 'react'
import { GetPartnerAuditTrialCancelToken, GetPartnerAuditTrialDropdownCancelToken } from '../../../services/WebsiteListServer';
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function AuditTrails() {

  const { id } = useParams()

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  const handleRedirect = (id, type, otherData) => {
    return `/website_management/partnerDetails/${id}`
  }

  return (
    <AuditTrailsTable
      apiFunction={GetPartnerAuditTrialCancelToken}
      columnsToShow={columnsToShow}
      apiParams={id ? {parent_action_id: id} : {}}
      filterArrApi={GetPartnerAuditTrialDropdownCancelToken}
      actionId={id}
      exportFileName={"Partners"}
      resourceNameRedirect={handleRedirect}
    />
  )
}

export default AuditTrails