const initialState = {
    brand_override : null
  };
  
  const brand_override_reducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_BRAND_OVERRIDE":
        state.brand_override = action.payload
        return { brand_override: state.brand_override };
      default:
        return state;
    }
  };
  
  export default brand_override_reducer;
  