const initialState = {
    course_type : null
  };
  
  const course_type_reducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_COURSE_TYPE":
        state.course_type = action.payload
        return { course_type: state.course_type };
      default:
        return state;
    }
  };
  
  export default course_type_reducer;
  