import React, { useEffect, useMemo, useState } from "react";
import { useHistory} from "react-router";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {GetDisplayLayouts, DeleteDisplayLayout} from "../../../services/SmartSignageService";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";

const ListOfLayouts = () => {
    
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [displayLayouts, setDisplayLayouts] = useState([]);
    const [search, setSearch] = useState("");
    const [layoutTypeState, setLayoutTypeState] = useState({ arr: [], checkObj: {} });
    const [dataToRender, setDataToRender] = useState([]);
    const [reloadLayouts, setReloadLayouts] = useState(false);
    useEffect(() => {
        const getDisplayLayouts = async () => {
            setLoading(true);
            const response = await GetDisplayLayouts();
            if(response?.data?.data){
            setDisplayLayouts(response?.data?.data);
            setLoading(false);
            }
        }
        getDisplayLayouts();
    }, [])
    
    useEffect(() => {
        const getDisplayLayouts = async () => {
            setLoading(true);
            const response = await GetDisplayLayouts();
            if(response?.data?.data){
            setDisplayLayouts(response?.data?.data);
            setLoading(false);
            }
        }
        getDisplayLayouts();
    }, [reloadLayouts])

    useEffect(() => {
        if (displayLayouts.length > 0 ) {
            // Filter out items not selected by layoutTypeState filter
            const filteredItems = displayLayouts.filter((item) => {
                const layoutType = item?.layout_type?.toString(); // Ensure it's a string
                // console.log("actual", layoutType);
                // console.log("filters", layoutTypeState.arr);
                return ((layoutTypeState.arr.length === 0 || layoutTypeState.arr.includes(layoutType)) &&
                        (search.length === 0 || item.name.toLowerCase().includes(search.toLowerCase()))); // Compare as strings
            });
            // console.log(filteredItems);
            setDataToRender(filteredItems);
        } else {
            setDataToRender(displayLayouts);
        }
    }, [displayLayouts, layoutTypeState, search]);
    
    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // Delete function
                DeleteDisplayLayout(id).then((res) => {
                    Swal.fire("Deleted!", "Your layout has been deleted.", "success");
                    setReloadLayouts(!reloadLayouts);
                })
                .catch((error) => Swal.fire("Error!", error, "error"));
            }
        });
    };
    
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link to={`/campusAdministration/smartsignage/layouts/edit/${row.id}`}>
        <span title={row.name} className="as-text-blue curser feature-name">
          <span className="textLimit100"  data-toggle="modal" data-target="#programmeDateForm" >{row.name || "-"}</span>
        </span>
        </Link>
      ),
    },
    {
      name: "Layout Type",
      selector: "layout_type",
      sortField: "layout_type",
      sortable: true,
      cell: (row) => (
        <span title={row.layout_type} >{row.layout_type === null ? "-" : row.layout_type === 0 ? "Dynamic Layout" : "Video-Only Layout"}</span>
      ),
    },
    {
        name: "Linked Groups",
        selector: "linked_groups",
        sortField: "linked_groups",
        sortable: true,
        cell: (row) => {
            let groups = row.linked_groups ? row.linked_groups.split(",") : [];
            return (
                <>
                <ul>
                    {groups.length == 0 ? "-" : groups.map((group, index) => (
                        <li key={index} title={group}>
                            {group}
                        </li>
                    ))}
                </ul>
                </>
            );
        },
    },
    {
        name: "Actions",
        selector: "actions",
        width: "100px",
        cell: (row) => (
            <>
            <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignlayoutview"]} >
                <button
                    type="button"
                    className="btn btn-primary rounded-circle"
                    title="Edit"
                    onClick={() => history.push(`/campusAdministration/smartsignage/layouts/edit/${row.id}`)}
                >
                    <i className="fal fa-edit"></i> 
                </button>
                </PermissionsGate>
                <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignlayoutdelete"]} >
                <button
                    type="button"
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.id)}
                >
                    <i className="fal fa-trash"></i> 
                </button>
                </PermissionsGate>
                </div>
                </div>
            </>
        ),
    },
  ], []);

    return (         
        <>
        {loading ? (
            <SkeletonTicketList />
        ) : 
        <DataTableComponentFrontPagination
        isActionAlignRight={true}
        data={dataToRender}
        columns={columns}
        loading={loading}
        defaultSort={'name'}
        search={search}
        setSearch={setSearch}
        isExport={false}
        // exportFunction={exportData}
        // exportFileName={"Layouts"}
        filters={[
          {
            filterName: "Layout Type",
            optionArr: [
                {label: "Dynamic Layout", value: 0},
                {label: "Video-Only Layout", value: 1},
            ],
            state: layoutTypeState,
            setState: setLayoutTypeState,
            // renderLabelFunction: RenderLearningMethod,
          }
        ]}
        tableButton={[
            <Link to={`/campusAdministration/smartsignage/layouts/add`}>
            <PermissionsGate errorProps={{ disabled: true }} scopes={["casmartsignlayoutadd"]} >
            <button
            type="button"
            className="btn btn-primary"
            title="Create new"
            >
            <i className="fal fa-plus"></i>Create New
            </button>
            </PermissionsGate>
            </Link>
        ]}
      />
    }
    </>
)

}

export default ListOfLayouts;