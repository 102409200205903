import React, { useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import PermissionsGate from "../../../utils/permissionGate";
import { TrimText } from "../../common/TrimText";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import {
  GetProgrammeStudentCancelToken,
  GetProgramsDetails,
  GetProgramsDetailsCancelToken,
} from "../../../services/ProgrammeServices";
import Hover from "../../common/Hover";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import {
  RenderProgrammeStudentStatus,
  RenderQuizAttemptResultStatus,
} from "../../../utils/CommonStatusItems";
import {
  RenderCampus,
  RenderLearningMethod,
  RenderLearningMethodGradebook,
} from "../../../utils/CommonGroupingItem";
import {
  getProgrameGradeMixGradebook,
  getProgrammeGradeStudentList,
  getStudentBrand,
  getStudentProgrammeGradeDetails,
} from "../../../services/GradeSettingService";
import { pdf } from "@react-pdf/renderer";
import LetterheadPDF from "../../../LetterheadPDF";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";
import Swal from "sweetalert2";
import $, { error, parseHTML } from "jquery";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import html2canvas from "html2canvas";
import { GetBrandSettingDetail } from "../../../services/BrandServices";
import LetterHeadPDF3 from "../../../LetterHeadPDF3";

function Students({ programmeDetails }) {
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [bulkPdfDownloading, setBulkPdfDownloading] = useState(false);
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [campus, setCampus] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({
    arr: [],
    checkObj: {},
  });
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(true);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [filterData, setFilterData] = useState({
    status: [],
    campus: [],
    learning: [],
  });
  const [pdfData, setPDFData] = useState([]);
  const [pdfsData, setPDFsData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowFinal, setIsShowFinal] = useState(false);
  const [isExcludeGrade, setIsExcludeGrade] = useState(false);
  const [studentID, setStudentID] = useState("");
  const [bulkDownload, setBulkDownload] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [programDetails, setProgramDetails] = useState({});
  const [someExtra,setSomeExtra] = useState([])
  const [isBrandOverride, setIsBrandOverride] = useState(false)
  const [brand_override, setBrandOverride] = useState("")
  const [st_disclaimer, setSt_disclaimer] = useState("")
  

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({
      scopes: ["psview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getStudList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetProgrammeStudentCancelToken(id, source.token);
        if (res.status === 200) {
          let allStdIds = [];
        res.data.programs.map((std) => {
          allStdIds.push(std?.id);
        });
        setAllStudents(allStdIds);
        setIsBrandOverride(res.data)
        setUserData(res.data.programs);
        setloading(false);
        setFilterData({
          ...res.data,
          status: res.data.studentStatusFilter,
          campus: res.data.campusStatusFilter,
          learning: res.data.deliveryMethodFilter,
        });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
  
    getStudList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const handlepdfData = (subTab) => {
    setPdfDownloading(true);
    const swalPro = Swal.mixin({
      title: "Downloading PDF",
      text: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    swalPro.fire();
    let studentDetails = userData?.find((student) => student?.id == subTab);
    let brand = {};
    const data = {
      id: [JSON.stringify(subTab)],
    };
    if(brand_override && isBrandOverride){

      GetBrandSettingDetail(brand_override)
      .then((res) => {
        brand = res.data.brand_setting
      }).catch((err) => console.log(err));
    }else{

      getStudentBrand(data)
        .then((res) => {
          brand = res.data;
      })
        .catch((err) => console.log(err));
    }
      if(programmeDetails.grading_standard == 2){
        getProgrameGradeMixGradebook({
          id,
          search: "",
          limit: "",
          page: "",
          exportStatus: "true",
          forPdf: 'false',
          student_id: subTab
        })
        .then((res) => {
          let details = res?.data?.courseWithAssessment;
          const pdfDetails = details?.map((course) => ({
            course_name: course?.name,
            weight: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.weight,
            grade: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.grading_type == 0
              ? Math.round(course?.scoreRecieved) + "%"
              : course.gradeText
              ? course.gradeText
              : "N/A",
            result: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.pass_status
              ? course?.pass_status
              : "N/A",
            isExclude: course?.isExclude,
            assessments: course?.assessments?.map((assessment) => ({
              name: assessment?.assessment_name,
              weight:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment.ass_weighting,
              result:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment.pass_status == "N/A"
                  ? "N/A"
                  : assessment.pass_status
                  ? assessment.pass_status
                  : "-",
              grade:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment?.grading_type == 3
                  ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                  : assessment?.gradeText
                  ? assessment?.gradeText
                  : "N/A",
              isExclude: assessment?.isExclude,
              id: assessment?.assessment_id,
            })),
            final_status: res?.data?.program?.programCalculate?.pass_status,
            final_grade:
              res?.data?.program?.programCalculate?.grading_type == 0
                ? Math.round(
                    res?.data?.program?.programCalculate?.scoreRecieved
                  ) + "%"
                : res?.data?.program?.programCalculate?.grading_type == 3
                ? `${res?.data?.program?.programCalculate?.programeAssMark}/${res?.data?.program?.programCalculate?.programeAssTotalMark}`
                : res?.data?.program?.programCalculate?.gradeText,
          }));
          // pdfDetails.unshift({isHeading: true})
          if (pdfDetails) {
            let modifiedSoR = pdfDetails
              ?.map((item) => ({
                ...item,
                assessments: !isChecked
                  ? []
                  : item?.assessments.filter(
                      (assessment) => !isExcludeGrade || !assessment.isExclude
                    ),
                final_grade: !isShowFinal ? "" : item?.final_grade,
                final_status: !isShowFinal ? "" : item?.final_status,
              }))
              .filter((course) => !isExcludeGrade || !course.isExclude);
              const uniqueId = 'st_disclaimer_' + Math.random() * 100
              // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
              let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
              ${res.data.program.st_disclaimer}
              </div>`
              kjs = parseHTML(kjs)
              // document.body.appendChild(kjs[0])
              document.querySelector("body").appendChild(kjs[0])
              let divHeight = document.getElementById(uniqueId).clientHeight
              divHeight = divHeight < 670 ? divHeight : 670
            
              let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
              let divWidth = document.getElementById(uniqueId).clientWidth
              
              divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
              html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
              var dataURL = canvas.toDataURL();
              return dataURL
            }).then(noteImage => {
              pdf(
                <LetterHeadPDF3
                  detailData={modifiedSoR}
                  divMinHeight = {divMinHeight}
              divHeight={divHeight+300}
              divWidth={divWidth > 565 ? 565 : divWidth}
              divOrgHeight={divHeight}
              divTopPosition = {1197 - divHeight - 130}
                  noteImage={res.data.program.st_disclaimer ? noteImage : null}
                  studentAndCourseDetails={{
                    program_name: studentDetails?.course,
                    Student_name:
                      studentDetails.first_name + " " + studentDetails.last_name,
                    ID_number: studentDetails?.number,
                    Student_number: studentDetails?.student_crm_id
                      ? studentDetails?.student_crm_id
                      : studentDetails?.student_num,
                    start: programmeDetails?.start
                      ? programmeDetails.start?.toString().length == 10
                        ? moment
                            .unix(programmeDetails?.start)
                            .format("DD MMMM YYYY")
                        : moment(programmeDetails?.start).format("DD MMMM YYYY")
                      : "",
                    end: programmeDetails?.end
                      ? programmeDetails.end?.toString().length == 10
                        ? moment.unix(programmeDetails?.end).format("DD MMMM YYYY")
                        : moment(programmeDetails?.end).format("DD MMMM YYYY")
                      : "",
                    intake:
                      programmeDetails?.number && programmeDetails?.year
                        ? `${programmeDetails?.number}-${programmeDetails?.year}`
                        : "",
                    learning_method: programmeDetails?.type
                      ? RenderLearningMethodGradebook(programmeDetails?.type).text
                      : "",
                  }}
                  brandData={brand_override && isBrandOverride ? brand : brand.getBrandDetail}
                />
              )
                .toBlob()
                .then((response) => {
                  // const blobURL = "";
                  const blobURL = window.URL.createObjectURL(response);
                  const a = document.createElement("a");
                  a.href = blobURL;
                  a.download = `${
                    studentDetails?.student_crm_id
                      ? studentDetails?.student_crm_id
                      : studentDetails?.student_num
                  }_${studentDetails.first_name + " " + studentDetails.last_name}_${
                    studentDetails?.course
                  }_SOR_${brandVisuals?.brand_name || ""}_${moment(new Date()).format(
                    "DD MM YY"
                  )}.pdf`; // Set the desired filename for the download
                  a.click();
  
                  URL.revokeObjectURL(blobURL);
                  setStudentID("");
                  setIsChecked(false);
                  setIsShowFinal(false);
                  setIsExcludeGrade(false);
                  swalPro.close();
                  $("#availableprogrammeinRereg").modal("hide");
                  setPdfDownloading(false);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Download Successfully",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  setPdfDownloading(false);
                  setStudentID("");
                  setIsChecked(false);
                  setIsShowFinal(false);
                  setIsExcludeGrade(false);
                  swalPro.close();
                  $("#availableprogrammeinRereg").modal("hide");
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err,
                  });
                });
            })
            
          }
        })
        .catch((err) => console.error("error :", err));
      } else {
        getStudentProgrammeGradeDetails(id, subTab)
        .then((res) => {
          let details = res?.data?.courseAndAssessment;
          const pdfDetails = details?.map((course) => ({
            course_name: course?.course_name,
            weight: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.weight,
            grade: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.grading_type == 0
              ? Math.round(course?.scoreRecieved) + "%"
              : course.gradeText
              ? course.gradeText
              : "N/A",
            result: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.pass_status
              ? course?.pass_status
              : "N/A",
            isExclude: course?.isExclude,
            assessments: course?.assessments?.map((assessment) => ({
              name: assessment?.name,
              weight:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment.ass_weighting,
              result:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment.pass_status == "N/A"
                  ? "N/A"
                  : assessment.pass_status
                  ? assessment.pass_status
                  : "-",
              grade:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment?.grading_type == 3
                  ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                  : assessment?.gradeText
                  ? assessment?.gradeText
                  : "N/A",
              isExclude: assessment?.isExclude,
              id: assessment?.assessment_id,
              isAssignedSickStudent : assessment?.isAssignedSickStudent,
              isSickForThisStudent : assessment?.isSickForThisStudent,
              isSupplementaryForThisStudent : assessment?.isSupplementaryForThisStudent,
            })),
            final_status: res?.data?.program?.programCalculate?.pass_status,
            final_grade:
              res?.data?.program?.programCalculate?.grading_type == 0
                ? Math.round(
                    res?.data?.program?.programCalculate?.scoreRecieved
                  ) + "%"
                : res?.data?.program?.programCalculate?.grading_type == 3
                ? `${res?.data?.program?.programCalculate?.programeAssMark}/${res?.data?.program?.programCalculate?.programeAssTotalMark}`
                : res?.data?.program?.programCalculate?.gradeText,
          }));
          // pdfDetails.unshift({isHeading: true})
          if (pdfDetails) {
            let modifiedSoR = pdfDetails
              ?.map((item) => ({
                ...item,
                assessments: !isChecked
                  ? []
                  : item?.assessments.filter(
                      (assessment) => !isExcludeGrade || !assessment.isExclude
                    ),
                final_grade: !isShowFinal ? "" : item?.final_grade,
                final_status: !isShowFinal ? "" : item?.final_status,
              }))
              .filter((course) => !isExcludeGrade || !course.isExclude);
              const uniqueId = 'st_disclaimer_' + Math.random() * 100
              // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
              let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
              ${res.data.program.st_disclaimer}
              </div>`
              kjs = parseHTML(kjs)
              // document.body.appendChild(kjs[0])
              
              document.querySelector("body").appendChild(kjs[0])
              let divHeight = document.getElementById(uniqueId).clientHeight
              divHeight = divHeight < 670 ? divHeight : 670
            
              let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
              let divWidth = document.getElementById(uniqueId).clientWidth
              
              divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
              
              html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
              
              var dataURL = canvas.toDataURL();
              return dataURL
            }).then(noteImage => {
              // divHeight = 300
            pdf(
              <LetterHeadPDF3
              programDetails={res.data.program}
              noteImage={res.data.program.st_disclaimer ? noteImage : null}
              divMinHeight = {divMinHeight}
              divHeight={divHeight+300}
              divWidth={divWidth > 565 ? 565 : divWidth}
              divOrgHeight={divHeight}
              divTopPosition = {1197 - divHeight - 130}
                detailData={modifiedSoR}
                studentAndCourseDetails={{
                  program_name: studentDetails?.course,
                  Student_name:
                    studentDetails.first_name + " " + studentDetails.last_name,
                  ID_number: studentDetails?.number,
                  Student_number: studentDetails?.student_crm_id
                    ? studentDetails?.student_crm_id
                    : studentDetails?.student_num,
                  start: programmeDetails?.start
                    ? programmeDetails.start?.toString().length == 10
                      ? moment
                          .unix(programmeDetails?.start)
                          .format("DD MMMM YYYY")
                      : moment(programmeDetails?.start).format("DD MMMM YYYY")
                    : "",
                  end: programmeDetails?.end
                    ? programmeDetails.end?.toString().length == 10
                      ? moment.unix(programmeDetails?.end).format("DD MMMM YYYY")
                      : moment(programmeDetails?.end).format("DD MMMM YYYY")
                    : "",
                  intake:
                    programmeDetails?.number && programmeDetails?.year
                      ? `${programmeDetails?.number}-${programmeDetails?.year}`
                      : "",
                  learning_method: programmeDetails?.type
                    ? RenderLearningMethodGradebook(programmeDetails?.type).text
                    : "",
                }}
                brandData={brand_override != null && brand_override != 0 && brand_override != undefined && isBrandOverride ? brand : brand?.getBrandDetail}
              />
            )
              .toBlob()
              .then((response) => {
                const blobURL = window.URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = blobURL;
                a.download = `${
                  studentDetails?.student_crm_id
                    ? studentDetails?.student_crm_id
                    : studentDetails?.student_num
                }_${studentDetails.first_name + " " + studentDetails.last_name}_${
                  studentDetails?.course
                }_SOR_${brandVisuals?.brand_name || ""}_${moment(new Date()).format(
                  "DD MM YY"
                )}.pdf`; // Set the desired filename for the download
                a.click();

                URL.revokeObjectURL(blobURL);
                setStudentID("");
                setIsChecked(false);
                setIsShowFinal(false);
                setIsExcludeGrade(false);
                swalPro.close();
                $("#availableprogrammeinRereg").modal("hide");
                setPdfDownloading(false);
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Download Successfully",
                });
                document.getElementById(uniqueId).remove();
              })
              .catch((err) => {
                setPdfDownloading(false);
                setStudentID("");
                setIsChecked(false);
                setIsShowFinal(false);
                setIsExcludeGrade(false);
                swalPro.close();
                $("#availableprogrammeinRereg").modal("hide");
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: err,
                });
              });
            })
          }
        })
        .catch((err) => console.error("error :", err));
      }
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleShowFinalToggle = () => {
    setIsShowFinal(!isShowFinal);
  };

  const handleExcludeFinal = () => {
    setIsExcludeGrade(!isExcludeGrade);
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setCampus({ arr: [], checkObj: {} });
    setLearningMethod({ arr: [], checkObj: {} });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes = item.student_crm_id
          ?.toString()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempNumber = allData.filter((item) => {
        let includes = item.student_num
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase().toString());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempfirst_name = allData.filter((item) => {
        let includes =
          item.first_name &&
          (item.first_name + " " + item.last_name)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let templast_name = allData.filter((item) => {
        let includes =
          item.last_name &&
          item.last_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes =
          item.email && item.email.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempCampus = allData.filter((item) => {
        let includes =
          item.campus &&
          item.campus.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLearningMethod = allData.filter((item) => {
        let includes =
          item.type && item.type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramme = allData.filter((item) => {
        return item.Programme;
      });
      let tempStatus = allData.filter((item) => {
        let status;
        if (item.status) {
          status = "active";
        } else {
          status = "inactive";
        }
        let includes =
          status.toLowerCase().includes(search.toLowerCase());

        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempId,
        ...tempfirst_name,
        ...templast_name,
        ...tempEmail,
        ...tempStatus,
        ...tempNumber,
        ...tempCampus,
        ...tempLearningMethod,
        ...tempProgramme,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (
            post.toString() === item.status.toString()
              ? item.status.toString()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (campus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = campus.arr.find(function (post, index) {
          if (
            post.toString() === item.campus.toString()
              ? item.campus.toString()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (learningMethod.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = learningMethod.arr.find(function (post, index) {
          if (
            post.toString() === item.type.toString() ? item.type.toString() : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (
      status.arr.length ||
      learningMethod.arr.length ||
      campus.arr.length ||
      search.length
    ) {
      return updatedData;
    } else {
      return userData;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Student",
      selector: "first_name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span
              className={
                "assigned-title-blk  name-icon cat-dark-red " +
                ColorRender(row.role_name)
              }
            >
              {row.photo ? (
                <img
                  src={`${IMAGE_URL}/${row.photo
                    .replace("public/", "")
                    .replaceAll("/home/myaie/public_html/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([row.first_name, row.last_name])
              )}
              <span
                className={`profile-box-2-status ${
                  row.activity_status
                    ? row.activity_status.toLowerCase() == "online"
                      ? "Online"
                      : row.activity_status.toLowerCase() == "away"
                      ? "Away"
                      : "Offline"
                    : "Offline"
                }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.first_name}
                lastName={row.last_name}
                photo={row.photo}
                email={row.email}
                mobile={row.mobile}
                status={row.status}
                activity_status={row.activity_status}
                showNumber={true}
                number={row.student_crm_id}
                right={true}
              />
            </span>
            <PermissionsGate
              scopes={["sview"]}
              RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}
            >
              <Link
                className="as-text-blue curser "
                to={`/studentAdministration/students/open/${row.id}/general`}
              >
                <span
                  className="textLimit76"
                  title={row.first_name + " " + row.last_name}
                >
                  {row.first_name + " " + row.last_name}
                </span>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      cell: (row) =>
        row.email ? (
          <span title={row.email}>{TrimText(row.email, 20)}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "ID Number",
      selector: "number",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2" title={row.number}>
            {row.number && row.number !== null ? TrimText(row.number, 20) : "-"}
          </span>
        </div>
      ),
    },
    {
      name: "Student Number",
      selector: "student_crm_id",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2" title={row.student_crm_id}>
            {row.student_crm_id ? TrimText(row.student_crm_id, 20) : "-"}
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) =>
        row.status ? RenderProgrammeStudentStatus(row.status).html : "-",
    },
    {
      name: "Campus",
      selector: "campus",
      sortField: "campus",
      sortable: true,
      cell: (row) =>
        row.campus ? (
          <span className="feature-name">
            <span className="textLimit100">
              {RenderCampus(row.campus).html}
            </span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Learning Method",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) =>
        row.type ? (
          <span className="feature-name">
            <span className="textLimit100">
              {RenderLearningMethod(row.type).html}
            </span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
      cell: (row) =>
        row.programCalculate?.pass_status == "N/A" ? (
          "N/A"
        ) : row.programCalculate?.grading_type == 0 
        ? Math.round(row.programCalculate?.scoreRecieved) + "%" 
        : row.programCalculate?.grading_type == 3 && row.programCalculate?.programeAssMark && row.programCalculate?.programeAssTotalMark ? (
          <span
            title={row.programCalculate?.programeAssMark}
          >
            {`${row.programCalculate?.programeAssMark}/${row.programCalculate?.programeAssTotalMark}`}
          </span>
        ) : (
          row.programCalculate?.gradeText
        ),
    },
    {
      name: "Result",
      selector: "status",
      sortable: true,
      cell: (row) =>
        row.programCalculate?.pass_status == "N/A"
          ? "N/A"
          : row.status
          ? RenderQuizAttemptResultStatus(row.programCalculate?.pass_status)
              .html
          : "-",
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["sview"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/studentAdministration/students/open/${row.id}/general`}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                title="More"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link
                  className="btn btn-primary rounded-circle"
                  title="Student Grade"
                  to={`/courseAdministration/Programmes/programme/open/${id}/grade/table`}
                >
                  <i className="fal fa-eye"></i>
                </Link>
                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#availableprogrammeinRereg"
                  onClick={() => {
                    setIsChecked(programDetails.show_assessments)
                    setIsShowFinal(programDetails.show_final_results)
                    setIsExcludeGrade(programDetails.show_empty_grades)
                    setStudentID(row?.id)
                  }}
                  title="Download SoR PDF"
                >
                  {pdfDownloading ? (
                    <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                  ) : (
                    <i className="fal fa-download download-SoR-icon"></i>
                  )}
                </button>
                <Link
                  className="btn btn-primary rounded-circle"
                  title="Student Grade"
                  to={`/courseAdministration/Programmes/programme/open/${id}/gradebook/table/${row?.id}`}
                >
                  <i className="fal fa-chart-bar"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Name",
      "Email",
      "ID Number",
      "Student Number",
      "Status",
      "Campus",
      "Learning Method",
      "Grade",
      "Result",
      "Programme",
    ];
    let file = data?.map((row) => row.course)[0];
    data = data?.map((row) => ({
      ...row,
      // ID: row.id? row.id : "-",
      Name: row?.first_name + " " + row.last_name,
      Email: row.email ? row.email : "-",
      Status: row?.status ? RenderProgrammeStudentStatus(row.status).text : "-",
      "ID Number": row.number && row.number !== null ? row.number : "-",
      "Student Number": row.student_crm_id ? row.student_crm_id : "-",
      Campus: row.campus
        ? row.campus.charAt(0).toUpperCase() + row.campus.slice(1).toLowerCase()
        : "-",
      "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
      Grade:
        row.programCalculate?.pass_status == "N/A"
          ? "N/A"
          : row.programCalculate?.grading_type == 0 ? Math.round(row.programCalculate?.scoreRecieved) + "%" : row.programCalculate?.grading_type == 3 &&
            row.programCalculate?.programeAssMark &&
            row.programCalculate?.programeAssTotalMark
          ? `${row.programCalculate?.programeAssMark}/${row.programCalculate?.programeAssTotalMark}`
          : row.programCalculate?.gradeText,
      Result:
        row.programCalculate?.pass_status == "N/A"
          ? "N/A"
          : row.status
          ? RenderQuizAttemptResultStatus(row.programCalculate?.pass_status)
              .text
          : "-",
      Programme: row.course ? row.course : "-",
    }));
    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = file;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, file + ".xlsx");

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.Name,
          row.Email,
          row["ID Number"],
          row["Student Number"],
          row.Status,
          row["Campus"],
          row["Learning Method"],
          row.Grade,
          row.Result,
          row["course"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(file + ".pdf");

      return false;
    }
  };

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        setBulkPdfDownloading(true);
        const data = dataToRender();
        var filteredIds = [];
        const filterArray = data?.map((std) => {
          filteredIds.push(std?.id);
        });

        let response = await GetProgramsDetailsCancelToken(id, source.token)
        if (response.status === 200) {
          if (response?.data?.programs.grading_standard == 2) {
            getProgrameGradeMixGradebook({
              id,
              search: "",
              limit: "",
              page: "",
              exportStatus: "true",
              forPdf: 'false'
            })
              .then(async (res) => {
                setSomeExtra(res.data?.getQulification)
                setSt_disclaimer(res.data?.getQulification?.st_disclaimer)
                setBrandOverride(res?.data?.getQulification?.override_brand)
                setIsBrandOverride(res?.data?.getQulification?.override_sor_brand != 0)
                const details = res?.data?.data_list;
                let programmeData = response?.data?.programs;
                setProgramDetails(programmeData)
                const courseAlongwithAssessment = details?.map((student) => ({
                  ...student,
                  courseWithAssessment: student.gradeMix?.map(
                    (course) => ({
                      id: student?.id,
                      course_name: course?.name,
                      weight: course?.grant_exemption
                        ? "Exempted"
                        : !course?.assessmentArr.length
                        ? "N/A"
                        : course?.weight,
                      grade: course?.grant_exemption
                        ? "Exempted"
                        : !course?.assessmentArr.length
                        ? "N/A"
                        : course?.getGradeMixCulc?.grading_type == 0
                        ? Math.round(course?.getGradeMixCulc?.scoreRecieved) + "%"
                        : course?.getGradeMixCulc?.grading_type == 3
                        ? `${course?.getGradeMixCulc?.allAss_ass_mark}/${course?.getGradeMixCulc?.allAss_total_mark}`
                        : course.getGradeMixCulc?.gradeText
                        ? course.getGradeMixCulc?.gradeText
                        : "N/A",
                      result: course?.grant_exemption
                        ? "Exempted"
                        : !course?.assessmentArr.length
                        ? "N/A"
                        : course?.getGradeMixCulc?.pass_status
                        ? course?.getGradeMixCulc?.pass_status
                        : "N/A",
                      isExclude: course?.isExclude,
                      assessments: course?.assessmentArr?.map((assessment) => ({
                        name: assessment?.assessment_name,
                        weight:
                          assessment?.grant_exemption || course?.grant_exemption
                            ? "Exempted"
                            : assessment.ass_weighting,
                        result:
                          assessment?.grant_exemption || course?.grant_exemption
                            ? "Exempted"
                            : assessment?.submited == "0" ||
                              moment.unix(assessment?.close_date_time) >
                                moment.unix(new Date())
                            ? "N/A"
                            : assessment.pass_status == "N/A"
                            ? "N/A"
                            : assessment.pass_status
                            ? assessment.pass_status
                            : "-",
                        grade:
                          assessment?.grant_exemption || course?.grant_exemption
                            ? "Exempted"
                            : assessment?.submited == "0" ||
                              moment.unix(assessment?.close_date_time) >
                                moment.unix(new Date())
                            ? "N/A"
                            : assessment?.grading_type == 3
                            ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                            : assessment?.gradeText
                            ? assessment?.gradeText
                            : "N/A",
                        isExclude: assessment?.isExclude,
                        isAssignedSickStudent : assessment?.isAssignedSickStudent,
                        isSickForThisStudent : assessment?.isSickForThisStudent,
                        isSupplementaryForThisStudent : assessment?.isSupplementaryForThisStudent,
                      })),
                      final_status: student?.getProgrammeGradeMixCulc?.pass_status,
                      final_grade:
                        student?.getProgrammeGradeMixCulc?.grading_type == 0
                          ? Math.round(student?.getProgrammeGradeMixCulc?.scoreRecieved) + "%"
                          : student?.getProgrammeGradeMixCulc?.grading_type == 3
                          ? `${student?.getProgrammeGradeMixCulc?.programeAssMark}/${student?.getProgrammeGradeMixCulc?.programeAssTotalMark}`
                          : student?.getProgrammeGradeMixCulc?.gradeText,
                    })
                  ),
                  studentDetails: {
                    program_name: student.course,
                    Student_name: student.first_name + " " + student.last_name,
                    ID_number: student.number,
                    Student_number: student.student_crm_id
                      ? student.student_crm_id
                      : student.student_num,
                    start: programmeData?.start
                      ? programmeData.start?.toString().length == 10
                        ? moment.unix(programmeData?.start).format("DD MMMM YYYY")
                        : moment(programmeData?.start).format("DD MMMM YYYY")
                      : "",
                    end: programmeData?.end
                      ? programmeData.end?.toString().length == 10
                        ? moment.unix(programmeData?.end).format("DD MMMM YYYY")
                        : moment(programmeData?.end).format("DD MMMM YYYY")
                      : "",
                    intake:
                      programmeData?.number && programmeData?.year
                        ? `${programmeData?.number}-${programmeData?.year}`
                        : "",
                    learning_method: programmeData?.type
                      ? RenderLearningMethodGradebook(programmeData?.type).text
                      : "",
                  },
                }));
                setPDFsData(courseAlongwithAssessment);
                setBulkPdfDownloading(false);
              })
              .catch((err) => console.log(err));
          } else {
            getProgrammeGradeStudentList({
              id,
              search: "",
              limit: "",
              page: "",
              exportStatus: "true",
              filteredIds: filteredIds,
              forPdf: 'true'
            })
              .then(async (res) => {
                const details = res?.data?.data_list;
                let programmeData = response?.data?.programs;
                setBrandOverride(res?.data?.getQulification?.override_brand)
                setSt_disclaimer(res.data?.getQulification?.st_disclaimer)
                setIsBrandOverride(res?.data?.getQulification?.override_sor_brand != 0)
                setProgramDetails(programmeData)
                const courseAlongwithAssessment = details?.map((student) => ({
                  ...student,
                  courseWithAssessment: student.getAssignedCoursesToStudent?.map(
                    (course) => ({
                      id: student?.id,
                      course_name: course?.course_name,
                      weight: course?.grant_exemption
                        ? "Exempted"
                        : !course?.assessemts.length
                        ? "N/A"
                        : course?.weight,
                      grade: course?.grant_exemption
                        ? "Exempted"
                        : !course?.assessemts.length
                        ? "N/A"
                        : course?.gradeCalculation?.grading_type == 0
                        ? Math.round(course?.gradeCalculation?.scoreRecieved) + "%"
                        : course?.gradeCalculation?.grading_type == 3
                        ? `${course?.gradeCalculation?.allAss_ass_mark}/${course?.gradeCalculation?.allAss_total_mark}`
                        : course.gradeCalculation?.gradeText
                        ? course.gradeCalculation?.gradeText
                        : "N/A",
                      result: course?.grant_exemption
                        ? "Exempted"
                        : !course?.assessemts.length
                        ? "N/A"
                        : course?.gradeCalculation?.pass_status
                        ? course?.gradeCalculation?.pass_status
                        : "N/A",
                      isExclude: course?.isExclude,
                      assessments: course?.assessemts?.map((assessment) => ({
                        name: assessment?.name,
                        weight:
                          assessment?.grant_exemption || course?.grant_exemption
                            ? "Exempted"
                            : assessment.ass_weighting,
                        result:
                          assessment?.grant_exemption || course?.grant_exemption
                            ? "Exempted"
                            : assessment?.submited == "0" ||
                              moment.unix(assessment?.close_date_time) >
                                moment.unix(new Date())
                            ? "N/A"
                            : assessment.pass_status == "N/A"
                            ? "N/A"
                            : assessment.pass_status
                            ? assessment.pass_status
                            : "-",
                        grade:
                          assessment?.grant_exemption || course?.grant_exemption
                            ? "Exempted"
                            : assessment?.submited == "0" ||
                              moment.unix(assessment?.close_date_time) >
                                moment.unix(new Date())
                            ? "N/A"
                            : assessment?.grading_type == 3
                            ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                            : assessment?.gradeText
                            ? assessment?.gradeText
                            : "N/A",
                        isExclude: assessment?.isExclude,
                        isAssignedSickStudent : assessment?.isAssignedSickStudent,
                        isSickForThisStudent : assessment?.isSickForThisStudent,
                        isSupplementaryForThisStudent : assessment?.isSupplementaryForThisStudent,
                        
                      })),
                      final_status: student?.programCalculate?.pass_status,
                      final_grade:
                        student?.programCalculate?.grading_type == 0
                          ? Math.round(student?.programCalculate?.scoreRecieved) + "%"
                          : student?.programCalculate?.grading_type == 3
                          ? `${student?.programCalculate?.programeAssMark}/${student?.programCalculate?.programeAssTotalMark}`
                          : student?.programCalculate?.gradeText,
                    })
                  ),
                  studentDetails: {
                    program_name: student.course,
                    Student_name: student.first_name + " " + student.last_name,
                    ID_number: student.number,
                    Student_number: student.student_crm_id
                      ? student.student_crm_id
                      : student.student_num,
                    start: programmeData?.start
                      ? programmeData.start?.toString().length == 10
                        ? moment.unix(programmeData?.start).format("DD MMMM YYYY")
                        : moment(programmeData?.start).format("DD MMMM YYYY")
                      : "",
                    end: programmeData?.end
                      ? programmeData.end?.toString().length == 10
                        ? moment.unix(programmeData?.end).format("DD MMMM YYYY")
                        : moment(programmeData?.end).format("DD MMMM YYYY")
                      : "",
                    intake:
                      programmeData?.number && programmeData?.year
                        ? `${programmeData?.number}-${programmeData?.year}`
                        : "",
                    learning_method: programmeData?.type
                      ? RenderLearningMethodGradebook(programmeData?.type).text
                      : "",
                  },
                }));
                setPDFsData(courseAlongwithAssessment);
                setBulkPdfDownloading(false);
              })
              .catch((err) => console.log(err));
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [campus, status, learningMethod]);

  const generateSinglePDF = async (data, studentDetail, getAssignedCoursesToStudent,i) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",data);
    let brand = [];
    const details = {
      id: allStudents,
    };
    let brandDetail = await getStudentBrand(details);
    if(brand_override && isBrandOverride){

      GetBrandSettingDetail(brand_override)
      .then((res) => {
        brand = res.data.brand_setting
      }).catch((err) => console.log(err));
      brand = brandDetail.data.getBrandDetail;
      let matchingBrand = brand.filter((b) => {
        return b.student_id == data[0]?.id;
      });
      let modifiedSoR = data
        ?.map((item) => ({
          ...item,
          assessments: !isChecked
            ? []
            : item?.assessments.filter(
                (assessment) => !isExcludeGrade || !assessment.isExclude
              ),
          final_grade: !isShowFinal ? "" : item?.final_grade,
          final_status: !isShowFinal ? "" : item?.final_status,
        }))
        .filter((course) => !isExcludeGrade || !course.isExclude);
      let st_disclaimerVar = getAssignedCoursesToStudent ? getAssignedCoursesToStudent[0]?.st_disclaimer : someExtra.st_disclaimer
      const uniqueId = 'st_disclaimer_' + Math.random() * 100
      let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
                ${st_disclaimer}
                </div>`
      kjs = parseHTML(kjs)
      document.querySelector("body").appendChild(kjs[0])
      let divHeight = document.getElementById(uniqueId).clientHeight
      divHeight = divHeight < 670 ? divHeight : 670
  
      let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length / 200) * 18
      let divWidth = document.getElementById(uniqueId).clientWidth
  
      divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
      const noteImage = await html2canvas(document.getElementById(uniqueId)).then(function (canvas) {
        var dataURL = canvas.toDataURL();
        return dataURL
    })
    // .then(async noteImage => {
      const blob = await pdf(
        <LetterHeadPDF3
          detailData={modifiedSoR}
          studentAndCourseDetails={studentDetail}
          brandData={brand_override ?  brand : matchingBrand}
          noteImage={st_disclaimer ? noteImage : null}
          divMinHeight = {divMinHeight}
          divHeight={divHeight+300}
          divWidth={divWidth > 565 ? 565 : divWidth}
          divOrgHeight={divHeight}
          divTopPosition = {1197 - divHeight - 130}
        />
      ).toBlob();
      document.getElementById(uniqueId).remove();
  
      return blob;
    }else{
      getStudentBrand(details)
      .then((res) => {
        brand = res.data.getBrandDetail
      }).catch((err) => console.log(err));
      brand = brandDetail.data.getBrandDetail;
      let matchingBrand = brand.filter((b) => {
        return b.student_id == data[0]?.id;
      });
      let modifiedSoR = data
        ?.map((item) => ({
          ...item,
          assessments: !isChecked
            ? []
            : item?.assessments.filter(
                (assessment) => !isExcludeGrade || !assessment.isExclude
              ),
          final_grade: !isShowFinal ? "" : item?.final_grade,
          final_status: !isShowFinal ? "" : item?.final_status,
        }))
        .filter((course) => !isExcludeGrade || !course.isExclude);
      let st_disclaimerVar = getAssignedCoursesToStudent ? getAssignedCoursesToStudent[0]?.st_disclaimer : someExtra.st_disclaimer
      const uniqueId = 'st_disclaimer_' + Math.random() * 100
      let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
                ${st_disclaimer}
                </div>`
      kjs = parseHTML(kjs)
      document.querySelector("body").appendChild(kjs[0])
      let divHeight = document.getElementById(uniqueId).clientHeight
      divHeight = divHeight < 670 ? divHeight : 670
  
      let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length / 200) * 18
      let divWidth = document.getElementById(uniqueId).clientWidth
  
      divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
      const noteImage = await html2canvas(document.getElementById(uniqueId)).then(function (canvas) {
        var dataURL = canvas.toDataURL();
        return dataURL
    })
    // .then(async noteImage => {
      const blob = await pdf(
        <LetterHeadPDF3
          detailData={modifiedSoR}
          studentAndCourseDetails={studentDetail}
          brandData={brand_override ?  brand[i] : matchingBrand}
          noteImage={st_disclaimer ? noteImage : null}
          divMinHeight = {divMinHeight}
          divHeight={divHeight+300}
          divWidth={divWidth > 565 ? 565 : divWidth}
          divOrgHeight={divHeight}
          divTopPosition = {1197 - divHeight - 130}
        />
      ).toBlob();
      document.getElementById(uniqueId).remove();
  
      return blob;
    }
  // })
    // const blob = await pdf(
    //   <LetterheadPDF
    //     detailData={modifiedSoR}
    //     studentAndCourseDetails={studentDetail}
    //     brandData={matchingBrand}
    //   />
    // ).toBlob();

    // return blob;
  };

  const generatePDFs = async () => {
    const zip = new JSZip();
    for (let i = 0; i < pdfsData.length; i++) {
      const pdfBlob = await generateSinglePDF(
        pdfsData[i].courseWithAssessment,
        pdfsData[i]?.studentDetails,
        pdfsData[i]?.getAssignedCoursesToStudent,
        i
      );
      zip
        // .folder(`${brandVisuals?.brand_name}_${pdfsData[i]?.studentDetails?.program_name}_SOR_${moment(new Date()).format("DD MM YY")}`)
        .file(
          `${pdfsData[i]?.studentDetails?.Student_number}_${
            pdfsData[i]?.studentDetails?.Student_name
          }_${pdfsData[i]?.studentDetails?.program_name}_SOR_${
            brandVisuals?.brand_name || ""
          }_${moment(new Date()).format("DD MM YY")}.pdf`,
          pdfBlob
        );
    }
    return zip.generateAsync({ type: "blob" });
  };

  const handleDownload = async () => {
    // Display SweetAlert modal with progress bar
    const swalProgress = Swal.mixin({
      title: "Creating ZIP",
      text: "Please wait while we create the ZIP file...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    swalProgress.fire();

    try {
      const zipBlob = await generatePDFs();
      const fileName = `${brandVisuals?.brand_name || ""}_${
        pdfsData[0]?.studentDetails?.program_name
      }_SOR_${moment(new Date()).format("DD MM YY")}${
        status.arr?.length ? `_${status.arr}` : ""
      }${campus.arr?.length ? `_${campus.arr}` : ""}${
        learningMethod.arr?.length ? `_${learningMethod.arr}` : ""
      }.zip`;

      // Hide the progress bar and show success icon
      swalProgress.close();
      setBulkDownload(false);
      setStudentID("");
      setIsChecked(false);
      setIsShowFinal(false);
      setIsExcludeGrade(false);
      $("#availableprogrammeinRereg").modal("hide");
      Swal.fire({
        title: "ZIP Created",
        text: "ZIP file has been created successfully!",
        icon: "success",
        timer: 2000, // Automatically close after 2 seconds
        showCancelButton: false,
        showConfirmButton: false,
      });

      // Trigger download
      saveAs(zipBlob, fileName);
    } catch (error) {
      // Hide the progress bar and show error icon
      console.log("errorrrrrr----->",error);
      swalProgress.close();
      setBulkDownload(false);
      setStudentID("");
      setIsChecked(false);
      setIsShowFinal(false);
      setIsExcludeGrade(false);
      $("#availableprogrammeinRereg").modal("hide");
      Swal.fire({
        title: "Error",
        text: "An error occurred while creating the ZIP file.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // const handleDownload = async () => {
  //   const zipBlob = await generatePDFs();
  //   saveAs(
  //     zipBlob,
  //     `${brandVisuals?.brand_name}_${pdfsData[0]?.studentDetails?.program_name}_SOR_${moment(new Date()).format("DD MM YY")}${status.arr?.length ? `_${status.arr}` : ""}${campus.arr?.length ? `_${campus.arr}` : ""}${learningMethod.arr?.length ? `_${learningMethod.arr}` : ""}.zip`
  //   );
  // };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderProgrammeStudentStatus}
                    />
                    <Tablefilter
                      filterName={"Campus"}
                      optionArr={filterData.campus}
                      state={campus}
                      setState={setCampus}
                      renderLabelFunction={RenderCampus}
                    />
                    <Tablefilter
                      filterName={"Learning Method"}
                      optionArr={filterData.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                  </div>
                </div>
                <div className="button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    title="Reset"
                    onClick={resetFilter}
                  >
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>
                <div className="button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    title="Download SoR's"
                    data-toggle="modal"
                    data-target="#availableprogrammeinRereg"
                    onClick={() => {setBulkDownload(true)
                      setIsChecked(programDetails.show_assessments)
                    setIsShowFinal(programDetails.show_final_results)
                    setIsExcludeGrade(programDetails.show_empty_grades)}
                    }
                    disabled={bulkPdfDownloading}
                  >
                    {bulkPdfDownloading ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-download"></i>
                    )}
                    Download SoR's
                  </button>
                </div>
                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Programmes_Students");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Programmes_Students");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "Programmes_Students");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="first_name"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
      <div
        className="topic-add-modal modal fade"
        id="availableprogrammeinRereg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-600 modal-dialog modal-dialog-centered modal-m"
          role="document"
        >
          <div className="modal-content modal-border-update sor">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-download"></i> Statement of Results
                  Download Options
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  data-toggle="modal"
                  data-target="#availableprogrammeinRereg"
                  onClick={() => {
                    setStudentID("");
                    setIsChecked(false);
                    setIsShowFinal(false);
                    setIsExcludeGrade(false);
                    setBulkDownload(false);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-17px">
                <i className="fal fa-cog SoR-icon"></i>
                <span className="SoR-settings"> Settings</span>
                <div className="toogles-inline mt-3 d-flex align-items">
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Show Assessments</label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleToggle}
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Show Final Results</label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isShowFinal}
                          onChange={handleShowFinalToggle}
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Exclude Empty Grades</label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isExcludeGrade}
                          onChange={handleExcludeFinal}
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group form-group-save-cancel mt-4">
                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    onClick={() => {
                      if (bulkDownload) {
                        handleDownload();
                      } else {
                        handlepdfData(studentID);
                      }
                    }}
                  >
                    <i className="fal fa-download"></i> Download SoR
                  </button>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => {
                      setStudentID("");
                      setIsChecked(false);
                      setIsShowFinal(false);
                      setIsExcludeGrade(false);
                      setBulkDownload(false);
                      $("#availableprogrammeinRereg").modal("hide");
                    }}
                  >
                    <i className="fal fa-times"></i> Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Students;
