import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import {
    getStaticPagesBrandList,
    getStaticPagesHeaderList,
} from "../../../services/StaticPgesServer";
import { getCampusPagesStatusList } from "../../../services/CampusPagesServer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FORM_DATE_FORMAT } from "../../../utils/Constants";
import { AddBlogAndNewsPages, GetBlogAndNewsUpdateList, updateBlogAndNewsPages } from "../../../services/BlogAndNewsPageServer";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField } from "../../common/FormFields";
import moment from "moment";
import SectionColorOverrideComponent from "../../website/SectionColorOverrideComponent";
import { getBlockVariantTypeDropDown } from "../../../services/BrandTemplateServices";
const BlogAndNewsPagesOpt = () => {
    const history = useHistory();
    const { id, type } = useParams();
    const [updateData, setUpdateData] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)
    const [brandList, setBrandList] = useState([]);
    const [statusArr, setStatusArr] = useState([]);
    const [selectKeywords, setSelectedKeywords] = useState([]);
    const [learnignBlockArr, setLearnignBlockArr] = useState([]);
    const [testimonialsBlockArr, setTestimonialsBlockArr] = useState([]);
    const [header, setHeader] = useState([]);

    const givenPermissions = useSelector((state) => state.givenPermission);
    
    let brandID = updateData?.brandTemplete.map((data) => data.brandID);
    
    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);
    
    useEffect(() => {
        const fetchData = async () => {
            getCampusPagesStatusList()
            .then(res => setStatusArr(res.data.publishedStatus))
            .catch(error => console.error("Error fetching status data", error));
            
            getStaticPagesBrandList()
            .then(res => setBrandList(res.data.brandTypesFilters))
            const [ BlockVariantTypeRes] = await Promise.all([
                getBlockVariantTypeDropDown()
            ]);
            const learnignBlockFilters = BlockVariantTypeRes.data.learnignBlock || [];
            const testimonialsBlockFilters = BlockVariantTypeRes.data.testimonialsBlock || [];
            setLearnignBlockArr(learnignBlockFilters);
            setTestimonialsBlockArr(testimonialsBlockFilters);
        };
        
        fetchData();
    }, []);
    
    useEffect(() => {
        GetBlogAndNewsUpdateList(id)
        .then(res => {
            setUpdateData(res?.data?.Data)
        })
        .catch(err => {
            if (type === 'update') {
                Swal.fire({
                    title: "Info",
                    text: "No record found with this ID",
                });
                history.push(`/website_management/pages/blogandnews/table`);
            }
        })
        
    }, [id])
    
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };
    
    const handleKeyDown1 = (event) => {
        let tags = selectKeywords;
        if (event.key === "Enter") {
            event.preventDefault();
            if (event.target.value) {
                tags.push(event.target.value);
                event.target.value = ""
                formik.setFieldValue("tags", tags);
            }
        }
    };
    
    const removeKeywords = (index) => {
        let tags = selectKeywords;
        let keywords = tags.splice(index, 1);
        formik.setFieldValue("tags", tags);
    };
    
    const formatDate = (date) => {
        if (!date) return "";
        
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const day = (`0${d.getDate()}`).slice(-2);
        const hours = (`0${d.getHours()}`).slice(-2);
        const minutes = (`0${d.getMinutes()}`).slice(-2);
        const seconds = (`0${d.getSeconds()}`).slice(-2);
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    useEffect(() => {
        const fetchData = async () => {
            getStaticPagesHeaderList()
                .then(res => setHeader(res?.data?.Data))
                .catch(error => console.error("Error fetching status data", error))
        }
        fetchData();
        }, []);
    
    const formik = useFormik({
        enableReinitialize: type === "update",
        initialValues: {
            id: updateData?.id || "",
            postTitle: updateData?.postTitle || "",
            Brand: brandID || [],
            tags: selectKeywords,
            status: updateData ? updateData?.status : "",
            snippetText: updateData?.snippetText || "",
            postContent: updateData?.postContent || "",
            showPageTitle: updateData?.showPageTitle || 0,
            conversionCode: updateData ? updateData?.conversionCode : "",
            metaTitle: updateData?.metaTitle || "",
            metaDescription: updateData?.metaDescription || "",
            metaKeywords: updateData?.metaKeywords || "",
            customCss: updateData?.customCss || "",
            checkCustomCss: updateData?.checkCustomCss || 0,
            publishedDate: updateData?.publishedDate ? new Date(updateData?.publishedDate) : "",
            news_image: updateData?.news_image || "",
            
            
            school_category_show_block: updateData?.school_category_show_block || 0,
            school_category_search_bar: updateData?.school_category_search_bar || 0,
            school_category_text: updateData?.school_category_text || "",
            secondary_category_show_block: updateData?.secondary_category_show_block || 0,
            secondary_category_text: updateData?.secondary_category_text || "",
            upcoming_opendays_show_block: updateData?.upcoming_opendays_show_block || 0,
            upcoming_opendays_text: updateData?.upcoming_opendays_text || "",
            learning_method_show_block: updateData?.learning_method_show_block || 0,
            learning_method_block_variant: updateData?.learning_method_block_variant || "",
            learning_method_block_text: updateData?.learning_method_block_text || "",
            testimonials_block_show: updateData?.testimonials_block_show || 0,
            testimonials_block_variant: updateData?.testimonials_block_variant || "",
            testimonials_block_text: updateData?.testimonials_block_text || "",
            news_block_show: updateData?.news_block_show || 0,
            news_block_text: updateData?.news_block_text || "",
            
            school_override_color: updateData?.school_override_color || 0,
            school_accentColor: updateData?.school_accentColor || "#007BFF",
            school_textColorOne: updateData?.school_textColorOne || "#007BFF",
            school_textColorTwo: updateData?.school_textColorTwo || "#007BFF",
            school_backgroundColor: updateData?.school_backgroundColor || "#007BFF",
            openday_override_color: updateData?.openday_override_color || 0,
            openday_accentColor: updateData?.openday_accentColor || "#007BFF",
            openday_textColorOne: updateData?.openday_textColorOne || "#007BFF",
            openday_textColorTwo: updateData?.openday_textColorTwo || "#007BFF",
            openday_backgroundColor: updateData?.openday_backgroundColor || "#007BFF",
            learning_method_override_color: updateData?.learning_method_override_color || 0,
            learning_method_accentColor: updateData?.learning_method_accentColor || "#007BFF",
            learning_method_textColorOne: updateData?.learning_method_textColorOne || "#007BFF",
            learning_method_textColorTwo: updateData?.learning_method_textColorTwo || "#007BFF",
            learning_method_backgroundColor: updateData?.learning_method_backgroundColor || "#007BFF",
            testimonials_override_color: updateData?.testimonials_override_color || 0,
            testimonials_accentColor: updateData?.testimonials_accentColor || "#007BFF",
            testimonials_textColorOne: updateData?.testimonials_textColorOne || "#007BFF",
            testimonials_textColorTwo: updateData?.testimonials_textColorTwo || "#007BFF",
            testimonials_backgroundColor: updateData?.testimonials_backgroundColor || "#007BFF",
            news_override_color: updateData?.news_override_color || 0,
            news_accentColor: updateData?.news_accentColor || "#007BFF",
            news_textColorOne: updateData?.news_textColorOne || "#007BFF",
            news_textColorTwo: updateData?.news_textColorTwo || "#007BFF",
            news_backgroundColor: updateData?.news_backgroundColor || "#007BFF",
            toggle_brand_template: updateData?.toggle_brand_template || 0,
            groupByCourseLandingPages: updateData?.groupByCourseLandingPages || 0,
            SliderID: updateData?.SliderID || " " ,
            
        },
        validationSchema: Yup.object({
            postTitle: Yup.string().required("Page Title is required"),
            snippetText: Yup.string().required("Snippet Text is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            tags: Yup.array().required("Tags is required"),
            postContent: Yup.string().required("Page Content is required"),
            publishedDate: Yup.date().required("Published Date is required"),
            status: Yup.string().required("Status is required"),
            // publishedDate: Yup.date().required("Published Date is required"),
            news_image: Yup.mixed().required('News Image is required'),
            customCss: Yup.string().when('checkCustomCss', {
                is: 1,
                then: Yup.string().required("Custom CSS is required")
            })
        }),
        onSubmit: async (values, actions) => {
            // values.publishedDate = formatDate(values.publishedDate);
            // values.publishedDate = moment(new Date(values.publishedDate).getTime()).format('YYYY-MM-DD HH:mm:ss');
            setIsDisabled(true)
            let publishDate = moment(values.publishedDate).format("YYYY-MM-DD") + " 00:00:00";
            const formData = new FormData();
            
            
            // Trim and clean values, replace undefined or null with empty string
            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );
            
            // Append values to FormData
            Object.keys(cleanedValues).forEach((key) => {
                if (key == "publishedDate") formData.append("publishedDate", publishDate)
                    else {
                    const value = cleanedValues[key];
                    
                    if (Array.isArray(value)) {
                        if (key === "assignedPagesAndPMC") {
                            formData.append(key, JSON.stringify(value));
                        } else {
                            value.forEach((val, idx) => {
                                formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                            });
                        }
                    } else {
                        formData.append(key, value !== undefined && value !== null ? value : "");
                    }
                }
            });
            
            if (type === "update") {
                updateBlogAndNewsPages(formData)
                .then(res => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated Successfully",
                    });
                    setIsDisabled(false)
                })
                .catch(err => {
                    console.error(err)
                    setIsDisabled(false)
                })
            } else {
                try {
                    await AddBlogAndNewsPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    setIsDisabled(false)
                    actions.resetForm();
                    history.push("/website_management/pages/blogandnews/table");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                    setIsDisabled(false)
                }
            }
        }
    });
    
    useEffect(() => {
        setSelectedKeywords((updateData?.tagsTemplate && updateData?.tagsTemplate.length > 0) ? updateData?.tagsTemplate.map(item => item.tags) : []);
    }, [updateData])
    
    return (
        <div className="card card-profile-info-card">
        <div>
        <div className="new-card-header">
        <div className="card-header pt-0">Page Information</div>
        </div>
        <div className="card-body-inr card-body-info">
        <form onSubmit={formik.handleSubmit}>
        <div className="row">
        <div className="col-md-6 col-lg-3">
        <div className="form-group-blk mb-3">
        <label>Page Title *</label>
        <input
        type="text"
        className={
            "form-control" +
            (formik.errors.postTitle && formik.touched.postTitle
                ? " is-invalid"
                : "")
            }
            placeholder="Enter Page Title"
            name="postTitle"
            id="postTitle"
            title="Page Title"
            value={formik.values.postTitle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <div className="form-group-blk mb-3" title="Assigned Brand Templates">
            <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
            <Select
            className={
                "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                    ? " is-invalid"
                    : "")
                }
                name="Brand"
                value={brandList.filter((data) => formik.values.Brand.includes(data.value))}
                onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                onBlur={formik.handleBlur}
                options={brandList}
                maxMenuHeight={175}
                isMulti
                placeholder={"Select Assigned Brand Templates"}
                />
                </div>
                </div>
                <div className="col-md-6 col-lg-3">
                <div className="form-group-blk mb-3" title="Status">
                <label>Status *</label>
                <Select
                className={
                    "form-control custom-select-box" +
                    (formik.errors.status && formik.touched.status
                        ? " is-invalid"
                        : "")
                    }
                    name="status"
                    value={statusArr.find(val => val.value === formik.values.status)}
                    onChange={(option) => {
                        formik.setFieldValue("status", option ? option.value : "");
                    }}
                    onBlur={formik.handleBlur}
                    options={statusArr}
                    maxMenuHeight={175}
                    placeholder={
                        formik.values.status || "Select Status"
                    }
                    isClearable
                    />
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-center">
                    <label className="mb-0">
                    Show Page Title <i className="fal fa-info-circle"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                    <label className="switch">
                    <input
                    type="checkbox"
                    checked={formik.values.showPageTitle}
                    onChange={(e) => formik.setFieldValue("showPageTitle", e.target.checked ? 1 : 0)}
                    />
                    <span className="slider slider-round"></span>
                    </label>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="form-group-blk mb-4">
                    <label>Published Date* <i className="fal fa-info-circle"></i></label>
                    {/* <DatePicker
                        selected={formik.values.publishedDate}
                        filterTime={filterPassedTime}
                        dateFormat={FORM_DATE_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                        "form-control cursor-pointer" +
                        (formik.errors.publishedDate &&
                        formik.touched.publishedDate
                        ? " is-invalid"
                        : "")
                        }
                        title="Publish Date"
                        placeholderText="Select Publish Date"
                        timeIntervals={15}
                        onChange={(date) => {
                            let nDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
                        console.log(">>>>date", nDate);
                        formik.setFieldValue("publishedDate", nDate);
                        }}
                        onChangeRaw={(e) => e.preventDefault()}
                        /> */}
                        <DatePicker
                        autoComplete="off"
                        selected={formik.values.publishedDate}
                        name="publishedDate"
                        onChange={(date) => {
                            formik.setFieldValue("publishedDate", date);
                        }}
                        dateFormat={FORM_DATE_FORMAT}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        className={
                            "picker-with-icon form-control cursor-pointer" +
                            (formik.errors.publishedDate &&
                                formik.touched.publishedDate
                                ? " is-invalid empty-date"
                                : "")
                            }
                            title="Published Date"
                            placeholderText="Select Start Date"
                            timeIntervals={15}
                            onChangeRaw={(e) => {
                                e.preventDefault();
                            }}
                            // minDate={new Date()}
                            />
                            </div>
                            </div>
                            <div className="col-md-6 col-lg-9 mb-3">
                            <label>Tags*</label>
                            <div className="form-icon-group">
                            <div className="form-control custom-select-box">
                            <input
                            type="text"
                            className={"form-control " + (formik.errors.tags && formik.touched.tags ? " is-invalid" : "")}
                            name="tags"
                            placeholder="tags"
                            title="Tags"
                            onBlur={formik.handleBlur}
                            onKeyDown={handleKeyDown1}
                            />
                            {formik.errors.tags && formik.touched.tags ? (<span className="exclamation"><i className="fal fa-exclamation-circle"></i></span>) : null}
                            </div>
                            </div>
                            {selectKeywords.length > 0 && (
                                <ul className="tag-list mb-0">
                                {selectKeywords?.map((ele, index) => (
                                    <li>
                                    {ele}
                                    <span onClick={() => removeKeywords(index)}>
                                    <i className="fal fa-times"></i>
                                    </span>
                                    </li>
                                ))}
                                </ul>
                            )}
                            </div>
                            <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                            <label htmlFor="notes">Snippet Text*</label>
                            
                            <textarea
                            className={"form-control " + (formik.errors.snippetText && formik.touched.snippetText ? " is-invalid" : "")}
                            rows="5"
                            placeholder="Snippet Text"
                            value={formik.values.snippetText}
                            name="snippetText"
                            id="snippetText"
                            title="Snippet Text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            ></textarea>
                            </div>
                            </div>
                            <FileUploadField formik={formik} name="news_image" required label="News Image" accept=".png,.jpg,.jpeg,.svg" />
                            <div className="col-md-12">
                            <div className="form-group-blk mb-3">
                            <label>Post Content*</label>
                            <HtmlInputEditor
                            editorState={formik.values.postContent}
                            setEditorState={(editorState) => formik.setFieldValue('postContent', editorState)}
                            hideSign
                                        isCKEditor
                                        initialValue={updateData?.postContent}
                            />
                            </div>
                            </div>
                            <div className="col-md-12">
                            <hr className="border border-2 mt-0" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="new-card-header col-md-12">
                            <div className="row mb-4">
                            <div className="col-md-12">
                            <div className="edit-icon new-card-header">
                            <h4 className="card-header">
                            <i className="fal fa-cog" style={{ color: "var(--topbar-color)" }}></i> Section Settings                                        
                            </h4>
                            </div>
                                    </div>
                                    <div className="row col-md-12">
                                        {/* Header Selection */}
                                        <div className="col-md-6 col-lg-3">
                                            <div className="form-group-blk mb-3" title="Header">
                                                <label>Header <i className="fal fa-info-circle"></i></label>
                                                <Select
                                                    className={`form-control custom-select-box ${formik.errors.SliderID && formik.touched.SliderID ? "is-invalid" : ""
                                                        }`}
                                                    name="SliderID"
                                                    value={header.find(val => val.value === formik.values.SliderID)}
                                                    onChange={(option) => {
                                                        formik.setFieldValue("SliderID", option ? option.value : "");
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    options={header}
                                                    maxMenuHeight={175}
                                                    placeholder={"AIE Default"}
                                                    isClearable
                                                />
                                            </div>
                                        </div>

                                        {/* Toggle Switch */}
                                        <div
                                            className="d-flex align-items-center col-md-6 col-lg-4"
                                            title="Overwrite Section Content and settings of page components. This overwrites the settings on the brand template and is only applicable to this page."
                                        >
                                            <label className="mb-0">
                                                Override Brand Template Section Settings <i className="fal fa-info-circle grade-icon"></i>
                                            </label>
                                            <div className="toggle-switch ml-3">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={formik.values.toggle_brand_template === 1}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "toggle_brand_template",
                                                                e.target.checked ? 1 : 0
                                                            );
                                                        }}
                                                    />
                                                    <span className="slider slider-round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
     
                            </div>
                            </div>
                            {formik.values.toggle_brand_template == 1 ? (<>
                                <div className="col-12">
                                <div className="row">
                                <div className="col-md-12">
                                <div className="edit-icon new-card-header">
                                <h4 className="card-header">Schools Category Section </h4>
                                </div>
                                </div>
                                
                                
                                <div className={`col-md-6 col-lg-3`}>
                                <label htmlFor={"Section Settings"}>
                                Section Settings
                                </label>
                                <div className="form-icon-group mt-3">
                                <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section">
                                <input
                                type="checkbox"
                                className="custom-control-input"
                                id="school_category_show_block"
                                name="school_category_show_block"
                                checked={formik.values.school_category_show_block == 1}
                                onChange={(e) => {
                                    console.log("Updating school_category_show_block:", e.target.checked);
                                    formik.setFieldValue(
                                        "school_category_show_block",
                                        e.target.checked ? 1 : 0
                                    );
                                }}
                                />
                                <label
                                className="custom-control-label"
                                htmlFor="school_category_show_block"
                                >
                                Show Section
                                </label>
                                </div>
                                <div className="custom-control custom-checkbox text-left" title="Show Course Search Bar">
                                <input
                                type="checkbox"
                                className="custom-control-input"
                                id="school_category_search_bar"
                                name="school_category_search_bar"
                                checked={formik.values.school_category_search_bar == 1}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "school_category_search_bar",
                                        e.target.checked ? 1 : 0
                                    );
                                }}
                                />
                                <label
                                className="custom-control-label"
                                htmlFor="school_category_search_bar"
                                >
                                Show Course Search Bar
                                </label>
                                </div>
                                </div>
                                </div>
                                <div className="col-md-6 col-lg-9">
                                <label htmlFor="school_category_text" title="Site Name">Section Title</label>
                                <div className="form-icon-group" title="site_Name">
                                <input
                                type="text"
                                className={`form-control ${formik.touched.school_category_text && formik.errors.school_category_text && "is-invalid"
                                }`}
                                name="school_category_text"
                                id="school_category_text"
                                title="Section Title"
                                placeholder="Section Title"
                                value={formik.values?.school_category_text}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                />
                                </div>
                                </div>
                                </div>
                                <div
                                className="d-flex my-20"
                                title="Group Content by Course Landing Pages and not by schools"
                                >
                                <label className="mb-0">
                                Group Content by Course Landing Pages
                                <i className="fal fa-info-circle grade-icon ml-2"></i>
                                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
                                </label>
                                <div className="toggle-switch ml-2">
                                <label className="switch">
                                <input
                                type="checkbox"
                                checked={formik.values.groupByCourseLandingPages == 1}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'groupByCourseLandingPages',
                                        e.target.checked ? 1 : 0
                                    );
                                }}
                                />
                                <span className="slider slider-round"></span>
                                </label>
                                </div>
                                </div>
                                <SectionColorOverrideComponent 
                                prefix="school_" 
                                formik={formik} 
                                fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                <hr />
                                </div>
                                
                                <div className="col-12">
                                <div className="row pt-1">
                                <div className="col-md-12">
                                <div className="edit-icon new-card-header">
                                <h4 className="card-header">Secondary Category Section</h4>
                                </div>
                                </div>
                                
                                <div className={`col-md-6 col-lg-3`}>
                                <label htmlFor={"Section Settings"}>
                                Section Settings
                                </label>
                                <div className="form-icon-group mt-3">
                                <div className="custom-control custom-checkbox text-left mb-3 mr-3" title="Show Section">
                                <input
                                type="checkbox"
                                className="custom-control-input"
                                id="secondary_category_show_block"
                                name="secondary_category_show_block"
                                checked={formik.values.secondary_category_show_block == 1}
                                onChange={(e) => {
                                    console.log("Updating secondary_category_show_block:", e.target.checked);
                                    formik.setFieldValue(
                                        "secondary_category_show_block",
                                        e.target.checked ? 1 : 0
                                    );
                                }}
                                />
                                <label
                                className="custom-control-label"
                                htmlFor="secondary_category_show_block"
                                >
                                Show Section
                                </label>
                                </div>
                                </div>
                                </div>
                                <div className="col-md-6 col-lg-9">
                                <label htmlFor="secondary_category_text" title="Site Name">Section Title</label>
                                <div className="form-icon-group" title="site_Name">
                                <input
                                type="text"
                                className={`form-control ${formik.touched.secondary_category_text && formik.errors.secondary_category_text && "is-invalid"
                                }`}
                                name="secondary_category_text"
                                id="secondary_category_text"
                                title="Section Title"
                                placeholder="Section Title"
                                value={formik.values?.secondary_category_text}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                />
                                </div>
                                </div>
                                </div>
                                <hr />
                                </div>
                                
                                
                                <div className="col-12">
                                <div className="row pt-1">
                                <div className="col-md-12">
                                <div className="edit-icon new-card-header">
                                <h4 className="card-header">Upcoming Open Days Block</h4>
                                </div>
                                </div>
                                
                                <div className={`col-md-6 col-lg-3`}>
                                <label htmlFor={"Section Settings"}>
                                Section Settings
                                </label>
                                <div className="form-icon-group mb-4 mt-3">
                                <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                <input
                                type="checkbox"
                                className="custom-control-input"
                                id="upcoming_opendays_show_block"
                                name="upcoming_opendays_show_block"
                                checked={formik.values.upcoming_opendays_show_block == 1}
                                onChange={(e) => {
                                    console.log("Updating upcoming_opendays_show_block:", e.target.checked);
                                    formik.setFieldValue(
                                        "upcoming_opendays_show_block",
                                        e.target.checked ? 1 : 0
                                    );
                                }}
                                />
                                <label
                                className="custom-control-label"
                                htmlFor="upcoming_opendays_show_block"
                                >
                                Show Section
                                </label>
                                </div>
                                </div>
                                </div>
                                <div className="col-md-6 col-lg-9">
                                <label htmlFor="upcoming_opendays_text" title="Site Name">Section Title</label>
                                <div className="form-icon-group" title="site_Name">
                                <input
                                type="text"
                                className={`form-control ${formik.touched.upcoming_opendays_text && formik.errors.upcoming_opendays_text && "is-invalid"
                                }`}
                                name="upcoming_opendays_text"
                                id="upcoming_opendays_text"
                                title="Section Title"
                                placeholder="Section Title"
                                value={formik.values?.upcoming_opendays_text}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                />
                                </div>
                                </div>
                                </div>
                                <SectionColorOverrideComponent 
                                prefix="openday_" 
                                formik={formik} 
                                fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                <hr />
                                </div>
                                
                                <div className="col-12">
                                <div className="row pt-1">
                                <div className="col-md-12">
                                <div className="edit-icon new-card-header">
                                <h4 className="card-header">Learning Methods Block</h4>
                                </div>
                                </div>
                                
                                <div className={`col-md-6 col-lg-3`}>
                                <label htmlFor={"Section Settings"}>
                                Section Settings
                                </label>
                                <div className="form-icon-group mb-4 mt-3">
                                <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                <input
                                type="checkbox"
                                className="custom-control-input"
                                id="learning_method_show_block"
                                name="learning_method_show_block"
                                checked={formik.values.learning_method_show_block == 1}
                                onChange={(e) => {
                                    console.log("Updating learning_method_show_block:", e.target.checked);
                                    formik.setFieldValue(
                                        "learning_method_show_block",
                                        e.target.checked ? 1 : 0
                                    );
                                }}
                                />
                                <label
                                className="custom-control-label"
                                htmlFor="learning_method_show_block"
                                >
                                Show Section
                                </label>
                                </div>
                                </div>
                                </div>
                                
                                <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Section Variant">
                                <label htmlFor="learning_method_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
                                </label>
                                <Select
                                className={
                                    "form-control custom-select-box " +
                                    (formik.errors.learning_method_block_variant && formik.touched.learning_method_block_variant
                                        ? " is-invalid"
                                        : "")
                                    }
                                    name="learning_method_block_variant"
                                    value={learnignBlockArr.filter(
                                        (item) => item.value == formik.values.learning_method_block_variant
                                    )}
                                    onChange={(value) => {
                                        if (value) {
                                            formik.setFieldValue("learning_method_block_variant", value.value);
                                        } else {
                                            formik.setFieldValue("learning_method_block_variant", "");
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    options={learnignBlockArr}
                                    maxMenuHeight={175}
                                    placeholder={"Select Section Variant"}
                                    />
                                    </div>
                                    </div>
                                    
                                    <div className="col-md-6 col-lg-4">
                                    <label htmlFor="learning_method_block_text" title="Site Name">Section Title</label>
                                    <div className="form-icon-group" title="site_Name">
                                    <input
                                    type="text"
                                    className={`form-control ${formik.touched.learning_method_block_text && formik.errors.learning_method_block_text && "is-invalid"
                                    }`}
                                    name="learning_method_block_text"
                                    id="learning_method_block_text"
                                    title="Section Title"
                                    placeholder="Section Title"
                                    value={formik.values?.learning_method_block_text}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    />
                                    </div>
                                    </div>
                                    </div>
                                    <SectionColorOverrideComponent 
                                    prefix="learning_method_" 
                                    formik={formik} 
                                    fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                    <hr />
                                    </div>
                                    
                                    <div className="col-12">
                                    <div className="row pt-1">
                                    <div className="col-md-12">
                                    <div className="edit-icon new-card-header">
                                    <h4 className="card-header">Testimonials Block</h4>
                                    </div>
                                    </div>
                                    
                                    <div className={`col-md-6 col-lg-3`}>
                                    <label htmlFor={"Section Settings"}>
                                    Section Settings
                                    </label>
                                    <div className="form-icon-group mb-4 mt-3">
                                    <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                    <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="testimonials_block_show"
                                    name="testimonials_block_show"
                                    checked={formik.values.testimonials_block_show == 1}
                                    onChange={(e) => {
                                        console.log("Updating testimonials_block_show:", e.target.checked);
                                        formik.setFieldValue(
                                            "testimonials_block_show",
                                            e.target.checked ? 1 : 0
                                        );
                                    }}
                                    />
                                    <label
                                    className="custom-control-label"
                                    htmlFor="testimonials_block_show"
                                    >
                                    Show Section
                                    </label>
                                    </div>
                                    </div>
                                    </div>
                                    
                                    <div className="col-md-6 col-lg-4">
                                    <div className="form-group-blk mb-3" title="Section Variant">
                                    <label htmlFor="testimonials_block_variant">Section Variant <i className="fal fa-info-circle grade-icon"></i>
                                    </label>
                                    <Select
                                    className={
                                        "form-control custom-select-box " +
                                        (formik.errors.testimonials_block_variant && formik.touched.testimonials_block_variant
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="Linked PMC Programme"
                                        value={testimonialsBlockArr.filter(
                                            (item) => item.value == formik.values.testimonials_block_variant
                                        )}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("testimonials_block_variant", value.value);
                                            } else {
                                                formik.setFieldValue("testimonials_block_variant", "");
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={testimonialsBlockArr}
                                        maxMenuHeight={175}
                                        placeholder={"Select Section Variant"}
                                        />
                                        </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-lg-4">
                                        <label htmlFor="testimonials_block_text" title="Site Name">Section Title</label>
                                        <div className="form-icon-group" title="site_Name">
                                        <input
                                        type="text"
                                        className={`form-control ${formik.touched.testimonials_block_text && formik.errors.testimonials_block_text && "is-invalid"
                                        }`}
                                        name="testimonials_block_text"
                                        id="testimonials_block_text"
                                        title="Section Title"
                                        placeholder="Section Title"
                                        value={formik.values?.testimonials_block_text}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                        </div>
                                        </div>
                                        </div>
                                        <SectionColorOverrideComponent 
                                        prefix="testimonials_" 
                                        formik={formik} 
                                        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                        <hr />
                                        </div>
                                        
                                        <div className="col-12">
                                        <div className="row pt-1">
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header">
                                        <h4 className="card-header">News Block</h4>
                                        </div>
                                        </div>
                                        
                                        <div className={`col-md-6 col-lg-3`}>
                                        <label htmlFor={"Section Settings"}>
                                        Section Settings
                                        </label>
                                        <div className="form-icon-group mb-4 mt-3">
                                        <div className="custom-control custom-checkbox text-left mr-3" title="Show Section">
                                        <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="news_block_show"
                                        name="news_block_show"
                                        checked={formik.values.news_block_show == 1}
                                        onChange={(e) => {
                                            console.log("Updating news_block_show:", e.target.checked);
                                            formik.setFieldValue(
                                                "news_block_show",
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        />
                                        <label
                                        className="custom-control-label"
                                        htmlFor="news_block_show"
                                        >
                                        Show Section
                                        </label>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6 col-lg-9">
                                        <label htmlFor="news_block_text" title="Site Name">Section Title</label>
                                        <div className="form-icon-group" title="site_Name">
                                        <input
                                        type="text"
                                        className={`form-control ${formik.touched.news_block_text && formik.errors.news_block_text && "is-invalid"
                                        }`}
                                        name="news_block_text"
                                        id="news_block_text"
                                        title="Section Title"
                                        placeholder="Section Title"
                                        value={formik.values?.news_block_text}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        />
                                        </div>
                                        </div>
                                        </div>
                                        <SectionColorOverrideComponent 
                                        prefix="news_" 
                                        formik={formik} 
                                        fields={["accentColor", "textColorOne", "textColorTwo", "backgroundColor"]} />
                                        </div>
                                        <hr/>
                                        </>): "" }
                                        <div className="col-md-12">
                                        <div className="edit-icon new-card-header mb-4 mt-1">
                                        <h4 className="card-header">
                                        <i
                                        className="fal fa-cog"
                                        style={{ color: "var(--topbar-color)" }}
                                        ></i> SEO Settings
                                        </h4>
                                        </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                        <div className="form-group-blk mb-3">
                                        <label htmlFor="code">Conversion Code</label>
                                        <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.conversionCode && formik.touched.conversionCode
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Enter Conversion Code"
                                            name="conversionCode"
                                            id="conversion"
                                            title="conversion"
                                            maxLength={20}
                                            value={formik.values.conversionCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            />
                                            </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                            <div className="form-group-blk mb-3">
                                            <label>Meta Title</label>
                                            <input
                                            type="text"
                                            className={
                                                "form-control" +
                                                (formik.errors.metaTitle && formik.touched.metaTitle
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Enter Meta Title"
                                                title="metaTitle"
                                                name="metaTitle"
                                                value={formik.values.metaTitle}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                />
                                                </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                <div className="form-group-blk mb-3">
                                                <label>Meta Description</label>
                                                <input
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (formik.errors.metaDescription && formik.touched.metaDescription
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    placeholder="Enter Meta Description"
                                                    title="metaDescription"
                                                    name="metaDescription"
                                                    value={formik.values.metaDescription}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    />
                                                    </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6">
                                                    <div className="form-group-blk mb-3">
                                                    <label>Meta Keywords</label>
                                                    <input
                                                    type="text"
                                                    className={
                                                        "form-control" +
                                                        (formik.errors.metaKeywords && formik.touched.metaKeywords
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                        placeholder="Enter Meta Keywords"
                                                        title="metaKeywords"
                                                        name="metaKeywords"
                                                        value={formik.values.metaKeywords}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        />
                                                        </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                        <div className="edit-icon new-card-header mb-4 mt-1">
                                                        <h4 className="card-header">
                                                        <i
                                                        className="fal fa-cog"
                                                        style={{ color: "var(--topbar-color)" }}
                                                        ></i> Style Settings
                                                        </h4>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3">
                                                        <label className="mb-0">
                                                        Add Custom CSS <i className="fal fa-info-circle"></i>
                                                        </label>
                                                        <div className="toggle-switch ml-2">
                                                        <label className="switch">
                                                        <input
                                                        type="checkbox"
                                                        checked={formik.values.checkCustomCss}
                                                        onChange={(e) => formik.setFieldValue("checkCustomCss", e.target.checked ? 1 : 0)}
                                                        />
                                                        <span className="slider slider-round"></span>
                                                        </label>
                                                        </div>
                                                        </div>
                                                        {formik.values.checkCustomCss == 1 && <div className="col-md-12">
                                                            <div className=" mb-3 mt-3" title="Custom CSS">
                                                            <label>Custom CSS*</label>
                                                            <div className="html-editor-input">
                                                            <textarea value={formik.values.customCss} className={`textarea bg-white form-control ${formik.touched.customCss && formik.errors.customCss && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="customCss"></textarea>
                                                            <div className="headerf">
                                                            <span className="title fw-bolds"> CSS</span>
                                                            </div>
                                                            </div>
                                                            </div>
                                                            </div>}
                                                            </div>
                                                            <div className="form-group form-group-save-cancel mt-4">
                                                            <PermissionsGate scopes={[type == "create" ? 'wbsblognewspageadd' : 'wbsblognewspageedit']} RenderError={() => (
                                                                <button className="btn btn-save btn-success" disabled>
                                                                <i className="fal fa-save"></i> Save
                                                                </button>
                                                            )}>
                                                            
                                                            <button
                                                            className="btn btn-save btn-success"
                                                            type="submit"
                                                            title="Save"
                                                            disabled={isDisabled}
                                                            >
                                                            {isDisabled ? <i className="fal fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                                            
                                                            Save
                                                            </button>
                                                            </PermissionsGate>
                                                            </div>
                                                            {Object.keys(formik.errors).map((key) => {
                                                                if (formik.touched[key]) {
                                                                    return (
                                                                        <div className="invalid-feedback d-block" key={key}>
                                                                        {formik.errors[key]}
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            </form>
                                                            </div>
                                                            </div>
                                                            </div>
                                                        );
                                                    };
                                                    
                                                    export default BlogAndNewsPagesOpt;
                                                    
                                                    