import axiosInstance from "../utils/axios";

export const getHeadersList = async (values) => {
  return await axiosInstance().post(`/getHeadersList`, values);
};

export const getHeadersListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getHeadersList`, values, {
    cancelToken: cancelToken,
  });
};
export const getHeaderDetails = async (id) => {
  return await axiosInstance().get(`/getHeaderDetails?SliderID=${id}`);
};
export const getAccentColorBaseOnBrandTmpList = async (values) => {
  return await axiosInstance().post(
    `/getAccentColorBaseOnBrandTmpList`,values
  );
};
export const addHeaderDetails = async (data) => {
  return await axiosInstance().post("/createHeader", data);
};

export const updateHeaderDetails = async (data) => {
  return await axiosInstance().post(`/updateHeader`, data);
};
export const getHeaderFilters = async () => {
  return await axiosInstance().get(`/getHeaderFilters`);
};
export const getHeadersPageList = async (id = 0) => {
  return await axiosInstance().get(`getHeadersPageList?header_id=${id}`);
};

export const GetAllHeaderPMCpageList = async (id = 0) => {
  return await axiosInstance().get(`getAllHeaderPMCpageList?header_id=${id}`);
};
export const getHeaderDropDownList = async () => {
  return await axiosInstance().get(`/getHeaderDropDownList`);
};
export const DeleteLayhoutHeader = async (values) => {
  return await axiosInstance().post(`/deleteHeader`, values);
};
export const getAttachedPageList = async (id) => {
  return await axiosInstance().get(
    `/getHeaderAttachPagesList?SliderID=${id}`
  );
};
export const getAttachedPageFilters = async (id) => {
  return await axiosInstance().get(`/getHeaderAttachedPagesFilters?SliderID=${id}`);
};

export const getPageList = async (id , isAssigned) => {
  return await axiosInstance().get(`/getHeadersPageList?header_id=${id}&isAssigned=${isAssigned}`);
};

export const getHeadersPageListFilters = async (id) => {
  return await axiosInstance().get(`/getHeadersPageListFilters?header_id=${id}`);
};

export const GetHeaderPMCpageFilter = async (id) => {
  return await axiosInstance().get(`/getHeaderPMCpageFilter?header_id=${id}`);
};

export const getQualificationList = async (id) => {
  return await axiosInstance().get(`/getAllHeaderProgrameAndShortCourseList?header_id=${id}`);
};

export const getHeaderProgrameAndShortCourseFilter = async (id) => {
  return await axiosInstance().get(`/getHeaderProgrameAndShortCourseFilter?header_id=${id}`);
};

