import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import React from 'react';
import * as Yup from 'yup';
import Select from 'react-select';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
// import hasPermission from "../../../utils/hasMultiplePermission";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  GetCampusMgtList,
  GetCampusRoomsListCancelToken,
} from '../../../services/CampusMgtService';
import axios from 'axios';
import { BookingConfirmation } from './BookingConfirmation';
import {
  GetBookingLinkedCourses,
  GetListOnRequestType,
} from '../../../services/BookingServices';
import PermissionsGate from '../../../utils/permissionGate';

const validationSchema = Yup.object({
  name: Yup.string()
    .strict(true)
    .required('Name is required')
    .trim('Leading and ending trail not allowed in name'),
  booking_date: Yup.date().strict(true).required('Date is required'),
  booking_start_time: Yup.date()
    .strict(true)
    .required('Start Time is required'),
  booking_end_time: Yup.date()
    .strict(true)
    .required('End Time is required')
    .test(
      'end-time-after-start-time',
      'End Time must be after Start Time',
      function (value) {
        const { booking_start_time } = this.parent;
        return (
          value &&
          booking_start_time &&
          new Date(value) > new Date(booking_start_time)
        );
      }
    ),
  booking_for: Yup.array()
    .of(Yup.string())
    .min(1, 'Select at least one option')
    .required('Booking for is required'),
});

export default function BookingForm({
  initialValues,
  onSubmit,
  showApprovalRequest = false,
  log_data = [],
  onChange = () => {},
  onUpdatedStatus = () => {}
}) {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [studentData, setStudentData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [campusArr, setCampusArr] = useState([]);
  const [campusId, setCampusId] = useState(initialValues.campus_id ?? '');
  const [campusRooms, setCampusRooms] = useState([]);
  const [requestType, setRequestType] = useState(initialValues.request_type);
  // const [selectedPersonId, setSelectedPersonId] = useState(initialValues.booking_for);
  const [userId, setUserId] = useState(initialValues.booking_for);

  const [linkedCourses, setLinkedCourses] = useState([]);
  const [listOfPeopleOnRequestType, setListofPeopleOnRequestType] = useState(
    []
  );

  const bookingReason = [
    {
      label: 'Study Session',
      value: 'Study Session',
    },
    {
      label: 'Class',
      value: 'Class',
    },
    {
      label: 'Exam',
      value: 'Exam',
    },
    {
      label: 'Assessment',
      value: 'Assessment',
    },
    {
      label: 'Practical Task',
      value: 'Practical Task',
    },
    {
      label: 'Practical Assessment',
      value: 'Practical Assessment',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  // this effect will run when the radio button for lecture and student or other is selected
  useEffect(() => {
    if (requestType) {
      const getListOnRequestType = async () => {
        try {
          const response = await GetListOnRequestType(requestType);
          const listofPeopleOnRequestType = response.data?.data?.map((user) => {
            if (requestType === 'employee') {
              return {
                label: user.Firstname + ' ' + user.Lastname,
                value: user.UserID,
              };
            } else if (requestType === 'student') {
              return {
                label: user.first_name + ' ' + user.last_name,
                value: user.id,
              };
            }
          });
          setListofPeopleOnRequestType(listofPeopleOnRequestType);
          setLoadingProgress(true);
        } catch (error) {
          console.log('error---', error);
        }
      };
      getListOnRequestType();
    }
  }, [requestType]);

  // this api will run only when the booking for or the student or lecturer or other gets selected

  useEffect(() => {
    const getLinkedCourses = async () => {
      try {
        if (userId != 0) {
          const response = await GetBookingLinkedCourses(requestType, userId);
          const courses_list = response.data?.data;
          const linked_courses = courses_list.map((course) => {
            return {
              label: course.course_name,
              value: course.intake_id,
            };
          });
          setLinkedCourses((prevCourses) => [
            ...prevCourses,
            ...linked_courses,
          ]);
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    getLinkedCourses();
  }, [userId]);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let res = await GetCampusMgtList(source.token);
        if (res.status == 200) {
          let tempCampusArr = [];
          res.data.data_list.map((data) => {
            tempCampusArr.push({
              value: `${data.id}`,
              label: `${data.campus_name}`,
            });
          });
          setCampusArr(tempCampusArr);
          setIsLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    const getRoomsByCampusId = async () => {
      try {
        if (campusId) {
          const campusRoomsResponse = await GetCampusRoomsListCancelToken(
            campusId
          );
          const rooms = campusRoomsResponse.data.data_list.map((room) => {
            return {
              value: room.room_name,
              label: room.room_name,
              id: room.id,
            };
          });
          setCampusRooms(rooms);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getRoomsByCampusId();
  }, [campusId]);

  useEffect(() => {
    // Set the value of booking_for field when initialValues changes
    if (initialValues.booking_for && showApprovalRequest) {
      formik.setFieldValue(
        'booking_for',
        initialValues.booking_for !== null
          ? initialValues.booking_for.split(',').map(Number)
          : ''
      );
    }
  }, [initialValues.booking_for]);

  // this function will set the request type to either student, lecture and other
  const handleRequestType = (event) => {
    const requestType_ = event.target.value;
    setRequestType(requestType_);
    formik.setFieldValue('request_type', requestType_);
  };

  // this function will set the booking for based on the multiple selected value
  const handleSelectedPerson = (selectedPersons) => {
    if (selectedPersons !== null) {
      const selectedPersonValues = selectedPersons.map(
        (person) => person.value
      );
      formik.setFieldValue('booking_for', selectedPersonValues);
      const lastSelectedPersonValue =
        selectedPersons[selectedPersons.length - 1].value;
      setUserId(lastSelectedPersonValue);
    } else {
      formik.setFieldValue('booking_for', '');
      setLinkedCourses([]);
      setUserId(0);
    }
  };

  const handleSelectedRoom = (selectedRooms) => {
    if (selectedRooms) {
      const room = campusRooms.find(
        (room) => room.value === selectedRooms.value
      );
      formik.setFieldValue('room_no', room?.value);
      formik.setFieldValue('room_id', room?.id);
    }
  };

  return (
    <>
      <div className="card card-body-inr">
        <form onSubmit={formik.handleSubmit}>
          {isLoaded ? (
            <SkeletonTicketList />
          ) : (
            <>
              <div className="booking-heading">
                <span style={{ fontWeight: 700, fontSize: '16px' }}>
                  {!showApprovalRequest
                    ? 'Request New Booking'
                    : 'Booking Details'}
                </span>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Name">Name *</label>
                  <div className="form-icon-group mb-4">
                    <input
                      type="Name"
                      id="Name"
                      className={
                        'form-control' +
                        (formik.errors.name && formik.touched.name
                          ? ' is-invalid'
                          : '')
                      }
                      name="name"
                      placeholder="Enter Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      title="Name"
                    />
                    {formik.errors.Name && formik.touched.Name ? (
                      <>
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Campus">Campus </label>
                  <div className="form-icon-group mb-4">
                    <Select
                      className={
                        'form-control custom-select-box' +
                        (formik.errors.campus && formik.campus
                          ? ' is-invalid'
                          : '')
                      }
                      name="campus"
                      value={formik.values.campus}
                      onChange={(selectedCampus) => {
                        if (selectedCampus) {
                          setCampusId(selectedCampus.value);
                          formik.setFieldValue('campus', selectedCampus.label);
                          formik.setFieldValue(
                            'campus_id',
                            selectedCampus.value
                          );
                        } else {
                          formik.setFieldValue('campus', '');
                          formik.setFieldValue('campus_id', 0);
                        }
                      }}
                      isClearable
                      onBlur={formik.handleBlur}
                      options={campusArr}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.campus
                          ? formik.values.campus
                          : 'Select Campus'
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Room">Room</label>
                  <div className="form-icon-group mb-4">
                    <Select
                      className={
                        'form-control custom-select-box' +
                        (formik.errors.room_no && formik.room_no
                          ? ' is-invalid'
                          : '')
                      }
                      name="room_no"
                      value={formik.values.room_no}
                      onChange={(selectedRoom) => {
                        handleSelectedRoom(selectedRoom);
                      }}
                      isClearable
                      onBlur={formik.handleBlur}
                      options={campusRooms}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.room_no
                          ? formik.values.room_no
                          : 'Select Room'
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Email">Date *</label>
                  <div className="form-icon-group mb-4">
                    <div className={'datepicker-wrapper-class'}>
                      <DatePicker
                        selected={formik.values.booking_date}
                        onChange={(date) =>
                          formik.setFieldValue('booking_date', date)
                        }
                        className={
                          'form-control' +
                          (formik.errors.booking_date &&
                          formik.touched.booking_date
                            ? ' is-invalid'
                            : '')
                        }
                        placeholderText="Select Date"
                        dateFormat="dd/MM/yyyy"
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.booking_date &&
                    formik.touched.booking_date ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <label htmlFor="StartTime">Start Time *</label>
                  <div className="form-icon-group mb-4">
                    <div className={'datepicker-wrapper-class'}>
                      <DatePicker
                        selected={formik.values.booking_start_time}
                        onChange={(date) =>
                          formik.setFieldValue('booking_start_time', date)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        dateFormat="p"
                        className={
                          'form-control' +
                          (formik.errors.booking_start_time &&
                          formik.touched.booking_start_time
                            ? ' is-invalid'
                            : '')
                        }
                        placeholderText="Select Start Time"
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.booking_start_time &&
                    formik.touched.booking_start_time ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <label htmlFor="EndTime">End Time *</label>
                  <div className="form-icon-group mb-4">
                    <div className={'datepicker-wrapper-class'}>
                      <DatePicker
                        selected={formik.values.booking_end_time}
                        onChange={(date) =>
                          formik.setFieldValue('booking_end_time', date)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="p"
                        className={
                          'form-control' +
                          (formik.errors.booking_end_time &&
                          formik.touched.booking_end_time
                            ? ' is-invalid'
                            : '')
                        }
                        placeholderText="Select End Time"
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.booking_end_time &&
                    formik.touched.booking_end_time ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <label>Request Type *</label>
                  <div className="form-icon-group mb-4">
                    <div className="radio-group-custom custom-radio radio-style">
                      <input
                        type="radio"
                        id="requestTypeOption1"
                        name="request_type"
                        value="employee"
                        checked={formik.values.request_type === 'employee'}
                        onChange={(event) => handleRequestType(event)}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        htmlFor="requestTypeOption1"
                        className="booking-radio-label"
                      >
                        Employee
                      </label>
                    </div>
                    <div className="radio-group-custom custom-radio radio-option radio-style">
                      <input
                        type="radio"
                        id="requestTypeOption2"
                        name="request_type"
                        value="student"
                        checked={formik.values.request_type === 'student'}
                        onChange={(event) => handleRequestType(event)}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        htmlFor="requestTypeOption2"
                        className="booking-radio-label"
                      >
                        Student
                      </label>
                    </div>
                    {/* <div className="radio-group-custom custom-radio radio-option radio-style">
                      <input
                        type="radio"
                        id="requestTypeOption3"
                        name="request_type"
                        value="other"
                        checked={formik.values.request_type === 'other'}
                        onChange={(event) => handleRequestType(event)}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="requestTypeOption3" className="radio-label">
                        Other
                      </label>
                    </div> */}
                  </div>
                  {formik.errors.request_type && formik.touched.request_type ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Student">
                    {requestType === 'employee' ? 'Employee *' : 'Student *'}
                  </label>
                  <div className="form-icon-group mb-4">
                    <Select
                      className={
                        'form-control custom-select-box' +
                        (formik.errors.booking_for && formik.touched.booking_for
                          ? ' is-invalid'
                          : '')
                      }
                      name="booking_for"
                      value={listOfPeopleOnRequestType.filter((people) =>
                        formik.values.booking_for.includes(people.value)
                      )}
                      onChange={(selected) => handleSelectedPerson(selected)}
                      onBlur={formik.handleBlur}
                      options={loadingProgress && listOfPeopleOnRequestType}
                      maxMenuHeight={175}
                      isMulti
                      placeholder={
                        requestType === 'employee'
                          ? 'Select Employee'
                          : 'Select Student'
                      }
                    />
                  </div>
                  {formik.errors.booking_for && formik.touched.booking_for ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>

                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Linked Course">Linked Course</label>
                  <div className="form-icon-group mb-4">
                    <Select
                      className={
                        'form-control custom-select-box' +
                        (formik.errors.linked_course &&
                        formik.touched.linked_course
                          ? ' is-invalid'
                          : '')
                      }
                      name="linked_course"
                      value={formik.values.linked_course}
                      onChange={(selectedCourse) => {
                        if (selectedCourse) {
                          formik.setFieldValue(
                            'linked_course',
                            selectedCourse.label
                          );
                          formik.setFieldValue(
                            'linked_course_id',
                            selectedCourse.value
                          );
                        } else {
                          formik.setFieldValue('linked_course', '');
                          formik.setFieldValue('linked_course_id', 0);
                        }
                      }}
                      isClearable
                      onBlur={formik.handleBlur}
                      options={linkedCourses}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.linked_course
                          ? formik.values.linked_course
                          : 'Select Course'
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <label htmlFor="Booking Reason">Booking Reason</label>
                  <div className="form-icon-group mb-4">
                    <Select
                      className={
                        'form-control custom-select-box' +
                        (formik.errors.booking_reason && formik.booking_reason
                          ? ' is-invalid'
                          : '')
                      }
                      name="booking_reason"
                      value={formik.values.booking_reason || null}
                      onChange={(reason) => {
                        formik.setFieldValue('booking_reason', reason.value);
                      }}
                      isClearable
                      onBlur={formik.handleBlur}
                      options={bookingReason}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.booking_reason
                          ? formik.values.booking_reason
                          : 'Select Reason'
                      }
                    />
                  </div>
                </div>

                {!showApprovalRequest && (
                  <div className="col-md-6 col-lg-12">
                    <label htmlFor="Additional Information">
                      Additional Information
                    </label>
                    <div className="form-icon-group mb-4">
                      <input
                        type="AdditionalInformation"
                        id="AdditionalInformation"
                        className={
                          'form-control' +
                          (formik.errors.additional_information &&
                          formik.touched.additional_information
                            ? ' is-invalid'
                            : '')
                        }
                        name="additional_information"
                        placeholder="Enter Additional Information"
                        value={formik.values.additional_information}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'additional_information',
                            event.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        title="AdditionalInformation"
                      />
                      {formik.errors.additional_information &&
                      formik.touched.additional_information ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              {
                showApprovalRequest && (
                  // <div className="col-md-6 col-lg-12">
                  <BookingConfirmation
                    booking_status={initialValues.booking_status}
                    additional_info={initialValues.additional_information}
                    booking_logs={log_data}
                      onChange={onChange}
                      onStatusChanged={onUpdatedStatus}
                      
                  />
                )
                // </div>
              }

              <div className="form-group form-group-save-cancel">
                <PermissionsGate scopes={['cabmedit']} errorProps={{ disabled: true }}>
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                  disabled={disabled}
                >
                  {disabled ? (
                    <i className="fas fa-cog fa-spin"></i>
                  ) : (
                    <i className="fal fa-save"></i>
                  )}
                  Save
                </button>
                </PermissionsGate>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => history.goBack()}
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
                {formik.errors.name ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.name}
                  </div>
                ) : null}
                {formik.errors.booking_date ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.booking_date}
                  </div>
                ) : null}
                {formik.errors.booking_start_time ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.booking_start_time}
                  </div>
                ) : null}
                {formik.errors.booking_end_time ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.booking_end_time}
                  </div>
                ) : null}
                {formik.errors.booking_for ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.booking_for}
                  </div>
                ) : null}
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
}
