import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../../../utils/Constants";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import hasPermission from "../../../../utils/hasMultiplePermission";
import Tablefilter from "../../../common/Tablefilter";
import {
  LayoutHeaderStatus,
  RenderWebsiteBrandTemplate,
  RenderWebsitelistStatus,
  UpcomingEventsStatus,
} from "../../../../utils/CommonStatusItems";
import axios from "axios";
import { handleTableScroll } from "../../../../utils/commonFunction";
import $ from "jquery";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import {
  deleteUpcomingEvent,
  getBookingsFiltersList,
  getBookingsList,
  getBookingsListCancelToken,
  getUpcomingEventFiltersList,
  getUpcomingEventsList,
  getUpcomingEventsListCancelToken,
} from "../../../../services/EventService";
import moment from "moment";

function Booking({ setHeaderName }) {
  const history = useHistory();
  const { subId, subTab } = useParams();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [qualification, setQualification] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [deleterow, setDeleteRow] = useState(false);
  const [updateData, setUpdateData] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({
    qualification: [],
  });

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const storedFilters = JSON.parse(
      localStorage.getItem("filterStateBooking")
    );
    if (storedFilters) {
      setQualification(storedFilters.qualification);
      setFilterData(storedFilters.filterData);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      qualification,
      filterData,
    };
    localStorage.setItem("filterStateBooking", JSON.stringify(filterState));
  }, [qualification, filterData]);
  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });
  useEffect(() => {
    const cancelTokenSources = [];
    const getBookingsList = async () => {
      setloading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);

      const data = {
        event_id: subId,
        page: page,
        perPage: perPage,
        keyval: sortkey,
        sort: sortOrder,
        search: search,
        exportStatus: false,
        viaQualifications: qualification.arr,
      };

      try {
        const res = await getBookingsListCancelToken(data, source.token);
        const event = res.data.data;
        setEventsData(event);
        setTotalRows(res.data?.total);
        setPerPage(res.data?.perPage);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setloading(false);
      }
    };

    getBookingsList();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    search,
    qualification,
    searchStatusCheck,
    updateData,
    subId,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBookingsFiltersList(subId);
        setFilterData({
          ...res.data,
          qualification: res.data?.qualificationFilter
            ? res.data?.qualificationFilter
            : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["sview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [deleterow]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setQualification({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setSearch("");
    setDebouncedTerm("");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };
  const handleRedirection = () => {

  }
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "first_name",
      sortField: "first_name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <span className="textLimit100">{row.first_name}</span>
        </div>
      ),
    },
    {
      name: "Surname",
      selector: "last_name",
      sortField: "last_name",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <span className="textLimit100">{row.last_name}</span>
        </div>
      ),
    },
    {
      name: "Email Address",
      selector: "email",
      sortField: "email",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <span className="textLimit100">{row.email}</span>
        </div>
      ),
    },
    {
      name: "Mobile Number",
      selector: "mobile",
      sortField: "mobile",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <span className="textLimit100">{row.mobile}</span>
        </div>
      ),
    },
    {
      name: "Qualification",
      selector: "qualification",
      sortField: "qualification",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <Link
            className="as-text-blue curser feature-name"
            to={`/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.qualification_id}`}
            title={row.qualification}
          >
            <span className="textLimit100">{row.qualification}</span>
          </Link>
        </div>
      ),
    },
    {
      name: "Time Registered",
      selector: "registration_date",
      sortField: "registration_date",
      minWidth: "190px",
      sortable: true,
      cell: (row) => {
        let dateString = row?.registration_date;
        let dateObj = new Date(dateString);
        let formattedDate = moment(dateObj)?.format("DD MMM YY HH:mm");
        return formattedDate;
      },
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Name",
      "Surname",
      "Email Address",
      "Mobile Number",
      "Qualification",
      "Time Registered",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const eventsData = {
      event_id: subId,
      page: page,
      perPage: perPage,
      keyval: sortkey,
      sort: sortOrder,
      search: search,
      exportStatus: true,
      viaQualifications: qualification.arr,
    };
    const dateFun = (registration_date) => {
      let dateString = registration_date;
      let dateObj = new Date(dateString);
      let formattedDate = moment(dateObj)?.format("DD MMM YY HH:mm");
      return formattedDate;
    };
    getBookingsList(eventsData)
      .then((res) => {
        data = res.data;
        data = data?.map((row) => ({
          ...row,
          Name: row?.first_name || "",
          Surname: row?.last_name || "",
          "Email Address": row?.email || "",
          "Mobile Number": row?.mobile || "",
          Qualification: row?.qualification || "",
          "Time Registered": dateFun(row?.registration_date) || "",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row?.first_name,
              row?.last_name,
              row?.email,
              row?.mobile,
              row?.qualification,
              dateFun(row?.registration_date),
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table pr-18">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>
                    <Tablefilter
                      filterName="Qualification"
                      optionArr={filterData?.qualification}
                      state={qualification}
                      setState={setQualification}
                    // renderLabelFunction={UpcomingEventsStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Upcoming_event");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Upcoming_event");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Upcoming_event");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          data={eventsData}
          progressComponent={<SkeletonTicketList />}
          defaultSortField={sortkey}
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          // noDataComponent={Str.noRecord}
          onSort={handleSort}
          sortServer
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default Booking;
