import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams} from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {GetDisplayLayout, AddUpdateDisplayLayout} from "../../../services/SmartSignageService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField } from "../../common/FormFields";
import HtmlParser from "react-html-parser";
import Select from "react-select";
import DisplayCardPreview from "./components/DisplayCardPreview";

const CreateLayout = () => {
    
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const { id, type } = useParams();
    const [displayLayout, setDisplayLayout] = useState({});
    const layoutTypeArr = [
        {
            value: "0",
            label: HtmlParser(
                `<span className="red" title="Video and scrolling text">Dynamic</span>`
            ),
        },
        {
            value: "1",
            label: HtmlParser(`<span className="blue" title="Only video based screen">Video Only</span> `),
        },
    ];
    const videoTypeArr = [
        {
            value: false,
            label: HtmlParser(
                `<span className="red" title="File">File</span>`
            ),
        },
        {
            value: "1",
            label: HtmlParser(`<span className="blue" title="Link">Link</span> `),
        },
    ];
    
    useEffect(() => {
        if(id) {
            setLoadingData(true);
            GetDisplayLayout({id: id}).then((res) => {                
                if(res.status === 200) {
                    if(res?.data?.data){
                        setDisplayLayout(res.data.data);                        
                    }
                }
                setLoadingData(false);
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error Fetching Details",
                    text: error,
                })
                console.log(error);
            });
        }
    }, [id])   
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: displayLayout?.name || "",
            layout_type: displayLayout?.layout_type !== null ? displayLayout?.layout_type : "",
            scrolling_text: displayLayout?.scrolling_text || "",
            background_video: displayLayout?.background_video || "",
            video_type: displayLayout?.video_type == 0 ? false : true
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            background_video: Yup.string()
            .when("video_type", {
                is: "1", // Check if video_type is "1"
                then: (schema) => schema.url("Must be a valid URL").required("Background Video is required"),
                otherwise: (schema) => schema.notRequired(),
            }),
            scrolling_text: Yup.string()
            .when('layout_type', {
                is: "0", 
                then: Yup.string().required("Scrolling Text is required"), 
                otherwise: Yup.string().notRequired()}
            )
        }),
        onSubmit: (values, action) => {
            const formData = new FormData();
            if(id) {
                formData.append("id", id);
            }
            setLoading(true);
            formData.append("background_video", values.background_video);
            formData.append("name", values.name);
            formData.append("layout_type", values.layout_type);
            formData.append("video_type", values.video_type == true ? 1 : 0);
            formData.append("scrolling_text", values.layout_type == 0 ? values.scrolling_text : "");
            
            AddUpdateDisplayLayout(formData).then((res) => {
                if(res.status === 200) {
                    let successText = id ? "Layout Updated successfully" : "Layout created successfully";
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: successText,
                        confirmButtonText: "OK",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if(!id) {
                                if(res?.data?.id)
                                    {
                                    history.push(`/campusAdministration/smartsignage/layouts/edit/${res?.data?.id}`);
                                }else{
                                    history.push(`/campusAdministration/smartsignage/layouts/list`);
                                }
                            }
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: error,
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error,
                })
            });
            action.setSubmitting(false);
        }
    });
    
    return (
        <div className={`col-md-12 ${type == 'edit' ? 'display-user-container' : ""}`}>
        <div className={`card card-profile-info-card filter-table-bg ${type == 'edit' ? 'col-lg-8' : ""}`}>
        <div className="row">
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header" title="Programme Page Details">{type === "add" ? <p>Create Layout</p>
            : <p>Modify Layout{loadingData ? (<><i className="fa fa-cog fa-spin"></i></>) : (<>: {displayLayout?.name} </>)}</p>} </h4>
            </div>
            </div>            
            <div className="col-md-6 col-lg-3">
            <label htmlFor="name">Name *</label>
            <div className="form-icon-group mb-4" title="name">
            <input
            type="text"
            className={`form-control ${formik.touched.name && formik.errors.name && "is-invalid"
            }`}
            // name="name"
            id="name"
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="Layout Type" >Layout Type <i className="fal fa-info-circle"></i></label>
            <div className="form-icon-group mb-4">
            <Select
            className={`form-control custom-select-box ${formik.touched.layout_type && formik.errors.layout_type && "is-invalid"
            }`}
            value={layoutTypeArr.filter(
                (item) => item.value == formik.values.layout_type
            )
        }
        id="layout_type"
        name="layout_type"
        onChange={(option) => formik.setFieldValue("layout_type", option.value)}
        onBlur={formik.handleBlur}
        options={layoutTypeArr}
        maxMenuHeight={175}
        placeholder="Select Layout Type"
        />
        </div>
        </div>        
            <div className="col-md-6 col-lg-3">
            <label htmlFor="Video Type" >Video Type <i className="fal fa-info-circle"></i></label>
            <div className="form-icon-group mb-4">
            <Select
            className={`form-control custom-select-box ${formik.touched.video_type && formik.errors.video_type && "is-invalid"
            }`}
            value={videoTypeArr.filter(
                (item) => item.value == formik.values.video_type
            )
        }
        id="video_type"
        name="video_type"
        onChange={(option) => formik.setFieldValue("video_type", option.value)}
        onBlur={formik.handleBlur}
        options={videoTypeArr}
        maxMenuHeight={175}
        placeholder="Select Video Type"
        />
        </div>
        </div>
        { formik.values.video_type == "0" &&
            <FileUploadField name="background_video" label="Video" formik={formik} accept="video/*" required={false} col_md={6} col_lg={3} />
        }
        { formik.values.video_type == "1" && 
            <div className="col-md-6 col-lg-4">
            <label htmlFor="name">Link</label>
            <div className="form-icon-group mb-4">
            <input
            type="text"
            className={`form-control ${formik.touched.background_video && formik.errors.background_video && "is-invalid"
            }`}
            // name="name"
            id="background_video"
            placeholder="https://<link to video>"
            value={formik.values.background_video}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
        }
        { formik.values.layout_type == "0" &&
            (<>
                <div className="col-10">
                <label htmlFor="name">Scrolling Text</label>
                <div className="form-icon-group mb-4" title="name">
                <input
                type="text"
                className={`form-control ${formik.touched.scrolling_text && formik.errors.scrolling_text && "is-invalid"
                }`}
                id="scrolling_text"
                placeholder="Scrolling Text"
                value={formik.values.scrolling_text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                </div>
                </div>
                </>)
            }
        </div>
        <div className="form-group form-group-save-cancel mt-4">
        <PermissionsGate scopes={[type == "add" ? 'casmartsignlayoutadd' : 'casmartsignlayoutedit']} errorProps={{ disabled: true }}>
        <button
        className="btn btn-save btn-success"
        type="submit"
        title="Save"
        disabled={loading}
        onClick={formik.handleSubmit}
        >
        {loading ? (
            <i className="fas fa-cog fa-spin"></i>
        ) : (
            <i className="fal fa-save"></i>
        )}
        Save
        </button>
        </PermissionsGate>    
        <button
        className="btn btn-close btn-danger"
        type="button"
        title="Cancel"
        onClick={() => {formik.resetForm()}}
        >
        <i className="fal fa-times"></i>Cancel
        </button>
        </div>
        </div>
        {(type == 'edit' && displayLayout) && 
            (
                <>
                <DisplayCardPreview
                videoUrl={displayLayout.background_video ? displayLayout.background_video.replace("/home/myaie/public_html/", "https://myaie.ac/") : null} 
                scrollingText={displayLayout.scrolling_text}             
                />
                </>
            )
        }
        </div>
        
    )
    
}

export default CreateLayout;