import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import CourseLandingPagesTable from "../components/pages/CourseLandingpages/CourseLandingPagesTable";
import { useSelector } from "react-redux";
import hasPermission from "../utils/hasMultiplePermission";
import { getCourseLandingPagesAuditTrailList, getCourseLandingPagesAuditTrailListFilters } from "../services/WebsiteListServer";
import EventAuditTrail from "../components/StudentAdministration/Students/EventAuditTrail";
// This will be shown in merge-all branch

function CourseLandingPages() {
    const history = useHistory();
    const { tab } = useParams();
    const givenPermission = useSelector(state => state.permission)
    useEffect(
        () => {
            let response = hasPermission({
                scopes: ["wbscourselpageview"],
                permissions: givenPermission,
            });
            if (!response) {
                history.push("/noaccess");
            }
        }, []
    )

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                Course&nbsp;
                                                <span>Landing Pages</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(tab === "table" || tab === "auditTrail") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/courselandingpages/table`)}>
                                                <Link
                                                    className={`nav-link ${tab === "table" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/courselandingpages/table`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                    title="Course Landing Pages"
                                                >
                                                    <i className="fa fa-file"></i>
                                                    Course Landing Pages
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/courselandingpages/auditTrail`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/courselandingpages/auditTrail`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                    title="Audit trail"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "table" && <CourseLandingPagesTable />}
                                            {/* {tab == "auditTrail" && <AuditTrail />} */}
                                            {tab == "auditTrail" && <EventAuditTrail apiParams={''} apiFunction={getCourseLandingPagesAuditTrailList} apiFilter={getCourseLandingPagesAuditTrailListFilters} exportName={"Course School Landing Pages"} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseLandingPages;
