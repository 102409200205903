import React, { useState } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Image,
  View,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";
import { downloadURL, IMAGE_URL, STUDENT_FILE_DOWNLOAD } from "./utils/Constants";
import { STUDENT_FILES_DOWNLOAD } from "./utils/Constants";
import footer from './assets/images/letterheadFooter_1706788148.png'
import sign from './assets/images/leonSign.png'
import header from './assets/images/letterheadHeader_1706788148.png'
import moment from "moment";
import { isNumber } from "underscore";




const LetterheadIntakePDF = ({
  programDetails = {},
  divMinHeight = 20,
  detailData = [],
  studentAndCourseDetails = {},
  brandData = [],
  noteImage,
  divOrgHeight = 20,
  divHeight = 100,
  divWidth = 100,
  divTopPosition = 460,
}) => {

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
    },
    header: {
      width: "100%",
      height: 90,
    },
    footer: {
      width: "100%",
      height: 15,
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    contentContainer: {
      padding: "0 40px",
      marginTop: 30,
      alignItems: "center",
    },
    heading: {
      fontSize: 30,
      lineHeight: "normal",
      marginBottom: 2,
      textAlign: "center",
    },
    subheading: {
      fontSize: 20,
      color: "#000",
      lineHeight: "normal",
      textAlign: "center",
    },
    detailLabel: {
      fontSize: 11,
    },
    detailValue: {
      fontSize: 11,
      color: "#000",
      paddingBottom: 5,
    },
    topmain: {
      marginTop: 10,
      padding: 15,
      display: "flex",
      flexDirection: "row",
      paddingBottom: 0,
      marginBottom: 15,
    },
    topleft: {
      width: "50%",
    },
    topright: {
      width: "50%",
    },
    tableBox: {
      paddingLeft: 15,
      paddingRight: 15,
      // paddingBottom: 15,
    },
    tableHead: {
      display: "flex",
      flexDirection: "row",
    },
    tablerowinner: {
      display: "flex",
      flexDirection: "row",
    },
  
    graderowinner: {
      marginTop: 15,
      display: "flex",
      flexDirection: "row",
    },
  
    tableFirst: {
      width: "300px",
      fontSize: 11,
      paddingTop: 15,
      paddingLeft: 10,
      paddingBottom: 10,
      paddingRight: 10,
      color: "#fff",
    },
    coursetableBodyFirst: {
      width: "300px",
      fontSize: 10,
      color: "#212529",
      padding: 10,
      borderLeft: "1px solid #DEE2E6",
      borderBottom: "1px solid #DEE2E6",
      borderRight: "1px solid #DEE2E6",
    },
    gradeBodyFirst: {
      width: "300px",
      fontSize: 10,
      color: "#212529",
      padding: 10,
      border: "1px solid #DEE2E6",
      backgroundColor: "#F8F9FA",
    },
    tableLabel: {
      flex: 1,
      fontSize: 11,
      paddingTop: 15,
      paddingLeft: 10,
      paddingBottom: 10,
      paddingRight: 10,
      color: "#fff",
    },
    coursetableBodyLabel: {
      flex: 1,
      fontSize: 10,
      color: "#212529",
      padding: 10,
      borderBottom: "1px solid #DEE2E6",
      borderRight: "1px solid #DEE2E6",
    },
    gradeBodyLabel: {
      flex: 1,
      fontSize: 10,
      color: "#212529",
      padding: 10,
      borderTop: "1px solid #DEE2E6",
      borderBottom: "1px solid #DEE2E6",
      borderRight: "1px solid #DEE2E6",
    },
    signimg: {
      width: 100,
      marginBottom: 10,
      marginTop: 10,
      maxHeight:120,
      // height: 100,
      // position: "absolute",
      // bottom: divOrgHeight > 340 ? 360 : divOrgHeight + 57,
      left: 15,
    },
    tableSignboxlabel: {
      fontSize: 12,
      paddingBottom: 5,
      position: "absolute",
      bottom: 45,
      left: 15,
    },
    tableSignboxsmall: {
      fontSize: 8,
      position: "absolute",
      bottom: 38,
      left: 15,
    },
    note: {
      width: divWidth,
      maxHeight : 360,
      // minHeight : divMinHeight,
      // position: "absolute",
      // bottom: 22,
      left: 20,
    },
  });

  
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const headerUrl = `${downloadURL}/${STUDENT_FILE_DOWNLOAD}/Library/brand/${
    brandData?.letterheadHeader ? brandData?.letterheadHeader : brandData[0]?.letterheadHeader ? brandData[0]?.letterheadHeader : brandVisuals?.letterheadHeader
  }`;
  const footerUrl = `${downloadURL}/${STUDENT_FILE_DOWNLOAD}/Library/brand/${
    brandData?.letterheadFooter ? brandData?.letterheadFooter : brandData[0]?.letterheadFooter ? brandData[0]?.letterheadFooter : brandVisuals?.letterheadFooter
  }`;
  const courseName = studentAndCourseDetails?.course_name;
  const brandSignature = `${downloadURL}/${STUDENT_FILE_DOWNLOAD}/Library/brand/${
    brandData?.brandSignature ? brandData?.brandSignature : brandData[0]?.brandSignature ? brandData[0]?.brandSignature : brandVisuals?.brandSignature 
  }`;
  
  
  const highestPossibleRows = Math.floor((670 - divHeight) / 30)
  let maxRowsPerPage = highestPossibleRows < 13 ? highestPossibleRows > 0 ? highestPossibleRows : 1 : 13 // Maximum rows per page
  let something = divOrgHeight > 360 ? 360 : divOrgHeight
  maxRowsPerPage = 14
  let pages = Math.ceil(detailData.length / (maxRowsPerPage)) + ((detailData.length % 14)*30 + 120 + something > 480 ? 1 : 0) + (detailData.length > 14 && detailData.length % 14 == 0 ? 1: 0) || 1;
  if(detailData.length){for(let d of detailData) {
    if(d?.name?.length > 150){
      if(maxRowsPerPage >= 2){

        maxRowsPerPage-=1
      }
    }
  }}
  

  let i = 0
  let isFinalShown = false

  if(detailData.length <= (detailData[1]?.final_grade != undefined && detailData[1]?.final_grade != null && detailData[1]?.final_grade !== "" && 
    detailData[1]?.final_status != null && detailData[1]?.final_status != undefined &&
    detailData[1]?.final_status !== "" ? 7 : 8 ) && divOrgHeight < 300) pages = 1
  console.log("AAAAAAAAAAAAAAaa",detailData,pages,maxRowsPerPage,something,noteImage);
  return (
    <Document>
       {[...Array(pages)].map((_, pageIndex) => (
      <Page size="A4" style={[styles.page, { paddingBottom: 40 }]}>
        <Image src={headerUrl} style={styles.header} fixed />

        <View style={styles.contentContainer} fixed>
          <Text
            style={[
              styles.heading,
              {
                color:"#0F274A"
              },
            ]}
          >
            Statement of Results
          </Text>
          <Text
            style={[
              styles.subheading,
              { fontFamily: "Helvetica" },
            ]}
          >
            {courseName}
          </Text>
        </View>

        <View style={styles.topmain} fixed>
          <View style={styles.topleft}>
            <Text style={styles.detailValue}>
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:"#0F274A"
                  },
                ]}
              >
                Student Name:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.Student_name}
              </Text>
            </Text>

            <Text style={styles.detailValue}>
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:"#0F274A"
                  },
                ]}
              >
                Student Number:{" "}
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.Student_number}
              </Text>
            </Text>

            <Text style={styles.detailValue}>
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:"#0F274A"
                  },
                ]}
              >
                ID / Passport Number:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.ID_number}
              </Text>
            </Text>
          </View>

          <View style={styles.topright}>
            <Text style={styles.detailValue}>
              {" "}
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:"#0F274A"
                  },
                ]}
              >
                Course Duration:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.start && studentAndCourseDetails?.end
                  ? `${studentAndCourseDetails?.start} - ${studentAndCourseDetails?.end}`
                  : "N/A"}
              </Text>
            </Text>

            <Text style={styles.detailValue}>
              {" "}
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:"#0F274A"
                  },
                ]}
              >
                Intake:
              </Text>{" "}
              <Text
                style={{ fontFamily: "Helvetica" }}
              >{`${studentAndCourseDetails?.intake} ${studentAndCourseDetails?.learning_method}`}</Text>
            </Text>

            <Text style={styles.detailValue}>
              {" "}
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:"#0F274A"
                  },
                ]}
              >
                Date Issued:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {moment(new Date()).format("DD MMMM YYYY")}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.tableBox}>
          <View style={styles.tableBody}>
            {(pageIndex == pages-1 ? !isFinalShown : true) &&
          (
                <View
                  style={[
                    styles.tableHead,
                    {
                      backgroundColor:"#0F274A"
                    },
                  ]}
                  fixed
                >
                  <Text
                    style={[
                      styles.tableFirst,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Name
                  </Text>

                  {/* <Text style={[styles.tableLabel, {fontFamily: 'Helvetica-Bold',fontWeight: "bold"}]}>Weight</Text> */}

                  <Text
                    style={[
                      styles.tableLabel,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Grade
                  </Text>

                  <Text
                    style={[
                      styles.tableLabel,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Result
                  </Text>
                </View>
              )
       }
            {detailData && detailData
            .slice(pageIndex * maxRowsPerPage, (pageIndex + 1) * maxRowsPerPage - (pageIndex == pages-1 ? 1 : 0))
            .map((assessment, assessmentIndex) => {
              i++
              if(pageIndex == pages - 2 && i == detailData.length) {isFinalShown = true}
              return assessment?.isHeading || (
                <View key={assessmentIndex}>
                  {/* Course row */}
                  <View style={styles.tablerowinner} wrap={false}>
                    <Text
                      style={[
                        styles.coursetableBodyFirst,
                        { fontFamily: assessment.isAssignedSickStudent || assessment.isSupplementaryForThisStudent ? "Helvetica-Bold" : "Helvetica" },
                        { opacity : assessment.isSickForThisStudent ? 0.6 : 1}
                      ]}
                    >
                      {assessment.name}
                    </Text>
                    {/* <Text style={[styles.coursetableBodyLabel, {fontFamily: 'Helvetica',fontWeight: "normal"}]}>
                      {assessment.weight}
                    </Text> */}
                    <Text
                      style={[
                        styles.coursetableBodyLabel,
                        { fontFamily: "Helvetica" },
                      ]}
                    >
                      {isNumber(assessment?.grade) ? Number(parseFloat(assessment.grade).toFixed(2)) : assessment?.grade}
                    </Text>
                    <Text
                      style={[
                        styles.coursetableBodyLabel,
                        { fontFamily: "Helvetica" },
                      ]}
                    >
                      {assessment.result}
                    </Text>
                  </View>
                </View>
              );
            })}
            {detailData[1]?.final_grade != undefined && detailData[1]?.final_grade != null && detailData[1]?.final_grade !== "" && 
              detailData[1]?.final_status != null && detailData[1]?.final_status != undefined &&
              detailData[1]?.final_status !== "" && 
              (detailData.length < 14 && pages == 1 ? pageIndex === pages - 1 : pageIndex == pages-2 && i == detailData.length ? pageIndex == pages-2 : pageIndex == pages-1 && !isFinalShown) &&(
                <View style={styles.graderowinner} wrap={false}>
                  <Text
                    style={[
                      styles.gradeBodyFirst,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Final Course Grade
                  </Text>
                  <Text
                    style={[
                      styles.gradeBodyLabel,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {detailData && detailData[1]?.final_grade}
                  </Text>
                  <Text
                    style={[
                      styles.gradeBodyLabel,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {detailData && detailData[1]?.final_status}
                  </Text>
                </View>
              )}
          </View>
        </View>
         {pageIndex == pages-1 && brandSignature && <Image src={brandSignature} style={styles.signimg} fixed />}
         {pageIndex == pages-1 && noteImage && <Image src={noteImage} style={styles.note} fixed/>}
        {/* <Text
          style={[
            styles.tableSignboxlabel,
            { color: brandVisuals?.topbar_color },
          ]}
          fixed
        >
          Leon Smalberger | CEO
        </Text>
        <Text
          style={[
            styles.tableSignboxsmall,
            { color: brandVisuals?.topbar_color },
          ]}
          fixed
        >
          Academic Institute of Excellence (AIE)
        </Text> */}
        <Image src={footerUrl} style={styles.footer} fixed />
      </Page>
       ))}
    </Document>
  );
};

export default LetterheadIntakePDF;
