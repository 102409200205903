import axiosInstance from "../utils/axios";

export const GetDisplayList = async (id, groupId) => {
  return await axiosInstance().get(`/getDisplayList?id=${id}&groupId=${groupId}`);
}

export const GetDisplayGroup = async (values, cancelToken) => {
  return await axiosInstance().post(`/getDisplayGroup`, values, {
    cancelToken: cancelToken
  });
};

export const GetDisplayGroups = async (values, cancelToken) => {
  return await axiosInstance().post(`/getDisplayGroups`, values, {
    cancelToken: cancelToken
  });
};
export const GetAuditTrailsCancelToken = async (values, cancelToken) => {
    return await axiosInstance().post(`/getSmartSignageAuditTrail`, values, {
      cancelToken: cancelToken
  });
}
export const GetSmartSignageAuditTrialDropdownCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getSmartSignageAuditDropdown`, { params, cancelToken: cancelToken });
};
export const AddUpdateDisplayGroup = async (data) => {
  return await axiosInstance().post(`/addUpdateDisplayGroup`, data);
};

export const DeleteDisplayGroup = async (id) => {
  return await axiosInstance().get(`/deleteDisplayGroup?id=${id}`);
}
export const GetDisplayUser = async (values, cancelToken) => {
  return await axiosInstance().post(`/getDisplayUser`, values, {
    cancelToken: cancelToken
  });
};

export const GetDisplayUsers = async (values, cancelToken) => {
  return await axiosInstance().post(`/getDisplayUsers`, values, {
    cancelToken: cancelToken
  });
};

export const AddUpdateDisplayUser = async (data) => {
  return await axiosInstance().post(`/addUpdateDisplayUser`, data);
};

export const DeleteDisplayUser = async (id) => {
  return await axiosInstance().get(`/deleteDisplayUser?id=${id}`);
}
export const GetDisplayLayout = async (values, cancelToken) => {
  return await axiosInstance().post(`/getDisplayLayout`, values, {
    cancelToken: cancelToken
  });
};

export const GetDisplayLayouts = async (values, cancelToken) => {
  return await axiosInstance().post(`/getDisplayLayouts`, values, {
    cancelToken: cancelToken
  });
};

export const AddUpdateDisplayLayout = async (data) => {
  return await axiosInstance().post(`/addUpdateDisplayLayout`, data);
};

export const DeleteDisplayLayout = async (id) => {
  return await axiosInstance().get(`/deleteDisplayLayout?id=${id}`);
};