import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { getCategoryLandingColorList, getMenuItemsColorDropdown, getMenuItemssub_menuDropdown, getMenuItemsTypeDropdown, getMenusItemsDropdownlist, GetUpdateMenuItems, UpdateMenuItems } from "../../../services/Menusserver";
import { AssignPageField, FileUploadField, FormField } from "../../common/FormFields";
import AssignLandingPagePopup from "./AssignLandingPagePopup";
import $ from 'jquery';

const UpdateMenuItemsMenu = (props) => {
    let history = useHistory();

    const [MenuDetails, setMenuDetails] = useState([]);
    const [menuColor, setmenuColor] = useState([]);
    const [MenuItemDetails, setMenuItemDetails] = useState([]);
    const [SubMenuItemDetails, setSubMenuItemDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateData, setUpdateData] = useState({});
    const [assignedQualification, setAssignedQualification] = useState({});

    const id = Number(props.id)

    const assignedQualificationRef = useRef(assignedQualification);

    const isObjectNotEmpty = (obj) => {
        return obj && Object.keys(obj).length > 0;
    };

    // get data for update
    const getMenuUpdateItemList = async () => {
        try {
            const res = await GetUpdateMenuItems(props.Userid);
            setUpdateData(res?.data?.data)
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {


        if (props.Userid) {
            getMenuUpdateItemList();
        }
    }, [props.Userid]);

    // get dropdown menu list

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await getMenusItemsDropdownlist()
                if (data.status === 200) {
                    setMenuDetails(data && data?.data)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        const gettypeData = async () => {
            try {
                const data = await getMenuItemsTypeDropdown()
                if (data.status === 200) {
                    setMenuItemDetails(data && data?.data?.data)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        const getsubMenuData = async () => {
            try {
                const data = await getMenuItemssub_menuDropdown(id)

                if (data.status === 200) {
                    setSubMenuItemDetails(data && data?.data)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        const getColorData = async () => {
            try {
                const data = await getCategoryLandingColorList()
                if (data.status === 200) {
                    setmenuColor(data && data?.data?.result)
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error :", error)
                }
            }
        }

        getColorData()
        getsubMenuData()
        gettypeData()
        getData()
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            MenuItemID: updateData.MenuItemID || '',
            MenuID: id,
            MenuItemAlias: updateData?.MenuItemAlias || '',
            MenuItemTypeID: updateData?.MenuItemTypeID || '',
            MenuItemStatus: updateData ? updateData?.MenuItemStatus : '',
            CategoryHeading: updateData ? updateData?.CategoryHeading : '',
            MenuItemCategory: updateData ? updateData?.MenuItemCategory : '',
            MenuCategory: 0,
            SubMenuID: updateData ? updateData?.SubMenuID : 0,
            MenuItemLabel: updateData?.MenuItemLabel || "",
            MenuItemLinkURL: updateData?.MenuItemLinkURL || "",
            MenuItemColor: updateData?.MenuItemColor || "",
            MenuItemOverrideColor: updateData?.MenuItemOverrideColor ? 1 : 0,
            isUseMenuIcon: updateData?.isUseMenuIcon == 1 ? 1 : 0,
            MenuIcon: updateData.MenuIcon || '',
            OpenInNewPage: 0,
            MenuItemOverrideCategory: 0,
            MenuItemPageID: 0,
            assignedPage: isObjectNotEmpty(assignedQualificationRef)
                ? {
                    id: assignedQualificationRef.current?.id,
                    type: assignedQualificationRef.current?.pageType,
                }
                : {},
        },
        validationSchema: Yup.object().shape({
            MenuItemAlias: Yup.string().required("Menu Item Alias is required"),
            MenuItemTypeID: Yup.string().required("Menu Item Resource Type is required"),
            MenuItemStatus: Yup.string().required("Status is required"),
            MenuItemLinkURL: Yup.string().when('MenuItemTypeID',{
                is: (val) => val == 2,
                then: Yup.string().matches(/^(\/|https?:\/\/)/, "URL must start with / or a valid protocol"),
                otherwise: Yup.string().nullable()
            }),
            // MenuItemColor: Yup.string().when('MenuItemTypeID', {
            //     is: (val) => val != 7 && val != 4,
            //     then: Yup.string().required("Menu Item Colour is required"),
            //     otherwise: Yup.string().nullable()
            // }),
            MenuIcon: Yup.mixed().when('isUseMenuIcon', {
                is: (val) => val == 1,
                then:  Yup.mixed().test('fileType', 'You must upload an image or provide a string', value => {
                    if (!value) return false; // no value
                
                    // Check if it's a File (image)
                    if (value instanceof File) {
                      return value.type.startsWith('image/');
                    }
                
                    // Check if it's a string
                    return typeof value === 'string' && value.trim().length > 0;
                  }),
                otherwise: Yup.mixed().nullable()
            }),
            // MenuIcon: Yup.mixed().test('fileType', 'You must upload an image or provide a string', value => {
            //     if (!value) return false; // no value
            
            //     // Check if it's a File (image)
            //     if (value instanceof File) {
            //       return value.type.startsWith('image/');
            //     }
            
            //     // Check if it's a string
            //     return typeof value === 'string' && value.trim().length > 0;
            //   }),
            assignedPage: Yup.string().when('MenuItemTypeID', {
                is: (val) => val === 6,
                then: Yup.string().required("Assigned Page is required"),
                otherwise: Yup.string().nullable()
            }),
            MenuItemLabel: Yup.string().when('MenuItemTypeID', {
                is: (val) => val === 2,
                then: Yup.string().required("Menu Item Title is required"),
                otherwise: Yup.string().nullable()
            }),
            CategoryHeading: Yup.string().when('MenuItemTypeID', {
                is: (val) => val === 4,
                then: Yup.string().required("Heading is required"),
                otherwise: Yup.string().nullable()
            }),
            SubMenuID: Yup.string().when('MenuItemTypeID', {
                is: (val) => val === 3,
                then: Yup.string().required("Available Sub-Menus is required"),
                otherwise: Yup.string().nullable()
            })
        }),
        onSubmit: (values, action) => {
            setLoading(true);

            const formData = new FormData();

            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );

            if (cleanedValues.assignedPage && typeof cleanedValues.assignedPage === 'object' && cleanedValues.assignedPage.id != undefined) {
                formData.append('assignedPage[id]', cleanedValues.assignedPage.id);
                formData.append('assignedPage[type]', cleanedValues.assignedPage.type !== undefined && cleanedValues.assignedPage.type !== null ? cleanedValues.assignedPage.type : "");
            }

            Object.keys(cleanedValues).forEach((key) => {
                if (key !== 'assignedPage') {
                    const value = cleanedValues[key];
                    if (Array.isArray(value)) {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    } else {
                        formData.append(key, value);
                    }
                }
            });

            UpdateMenuItems(formData)
                .then((response) => {
                    Swal.fire('Success!', 'Menu Items Updated successfully!', 'success');
                    getMenuUpdateItemList()
                    props.setRefresh(!props.refresh)
                    $("#updatecreatemenuitem").modal("hide");
                    action.resetForm();
                    props.setUpdateMenuItemModal(false)
                })
                .catch((error) => {
                    console.error('Error creating menus:', error);
                    Swal.fire('Error!', 'Failed to Update menu Items', 'error');
                })
                .finally(() => setLoading(false));
        }
    });

    useEffect(() => {
        if (assignedQualification) {
            formik.setFieldValue("assignedPage", {
                id: assignedQualification?.id,
                type: assignedQualification?.pageType,
            });
        }
    }, [assignedQualification]);

    useEffect(() => {
        formik.setFieldValue("assignedPage", {
            id: updateData?.assignedPage?.id || "",
            type: updateData?.assignedPage?.pageType || "",
        });
        setAssignedQualification(updateData?.assignedPage)
    }, [updateData])

    useEffect(() => {
        if (!props.updateMenuItemModal) {
            formik.resetForm()
        }
    }, [props.updateMenuItemModal])

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12">
                        <div className="card card-profile-info-card course-details-tab-sec border-0" style={{ padding: "30px" }}>
                            <div className="course-info-sec">
                                <div className="edit-icon new-card-header">
                                    <div className="card-header">
                                        Menu Information
                                    </div>
                                </div>
                                <div className="card-body-inr card-body-info">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-icon-group mb-4">
                                                <label>Menu Item Alias *</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${formik.errors.MenuItemAlias && formik.touched.MenuItemAlias && "is-invalid"}`}
                                                    name="MenuItemAlias"
                                                    title="MenuItemAlias"
                                                    placeholder="Menu Item Alias"
                                                    value={formik.values.MenuItemAlias}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.errors.MenuItemAlias && formik.touched.MenuItemAlias && (
                                                    <span className="exclamation">
                                                        <i className="fal fa-exclamation-circle"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group-blk mb-4" title="MenuItemResourceType">
                                                <label>Menu Item Resource Type * <i className="fal fa-info-circle"></i></label>
                                                <Select
                                                    className={"form-control custom-select-box" + (formik.errors.MenuItemTypeID && formik.touched.MenuItemTypeID ? " is-invalid" : "")}
                                                    name="MenuItemTypeID"
                                                    value={MenuItemDetails?.find(item => item.value === formik.values.MenuItemTypeID)}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            formik.setFieldValue("MenuItemTypeID", value.value);
                                                            formik.setFieldValue("CategoryHeading", '');
                                                            formik.setFieldValue("MenuItemLinkURL", '');


                                                        } else {
                                                            formik.setFieldValue("MenuItemTypeID", "");
                                                        }
                                                    }}
                                                    placeholder={"Select..."}
                                                    onBlur={formik.handleBlur}
                                                    options={MenuItemDetails}
                                                    maxMenuHeight={175}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group-blk mb-4" title="MenuItemResourceType">
                                                <label>Status * <i className="fal fa-info-circle"></i></label>
                                                <Select
                                                    className={"form-control custom-select-box" + (formik.errors.MenuItemStatus && formik.touched.MenuItemStatus ? " is-invalid" : "")}
                                                    name="MenuItemStatus"
                                                    value={MenuDetails?.menusItemStatus?.find(item => item.value === formik.values.MenuItemStatus)}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            formik.setFieldValue("MenuItemStatus", value.value);
                                                        } else {
                                                            formik.setFieldValue("MenuItemStatus", "");
                                                        }
                                                    }}
                                                    placeholder={formik.values.MenuItemStatus ? formik.values.MenuItemStatus : "Select..."}
                                                    onBlur={formik.handleBlur}
                                                    options={MenuDetails.menusItemStatus}
                                                    maxMenuHeight={175}
                                                    isClearable
                                                />

                                                {formik.errors.MenuItemStatus &&
                                                    formik.touched.MenuItemStatus && (
                                                        <span className="exclamation">
                                                            <i className="fal fa-exclamation-circle"></i>
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                        {formik.values.MenuItemTypeID === 2 &&
                                            <div className="col-md-6 col-lg-4">
                                                <div className="form-icon-group mb-4">
                                                    <label>Menu Item Title *</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.errors.MenuItemLabel && formik.touched.MenuItemLabel && "is-invalid"}`}
                                                        name="MenuItemLabel"
                                                        title="MenuItemLabel"
                                                        placeholder="Menu Item Title"
                                                        value={formik.values.MenuItemLabel}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.errors.MenuItemLabel && formik.touched.MenuItemLabel && (
                                                        <span className="exclamation">
                                                            <i className="fal fa-exclamation-circle"></i>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        {formik.values.MenuItemTypeID === 2 &&
                                            <div className="col-md-6 col-lg-8">
                                                <div className="form-group-blk mb-4">
                                                    <label>Menu Item URL <i className="fal fa-info-circle"></i></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.errors.MenuItemLinkURL && formik.touched.MenuItemLinkURL && "is-invalid"}`}
                                                        name="MenuItemLinkURL"
                                                        title="MenuItemLinkURL"
                                                        placeholder="Menu Item URL"
                                                        value={formik.values.MenuItemLinkURL}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.errors.MenuItemLinkURL && formik.touched.MenuItemLinkURL && (
                                                        <span className="exclamation">
                                                            <i className="fal fa-exclamation-circle"></i>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        {formik.values.MenuItemTypeID === 4 &&
                                            <div className="col-md-6 col-lg-4">
                                                <div className="form-icon-group mb-4">
                                                    <label>Heading*</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.errors.CategoryHeading && formik.touched.CategoryHeading && "is-invalid"}`}
                                                        name="CategoryHeading"
                                                        title="Heading"
                                                        placeholder="Short Courses"
                                                        value={formik.values.CategoryHeading}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.errors.CategoryHeading && formik.touched.CategoryHeading && (
                                                        <span className="exclamation">
                                                            <i className="fal fa-exclamation-circle"></i>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        {formik.values.MenuItemTypeID === 6 &&
                                            <div className="col-md-6 col-lg-4">
                                                <div className="form-icon-group mb-4">
                                                    <label>Override Page Title</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.errors.MenuItemOverridePageTitle && formik.touched.MenuItemOverridePageTitle && "is-invalid"}`}
                                                        name="MenuItemOverridePageTitle"
                                                        title="Override Page Title"
                                                        placeholder="Short Courses"
                                                        value={formik.values.MenuItemOverridePageTitle}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.errors.MenuItemOverridePageTitle && formik.touched.MenuItemOverridePageTitle && (
                                                        <span className="exclamation">
                                                            <i className="fal fa-exclamation-circle"></i>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        {formik.values.MenuItemTypeID === 6 &&
                                            <AssignPageField name="assignedPage" label="Assigned Page*" formik={formik} dataTarget="#assignpagePopupupdate" assignedPage={assignedQualification}
                                                setAssignedPage={setAssignedQualification} />
                                        }

                                        {
                                            formik.values.MenuItemTypeID === 3 &&
                                            <div className="col-md-6 col-lg-4">
                                                <div className="form-group-blk mb-4">
                                                    <label>Available Sub-Menus* <i className="fal fa-info-circle"></i></label>
                                                    <Select
                                                        className={"form-control custom-select-box" + (formik.errors.SubMenuID && formik.touched.SubMenuID ? "is-invalid" : "")}
                                                        name="SubMenuID"
                                                        value={SubMenuItemDetails?.find(item => item.value === formik.values.SubMenuID)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                formik.setFieldValue("SubMenuID", value.value);
                                                            } else {
                                                                formik.setFieldValue("SubMenuID", "");
                                                            }
                                                        }}
                                                        placeholder={"Select..."}
                                                        onBlur={formik.handleBlur}
                                                        options={SubMenuItemDetails}
                                                        maxMenuHeight={175}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {formik.values.MenuItemTypeID === 1 &&
                                            <div className="col-md-6 col-lg-4">
                                                <div className="form-group-blk mb-4">
                                                    <label>Available Categories <i className="fal fa-info-circle"></i></label>
                                                    <Select
                                                        className={"form-control custom-select-box" + (formik.errors.MenuItemCategory && formik.touched.MenuItemCategory ? " is-invalid" : "")}
                                                        name="MenuItemCategory"
                                                        value={MenuDetails.availableCategory?.find(item => item.value === formik.values.MenuItemCategory)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                formik.setFieldValue("MenuItemCategory", value.value);
                                                            } else {
                                                                formik.setFieldValue("MenuItemCategory", "");
                                                            }
                                                        }}
                                                        placeholder={"Select..."}
                                                        onBlur={formik.handleBlur}
                                                        options={MenuDetails.availableCategory}
                                                        maxMenuHeight={175}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {formik.values.MenuItemTypeID === 1 &&
                                            <div className="col-md-6 col-lg-8">
                                                <div className="form-group-blk mb-4">
                                                    <label>Override Category Heading <i className="fal fa-info-circle"></i></label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${formik.errors.CategoryHeading && formik.touched.CategoryHeading && "is-invalid"}`}
                                                        name="CategoryHeading"
                                                        title="CategoryHeading"
                                                        placeholder="Override Category Heading"
                                                        value={formik.values.CategoryHeading}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.errors.CategoryHeading && formik.touched.CategoryHeading && (
                                                        <span className="exclamation">
                                                            <i className="fal fa-exclamation-circle"></i>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        {formik.values.MenuItemTypeID === 7 &&
                                            <p className="ml-3">
                                                <i className="fal fa-info-circle"></i> Note: Submenus can not be placed within a menu that uses columns
                                            </p>
                                        }

                                        {
                                            formik.values.MenuItemTypeID === 4 &&
                                            <p className="ml-3 mt-5">
                                                <i className="fal fa-info-circle"></i> Note: Submenus can not be placed within a menu that uses static headings
                                            </p>
                                        }
                                    </div>

                                    {formik.values.MenuItemTypeID !== 7 && formik.values.MenuItemTypeID !== 4 &&
                                        <>
                                            <hr />
                                            <div className="course-sec-gap course-resource-sec">
                                                <div className="edit-icon new-card-header">
                                                    <div className="card-header">
                                                        <i className="fal fa-cog"></i>&nbsp;
                                                        Menu Item Settings
                                                    </div>
                                                </div>

                                                <div className="card-body-inr">
                                                    <div className="row">
                                                        {!formik.values.MenuItemOverrideColor ?
                                                            <div className="col-md-6 col-lg-2">
                                                                <div className="form-icon-group mb-4" title="MenuItemColour">
                                                                    <label>Menu Item Colour</label>
                                                                    <Select
                                                                        className={`form-control custom-select-box ${formik.errors.MenuItemColor && formik.touched.MenuItemColor && "is-invalid"}`}
                                                                        name="MenuItemColor"
                                                                        value={menuColor.filter(val => formik.values.MenuItemColor.includes(val.value.toString()))}
                                                                        onChange={(value) => {
                                                                            if (value) {
                                                                                formik.setFieldValue("MenuItemColor", value.value);
                                                                            } else {
                                                                                formik.setFieldValue("MenuItemColor", "");
                                                                            }
                                                                        }}
                                                                        isClearable
                                                                        onBlur={formik.handleBlur}
                                                                        options={menuColor}
                                                                        maxMenuHeight={175}
                                                                        placeholder={"Select..."}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <>


                                                                {[
                                                                    { label: "Menu Item Colour", name: "MenuItemColor", color: true, required: true, type: "color", },
                                                                ].map((field, index) => (
                                                                    <FormField key={index} field={field} formik={formik} col_md={6} col_lg={2} />
                                                                ))}

                                                                {formik.errors.MenuItemColor &&
                                                                    formik.touched.MenuItemColor ? (
                                                                    <span className="exclamation">
                                                                        <i className="fal fa-exclamation-circle"></i>{" "}
                                                                    </span>
                                                                ) : null}

                                                            </>
                                                        }

                                                        <div className="col-md-6 col-lg-4 d-flex" style={{ marginTop: 37 }}>
                                                            <label className="mb-0">
                                                                Override Colour Variable <i className="fal fa-info-circle"></i>
                                                            </label>
                                                            <div className="toggle-switch ml-2" title="Override Colour Variable">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="MenuOverrideColor"
                                                                        checked={formik.values.MenuItemOverrideColor == 1}
                                                                        onChange={(e) => {
                                                                            formik.setFieldValue(
                                                                                "MenuItemOverrideColor",
                                                                                e.target.checked ? 1 : 0
                                                                            )
                                                                            if (e.target.checked) {
                                                                                formik.setFieldValue("MenuItemColor", "#000")
                                                                            } else {
                                                                                formik.setFieldValue("MenuItemColor", "")

                                                                            }
                                                                        }}

                                                                    />
                                                                    <span className="slider slider-round"></span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-4 d-flex" style={{ marginTop: 37 }}>
                                                            <label className="mb-0">
                                                                Use Menu Item Icon <i className="fal fa-info-circle"></i>
                                                            </label>
                                                            <div className="toggle-switch ml-2" title="Use Menu Item Icon">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="isUseMenuIcon"
                                                                        checked={formik.values.isUseMenuIcon == 1}
                                                                        onChange={(e) => {
                                                                            formik.setFieldValue("isUseMenuIcon", e.target.checked ? 1 : 0)
                                                                            formik.setFieldValue("MenuIcon", "")
                                                                        }}
                                                                    />
                                                                    <span className="slider slider-round"></span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {formik.values.isUseMenuIcon == 1 && <FileUploadField
                                                            title="Menu Icon"
                                                            name="MenuIcon"
                                                            label="Menu Icon *"
                                                            formik={formik}
                                                            accept=".png,.jpg,.jpeg"
                                                            col_md={6}
                                                            col_lg={2}
                                                        />}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="form-group form-group-save-cancel">
                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                    disabled={loading ? true : false}
                                >
                                    {loading === false ? (
                                        <i className="fal fa-save"></i>
                                    ) : (
                                        <i className="fas fa-cog fa-spin"></i>
                                    )}
                                    Save
                                </button>
                                <button
                                    className="btn btn-close btn-danger"
                                    type="button"
                                    title="Cancel"
                                    onClick={() => {
                                        formik.resetForm()
                                        props.setUpdateMenuItemModal(false)
                                    }}
                                >
                                    <i className="fal fa-times"></i>
                                    Cancel
                                </button>
                            </div>

                            {Object.keys(formik.errors).map((key) => {
                                if (formik.touched[key]) {
                                    return (
                                        <div className="invalid-feedback d-block" key={key}>
                                            {formik.errors[key]}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>
            </form>

            <div
                className="topic-add-modal modal able_modal01 fade"
                id="assignpagePopupupdate"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered available-assessment-modal"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title">
                                <i className="fal fa-file"></i> Assign Page
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                dataTarget="#assignQualificationPopup"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <AssignLandingPagePopup page={props.page} popDataTarget={"#assignQualificationPopup"} formik={formik} id={props.id} assignedPage={assignedQualification} setAssignedPage={setAssignedQualification} assignedQualificationRef={assignedQualificationRef} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateMenuItemsMenu;
