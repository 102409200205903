import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/StudentAdministration/Students/AuditTrail";
import CategoryLandingPagesTable from "../components/pages/CategoryLandingpages/CategoryLandingPagesTable";
import { useSelector } from "react-redux";
import hasPermission from "../utils/hasMultiplePermission";
import EventAuditTrail from "../components/StudentAdministration/Students/EventAuditTrail";
import { getCategoryPagesAuditTrailList, getCategoryPagesAuditTrailListFilters } from "../services/WebsiteListServer";
// This will be shown in merge-all branch

function CategoryLandingpages() {
    const history = useHistory();
    const { tab } = useParams();
    const givenPermission = useSelector(state => state.permission)
    useEffect(
        () => {
            let response = hasPermission({
                scopes: ["wbscategorylpageview"],
                permissions: givenPermission,
            });
            if (!response) {
                history.push("/noaccess");
            }
        }, []
    )

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradebook" ? "bottom-100" : "")}>
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center" title="School Landing Pages">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {/* Student <span>List</span> */}
                                                School<span> Landing Pages</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {(tab === "table" || tab === "auditTrail") && (
                                        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/categorylandingpages/table`)}>
                                                <Link
                                                    className={`nav-link ${tab === "table" && "active"}`}
                                                    id="pills-tab1"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/categorylandingpages/table`}
                                                    role="tab"
                                                    aria-controls="pills-tab11"
                                                    aria-selected="true"
                                                    title="School Landing Pages"
                                                >
                                                    <i className="fa fa-file"></i>
                                                    School Landing Pages
                                                </Link>
                                            </li>
                                            <li className="nav-item" onClick={() => history.push(`/website_management/pages/categorylandingpages/auditTrail`)}>
                                                <Link
                                                    className={`nav-link ${tab === "auditTrail" && "active"}`}
                                                    id="pills-tab2"
                                                    data-toggle="pill"
                                                    to={`/website_management/pages/categorylandingpages/auditTrail`}
                                                    role="tab"
                                                    aria-controls="pills-tab12"
                                                    aria-selected="true"
                                                    title="Audit trail"
                                                >
                                                    <i className="fal fa-history"></i>Audit trail
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "table" && <CategoryLandingPagesTable />}
                                            {tab == "auditTrail" && <EventAuditTrail apiParams={''} apiFunction={getCategoryPagesAuditTrailList} apiFilter={getCategoryPagesAuditTrailListFilters} exportName={"School Course Category Landing Pages"} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryLandingpages;
