import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ListOfPartners from "../components/website/partners/ListOfPartners";
import AuditTrail from "../components/website/partners/AuditTrails";
import { Link, useHistory } from "react-router-dom";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const PartnerManagement = () => {
  const [activeTab, setActiveTab] = useState("partners"); // State to control the active tab
  const history = useHistory();

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Change the active tab
  };

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Partner <span>Management</span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${activeTab === "partners" ? "active" : ""}`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to="#pills-tab1"
                        role="tab"
                        aria-controls="pills-tab1"
                        aria-selected={activeTab === "partners"}
                        onClick={() => handleTabChange("partners")}
                      >
                        <i className="fal fa-users"></i>
                        Partners
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${activeTab === "audit" ? "active" : ""}`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to="#pills-tab2"
                        role="tab"
                        aria-controls="pills-tab2"
                        aria-selected={activeTab === "audit"}
                        onClick={() => handleTabChange("audit")}
                      >
                        {GlobalIconNames("audittrail").html}
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {activeTab === "partners" && (
                      <div className="tab-pane fade active show" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab1">
                        <ListOfPartners />
                      </div>
                    )}
                    {activeTab === "audit" && (
                      <div className="tab-pane fade active show" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab2">
                        <AuditTrail />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerManagement;
