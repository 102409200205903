import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import { TrimText } from "../../common/TrimText";
import AuditTrail from "../../courses/Programs/AuditTrail";
import DetailsOfPrograms from "../../courses/Programs/DetailsOfPrograms";
import ProgrammeGradeBookTable from "../../courses/Programs/ProgrammeGradeBookTable";
import { GetProgramsDetailsCancelToken } from "../../../services/ProgrammeServices";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import { GetStudentDetails, GetStudentDetailsCancelToken } from "../../../services/StudentsSettingServices";
import axios from "axios";
import Swal from "sweetalert2";
import ProgrammeGrademixGradeBookTable from "../../courses/Programs/ProgrammeGrademixGradeBookTable";
import Program from "../../pmc/program/Program";
import ProgrammePage from "./ProgrammePage";
import ProgrammePageDetails from "./ProgrammePageDetails";
import ProgrammeContent from "./ProgrammeContent";
import ProgrammeDates from "./ProgrammeDates";
import PageCustomisation from "./PageCustomisation";
import ShortCoursePage from "./ShortCoursePage";
import ShortCoursePageDetails from "./ShortCoursePageDetails";
import { ta } from "date-fns/locale";
import ShortCourseContent from "./ShortCourseContent";
import ShortCoursePageCustomisation from "./ShortCoursePageCustomisation";
import { GetProgrammePageDetailCancelToken } from "../../../services/ProgramService";
import CourseDate from "./CourseDate";
import EventAuditTrail from "../../StudentAdministration/Students/EventAuditTrail";
import { getProgrammeAndShortCoursesAuditTrailList, getProgrammeAndShortCoursesAuditTrailListFilters } from "../../../services/WebsiteListServer";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";



const ProgrammeShortCourse = () => {
  const history = useHistory();
  const location = useLocation();
  let currentTabValue = location?.pathname?.split("/");
  const { tab, type, id, subTab, subId } = useParams();
  const [programmeData, setProgrammeData] = useState({ course: "" });
  const [studentName, setStudentName] = useState("")
  const [studentData, setStudentData] = useState({})
  const [timeTableDoc, setTimeTableDoc] = useState();
  const [timeTableDocName, setTimeTableDocName] = useState("");
  const [refresh, setRefresh] = useState(false)
  const [pageData, setPageData] = useState([])
  const [headerName, setHeaderName] = useState("");
  const [loading, setLoading] = useState(false)
  const [updateData, setUpdateData] = useState(Date.now());
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [pageDomain, setPageDomain] = useState("https://next.aie.ac");
  const [pageTypeUrl, setPageTypeUrl] = useState("detailedQualification");
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  const apiParams = {
    action_id: subId || id,
  }
  useEffect(() => {
    const cancelTokenSources = [];
    
    const getStudenDetails = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      
      try {
        const res = await GetStudentDetailsCancelToken(subTab, source.token);
        if (res.status === 200) {
          setStudentData(res.data?.data_list)
          setStudentName(res.data?.data_list?.first_name + " " + (res.data?.data_list?.last_name ? res.data?.data_list?.last_name : ""));
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (subTab) {
      getStudenDetails();
    }
    
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [subTab, type, id]);
  
  useEffect(() => {
    const cancelTokenSources = [];
    if (type == "open" || type == "duplicate") {
      const getProgrammeDetails = async () => {
        
        cancelTokenSources.forEach(source => {
          source.cancel('New request made');
        });
        
        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);
        try {
          const res = await GetProgramsDetailsCancelToken(id, source.token);
          if (res.status === 200) {
            if (res.data) {
              setProgrammeData(res?.data?.programs);
              setTimeTableDoc(res?.data?.programs?.timeTable);
              setTimeTableDocName(res?.data?.programs?.timeTable)
            }
          } else {
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
            history.push("/courseAdministration/Programmes/programme/show")
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      };
      
      getProgrammeDetails();
    } else {
      setProgrammeData({ course: "" });
      setTimeTableDoc();
      setTimeTableDocName("")
    }
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [subTab, type, id, refresh, updateData]);
  
  
  useEffect(() => {
    let response1 = hasPermission({
      scopes: ["wbspagespmcpview"],
      permissions: givenPermsisions,
    });
    if (!response1 && tab == "programme_pages") {
      history.push("/noaccess");
    }
    
    let response2 = hasPermission({
      scopes: ["wbspagespmcsview"],
      permissions: givenPermsisions,
    });
    if (!response2 && tab !== "programme_pages") {
      history.push("/noaccess");
    }
    
    const cancelTokenSources = [];
    let isMounted = true;  // Flag to track if the component is still mounted
    const source = axios.CancelToken.source();
    cancelTokenSources.push(source);
    
    const getData = async () => {
      setLoading(true);
      try {
        const res = await GetProgrammePageDetailCancelToken({ id }, source.token);
        if (res.status === 200) {
          setPageData(res.data.data);
          let brandIds = res?.data?.data?.brand_ids;
          if (brandIds) {
            //check if brand_ids contain 1
            if (brandIds.includes(1)) {
              setPageDomain("https://next.aie.ac");
            } else if (brandIds.includes(4)) {
              setPageDomain("https://next.designcenter.co.za");
            } else if(brandIds.includes(3)) {
              setPageDomain("https://next.emendy.co.za");
            } else if(brandIds.includes(2)) {
              setPageDomain("https://next.learnfast.co.za");
            } else if(brandIds.includes(6)) {
              setPageDomain("https://next.cadco.co.za");
            }
          }else{
            setPageDomain("https://next.aie.ac");
          }
          let pageType = res?.data?.data?.page_type;
          if(pageType == 0){
            setPageTypeUrl('detailedQualification');
          }else{
            setPageTypeUrl('detailedShortCourse');
          }
        }
        setHeaderName(res.data?.data?.name);
      } catch (error) {
        if (error.response?.data?.message !== "") {
          Swal.fire({
            icon: "info",
            title: "Info",
            text: "No record found with this id",
          })
          history.push(`${tab !== "short_course_page" ? '/websiteManagement/pages/programmes_and_short_courses/programme_pages/table' : '/websiteManagement/pages/programmes_and_short_courses/short_course_page/table'}`)
        }
      } finally {
        if (isMounted) setLoading(false);
      }
    };
    
    if ((id && (type == "update" || type == "open")) && (tab == "programme_pages" ? response1 : response2)) {
      getData();
    }
    
    return () => {
      isMounted = false; // Clean up flag
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted or new request made');
      });
    };
  }, [id, updateData]);
  
  
  return (
    <>
    <Header></Header>
    <div className="content-wrapper d-flex flex-wrap">
    <SideBar></SideBar>
    <div className={"sidebar-right " + (currentTabValue[3] === "programme" && (currentTabValue[6] === "grade" || currentTabValue[6] === "gradebook") && currentTabValue[7] === "table" ? "bottom-100" : "")}>
    <div className="sub-menu-content-block">
    {/* <Tabs /> */}
    <div className="sub-menu-right-block">
    <div className="wrap-box-right">
    <div className="my-tickets-heading-blk flex-direction mb-2">
    <div className="d-flex align-items-center ">
    <div className="name-w-head d-flex align-items-center ">
    <h4 className="text-left-align landing-heading heading_color_dynamic">
    {(tab == "programme_pages" || tab == "short_course_page" || tab == "auditTrail") && subTab == "table" && <>Programme <span>& Courses</span> </>}
    {tab == "programme_pages" && (type == "open" || type == "create" || type == "duplicate") && "Programme Pages: "}
    {tab == "short_course_page" && (type == "open" || type == "create" || type == "duplicate") && "Course Pages: "}
    {(tab == "programme_pages" || tab == "short_course_page") && type == "create" ? <span>Create New</span> : (type == "open" || type == "duplicate") ? <span>{headerName}</span> : ""}
    {/* {tab == "programme_pages" && type == "open" && <span></span> : headerName} */}
    </h4>
    </div>
    <div className="text-md-right action2-blk">
    <div className="ticket-view-btn d-flex align-items-center">
    <div className="add-new-ticket-bx">
    <button
    onClick={() => history.goBack()}
    title="Back"
    className="btn btn-white-bordered"
    >
    <i className="fal fa-angle-left"></i>Back
    </button>
    </div>
    </div>
    </div>
    {pageData?.slug && <div className="text-md-right action2-blk">
      <div className="ticket-view-btn d-flex align-items-center">
      <div className="add-new-ticket-bx">
      <a
      href={`${pageDomain}/${pageTypeUrl}/${encodeURIComponent(encodeURIComponent(pageData?.slug))}`}
      title="review"
      target="_blank"
      className="btn btn-white-bordered"
      >
      <i className="fal fa-eye"></i>Preview
      </a>
      </div>
      </div>
      </div>
    }
    </div>
    </div>
    <div className="tabs-wrap">
    {(((type !== "create" && type == "open") || subTab == "table") && type !== "duplicate") && <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
      {subTab == "table" && <>
        <PermissionsGate scopes={['wbspagespmcpview']} RenderError={() => null}>
        <li
        className="nav-item"
        onClick={() =>
          history.push(
            type == "open"
            ? `/courseAdministration/Programmes/programme/open/${id}/details`
            : `/websiteManagement/pages/programmes_and_short_courses/programme_pages/table`
          )
        }
        >
        <a
        className={`nav-link cursor-pointer ${tab === "programme_pages" ? "active" : ""
        }`}
        id="pills-tab1"
        data-toggle="pill"
        role="tab"
        aria-controls="pills-tab11"
        aria-selected="true"
        >
        {GlobalIconNames("Programme Pages", "fal fa-chalkboard", "Programme Pages").html}
        </a>
        </li>
        </PermissionsGate>
        
        <PermissionsGate scopes={['wbspagespmcsview']} RenderError={() => null}>
        <li
        className="nav-item"
        onClick={() =>
          history.push(
            type == "open"
            ? `/courseAdministration/Programmes/programme/open/${id}/details`
            : `/websiteManagement/pages/programmes_and_short_courses/short_course_page/table`
          )
        }
        >
        <a
        className={`nav-link cursor-pointer ${tab == "short_course_page" ? "active" : ""
        }`}
        id="pills-tab1"
        data-toggle="pill"
        role="tab"
        aria-controls="pills-tab11"
        aria-selected="true"
        >
        {GlobalIconNames("Course Pages", "fal fa-laptop", "Course Pages").html}
        </a>
        </li>
        </PermissionsGate>
        
        <li
        className="nav-item"
        onClick={() =>
          history.push(
            type == "open"
            ? `/courseAdministration/Programmes/singleAuditTrail/open/${id}`
            : `/websiteManagement/pages/programmes_and_short_courses/auditTrail/table`
          )
        }
        >
        <a
        className={`nav-link cursor-pointer ${tab === "auditTrail"
          ? "active"
          : ""
        }`}
        id="pills-tab2"
        data-toggle="pill"
        role="tab"
        aria-controls="pills-tab12"
        aria-selected="true"
        >
        {GlobalIconNames("audittrail").html}
        </a>
        </li>
        </>}
        {(tab == "programme_pages" || tab == "short_course_page") && type == "open" && <>
          <li
          className="nav-item"
          onClick={() =>
            history.push(
              tab == "programme_pages" && type == "open" ?
              `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${id}` :
              `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${id}`
            )
          }
          >
          <a
          className={`nav-link cursor-pointer ${(tab === "programme_pages" || tab == "short_course_page") && subTab == "details"
            ? "active"
            : ""
          }`}
          id="pills-tab2"
          data-toggle="pill"
          role="tab"
          aria-controls="pills-tab12"
          aria-selected="true"
          >
          {GlobalIconNames("Details").html}
          </a>
          </li>
          
          <li
          className="nav-item"
          onClick={() =>
            history.push(
              tab == "programme_pages" && type == "open" ?
              `/websiteManagement/pages/programmes_and_short_courses/programme_pages/content/open/${id}` :
              `/websiteManagement/pages/programmes_and_short_courses/short_course_page/content/open/${id}`
            )
          }
          >
          <a
          className={`nav-link cursor-pointer ${(tab === "programme_pages" || tab == "short_course_page") && subTab == "content"
            ? "active"
            : ""
          }`}
          id="pills-tab2"
          data-toggle="pill"
          role="tab"
          aria-controls="pills-tab12"
          aria-selected="true"
          >
          {GlobalIconNames("Content").html}
          </a>
          </li>
          
          <li
          className="nav-item"
          onClick={() =>
            history.push(
              tab == "programme_pages" && type == "open" ?
              `/websiteManagement/pages/programmes_and_short_courses/programme_pages/page_customisation/open/${id}` :
              `/websiteManagement/pages/programmes_and_short_courses/short_course_page/page_customisation/open/${id}`
            )
          }
          >
          <a
          className={`nav-link cursor-pointer ${(tab === "programme_pages" || tab == "short_course_page") && subTab == "page_customisation"
            ? "active"
            : ""
          }`}
          id="pills-tab2"
          data-toggle="pill"
          role="tab"
          aria-controls="pills-tab12"
          aria-selected="true"
          >
          {GlobalIconNames("Page Customisation").html}
          </a>
          </li>
          {tab == "programme_pages" && <li
            className="nav-item"
            onClick={() =>
              history.push(
                `/websiteManagement/pages/programmes_and_short_courses/programme_pages/programme_dates/open/${id}`
              )
            }
            >
            <a
            className={`nav-link cursor-pointer ${tab == "programme_pages" && subTab == "programme_dates"
              ? "active"
              : ""
            }`}
            id="pills-tab2"
            data-toggle="pill"
            role="tab"
            aria-controls="pills-tab12"
            aria-selected="true"
            >
            {GlobalIconNames("Programme Dates").html}
            </a>
            </li>}
            
            {tab == "short_course_page" && <li
              className="nav-item"
              onClick={() =>
                history.push(
                  `/websiteManagement/pages/programmes_and_short_courses/short_course_page/course_dates/open/${id}`
                )
              }
              >
              <a
              className={`nav-link cursor-pointer ${tab === "short_course_page" && subTab == "course_dates"
                ? "active"
                : ""
              }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
              >
              {GlobalIconNames("course_dates").html}
              </a>
              </li>}
              
              <li
              className="nav-item"
              onClick={() =>
                history.push(
                  tab == "programme_pages" && type == "open" ?
                  `/websiteManagement/pages/programmes_and_short_courses/programme_pages/auditTrail/open/${id}` :
                  `/websiteManagement/pages/programmes_and_short_courses/short_course_page/auditTrail/open/${id}`
                )
              }
              >
              <a
              className={`nav-link cursor-pointer ${(tab == "auditTrail" || subTab == "auditTrail")
                ? "active"
                : ""
              }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
              >
              {GlobalIconNames("audittrail").html}
              </a>
              </li>
              </>}
              </ul>}
              
              <div className="tab-content" id="pills-tabContent">
              <div
              className="tab-pane fade active show"
              id="pills-tab11"
              role="tabpanel"
              aria-labelledby="pills-tab1"
              >
              {/* {((tab === "programme" || tab == "singleAuditTrail") &&
                !currentTabValue.includes("gradebook")) && (
                <DetailsOfPrograms
                programmeDetails={programmeData}
                programmeName={
                programmeData && programmeData.course
                }
                setProgrammeData={setProgrammeData}
                timeTableDoc={timeTableDoc}
                timeTableDocName={timeTableDocName}
                setTimeTableDoc={setTimeTableDoc}
                setTimeTableDocName={setTimeTableDocName}
                studentDetails={studentData}
                refresh={refresh}
                setRefresh={setRefresh}
                />
                )} */}
                {tab == "programme_pages" && subTab == "table" && <ProgrammePage />}
                {tab == "short_course_page" && subTab == "table" && <ShortCoursePage />}
                
                {(tab == "programme_pages" && subTab == "details" && type == "create") && <ProgrammePageDetails />}
                {(tab == "programme_pages" && subTab == "details" && type == "open") && <ProgrammePageDetails pageData={pageData} pageLoading={loading} setUpdateData=
                {setUpdateData} />}
                {(tab == "programme_pages" && subTab == "details" && type == "duplicate") && <ProgrammePageDetails pageData={pageData} pageLoading={loading} setUpdateData=
                {setUpdateData} />}
                
                {(tab == "programme_pages" && subTab == "content" && type == "create") && <ProgrammeContent />}
                {(tab == "programme_pages" && subTab == "content" && type == "open") && <ProgrammeContent setUpdateData={setUpdateData} detail={pageData} updateData={updateData} />}
                
                {(tab == "programme_pages" && subTab == "page_customisation" && type == "create") && <PageCustomisation />}
                {(tab == "programme_pages" && subTab == "page_customisation" && type == "open") && <PageCustomisation />}
                {(tab == "programme_pages" && subTab == "programme_dates" && type == "open") && <ProgrammeDates />}
                
                {(tab == "short_course_page" &&  subTab == "details" && type == "create") && <ShortCoursePageDetails />}
                {(tab == "short_course_page" &&  subTab == "details" &&  type == "open") && <ShortCoursePageDetails pageData={pageData} pageLoading={loading} setUpdateData={setUpdateData}/>}
                {(tab == "short_course_page" &&  subTab == "details" &&  type == "duplicate") && <ShortCoursePageDetails pageData={pageData} pageLoading={loading} setUpdateData={setUpdateData}/>}
                
                {(tab == "short_course_page" && subTab == "content" && type == "open") && <ShortCourseContent detail={pageData} setUpdateData={setUpdateData} updateData={updateData} />}
                {(tab == "short_course_page" && subTab == "page_customisation" && type == "open") && <ShortCoursePageCustomisation />}
                {(tab == "short_course_page" && subTab == "course_dates" && type == "open") && <CourseDate />}
                {/* {tab == "auditTrail" && <AuditTrail />} */}
                {tab == "auditTrail" && <EventAuditTrail apiParams={''} apiFunction={getProgrammeAndShortCoursesAuditTrailList} apiFilter={getProgrammeAndShortCoursesAuditTrailListFilters} exportName={"Programme page audit trails"} />}
                
                {subTab == "auditTrail" && <AuditTrailsTable
                  apiFunction={getProgrammeAndShortCoursesAuditTrailList}
                  filterArrApi={getProgrammeAndShortCoursesAuditTrailListFilters}
                  columnsToShow={columnsToShow}
                  exportFileName={tab == "short_course_page" ? "course pages audit trails" : "Programme pages audit trails"}
                  isResourceNameShown={true}
                  apiParams={apiParams}
                  actionId={subId || id}
                  isShowingResourceTypeFilter
                  renderResourceTypeFunction={RenderStudentResourceType}
                  tabType={tab == "short_course_page" ? "Course" : "Programme"}
                  />}
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </>
                );
              };
              
              export default ProgrammeShortCourse;
              