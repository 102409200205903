import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission"
import HtmlInputEditor from "../../common/HtmlInputEditor";
import {
    getStaticPagesBrandList
} from "../../../services/StaticPgesServer";
import { getCampusLocationDropdown, getCampusPagesStatusList } from "../../../services/CampusPagesServer";
import { AddPopupPages, getAssigendPagesAndProgrammeShortcourse, GetPopupsUpdateList, updatePopupPages } from "../../../services/PopupsPagesServer";
import PopupAssignedProgrammes from "./assignedProgrammes/PopupAssignedProgrammes";
import axios from "axios";
import PermissionsGate from "../../../utils/permissionGate";

import { GetAllHeaderPMCpageList, getHeaderDropDownList, getHeadersPageList } from "../../../services/LayoutHeaderServices.js";
import AssignQualificationPopup from "../../layoutNavigation/layoutHeaders/AssignQualificationPopup.jsx";
import AssignPagePopup from "../../layoutNavigation/layoutHeaders/AssignPagePopup.jsx";
import { AssignPageField, FormField } from "../../common/FormFields.js";

const PopupsPagesOpt = ({ id, subId, subTab }) => {
    const history = useHistory();
    const { type } = useParams();
    const [updateData, setUpdateData] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [linkedCampusData, setLinkedCampusData] = useState([]);
    const [assignedRec, setAssignedRec] = useState([]);
    const [assignloading, setAssignLoading] = useState(false);
    const [toggle,setToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusArr, setStatusArr] = useState([]);
    const [refresh, setRefresh] = useState(Date.now())
    const [buttonTypeData, setbuttonTypeData] = useState([])
    const [assignedPopupPages, setAssignedPopupPages] = useState({ id: "", type: "" });
    const [assignedQualification, setAssignedQualification] = useState({ id: "", type: "" });
    const assignedQualificationRef = useRef(assignedQualification);
    const assignedPageRef = useRef(assignedPopupPages);
    const [headerData, setHeaderData] = useState([])
    const [Qualification, setQualification] = useState([])
    const [assignedPage, setAssignedPage] = useState([]);

    useEffect(() => {
        assignedPageRef.current = assignedPopupPages;
    }, [assignedPopupPages]);

    useEffect(() => {
        assignedQualificationRef.current = assignedQualification;
    }, [assignedQualification])
    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete?.map((data) => data?.brandID);

    useEffect(() => {
        const getProgramsList = async () => {
            setAssignLoading(true);

            try {
                let res = await getAssigendPagesAndProgrammeShortcourse(id, "0");

                if (res.status === 200) {
                    setAssignedRec(res.data && res?.data ? res?.data : []);
                    setAssignLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log("error:", error)
                    setAssignLoading(false)
                }
            }
        }

        getProgramsList()
    }, [id, refresh])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [CtaButtonData] = await Promise.all([
                    getHeaderDropDownList()
                ]);
         
                const ctaButtontypeFilterData = CtaButtonData.data?.headerButtonType
          
                setbuttonTypeData(ctaButtontypeFilterData);

            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        if (assignedQualification) {
            formik.setFieldValue("assignPage", {
                id: assignedQualification.id,
                type: assignedQualification.flag,
            });
        }
    }, [assignedQualification]);

    useEffect(() => {
        if (assignedPopupPages && assignedPopupPages.PageID) {
            formik.setFieldValue("assignPage", {
                id: assignedPopupPages.PageID,
                type: assignedPopupPages.flag,
            });
        }
    }, [assignedPopupPages]);
    useEffect(() => {
        if (assignedQualification) {
            formik.setFieldValue("assignPage", {
                id: assignedQualification.id,
                type: assignedQualification.flag,
            });
        }
    }, [assignedQualification]);
    useEffect(() => {
        console.log("updateData?.assignPage", updateData?.assignPage)
        if (updateData?.buttonType == 1) {

            formik.setFieldValue("assignPage", {
                id: updateData?.assignPage?.id || "",
                type: updateData?.assignPage?.flag || "",
            });
            setAssignedQualification(updateData?.assignPage)
        } else if (updateData?.buttonType == 0) {
            formik.setFieldValue("assignPage", {
                id: updateData?.assignPage?.id || "",
                type: updateData?.assignPage?.flag || "",
            });
            setAssignedPopupPages(updateData?.assignPage)
        }
    }, [updateData])
    console.log('updateData------>',updateData)
    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            getCampusPagesStatusList()
                .then(res => setStatusArr(res.data.publishedStatus))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getCampusLocationDropdown()
                .then(res => setLinkedCampusData(res.data.campusLocationFilters))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));

            getStaticPagesBrandList()
                .then(res => setBrandList(res.data.brandTypesFilters))
                .catch(error => console.error("Error fetching status data", error))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, [refresh]);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            PopUpID: "",
            PopUpName: "",
            IsDefault: 0,
            BodyContent: "",
            buttonText: "",
            buttonType: "",
            ButtonLink: "",
            checkCustomCss: 0,
            IsPublished: "",
            CustomCss: "",
            Brand: [],
            assignedPages: [],
            assignPage:[]
        },
        validationSchema: Yup.object({
            PopUpName: Yup.string().required("Title is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            BodyContent: Yup.string().required("Popup Content is required"),
            buttonText: Yup.string().required("Button Text is required"),
            // buttonType: Yup.string().required("Button Type is required"),
            // CustomCss: Yup.string().when('checkCustomCss', {
            //     is: (value) => value == 1,
            //     then: Yup.string().required("Custom CSS is required"),
            //     otherwise: Yup.string()
            // })
            buttonType: Yup.number().required("Button type is required"),
            assignPage: Yup.object().when('buttonType', {
                is: 1, // When buttonType is 1
                then: Yup.object().shape({
                    id: Yup.string().required("Assigned Qualification for Button is required"),
                    type: Yup.string().required("Assigned Qualification Type is required"),
                }),
                otherwise: Yup.object().when('buttonType', {
                    is: 0, // When buttonType is 0
                    then: Yup.object().shape({
                        id: Yup.string().required("Assigned Page for Button is required"),
                        type: Yup.string().required("Assigned Page Type is required"),
                    }),
                }),
            }),
            ButtonLink: Yup.string()
                .nullable() // Allows `ButtonLink` to be null if not needed
                .when("buttonType", {
                    is: 2, // When buttonType is 2
                    then: Yup.string()
                        .required("Custom Link is required")
                        .matches(/^(\/|https?:\/\/)/, "URL must start with / or a valid protocol"),
                }),
        }),
        onSubmit: async (values, actions) => {
            let temp = { ...values }
            // delete temp.assignPage
            console.log('temp----->',temp);
            if (temp.buttonType == 2) {
                delete temp.assignPage;
            }
            if (temp.buttonType == 1) {
                delete temp.ButtonLink;
            }
            if (temp.buttonType == 0) {
                delete temp.ButtonLink;
            }
            const formData = new FormData();

            // Trim and clean values, replace undefined or null with empty string
            const cleanedValues = Object.fromEntries(
                Object.entries(temp).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );
console.log('cleanedva ',cleanedValues)
            if (cleanedValues.assignPage && typeof cleanedValues.assignPage === 'object') {
                formData.append('assignPageId', values.assignPage.id || "");
                formData.append('assignPageType', values.assignPage.type ?? "");
            }
            delete cleanedValues.assignPage
            // Append values to FormData
            Object.keys(cleanedValues).forEach((key) => {
                console.log('bahsvdsdvaasdasd',key);
                
                const value = cleanedValues[key];
                if (key == "assignPage") {
                
                    formData.append(key, JSON.stringify(value));
                }
                if (Array.isArray(value)) {
                   
                    if (key === "assignedPages") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    }
                } else {
                    formData.append(key, value !== undefined && value !== null ? value : "");
                }
            });


            try {
                if (type === "open") {
                    await updatePopupPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated Successfully",
                    });
                    setToggle(!toggle)
                    setRefresh(Date.now())
                } else {
                    await AddPopupPages(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    setToggle(!toggle)
                    actions.resetForm();
                    history.push("/website_management/pages/popupspages/table");
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response?.data?.message || "An error occurred",
                });
            }
        }

    });


    useEffect(() => {
        const getUpdateData = () => {
            GetPopupsUpdateList(id)
                .then(res => setUpdateData(res?.data?.Data))
                .catch(err => {
                    if (type === 'open') {
                        Swal.fire({
                            title: "Info",
                            text: "No record found with this ID",
                        });
                        history.push(`/website_management/pages/popupspages/table`);
                    }
                })
        }

        { type === "open" && getUpdateData() }
    }, [id,toggle])
    useEffect(() => {
        let data = assignedRec.map((item) => ({
            id: item?.id,
            flag: item?.flag,
        }))
        formik.setFieldValue("assignedPages", data)
    }, [assignedRec])

    useEffect(() => {

        formik.setFieldValue("PopUpID", updateData?.PopUpID);
        formik.setFieldValue("PopUpName", updateData?.PopUpName);
        formik.setFieldValue("IsDefault", updateData?.IsDefault);
        formik.setFieldValue("BodyContent", updateData?.BodyContent);
        formik.setFieldValue("buttonText", updateData?.ButtonText);
        formik.setFieldValue("buttonType", updateData?.buttonType);
        formik.setFieldValue("ButtonLink", updateData?.ButtonLink);
        formik.setFieldValue("checkCustomCss", updateData?.checkCustomCss);
        formik.setFieldValue("IsPublished", type === "open" ? updateData?.IsPublished : '');
        formik.setFieldValue("CustomCss", updateData?.CustomCss);
        formik.setFieldValue("Brand", updateData?.brandTemplete?.map((item) => item.brandID) || []);



    }, [updateData, refresh]);
    const handleCallback = () => {
        formik.setFieldValue("assignPage", "")
        formik.setFieldValue("ButtonLink", "")
        setAssignedQualification({ id: "", type: "" })
        setAssignedPopupPages({ id: "", type: "" })

    }
    useEffect(() => {
        const assingedQualification = () => {
            GetAllHeaderPMCpageList(subTab == "update" && subId)
                .then(res => {
                    setQualification(res.data?.data)
                })
                .catch(err => console.log(err))
        }

        const assignedPage = () => {
            getHeadersPageList(subTab == "update" && subId)
                .then(res => {
                    setHeaderData(res.data?.data)
                })
                .catch(err => console.log(err))
        }
        if (formik.values.buttonType == 0) {
            assignedPage();
        }
        if (formik.values.buttonType == 1) {
            assingedQualification();
        }
    }, [formik.values.buttonType])
  
    
    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0" title="Popup Information">Popup Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Title">
                                    <label>Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.PopUpName && formik.touched.PopUpName
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Title"
                                        name="PopUpName"
                                        id="PopUpName"
                                        value={formik.values.PopUpName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandList.filter((data) => formik.values.Brand.includes(data.value))}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={brandList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select Assigned Brand Templates"}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.IsPublished && formik.touched.IsPublished
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={statusArr.find(val => val.value === formik.values.IsPublished)}
                                        onChange={(option) => {
                                            formik.setFieldValue("IsPublished", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={statusArr}
                                        maxMenuHeight={175}
                                        placeholder={"Select Status"}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center" title="Default Popup">
                                <label className="mb-0">
                                    Default Popup <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.IsDefault}
                                            onChange={(e) => formik.setFieldValue("IsDefault", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group-blk mb-3" title="Popup Content">
                                    <label>Popup Content*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.BodyContent}
                                        setEditorState={(editorState) => formik.setFieldValue('BodyContent', editorState)}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="new-card-header col-md-12">
                                <h4 className="card-header pt-0" title="Popup Settings">
                                    <i
                                        className="fal fa-cog"
                                        style={{ color: "var(--topbar-color)" }}
                                    ></i> Popup Settings
                                </h4>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                <div className="form-group-blk mb-3 col-12 col-md-4" title="Button Text">
                                    <label>Button Text*</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.buttonText && formik.touched.buttonText
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Button Text"
                                        name="buttonText"
                                        id="buttonText"
                                        value={formik.values.buttonText}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    </div>
                                 
                                <FormField
                                    field={{ name: "buttonType", label: "Button Type", required: true }}
                                    required={true}
                                    selectOptions={buttonTypeData}
                                    formik={formik}
                                    col_md={4} 
                                    callback={handleCallback}
                                />

                                {formik.values.buttonType === 2 ? (
                                    <FormField
                                        field={{ name: "ButtonLink", label: "Custom Link URL", required: false }}
                                        required={true}
                                        formik={formik}
                                        col_md={4} 
                                        placeholder="Enter Custom Link URL"
                                    />
                                ) : formik.values.buttonType === 0 ? (
                                    <AssignPageField
                                        name="assignPage"
                                        label="Assigned Page for Button*"
                                        formik={formik}
                                        dataTarget="#assignPagePopupBrandTemp"
                                        assignedPage={assignedPopupPages}
                                        setAssignedPage={setAssignedPopupPages}
                                        col_md={4} 
                                    />
                                ) : formik.values.buttonType === 1 ? (
                                    <AssignPageField
                                        name="assignPage"
                                        label="Assigned Qualification for Button*"
                                        formik={formik}
                                        dataTarget="#assignQualificationPopup"
                                        assignedPage={assignedQualification}
                                        setAssignedPage={setAssignedQualification}
                                        buttonName="Assign Qualification"
                                        col_md={4} 
                                    />
                                        ) : null}
                                  
                                </div></div>
                            <div className="col-md-6 col-lg-6">
                                <div className="row" title="Button Type">
                                    {/* <label>Button Type *</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.buttonType && formik.touched.buttonType
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="buttonType"
                                        value={linkedCampusData.find(val => val.value === formik.values.buttonType)}
                                        onChange={(option) => {
                                            formik.setFieldValue("buttonType", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={buttonTypeData}
                                        maxMenuHeight={175}
                                        placeholder={"Select Button Type"}
                                        isClearable
                                    /> */}
                               
                             

                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-4">
                                <div className="form-group-blk mb-3" title="Button URL">
                                    <label>Button URL</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.ButtonLink && formik.touched.ButtonLink
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Button URL"
                                        name="ButtonLink"
                                        id="ButtonLink"
                                        value={formik.values.ButtonLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Style Settings">
                                    <h4 className="card-header">
                                        <i
                                            className="fal fa-cog"
                                            style={{ color: "var(--topbar-color)" }}
                                        ></i> Style Settings
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3" title="Add Custom CSS">
                                <label className="mb-0">
                                    Add Custom CSS <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values.checkCustomCss}
                                            onChange={(e) => formik.setFieldValue("checkCustomCss", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                            {formik.values.checkCustomCss == 1 && <div className="col-md-12">
                                <div className=" mb-3 mt-3" title="Custom CSS">
                                    <label>Custom CSS*</label>
                                    <div className="html-editor-input">
                                        <textarea value={formik.values.CustomCss} className={`textarea bg-white form-control ${formik.touched.CustomCss && formik.errors.CustomCss && "is-invalid"}`} onBlur={formik.handleBlur} onChange={formik.handleChange} name="CustomCss"></textarea>
                                        <div className="headerf">
                                            <span className="title fw-bolds"> CSS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className="col-md-12">
                                <hr className="border border-2" style={{ borderColor: "rgb(230, 235, 241)" }} />
                            </div>
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1" title="Assigned Programmes & Pages">
                                    <h4 className="card-header">
                                        Assigned Programmes & Pages
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <PopupAssignedProgrammes
                                    id={id}
                                    assignedRec={assignedRec}
                                    setAssignedRec={setAssignedRec}
                                    assignloading={assignloading}
                                    setAssignLoading={setAssignLoading}
                                    refresh={refresh}
                                />
                            </div>

                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[type == 'create' ? 'wbspopuppageadd' : 'wbspopuppageedit']} RenderError={() => (
                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                    disabled
                                >
                                    <i className="fal fa-save"></i>
                                    Save
                                </button>
                            )}>

                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                    disabled={loading}
                                >
                                    {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                    Save
                                </button>
                            </PermissionsGate>

                            <button
                                className="btn btn-close btn-danger"
                                type="button"
                                title="Cancel"
                                onClick={() => {
                                    if (type === "open" || type === "update") {
                                        formik.resetForm();
                                        setRefresh(Date.now());
                                    } else {
                                        history.push("/website_management/pages/popupspages/table");
                                    }
                                }}
                            >
                                <i className="fal fa-times"></i>
                                Cancel
                            </button>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                const error = formik.errors[key];

                                // Check if it's the assignedPage error and handle it
                                if (key === 'assignPage') {
                                    return (
                                        <div className="invalid-feedback d-block" key={key}>
                                            {error.id ? error.id : error.type ? error.type : "Invalid input"}
                                        </div>
                                    );
                                }

                                // For other fields
                                const errorMessage = typeof error === 'string' ? error : error.message || "Invalid input";

                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {errorMessage}
                                    </div>
                                );
                            }
                            return null;
                        }
                        )}
                    </form>
                    <div
                        className="topic-add-modal modal able_modal01 fade"
                        id="assignPagePopupBrandTemp"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered available-assessment-modal"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="modal-header modal-header-custom">
                                    <h5 className="modal-title">
                                        <i className="fal fa-file"></i> Assign Page
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                {console.log('assignedPopupPages', assignedPopupPages , formik.values)}
                                <div className="modal-body">
                                    <AssignPagePopup headerData={headerData} formik={formik} id={subId} assignedPage={assignedPopupPages} assignedPageRef={assignedPageRef} setAssignedPage={setAssignedPopupPages} />
                                    {/* <AssignPagePopup headerData={headerData} formik={formik} id={subId} assignedPage={assignedPopupPages} assignedPageRef={assignedPageRef} setAssignedPage={setAssignedPopupPages} paginationServer={false} /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="topic-add-modal modal able_modal01 fade"
                        id="assignQualificationPopup"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered available-assessment-modal"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="modal-header modal-header-custom">
                                    <h5 className="modal-title">
                                        <i className="fal fa-file"></i> Assign Qualification
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <AssignQualificationPopup formik={formik} id={subId} assignedPage={assignedQualification} setAssignedPage={setAssignedQualification} assignedQualificationRef={assignedQualificationRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupsPagesOpt;
