import React, { useEffect, useState } from "react";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorRender } from "../../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../../common/Helper";
import { TrimText } from "../../../common/TrimText";
import { RenderQuizAttemptResultStatus } from "../../../../utils/CommonStatusItems";
import Hover from "../../../common/Hover";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import hasPermission from "../../../../utils/hasMultiplePermission";
import PermissionsGate from "../../../../utils/permissionGate";
import {
  getGradeMixOfPrograme,
  getProgrameGradeMixGradebook,
  updateProgameScore,
  updateProgrammeScore,
} from "../../../../services/GradeSettingService";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import GradeBookSaveBar from "../../../common/GradeBookSaveBar";
import ExcelJS from "exceljs";
import { RenderLearningMethodGradebook } from "../../../../utils/CommonGroupingItem";
import { getGradeMixAssessments, gradebookOfgradeMix } from "../../../../services/GradeMixService";
import axios from "axios";
let options = [
  { label: "Red", value: "0" },
  { label: "Yellow", value: "49" },
  { label: "Green", value: "100" },
];

const stdIds = [];

function GradeMixGradeList({ programmeName }) {
  const { id } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [userData, setUserData] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState({
    courseTable: false,
    assessmentHeader: false,
    assessmentTable: false,
  });
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [errorMessage, setErrorMessage] = useState("");
  const [openedCourse, setOpenedCourse] = useState({});
  const [assmentList, setAssmentList] = useState([]);
  const [percentageView, setPercentageView] = useState(false);
  const [assessmentHeader, setAssessmentHeader] = useState([]);
  const [paginationState, setPaginationState] = useState({
    from: 0,
    to: 0,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
  });
  const [tableState, setTableState] = useState({ page: 1, limit: 10 });
  const [updateData, setUpdateData] = useState(true);
  const [programmeGradeTitle, setProgrammeGradeTitle] = useState();
  const [oldValue, setOldValue] = useState();
  const [slider, setSlider] = useState();
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const [isProgrammeEdited, setIsProgrammeEdited] = useState(0);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["capgview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [givenPermsisions, history]);

  useEffect(() => {
    window.addEventListener("swallActive", function (e) {
      checkbtnValue(e.detail);
    });

    return () => {
      window.removeEventListener("swallActive", function (e) {
        checkbtnValue(e.detail);
      });
    };
  }, []);

  const shouldBlockNavigation = updateValueArr.length;

  const handleNavigation = (location,page, states) => {
    if (shouldBlockNavigation) {
      Swal.fire({
        title: "Warning",
        text: "You Have Unsaved Changes Left! Do you want to save changes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Save Changes",
        cancelButtonText: "No, Leave",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "Saving changes...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
              const values = {
                id: [id],
                course: updateValueArr,
              };
              updateProgameScore(values)
                .then((res) => {
                  setUpdateValueArr([]);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Updated Successfully",
                  }).then(() => {
                    if (location == "assClosing") {
                      let list = courseList;
                      list = list?.map((item) => {
                        if (item?.id === page)
                          return {
                            ...item,
                            assHeader: [],
                            assList: [],
                            isOpen: false,
                          };
                        return item;
                      });
                      setCourseList(list);
                      setAssessmentHeader([]);
                    } else if (location) {
                      // window.location.assign(location.pathname); // Redirect to the desired location
                      history.push(location.pathname); // Redirect to the desired location
                    } else if(!location && page){
                      if (page == "exempted") {
                        // This is not in use from when exemption is also send like marks in save api
                        // handleExemption(...states)
                      } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page:
                          page === "firstPage"
                            ? 1
                            : page === "prevPage"
                            ? paginationState.currentPage - 1
                            : page === "nextPage"
                            ? +paginationState.currentPage + 1
                            : paginationState.lastPage,
                      }));
                    }
                    }
                  });
                })
                .catch((err) => {
                  Swal.close(); // Close the "Saving changes..." popup if there was an error
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data?.data}`,
                  });
                });
            },
          });
        } else {
          setUpdateValueArr([]);
          if (location == "assClosing") {
            let list = courseList;
            list = list?.map((item) => {
              if (item?.id === page)
                return { ...item, assHeader: [], assList: [], isOpen: false };
              return item;
            });
            setCourseList(list);
            setAssessmentHeader([]);
          } else if (location) {
            // window.location.assign(location.pathname); // Redirect to the desired location
            history.push(location.pathname); // Redirect to the desired location
          } else if(!location && page) {
            if (page == "exempted") {
              // This is not in use from when exemption is also send like marks in save api
              // handleExemption(...states)
            } else {
            setTableState((prevState) => ({
              ...prevState,
              page:
                page === "firstPage"
                  ? 1
                  : page === "prevPage"
                  ? paginationState.currentPage - 1
                  : page === "nextPage"
                  ? +paginationState.currentPage + 1
                  : paginationState.lastPage,
            }));
          }
          }
          // Handle other cases, e.g., discard changes
        }
      });

      return false; // Prevent the default navigation behavior
    } else if (page == "exempted") {
      // This is not in use from when exemption is also send like marks in save api
      // handleExemption(...states)
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
        // event.returnValue = ''; // This is necessary for Chrome/Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  function mergeArraysById(array1, array2) {
    var mergedArray = [];
    array2.map((item) => {
      let originalObj = array1.find((val) => val.id == item.id);
      if (originalObj) {
        mergedArray.push({
          ...item,
          course_name: item.name,
          intake_id: item.id,
          ...originalObj,
          isNotAssigned: false,
        });
      } else {
        mergedArray.push({
          ...item,
          course_name: item.courseName,
          intake_id: item.id,
          isNotAssigned: true,
        });
      }
    });
    return mergedArray;
  }

  const fixArr = (arr, courseHeader, isExporting) => {
    let tempArr = arr.map((item) => {
      return {
        ...item,
        getAssignedCoursesToStudent: mergeArraysById(
          item.gradeMix,
          courseHeader
        ),
      };
    });
    if (isExporting) {
      return tempArr.length ? tempArr : [];
    }
    let updateArr = [...tempArr[0].getAssignedCoursesToStudent];
    for (let i = 0; i < updateArr.length; i++) {
      updateArr[i] = {
        ...updateArr[i],
        getAssignedCoursesToStudent: tempArr,
        assHeader: [],
        assList: [],
      };
    }
    return updateArr;
  };

  // useEffect(() => {
  //   setLoading((prevState) => ({ ...prevState, courseTable: true }));
  //   getGradeMixOfPrograme(id)
  //     .then((res) => {
  //       let getCourse = res.data?.getAssessments;
  //       getProgrameGradeMixGradebook({
  //         id,
  //         search,
  //         limit: tableState.limit,
  //         page: tableState.page,
  //         exportStatus: "false",
  //         forPdf: "false"
  //       })
  //         .then((res) => {
  //           setIsProgrammeEdited(res.data.getQulification.allUpdateProgram)
  //           setUserData(res?.data.data_list.map(item => ({ ...item, isDis: true })));
  //           setProgrammeGradeTitle(res.data?.getQulification);
  //           setCourseList(
  //             res.data?.data_list.length
  //               ? fixArr(res.data?.data_list, getCourse)
  //               : []
  //           );
  //           setPaginationState({
  //             from: res.data?.pagination?.from,
  //             to: res.data?.pagination?.to,
  //             total: res.data?.pagination?.total,
  //             perPage: res.data?.pagination?.per_page,
  //             currentPage: res.data?.pagination?.current_page,
  //             lastPage: res.data?.pagination?.last_page,
  //           });
  //           setLoading((prevState) => ({ ...prevState, courseTable: false }));
  //         })
  //         .catch((err) => {
  //           setLoading((prevState) => ({ ...prevState, courseTable: false }));
  //           console.error(err);
  //         });
  //     })
  //     .catch((err) => {
  //       setLoading((prevState) => ({ ...prevState, courseTable: false }));
  //       console.error("error:", err);
  //     });
  // }, [id, updateData, search, tableState]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setLoading((prevState) => ({ ...prevState, courseTable: true }));
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await getGradeMixOfPrograme(id, source.token);
        if (res.status === 200) {
          let getCourse = res.data?.getAssessments;
          getProgrameGradeMixGradebook({
            id,
            search,
            limit: tableState.limit,
            page: tableState.page,
            exportStatus: "false",
            forPdf: "false"
          })
            .then((res) => {
              setIsProgrammeEdited(res.data.getQulification.allUpdateProgram)
              setUserData(res?.data.data_list.map(item => ({ ...item, isDis: true })));
              setProgrammeGradeTitle(res.data?.getQulification);
              setCourseList(
                res.data?.data_list.length
                  ? fixArr(res.data?.data_list, getCourse)
                  : []
              );
              setPaginationState({
                from: res.data?.pagination?.from,
                to: res.data?.pagination?.to,
                total: res.data?.pagination?.total,
                perPage: res.data?.pagination?.per_page,
                currentPage: res.data?.pagination?.current_page,
                lastPage: res.data?.pagination?.last_page,
              });
              setLoading((prevState) => ({ ...prevState, courseTable: false }));
            })
            .catch((err) => {
              setLoading((prevState) => ({ ...prevState, courseTable: false }));
              console.error(err);
            });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading((prevState) => ({ ...prevState, courseTable: false }));
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [id, updateData, search, tableState]);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("");
  };

  const handleStateChange = (ind, intakeId, isOpening) => {
    if (isOpening) {
      setLoading((prevState) => ({
        ...prevState,
        assessmentHeader: true,
        assessmentTable: true,
      }));
      setOpenedCourse((prevState) => ({ ...prevState, tempId: ind }));
      let stdIds = userData.map((item) => item.id);
      const Values = {
        id: intakeId,
        key: "id",
        sort: "ASC",
        exportStatus: "false",
        search: "",
        page: 1,
        limit: 10,
        is_student: 1,
        students: stdIds,
      };
      gradebookOfgradeMix(Values)
        .then((res) => {
          getGradeMixAssessments(intakeId)
            .then((response) => {
              setAssessmentHeader(response.data?.getAssessments);
              setAssmentList(res.data?.result);
              let list = courseList;
              list = list?.map((item) => {
                if (item?.id === ind)
                  return {
                    ...item,
                    assHeader: response.data?.getAssessments,
                    // assList: res.data?.result,
                    assList: res.data?.result.map((item)=> (
                      {
                        ...item,
                        isSupplementary: item.assessmentArr.some((supplement) =>  supplement.isSupplementaryForThisStudent)
                      }
                    )),
                    isOpen: isOpening,
                  };
                return item;
              });
              setCourseList(list);
              setLoading((prevState) => ({
                ...prevState,
                assessmentTable: false,
                assessmentHeader: false,
              }));
            })
            .catch((err) => {
              console.error("error", err);
              setLoading((prevState) => ({
                ...prevState,
                assessmentTable: false,
                assessmentHeader: false,
              }));
            });
        })
        .catch((err) => {
          console.error("error :", err);
          setLoading((prevState) => ({ ...prevState, assessmentTable: false }));
        });
    } else if (updateValueArr.length) {
      handleNavigation("assClosing", ind);
    } else {
      let list = courseList;
      list = list?.map((item) => {
        if (item?.id === ind)
          return { ...item, assHeader: [], assList: [], isOpen: isOpening };
        return item;
      });
      setCourseList(list);
      setAssessmentHeader([]);
    }
  };

  const handleUpdateMarks = (
    stdId,
    assId,
    gradeType,
    grade,
    maxMark,
    ind,
    intakeId
  ) => {
    if (grade === oldValue) {
      setOldValue();
      return;
    }
    if (
      (grade > maxMark && gradeType == "3") ||
      (gradeType == "0" && grade > maxMark)
    ) {
      setErrorMessage("Sorry you can't put mark more than the Total mark");
      return false;
    }
    setErrorMessage("");

    let tempArr = userData;
    let isdisabledcheck = tempArr.map((item) => {
      if (item.id === stdId) {
        return { ...item, isDis: false };
      }
      return { ...item };
    });
    setUserData(isdisabledcheck);
    let ifCourseExists = false
    let ifAssessmentExists = false
    let arr = updateValueArr
    arr.map((item, index) => {
      if(item.id === intakeId && item.student_id === stdId) {
        ifCourseExists = index
        item.assessments.map((val, ind) => {
          if (val.assessment_id === assId) {
            ifAssessmentExists = ind
          }
        })
      }
    });
    if (ifCourseExists === false) {
      arr.push({
        id: intakeId,
        student_id: stdId,
        qid: id,
        // it is set to 0 because if somehow u are updating any assessment then its course MUST not be exempted
        grant_exemption: 0,
        assessments: [
          {
            student_id: stdId,
            assessment_id: assId,
            grade: grade,
            grade_type: gradeType,
            // grant_exemption :grant_exemption
          }
        ]
      })
    } else if (ifAssessmentExists === false) {
      arr[ifCourseExists].assessments = [ ...arr[ifCourseExists].assessments,
        {
          student_id: stdId,
          assessment_id: assId,
          grade,
          grade_type: gradeType,
          // grant_exemption :grant_exemption
        }
      ]
    } else {
      arr[ifCourseExists].assessments[ifAssessmentExists] = { ...arr[ifCourseExists].assessments[ifAssessmentExists], grade, grade_type: gradeType}
    }
    setUpdateValueArr([...arr]);
  };

  const handleSaveGrades = (studentID, updatedArr) => {
    Swal.fire({
      title: "Updating",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    let updatedValues = [];
    if (!updateValueArr.length) {
      updatedValues = updatedArr;
    } else {
      updatedValues = updateValueArr;
    }
    setSaveGradesLoading(true);
    const values = {
      id: [id],
      grademix: updatedValues,
      student_id: studentID,
    };
    if (!studentID) {
      delete values.student_id;
    }
    updateProgrammeScore(values)
      .then((res) => {
        setSaveGradesLoading(false);
        if (studentID) {
          const filteredUpdateValueArr = updateValueArr.map((item) => ({
            ...item,
            assessments: item.assessments.filter(
              (assessment) => assessment.student_id !== studentID
            ),
          }));
          setUpdateValueArr(filteredUpdateValueArr);
        } else {
          setUpdateValueArr([]);
        }
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setUpdateData(!updateData));
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  const handleSaveSingleGrade = (stdId) => {
    let otherIds = stdIds.filter((item) => item !== stdId);
    if (otherIds.length) {
      Swal.fire({
        title: "Are You Sure?",
        text: "You have unsaved data in other students",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        html: `
          <div class="custom-swal-button">
            <p>You have unsaved data in other students!</p>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "saveAll", updatedArr: ${JSON.stringify(
              updateValueArr
            )}})' >Save All</button>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "save", stdId: ${stdId}, updatedArr: ${JSON.stringify(
          updateValueArr
        )}})'>Save</button>
            <button class="swal2-cancel swal2-styled" onclick="triggerSwall({type: 'cancel'})" style="background-color: rgb(221, 51, 51);">Cancel</button>
          </div>`,
      });
    } else {
      handleSaveGrades(stdId, updateValueArr);
    }
  };

  const checkbtnValue = (values) => {
    if (values.type === "saveAll") {
      handleSaveGrades(undefined, values.updatedArr);
    } else if (values.type === "save") {
      handleSaveGrades(values.stdId, values.updatedArr);
    }
    Swal.close();
  };

  const handleExemption = (assId, stdId, val, type, gradeId, gradeTypeAndValue) => {
    if (type) {
      const updatedCourseList = courseList.map(course => {
        if (course.id === gradeId) {
          const updatedAssignedCourses = course.getAssignedCoursesToStudent.map(ass => {
            if (ass.id === stdId) {
              return {
                ...ass,
                getAssignedCoursesToStudent: ass.getAssignedCoursesToStudent.map(obj => {
                  if (obj.id === gradeId) {
                    let arr = updateValueArr
                    let ifItemExists = false
                    arr.forEach((item, index) => {
                      if (item.student_id === stdId && item.id === gradeId) {
                        ifItemExists = index
                      }
                    })
                    if (ifItemExists === false) {
                      arr.push({
                        student_id: stdId,
                        qid: id,
                        id: gradeId,
                        grant_exemption: val,
                        assessments: []
                      })
                    } else {
                      arr[ifItemExists] = { ...arr[ifItemExists], grant_exemption: val }
                    }
                    setUpdateValueArr([...arr]);
                    return { ...obj, grant_exemption: val };
                  }
                  return obj;
                })
              };
            }
            return ass;
          });

          return {
            ...course,
            getAssignedCoursesToStudent: updatedAssignedCourses
          };
        }
        return course;
      });
      setCourseList(() => [...updatedCourseList]);
    } else {
      const updatedCourseList = courseList.map(course => {
        if (course.id === gradeId) {
          return {
            ...course,
            assList: course.assList.map(student => {
              if (student.id === stdId) {
                return {
                  ...student,
                  assessmentArr: student.assessmentArr.map(assessment => {
                    if (assessment.assessment_id === assId) {
                      let arr = updateValueArr
                      let ifCourseExists = false
                      let ifAssessmentExists = false
                      arr.forEach((item, index) => {
                        if (item.student_id === stdId && item.id === gradeId) {
                          ifCourseExists = index
                          item.assessments.forEach((val, ind) => {
                            if (val.assessment_id === assId) {
                              ifAssessmentExists = ind
                            }
                          })
                        }
                      })
                      if (ifCourseExists === false) {
                        arr.push({
                          student_id: stdId,
                          qid: id,
                          id: gradeId,
                          // it is set to 0 because if somehow u are updating any assessment then its course MUST not be exempted
                          grant_exemption: 0,
                          assessments: [{
                            assessment_id: assId,
                            student_id: stdId,
                            grant_exemption: val,
                            // DO NOT DELETE
                            // grade: gradeTypeAndValue.grade,
                            // gradeType: gradeTypeAndValue.grade_type
                          }]
                        })
                      } else if (ifAssessmentExists === false) {
                        arr[ifCourseExists].assessments = [...arr[ifCourseExists].assessments, {
                          assessment_id: assId,
                          student_id: stdId,
                          grant_exemption: val,
                          // DO NOT DELETE
                          // grade: gradeTypeAndValue.grade,
                          // gradeType: gradeTypeAndValue.grade_type
                        }]
                        // DO NOT DELETE
                      // } else if (!arr[ifCourseExists].assessments[ifAssessmentExists].grade) {
                      //   arr[ifCourseExists].assessments[ifAssessmentExists] = { ...arr[ifCourseExists].assessments[ifAssessmentExists], grant_exemption: val, grade: gradeTypeAndValue.grade,  gradeType: gradeTypeAndValue.grade_type }
                      } else {
                        arr[ifCourseExists].assessments[ifAssessmentExists] = { ...arr[ifCourseExists].assessments[ifAssessmentExists], grant_exemption: val }
                      }
                      setUpdateValueArr([...arr]);
                      let finalObj = {
                        ...assessment,
                        grant_exemption: val
                      };
                      if (ifCourseExists !== false && ifAssessmentExists !== false) {
                        finalObj.grading_type = gradeTypeAndValue.grade_type
                        if (gradeTypeAndValue.grade_type == 1 || gradeTypeAndValue.grade_type == 2) {
                          finalObj.grade = arr[ifCourseExists].assessments[ifAssessmentExists].grade
                        } else {
                          finalObj.ass_mark = arr[ifCourseExists].assessments[ifAssessmentExists].grade
                        }
                      } else {
                        finalObj.grading_type = gradeTypeAndValue.grade_type
                        if (gradeTypeAndValue.grade_type == 1 || gradeTypeAndValue.grade_type == 2) {
                          finalObj.grade = gradeTypeAndValue.grade
                        } else {
                          finalObj.ass_mark = gradeTypeAndValue.grade
                        }
                      }
                      return finalObj
                    }
                    return assessment;
                  })
                };
              }
              return student;
            })
          };
        }
        return course;
      });
      setCourseList(() => [...updatedCourseList])
    }
    // DO NOT DELETE BELOW CODE
    // let data = {
    //   id: assId,
    //   student_id: stdId,
    //   grant_exemption: val,
    //   grade_id: gradeId,
    // };
    // if (type) {
    //   data = { ...data, type, qid: +id };
    // }
    // exemptionToggle(data).then((res) => {
    //   Swal.fire("Updated!", res.data.message, "success").then((result) =>
    //     setUpdateData(!updateData)
    //   );
    // });
    // DO NOT DELETE ABOVE CODE
  };

  useEffect(() => {
    setTimeout(() => {
      setSlider(document.querySelector(".table-book-mid"));
    }, 500);
  }, [loading]);

  useEffect(() => {
    if (slider) {
      let mouseDown = false;
      let startX, scrollLeft;

      let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };
      let stopDragging = function (event) {
        mouseDown = false;
      };

      slider.addEventListener("mousemove", (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      });

      // Add the event listeners
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);
    }
  }, [slider]);

  const exportData = (fileType, fileName) => {
    getProgrameGradeMixGradebook({
      id,
      search,
      limit: tableState.limit,
      page: tableState.page,
      exportStatus: "true",
      forPdf: 'false'
    })
      .then((res) => {
        const respData = res?.data?.data_list;
        const header = [
          "Student Name",
          "Student Number",
          "GradeMix Name",
          "Assessment Name",
          "Assessment Grade",
          "Assessment Result",
          "GradeMix Grade",
          "GradeMix Result",
          "Programme Grade",
          "Programme Result",
        ];
        const data = [];
        respData.map((item) => {
          let dataItem = {
            "Student Name": `${item?.full_name}`,
            "Student Number": item?.student_num
              ? item?.student_num
              : item?.student_crm_id,
            "GradeMix Name": "",
            "Assessment Name": "",
            "Assessment Grade": "",
            "Assessment Result": "",
            "GradeMix Grade": "",
            "GradeMix Result": "",
            "Programme Grade": percentageView
              ? Math.round(item.getProgrammeGradeMixCulc?.scoreRecieved) + "%"
              : item.getProgrammeGradeMixCulc?.grading_type == "3"
              ? `${item.getProgrammeGradeMixCulc?.programeAssMark}/${item.getProgrammeGradeMixCulc?.programeAssTotalMark}`
              : typeof item.getProgrammeGradeMixCulc?.gradeText == "string" &&
                item.getProgrammeGradeMixCulc?.gradeText.includes("%")
              ? Math.round(
                  parseFloat(item.getProgrammeGradeMixCulc?.gradeText.replace("%", ""))
                ).toString() + "%"
              : item.getProgrammeGradeMixCulc?.gradeText
              ? item.getProgrammeGradeMixCulc?.gradeText
              : "N/A",
            "Programme Result":
              item.getProgrammeGradeMixCulc?.pass_status == "N/A"
                ? "N/A"
                : RenderQuizAttemptResultStatus(
                    item.getProgrammeGradeMixCulc?.pass_status
                  ).text,
          };
          data.push(dataItem);
          item.gradeMix &&
            item.gradeMix.map((courseItem, index) => {
              let courseItemData = {
                "Student Name": "",
                "Student Number": "",
                "GradeMix Name": `${courseItem?.name}`,
                "Assessment Name": "",
                "Assessment Grade": "",
                "Assessment Result": "",
                "GradeMix Grade": courseItem?.grant_exemption
                  ? "Exempted"
                  : percentageView ||
                    courseItem?.getGradeMixCulc?.grading_type == 0
                  ? Math.round(courseItem?.getGradeMixCulc?.scoreRecieved) + "%"
                  : courseItem?.getGradeMixCulc?.grading_type == 3
                  ? `${courseItem?.getGradeMixCulc?.allAss_ass_mark}/${courseItem?.getGradeMixCulc?.allAss_total_mark}`
                  : courseItem?.getGradeMixCulc?.gradeText
                  ? courseItem?.getGradeMixCulc?.gradeText
                  : "N/A",
                "GradeMix Result": courseItem?.grant_exemption
                  ? "Exempted"
                  : courseItem?.getGradeMixCulc?.pass_status == "N/A"
                  ? "N/A"
                  : RenderQuizAttemptResultStatus(
                      courseItem?.getGradeMixCulc?.pass_status
                    ).text,
                "Programme Grade": "",
                "Programme Result": "",
              };
              data.push(courseItemData);
              courseItem.assessmentArr &&
                courseItem.assessmentArr.map((assItem, index) => {
                  let assessmentData = {
                    "Student Name": "",
                    "Student Number": "",
                    "GradeMix Name": "",
                    "Assessment Name": `${assItem?.assessment_name}`,
                    "Assessment Grade": assItem?.grant_exemption
                      ? "Exempted"
                      : percentageView || assItem?.grading_type == 0
                      ? assItem?.ass_percentage + "%"
                      : assItem?.grading_type == 3
                      ? `${assItem?.ass_mark}/${assItem?.ass_total_mark}`
                      : assItem?.gradeText
                      ? assItem?.gradeText
                      : "N/A",
                    "Assessment Result": assItem?.grant_exemption
                      ? "Exempted"
                      : assItem?.pass_status == "N/A"
                      ? "N/A"
                      : RenderQuizAttemptResultStatus(assItem?.pass_status)
                          .text,
                    "GradeMix Grade": "",
                    "GradeMix Result": "",
                    "Programme Grade": "",
                    "Programme Result": "",
                  };
                  data.push(assessmentData);
                });
            });
        });
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Student Name"],
              row["Student Number"],
              row["GradeMix Name"],
              row["Assessment Name"],
              row["Assessment Grade"],
              row["Assessment Result"],
              row["GradeMix Grade"],
              row["GradeMix Result"],
              row["Programme Grade"],
              row["Programme Result"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => console.error(err));
  };

  function getNewChar(currentValue) {
    var alphabets = "";
    var defaultData = "A";
    if (currentValue === currentValue.toUpperCase()) {
      alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      defaultData = "A";
    } else {
      alphabets = "abcdefghijklmnopqrstuvwxyz";
      defaultData = "a";
    }

    let result = "";
    if (currentValue === "") {
      return defaultData;
    }

    const lastIndex = currentValue.length - 1;
    let carry = 1;

    for (let i = lastIndex; i >= 0; i--) {
      const char = currentValue[i];
      const charIndex = alphabets.indexOf(char);

      if (carry) {
        if (charIndex === 25) {
          carry = 1;
          result = defaultData + result;
        } else {
          carry = 0;
          result = alphabets[charIndex + 1] + result;
        }
      } else {
        result = char + result;
      }
    }

    if (carry) {
      result = defaultData + result;
    }

    return result;
  }

  const exportXlsxData = (fileName) => {
    getGradeMixOfPrograme(id).then((res) => {
      let getCourse = res.data?.getAssessments;
      getProgrameGradeMixGradebook({
        id,
        search,
        limit: tableState.limit,
        page: tableState.page,
        exportStatus: "true",
        forPdf: 'false'
      }).then(async (res) => {
        let fixedArr = fixArr(res.data?.data_list, getCourse, true);
        let courses = fixedArr[0]?.getAssignedCoursesToStudent;
        let assessmentsList = courses.map((item) => item.assessmentArr);
        let assessments = [];
        assessmentsList.map((item) => {
          if (item?.length) {
            assessments.push(...item);
          } else {
            assessments.push("");
          }
          assessments.push({ assessment_name: "Course Mark" });
        });
        let assNames = assessments.map((item) =>
          item?.assessment_name ? item?.assessment_name : ""
        );
        // return
        // let assessments = [].concat.apply([], assessmentsList);

        let header = [];
        if (fixedArr.length) {
          header = [
            "Name",
            "Surname",
            "ID Number",
            "Student Number",
            "Study Method",
            ...assNames,
            "Final Mark",
          ];
        } else {
          header = [
            "Name",
            "Surname",
            "ID Number",
            "Student Number",
            "Study Method",
            "Final Mark",
          ];
        }
        const respData = fixedArr;
        let coursesData = {}
        for (let index = 0; index < respData.length; index++) {
          const element = respData[index];
          for (let j = 0; j < element?.getAssignedCoursesToStudent.length; j++) {
            const course = element?.getAssignedCoursesToStudent[j];
            if (!coursesData[course.intake_id] && course?.assessmentArr?.length) {
              coursesData[course.intake_id] = {
                assObj: course.assessmentArr
              }
            }
          }
        }
        // Process assessment and grade mix marks
        const data = respData.flatMap((item) => {
          let assData = [];
          item.getAssignedCoursesToStudent.map((course) => {
            // if (course.isActiveCourse === 0){
            //   assData.push("Exempted", "Exempted");
            // } 
             if (course.assessmentArr?.length) {
              assData.push(
                ...course.assessmentArr.map((assessmentData) =>
                  assessmentData?.grant_exemption || course?.isActiveCourse === 0 || course?.grant_exemption === 1
                    ? "Exempted"
                    : percentageView
                    ? assessmentData?.ass_percentage.toString().replace(".",",")
                    : assessmentData?.grading_type == "0"
                    ? assessmentData?.ass_percentage.toString().replace(".",",")
                    : assessmentData?.grading_type == "3"
                    ? `${assessmentData?.ass_mark}/${assessmentData?.ass_total_mark}`
                    : assessmentData?.gradeText 
                    ? assessmentData?.gradeText.toString().replace(".",",") 
                    : "N/A"
                ),
                course?.grant_exemption || course?.isActiveCourse === 0
                  ? "Exempted"
                  : percentageView ||
                    course?.getGradeMixCulc?.grading_type == 0
                  ? Math.round(course?.getGradeMixCulc?.scoreRecieved)
                  : course?.getGradeMixCulc?.grading_type == 3
                  ? `${course?.getGradeMixCulc?.allAss_ass_mark}/${course?.getGradeMixCulc?.allAss_total_mark}`
                  : typeof item.programCalculate?.gradeText == "string" &&
                  item.getProgrammeGradeMixCulc?.gradeText.includes("%")
                  ? (Math.round(parseFloat(course?.getGradeMixCulc?.gradeText?.toString().replace("%",""))))
                  : course?.getGradeMixCulc?.gradeText
                  ? course?.getGradeMixCulc?.gradeText
                  : "N/A"
              );
            } else if (coursesData[course.intake_id] && coursesData[course.intake_id].assObj.length) {
              assData.push(
                ...coursesData[course.intake_id].assObj.map((assessmentData) => "N/A"),
                "N/A"
              );
            } else {
              assData.push("N/A", "N/A");
            }
          });
          // Calculate final marks
          const rowData = [
            item.first_name,
            item.last_name,
            item?.number ? item?.number : " ",
            item?.student_crm_id ? item?.student_crm_id : item?.student_num ? item?.student_num : " ",
            item?.type ? RenderLearningMethodGradebook(item?.type).text : " ",
            ...assData,
            percentageView
              ? Math.round(item.getProgrammeGradeMixCulc?.scoreRecieved)
              : item.getProgrammeGradeMixCulc?.grading_type == "3"
              ? `${item.getProgrammeGradeMixCulc?.programeAssMark}/${item.getProgrammeGradeMixCulc?.programeAssTotalMark}`
              : typeof item.programCalculate?.gradeText == "string" &&
                item.getProgrammeGradeMixCulc?.gradeText.includes("%")
              ? Math.round(parseFloat(item.getProgrammeGradeMixCulc?.gradeText.replace("%", ""))).toString()
              : item.getProgrammeGradeMixCulc?.gradeText
              ? item.getProgrammeGradeMixCulc?.gradeText.toString().replace("%", "")
              : "N/A",
          ];
          return [rowData];
        });

        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet("Excel Data");

        // Set border for the entire worksheet
        ws.views = [
          {
            state: "frozen",
            ySplit: 1,
            xSplit: 1,
          },
        ];
        ws.properties.showGridLines = true;

        const columnStyles = [];
        header.forEach((col, colIndex) => {
          const style = {};
          if (
            [
              "Name",
              "Surname",
              "ID Number",
              "Student Number",
              "Study Method",
            ].includes(col)
          ) {
            style.font = { bold: true };
            style.alignment = { horizontal: 'center' };
            style.width = 75;
            style.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFDBDBDB" }, // Light gray background color
            };
            style.border = {
              top: { style: "thin", color: { argb: "FF000000" } },
              bottom: { style: "thin", color: { argb: "FF000000" } },
              left: { style: "thin", color: { argb: "FF000000" } },
              right: { style: "thin", color: { argb: "FF000000" } },
            };
          } else if (col === "Final Mark") {
            style.font = { color: { argb: "FFFFFF" } };
            style.alignment = { horizontal: 'center' };
            style.width = 60
            style.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF7030A0" }, // Purple background color
            };
            style.border = {
              top: { style: "thin", color: { argb: "FF000000" } }, // Black top border
              bottom: { style: "thin", color: { argb: "FF000000" } }, // Black bottom border
              left: { style: "thin", color: { argb: "FF000000" } }, // Black left border
              right: { style: "thin", color: { argb: "FF000000" } }, // Black right border
            };
          } else if (col === "Course Mark") {
            style.font = { color: { argb: "FFFFFF" } };
            style.alignment = { horizontal: 'center' };
            style.width = 70
            style.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF203764" }, // Blue background
            };
            style.border = {
              top: { style: "thin", color: { argb: "FF000000" } }, // Black top border
              bottom: { style: "thin", color: { argb: "FF000000" } }, // Black bottom border
              left: { style: "thin", color: { argb: "FF000000" } }, // Black left border
              right: { style: "thin", color: { argb: "FF000000" } }, // Black right border
            };
          } else {
            style.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFDBDBDB" }, // Light gray background color
            };
            style.border = {
              top: { style: "thin", color: { argb: "FF000000" } }, // Black top border
              bottom: { style: "thin", color: { argb: "FF000000" } }, // Black bottom border
              left: { style: "thin", color: { argb: "FF000000" } }, // Black left border
              right: { style: "thin", color: { argb: "FF000000" } }, // Black right border
            };
          }
          columnStyles.push(style);
        });
        const staticRow = ws.addRow([programmeName, "", "", "", ""]);
        staticRow.height = 16;
        staticRow.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF66CCFF" },
        }; // Sky blue background
        // Set cell styles
        staticRow.getCell(1).font = { bold: true};
        staticRow.getCell(1).alignment = { horizontal: 'center',vertical: "bottom" };
        staticRow.getCell(1).border = {};
        staticRow.getCell(5).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF203764" },
        }; // Blue background
        let rowArr = ["", "", "", "", ""];
        // Push course column names
        fixedArr[0].getAssignedCoursesToStudent.map((item) => {
          rowArr.push(item.courseName);
          if (item.assessmentArr?.length) {
            item.assessmentArr.map((ass, index) => {
              if (index) rowArr.push("");
            });
          }
          rowArr.push("");
        });

        ws.addRow([...rowArr]);
        // Set row height
        const firstDataRow = ws.getRow(staticRow.number + 1);
        firstDataRow.height = 16;
        // Set border
        firstDataRow.eachCell((cell, colNumber) => {
          if (colNumber > 5) {
            // Check if it's after the Study Method column
            cell.border = {
              top: { style: "thin", color: { argb: "FF000000" } }, // Add bottom border
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
          }
        });
        let currentCol = "F";
        let prevCol = "F";
        // Merge cells
        fixedArr[0].getAssignedCoursesToStudent.map((item) => {
          const arr = Array(
            item.assessmentArr?.length ? item.assessmentArr.length : 1
          ).fill("");
          arr.forEach((item) => (currentCol = getNewChar(currentCol)));
          ws.mergeCells(`${prevCol}2:${currentCol}2`);
          currentCol = getNewChar(currentCol);
          prevCol = currentCol;
        });

        // Merge cells
        ws.mergeCells(`A${staticRow.number}:D${staticRow.number}`);
        const headerRow = ws.addRow(header);
        headerRow.height = 15;
        // Set secondary header cell names
        ws.mergeCells(`A2:A3`);
        ws.getCell(`A2:A3`).value = "Name";
        ws.mergeCells(`B2:B3`);
        ws.getCell(`B2:B3`).value = "Surname";
        ws.mergeCells(`C2:C3`);
        ws.getCell(`C2:C3`).value = "ID Number";
        ws.mergeCells(`D2:D3`);
        ws.getCell(`D2:D3`).value = "Student Number";
        ws.mergeCells(`E2:E3`);
        ws.getCell(`E2:E3`).value = "Study Method";
        let finalGradeCell = `${currentCol}2:${currentCol}3`;
        // Merce final grade cell
        ws.mergeCells(finalGradeCell);
        // Set final grade cell text
        ws.getCell(finalGradeCell).value = "Final Grade";
        
        // making headers style different
        headerRow.eachCell((cell, colNumber) => {
          if (columnStyles[colNumber - 1].font) {
            cell.font = columnStyles[colNumber - 1].font;
            cell.width = columnStyles[colNumber - 1].width
          }
          cell.fill = columnStyles[colNumber - 1].fill;
          cell.border = columnStyles[colNumber - 1].border;
        });
        // addingborders to cells
        data.forEach((row) => {
          const dataRow = ws.addRow(row);
          dataRow.eachCell((cell, colNumber) => {
            if(colNumber){
              cell.border = columnStyles[colNumber - 1]?.border;
            }
          });
        });

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();

        Swal.close();
      });
    });
  };

  const handleResize = () => {
    let pageWidth = document.querySelector(
      ".my-tickets-info-list"
    )?.offsetWidth;
    setTableWidth(pageWidth);
  };

  useEffect(() => {
    var resizeRequested = false;
    handleResize();
    window.addEventListener(
      "resize",
      function () {
        if (!resizeRequested) {
          resizeRequested = true;
          window.requestAnimationFrame(function () {
            setTableWidth(
              document.querySelector(".table-book-responsive")?.clientWidth
            );
            resizeRequested = false;
          });
        }
      },
      100
    );
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleWidth = () => {
    const leftTableWidth =
      document.querySelector(".table-book-left").clientWidth;
    const midTableElements = document.getElementsByClassName("grade-acc-in");
    let midTableWidth = 0;
    for (let index = 0; index < midTableElements.length; index++) {
      const element = midTableElements[index];
      midTableWidth += element.clientWidth;
    }
    const rightTableWidth =
      document.querySelector(".table-book-right").clientWidth;
    let maxAllowedWidth = tableWidth - leftTableWidth - rightTableWidth;
    let lastTableClass =
      document.querySelector(".table-book-right").firstChild.className;
    let firstTableClass =
      document.querySelector(".table-book-left").firstChild.className;
    // 13 is a imaginary offset we can change it if in any case table is distorted by adding and subtracting the value
    if (maxAllowedWidth < midTableWidth + 13) {
      document.querySelector(".table-book-mid").style.overflowY = "auto";
      document.querySelector(".table-book-right").style.maxWidth =
        "fit-content";
      if (!lastTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-right"
        ).firstChild.className = `${lastTableClass} border-shadow-custom-table`;
      }
      if (!firstTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-left"
        ).firstChild.className = `${firstTableClass} border-shadow-custom-table`;
      }
    } else {
      document.querySelector(".table-book-mid").style.overflowY = "unset";
      document.querySelector(".table-book-right").style.maxWidth = "unset";
      document.querySelector(".table-book-right").firstChild.className =
        lastTableClass.replaceAll("border-shadow-custom-table", "");
      document.querySelector(".table-book-left").firstChild.className =
        firstTableClass.replaceAll("border-shadow-custom-table", "");
    }
  };

  useEffect(() => {
    if (userData?.length && tableWidth) {
      setTimeout(() => {
        handleWidth();
      }, 500);
    }
  }, [userData, tableWidth, assessmentHeader, assmentList]);

  return (
    <>
      <Prompt when={!!shouldBlockNavigation} message={handleNavigation} />
      <div className="my-tickets-info-list Tickets-main-wrap programme-gradebook grade-mix-programme-gradebook">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Reset"
                      onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportXlsxData(`${programmeName} Programme Grade Book`);
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData(
                          "csv",
                          `${programmeName} Programme Grade Book`
                        );
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData(
                          "pdf",
                          `${programmeName} Programme Grade Book`
                        );
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <div>
                    <button
                      className="btn btn-primary"
                      title={
                        percentageView ? "Standard View" : "Percentage View"
                      }
                      onClick={() => {
                        setPercentageView(!percentageView);
                      }}
                    >
                      <i className="fal fa-eye"></i>{" "}
                      {percentageView ? "Standard View" : "Percentage View"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading.courseTable ? (
            <SkeletonTicketList />
          ) : userData?.length ? (
            <div className="table-book-responsive">
              <div className="table-book row m-0">
                <div className={"table-book-left"}>
                  <table
                    className={
                      "table-book-main " +
                      (courseList.length < 6 && assessmentHeader.length < 6
                        ? ""
                        : " border-shadow-custom-table")
                    }
                  >
                    <thead>
                      <tr>
                        <th>Student</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="assigned-title-block-new">
                              <div className="assigned-title-itm">
                                <span
                                  className={
                                    "assigned-title-blk  name-icon cat-dark-red " +
                                    ColorRender(item.role_name)
                                  }
                                >
                                  {item.photo ? (
                                    <img
                                      src={`${IMAGE_URL}/${item.photo
                                        .replace("public/", "")
                                        .replaceAll(
                                          "/home/myaie/public_html/",
                                          ""
                                        )}`}
                                      alt="AIE"
                                    />
                                  ) : (
                                    InitialRender([
                                      item.first_name,
                                      item.last_name,
                                    ])
                                  )}
                                  <span
                                    className={`profile-box-2-status ${
                                      item.activity_status
                                        ? item.activity_status.toLowerCase() ===
                                          "online"
                                          ? "Online"
                                          : item.activity_status.toLowerCase() ===
                                            "away"
                                          ? "Away"
                                          : "Offline"
                                        : "Offline"
                                    }`}
                                  >
                                    <i className="fas fa-circle"></i>
                                  </span>
                                  <Hover
                                    firstName={item.first_name}
                                    lastName={item.last_name}
                                    photo={item.photo}
                                    email={item.email}
                                    mobile={item.mobile}
                                    status={item.status}
                                    activity_status={item.activity_status}
                                    showNumber={true}
                                    number={item.student_crm_id}
                                    right={true}
                                  />
                                </span>
                                <PermissionsGate
                                  scopes={["sview"]}
                                  RenderError={() => (
                                    <p>
                                      {item.first_name + " " + item.last_name}
                                    </p>
                                  )}
                                >
                                  <Link
                                    className="as-text-blue curser "
                                    to={`/studentAdministration/students/open/${item.id}/general`}
                                  >
                                    <span
                                      title={
                                        item.first_name + " " + item.last_name
                                      }
                                    >
                                      {item.first_name + " " + item.last_name}
                                    </span>
                                  </Link>
                                </PermissionsGate>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="table-book-mid">
                  <div className="grade-acc">
                    {courseList.map((item, ind) => (

                      <div
                        className={
                          "grade-acc-in " + (item?.isOpen ? "active" : "")
                        }
                        key={ind}
                      >
                        <table className="table-book-main table-book-main-first">
                          <thead>
                            <tr>
                              <th>
                                <button
                                  className="ic-table btn-grade-acc"
                                  onClick={() => {
                                    handleStateChange(
                                      item.id,
                                      item.id,
                                      !item?.isOpen
                                    );
                                  }}
                                >
                                  {item?.isOpen ? (
                                    <i className="fas fa-minus-circle"></i>
                                  ) : (
                                    <i className="fas fa-plus-circle"></i>
                                  )}
                                </button>
                                {(loading.assessmentHeader ||
                                  loading.assessmentTable) &&
                                item.id === openedCourse.tempId ? (
                                  <i className="fas fa-cog fa-spin"></i>
                                ) : (
                                  ""
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item && item?.getAssignedCoursesToStudent
                              ? item?.getAssignedCoursesToStudent.map(
                                  (ass, index) => (
                                    
                                    <tr key={index}>
                                      <td>
                                        {ass?.getAssignedCoursesToStudent[ind]
                                          .isActiveCourse === 0 ? (
                                            <PermissionsGate
                                            scopes={["programgge"]}
                                            RenderError={() => (
                                              <div
                                                className="ic-table curser"
                                                title="Course Grant Exemption"
                                              >
                                                <i className="fal fa-user-plus"></i>
                                              </div>
                                            )}
                                          >
                                            <div
                                              className="ic-table curser"
                                              title="Course Grant Exemption"
                                              onClick={() => handleExemption(item?.intake_id, ass?.id, 0, 1, item?.intake_id, {})}
                                            >
                                              <i className="fal fa-user-plus"></i>
                                            </div>
                                          </PermissionsGate>
                                        ) : (
                                          <PermissionsGate
                                          scopes={["programgge"]}
                                            RenderError={() => (
                                              <div
                                                className="ic-table curser"
                                                title="Course Grant Exemption"
                                              >
                                                {ass
                                                  ?.getAssignedCoursesToStudent[
                                                  ind
                                                ].grant_exemption ? (
                                                  <i className="fal fa-user-plus"></i>
                                                ) : (
                                                  <i className="fal fa-user-times"></i>
                                                )}
                                              </div>
                                            )}
                                          >
                                            <div
                                              className="ic-table curser"
                                              title="Course Grant Exemption"
                                              onClick={() => {
                                                handleExemption(item?.intake_id, ass?.id, ass.getAssignedCoursesToStudent[ind].grant_exemption == 0 ? 1: 0, 1, item?.intake_id, {})
                                              }}
                                            >
                                              {ass?.getAssignedCoursesToStudent[
                                                ind
                                              ].grant_exemption ? (
                                                <i className="fal fa-user-plus"></i>
                                              ) : (
                                                <i className="fal fa-user-times"></i>
                                              )}
                                            </div>
                                          </PermissionsGate>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )
                              : ""}
                          </tbody>
                        </table>
                        <table className="table-book-main table-book-main-contetn">
                          <thead>
                            <tr>
                              {item?.assHeader?.length ? (
                                item?.assHeader?.map((headItem, index) => (
                                  <th key={index}>
                                     {index == 0 ? <Link
                                      to={`/courseAdministration/gradeMix/${id}/details/${item.intake_id}/open`}
                                      className="grademix-inside-assessment"
                                      title={item.course_name}
                                    >
                                      {item?.assHeader?.length > 1 ? item.course_name : TrimText(item.course_name, 20)}
                                    </Link> : <>&nbsp;</>}
                                    <Link
                                      className="curser"
                                      to={`/courseAdministration/coursesdetails/${headItem.intake}/assessments/open/assigned/Details/${headItem?.assignment_id}`}
                                      title={headItem?.name}
                                    >
                                      <p className="tb-book-tit">
                                        {TrimText(headItem?.name, 20)}
                                        {item.assList[0]?.assessmentArr[index]
                                          ?.pass_required ? (
                                          <span
                                            className="grade-info-red"
                                            data-toggle="tooltip"
                                            title={"Pass Required"}
                                          >
                                            <i className="fal fa-exclamation-circle"></i>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </Link>
                                  </th>
                                ))
                              ) : (
                                <th></th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {item?.assList?.map((assItem, index) => (
                              <tr key={index}>
                                {assItem?.assessmentArr.map((ass, i) => {
                                  return (
                                    <td
                                      key={i}
                                      className={
                                        item?.getAssignedCoursesToStudent[index]
                                          ?.getAssignedCoursesToStudent[ind]
                                          ?.grant_exemption
                                          ? "course-exempted-td"
                                          : ass?.grant_exemption
                                          ? `exempted-td ${(assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent ? "supplementry-opacity" : ""}`
                                          : (ass?.ass_type == 8 && !ass.isSupplementaryForThisStudent)
                                          ? `supplement-td`
                                          : ""
                                      }
                                      style={item?.getAssignedCoursesToStudent[index]
                                        ?.getAssignedCoursesToStudent[ind]
                                        ?.isActiveCourse === 0 ? {verticalAlign: "middle"} : {}}
                                    >
                                      {item?.getAssignedCoursesToStudent[index]
                                        ?.getAssignedCoursesToStudent[ind]
                                        ?.isActiveCourse === 0 ? (
                                        <div class="as-widget-outer text-center"><div className="exempted-text">
                                        Exempted{" "}
                                        <i className="fal fa-info-circle"></i>
                                      </div></div>
                                      ) : item?.getAssignedCoursesToStudent[index]
                                        ?.getAssignedCoursesToStudent[ind]
                                        ?.grant_exemption ? (
                                        <div className="exempted-text">
                                          Exempted{" "}
                                          <i className="fal fa-info-circle"></i>
                                        </div>
                                      ) : ass?.grant_exemption ? (
                                        <PermissionsGate
                                          scopes={["programgge"]}
                                          RenderError={() => (
                                            <>
                                              <div
                                                className="as-widget-text grade-info-green mt-2 curser"
                                                title="Remove Exemption"
                                              >
                                                {ass?.grant_exemption ? (
                                                  <i className="fal fa-user-plus"></i>
                                                ) : (
                                                  <i className="fal fa-user-times"></i>
                                                )}
                                                <div className="exempted-text">
                                                  Exempted{" "}
                                                  <i className="fal fa-info-circle"></i>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        >
                                          <>
                                            <div
                                              className={`as-widget-text grade-info-green mt-2 curser ${(assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent ? "supplementry-pointer-events" : ""}`}
                                              title="Remove Exemption"
                                              onClick={() => {
                                                handleExemption(ass?.assessment_id, assItem?.id, ass?.grant_exemption == 0 ? 1 : 0, "", item?.intake_id, { grade: ass.grading_type == 1 || ass.grading_type == 2 ? ass?.grade : ass.ass_mark, grade_type: ass.grading_type })
                                              }}
                                            >
                                              {ass?.grant_exemption ? (
                                                <i className="fal fa-user-plus"></i>
                                              ) : (
                                                <i className="fal fa-user-times"></i>
                                              )}
                                              <div className="exempted-text">
                                                Exempted{" "}
                                                <i className="fal fa-info-circle"></i>
                                              </div>
                                            </div>
                                          </>
                                        </PermissionsGate>
                                      ) : (ass?.ass_type == 8 && !ass.isSupplementaryForThisStudent) ? (
                                        <div className="as-widget-text mt-2 supplement-margin">N/A</div>
                                      ) : (ass?.ass_type == 9 && !ass.isAssignedSickStudent) ? (
                                        <div className="as-widget-text mt-2 supplement-margin">N/A</div>
                                      ) : (
                                        <div className={`d-flex ${(assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
                                          <div className="marks-number-info">
                                            <div className="marks-input-text">
                                              {percentageView ? (
                                                <div className="resultstatus_flex">
                                                  <div className="form-icon-group status-select-form">
                                                    <PermissionsGate
                                                      scopes={["capgedit"]}
                                                      errorProps={{
                                                        disabled: true,
                                                      }}
                                                    >
                                                      <span
                                                       className={`
                                                       ${ass.ass_percentage ==
                                                       "0"
                                                         ? "percentage-select-red fake-input"
                                                         : "fake-input"} ${(assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent ? "supplementry-opacity" : ""}
                                                     `}
                                                      >
                                                        {ass.ass_percentage}
                                                      </span>
                                                    </PermissionsGate>{" "}
                                                    %
                                                  </div>
                                                </div>
                                              ) : ass.grading_type === 0 ? (
                                                <div className="resultstatus_flex">
                                                  <div className="form-icon-group status-select-form percentage-box">
                                                    <PermissionsGate
                                                      scopes={["capgedit"]}
                                                      errorProps={{
                                                        disabled: true,
                                                      }}
                                                    >
                                                      <input
                                                        className={
                                                          ass.ass_percentage ==
                                                          "0"
                                                          ? "input-red percentage-input" 
                                                          : "percentage-input"
                                                        }
                                                        type="number"
                                                        defaultValue={
                                                          ass.ass_mark
                                                        }
                                                        onBlur={(e) => {
                                                          stdIds.push(
                                                            assItem.id
                                                          );
                                                          handleUpdateMarks(
                                                            assItem.id,
                                                            ass.assessment_id,
                                                            0,
                                                            e?.target?.value,
                                                            ass.ass_total_mark,
                                                            item.id,
                                                            item.intake_id
                                                          );
                                                        }}
                                                        disabled={
                                                          ass.lock_grades == "1" || ass.quiz_id || (assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                        }
                                                        onMouseUp={(e) =>
                                                          setOldValue(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </PermissionsGate>
                                                    {
                                                    <span className="ml-1">
                                                      {/* % */}
                                                      <span>/ {ass.ass_total_mark}</span>
                                                    </span>
                                                  }
                                                  <span className="percentage">{`| ${ass.ass_percentage}`}%</span>
                                                  </div>
                                                </div>
                                              ) : ass.grading_type === 1 ? (
                                                <div className="form-icon-group marks-input-text">
                                                  <PermissionsGate
                                                    scopes={["capgedit"]}
                                                    errorProps={{
                                                      disabled: true,
                                                    }}
                                                  >
                                                    <select
                                                      className={
                                                        "mid-scroll-custom-table-select " +
                                                        (ass.ass_percentage ==
                                                        "0"
                                                          ? "percentage-select-red"
                                                          : "")
                                                      }
                                                      // defaultValue={ass?.grade}
                                                      defaultValue={ass?.grade == 0 ? "0" : ass?.grade > 0 && ass?.grade <= 49 ? '49' : "100"}
                                                      onChange={(e) => {
                                                        stdIds.push(
                                                          assItem.id
                                                        );
                                                        handleUpdateMarks(
                                                          assItem.id,
                                                          ass.assessment_id,
                                                          1,
                                                          e?.target?.value,
                                                          ass.ass_total_mark,
                                                          item.id,
                                                          item.intake_id
                                                        );
                                                      }}
                                                      disabled={
                                                        ass?.lock_grades == "1" || ass.quiz_id || (assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                      }
                                                      onMouseUp={(e) =>
                                                        setOldValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      {options?.length
                                                        ? options.map(
                                                            (opt, index) => (
                                                              <option
                                                                key={index}
                                                                value={
                                                                  opt.value
                                                                }
                                                              >
                                                                {opt.label}
                                                              </option>
                                                            )
                                                          )
                                                        : ""}
                                                    </select>
                                                  </PermissionsGate>
                                                </div>
                                              ) : ass.grading_type === 2 ? (
                                                <div className="form-icon-group marks-input-text">
                                                  <PermissionsGate
                                                    scopes={["capgedit"]}
                                                    errorProps={{
                                                      disabled: true,
                                                    }}
                                                  >
                                                    <select
                                                      className={
                                                        "mid-scroll-custom-table-select " +
                                                        (ass.ass_percentage ==
                                                        "0"
                                                          ? "percentage-select-red"
                                                          : "")
                                                      }
                                                      defaultValue={ass?.grade}
                                                      onChange={(e) => {
                                                        stdIds.push(
                                                          assItem.id
                                                        );
                                                        handleUpdateMarks(
                                                          assItem.id,
                                                          ass.assessment_id,
                                                          2,
                                                          e?.target?.value,
                                                          ass.ass_total_mark,
                                                          item.id,
                                                          item.intake_id
                                                        );
                                                      }}
                                                      disabled={
                                                        ass?.lock_grades == "1" || ass.quiz_id || (assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                      }
                                                      onMouseUp={(e) =>
                                                        setOldValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      {ass?.letterGrades &&
                                                      ass?.letterGrades?.length
                                                        ? ass?.letterGrades.map(
                                                            (opt, index) => (
                                                              <option
                                                                key={index}
                                                                value={
                                                                  opt.value
                                                                }
                                                              >
                                                                {opt.label}
                                                              </option>
                                                            )
                                                          )
                                                        : ""}
                                                    </select>
                                                  </PermissionsGate>
                                                </div>
                                              ) : (
                                                <div className="resultstatus_flex">
                                                  <div className="form-icon-group status-select-form">
                                                    <PermissionsGate
                                                      scopes={["capgedit"]}
                                                      errorProps={{
                                                        disabled: true,
                                                      }}
                                                    >
                                                      <input
                                                        className={
                                                          ass.ass_percentage ==
                                                          "0"
                                                            ? "input-red"
                                                            : ""
                                                        }
                                                        type="number"
                                                        defaultValue={
                                                          ass.ass_mark
                                                        }
                                                        onBlur={(e) => {
                                                          stdIds.push(
                                                            assItem.id
                                                          );
                                                          handleUpdateMarks(
                                                            assItem.id,
                                                            ass.assessment_id,
                                                            3,
                                                            e?.target?.value,
                                                            ass.ass_total_mark,
                                                            item.id,
                                                            item.intake_id
                                                          );
                                                        }}
                                                        disabled={
                                                          ass.lock_grades == "1" || ass.quiz_id || (assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent
                                                        }
                                                        onMouseUp={(e) =>
                                                          setOldValue(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </PermissionsGate>
                                                    <span className="ml-1 min-w-40">
                                                      / {ass.ass_total_mark}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            {ass?.grant_exemption ? (
                                              ""
                                            ) : (
                                              <div className="grade-info-ic-box">
                                                {ass?.assessmentMarkingStatus?.toString() ===
                                                  "notsubmitted" ||
                                                ass?.assessmentMarkingStatus?.toString() ===
                                                  "NOT SUBMITTED" ? (
                                                  <div
                                                    className="grade-info-ic grade-info-red"
                                                    title="Not Submitted"
                                                  >
                                                    <i className="fal fa-file-times"></i>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {!ass?.mark_edited ||
                                                ass?.mark_edited === "0" ? (
                                                  ""
                                                ) : (
                                                  <div
                                                    className="grade-info-ic grade-info-orange"
                                                    title="Mark Edited"
                                                  >
                                                    <i className="fal fa-edit"></i>
                                                  </div>
                                                )}
                                                {ass?.assessmentStatus?.toString() ===
                                                "OPEN" ? (
                                                  <div
                                                    className="grade-info-ic grade-info-green"
                                                    title="Open/Extension"
                                                  >
                                                    <i className="fal fa-calendar-day"></i>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {ass?.assessmentStatus?.toString() ===
                                                "PENDING" ? (
                                                  <div
                                                    className="grade-info-ic grade-info-blue"
                                                    title="Upcoming Assessment"
                                                  >
                                                    <i className="fal fa-calendar-alt"></i>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          <div className="as-widget-outer text-center ml-3">
                                            {ass?.assessmentMarkingStatus?.toString() !==
                                              "NOT SUBMITTED" &&
                                            ass.ass_total_mark
                                              ? RenderQuizAttemptResultStatus(
                                                  ass.pass_status
                                                ).html
                                              : ""}
                                            <PermissionsGate
                                              scopes={["programgge"]}
                                              RenderError={() => (
                                                <div
                                                  className="as-widget-text grade-info-red mt-2 curser"
                                                  title="Grant Exemption"
                                                >
                                                  {ass?.grant_exemption ? (
                                                    <i className="fal fa-user-plus"></i>
                                                  ) : (
                                                    <i className="fal fa-user-times"></i>
                                                  )}
                                                </div>
                                              )}
                                            >
                                              <div
                                               className={`as-widget-text grade-info-red mt-2 curser ${(assItem.isSupplementary && !ass.isSupplementaryForThisStudent) || ass.isSickForThisStudent ? "supplementry-pointer-events" : ""}`}
                                                title="Grant Exemption"
                                                onClick={() => {
                                                  handleExemption(ass?.assessment_id, assItem?.id, ass?.grant_exemption == 1 ? 0 : 1, "", item?.intake_id, { grade: ass.grading_type == 1 || ass.grading_type == 2 ? ass.grade : ass.ass_mark, grade_type: ass.grading_type })
                                                }}
                                              >
                                                {ass?.grant_exemption ? (
                                                  <i className="fal fa-user-plus"></i>
                                                ) : (
                                                  <i className="fal fa-user-times"></i>
                                                )}
                                              </div>
                                            </PermissionsGate>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <table className="table-book-main table-book-main-final">
                          <thead>
                            <tr>
                              <th>
                                <Link
                                  to={`/courseAdministration/gradeMix/${id}/details/${item.intake_id}/open`}
                                  className="head-link"
                                  title={item.course_name}
                                >
                                  {TrimText(item.course_name, 18)}
                                </Link>
                                <div className="tb-book-tit">
                                  Final Grade
                                  {item.passReqAssessment?.length ? (
                                    <span
                                      className=" grade-info-red"
                                      data-toggle="tooltip"
                                      title={`Required pass mark on ${item.passReqAssessment.toString()} and a final grade above ${
                                        item.requiredPassMarkforCourse
                                      }`}
                                    >
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item && item?.getAssignedCoursesToStudent
                              ? item?.getAssignedCoursesToStudent.map(
                                  (ass, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="as-widget-outer text-center">
                                          {ass?.getAssignedCoursesToStudent[ind]
                                            ?.isActiveCourse === 0 ? (
                                              <div>
                                              <span className="exempted-text">
                                                Exempted{" "}
                                                <i className="fal fa-info-circle"></i>
                                              </span>
                                            </div>
                                          ) : ass?.getAssignedCoursesToStudent[
                                              ind
                                            ]?.grant_exemption ? (
                                            <PermissionsGate
                                              scopes={["capgedit"]}
                                              RenderError={() => (
                                                <>
                                                  <div
                                                    className="as-widget-text grade-info-green mt-2 curser"
                                                    title="Remove Exemption"
                                                  >
                                                    <i className="fal fa-user-plus"></i>
                                                  </div>
                                                  <div>
                                                    <span className="exempted-text">
                                                      Exempted{" "}
                                                      <i className="fal fa-info-circle"></i>
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                            >
                                              <>
                                                <div
                                                  className="as-widget-text grade-info-green mt-2 curser"
                                                  title="Remove Exemption"
                                                  onClick={(e) => {
                                                    handleExemption(item?.intake_id, ass?.id, ass?.getAssignedCoursesToStudent[ind]?.grant_exemption == 0 ? 1 : 0, 1, item?.intake_id, { grade: ass.grading_type == 1 || ass.grading_type == 2 ? ass.grade : ass.ass_mark, grade_type: ass.grading_type })
                                                  }}
                                                >
                                                  <i className="fal fa-user-plus"></i>
                                                </div>
                                                <div>
                                                  <span className="exempted-text">
                                                    Exempted{" "}
                                                    <i className="fal fa-info-circle"></i>
                                                  </span>
                                                </div>
                                              </>
                                            </PermissionsGate>
                                          ) : ass?.getAssignedCoursesToStudent[
                                              ind
                                            ]?.getGradeMixCulc?.pass_status ==
                                            "N/A" ? (
                                            "N/A"
                                          ) : ass?.getAssignedCoursesToStudent[
                                              ind
                                            ]?.getGradeMixCulc ? (
                                            <>
                                              {
                                                RenderQuizAttemptResultStatus(
                                                  ass
                                                    ?.getAssignedCoursesToStudent[
                                                    ind
                                                  ]?.getGradeMixCulc
                                                    ?.pass_status
                                                ).html
                                              }
                                              <div className="as-widget-text">
                                                { percentageView
                                                  ? Math.round(ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.scoreRecieved) + "%"
                                                  : ass?.getAssignedCoursesToStudent[ind].grading_type == 3
                                                  ? ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.allAss_ass_mark + "/" + ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.allAss_total_mark
                                                  : typeof ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.gradeText == "string" && ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.gradeText.includes("%")
                                                  ? Math.round(parseFloat(ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.gradeText.replace("%", ""))).toString() + "%"
                                                  : ass?.getAssignedCoursesToStudent[ind]?.getGradeMixCulc?.gradeText
                                                  }
                                              </div>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={
                    "table-book-right" +
                    (courseList.length < 6 && assessmentHeader.length < 6
                      ? ""
                      : " max-width-fit-content")
                  }
                >
                  <table
                    className={
                      "table-book-main text-center " +
                      (courseList.length < 6 && assessmentHeader.length < 6
                        ? ""
                        : " border-shadow-custom-table")
                    }
                  >
                    <thead>
                      <tr>
                        <th className="second-last-cell-programme">
                          <span className="right-border-grade"></span>
                          <div className="tb-book-tit">
                            Programme Grade
                            <span
                              href="#"
                              className=" grade-info-red"
                              data-toggle="tooltip"
                              title={
                                programmeGradeTitle?.passReqCourseArr?.length
                                  ? `Requires pass mark for ${programmeGradeTitle?.passReqCourseArr?.toString()} and an average above ${
                                      programmeGradeTitle?.requiredPassMarkforProgram
                                    }`
                                  : `Requires an average above ${programmeGradeTitle?.requiredPassMarkforProgram}`
                              }
                            >
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="tb-book-tit text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span
                                className={
                                  "right-border-grade " +
                                  (userData.length - 1 == index
                                    ? "last-cell-border"
                                    : "")
                                }
                              ></span>
                              {item.getProgrammeGradeMixCulc?.pass_status == "N/A" ? (
                                <div className="as-widget-outer text-center">
                                  N/A
                                </div>
                              ) : item.getProgrammeGradeMixCulc?.pass_status ? (
                                <div className="as-widget-outer text-center">
                                  {
                                    RenderQuizAttemptResultStatus(
                                      item.getProgrammeGradeMixCulc?.pass_status
                                    ).html
                                  }
                                  <div className="as-widget-text">
                                    { percentageView
                                      ? Math.round(item.getProgrammeGradeMixCulc?.scoreRecieved) + "%"
                                      : item.getProgrammeGradeMixCulc?.grading_type == "3"
                                      ? `${item.getProgrammeGradeMixCulc?.programeAssMark}/${item.getProgrammeGradeMixCulc?.programeAssTotalMark}`
                                      : typeof item.getProgrammeGradeMixCulc?.gradeText == "string" && item.getProgrammeGradeMixCulc?.gradeText.includes("%")
                                      ? Math.round(parseFloat(item.getProgrammeGradeMixCulc?.gradeText.replace("%", ""))).toString() + "%"
                                      : item.getProgrammeGradeMixCulc?.gradeText
                                      }
                                  </div>
                                </div>
                              ) : (
                                <div className="as-widget-outer text-center">
                                  N/A
                                </div>
                              )}
                            </td>
                            <td className="justify-content-end d-flex calendar-cart-item-inner">
                              <div className="assessment-08 btn-dropdown-grp">
                                <div className="as-buttons d-flex">
                                  <PermissionsGate
                                    scopes={["capsvview"]}
                                    RenderError={() => (
                                      <button
                                        className="btn btn-primary rounded-circle"
                                        title="Open"
                                        disabled
                                      >
                                        <i className="fal fa-folder-open"></i>
                                      </button>
                                    )}
                                  >
                                    <Link
                                      className="btn btn-primary rounded-circle"
                                      title="Open"
                                      target="_blank"
                                      to={`/courseAdministration/Programmes/programme/open/${id}/gradebook/table/${item?.id}`}
                                    >
                                      <i className="fal fa-folder-open"></i>
                                    </Link>
                                  </PermissionsGate>
                                  <PermissionsGate
                                    scopes={["capsvview"]}
                                    RenderError={() => (
                                      <button
                                        className="btn btn-primary rounded-circle"
                                        title="Save Grades"
                                        disabled
                                      >
                                        <i className="fal fa-save"></i>
                                      </button>
                                    )}
                                  >
                                    <button
                                      className="btn btn-primary rounded-circle"
                                      title="Save Grades"
                                      disabled={item?.isDis || errorMessage}
                                      onClick={() => {
                                        handleSaveSingleGrade(item?.id);
                                      }}
                                    >
                                      <i className="fal fa-save"></i>
                                    </button>
                                  </PermissionsGate>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="custom-table-msg">{Str.noRecord}</div>
          )}
          <nav className="pagination-nav">
            <div>
              {errorMessage ? (
                <div className="invalid-feedback d-block">{errorMessage}</div>
              ) : (
                ""
              )}
            </div>
            <div className="second-child">
              <span>Rows per page:</span>
              <div className="pagination-select">
                <select
                  defaultValue={tableState?.limit}
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setTableState((prevState) => ({
                      ...prevState,
                      limit: value ? value : 10,
                      page: 1,
                    }));
                  }}
                >
                  {TABLE_ROWS_PER_PAGE.map((page, index) => (
                    <option key={index}>{page}</option>
                  ))}
                </select>
              </div>
              <span className="mx-3">
                {paginationState.from}-
                {paginationState.to > paginationState.total
                  ? paginationState.total
                  : paginationState.to}{" "}
                of {paginationState.total}
              </span>
              <div className="pagination-btns">
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == 1 ||
                    paginationState.currentPage == 0
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "firstPage");
                    } else {
                      setTableState((prevState) => ({ ...prevState, page: 1 }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == 1 ||
                    paginationState.currentPage == 0
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "prevPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.currentPage - 1,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == paginationState.lastPage
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "nextPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: +paginationState.currentPage + 1,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == paginationState.lastPage
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "lastPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.lastPage,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </nav>
        </div>
       {userData?.length && (updateValueArr.length || isProgrammeEdited) ? <GradeBookSaveBar handleClick={() => handleSaveGrades()} disabled={errorMessage} saveGradesLoading={saveGradesLoading} /> : <></>}
      </div>
    </>
  );
}

export default GradeMixGradeList;
