import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Select from 'react-select';
// import SkeletonTicketList from '../../../../../loaders/SkeletonTicketList';
import {
  // GetStudentEditData,
  SendMenualNotification,
  // SendStudentLoginEmails,
  UpdateStudentGeneralDetails,
} from '../../../../../services/StudentsSettingServices';
import Swal from 'sweetalert2';
import { myCompare } from '../../../../common/MyCompare';
import PermissionsGate from '../../../../../utils/permissionGate';

function GeneralTab({
  studentData,
  // smsLog,
  // emailLog,
  statusLog,
  loading,
  updateData,
  setUpdateData,
  brandError,
  setBrandError,
  setBrand
}) {
  // const history = useHistory();

  const { id } = useParams();
  const [disabled, setDisabled] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [brandArr, setBrandArr] = useState([]);
  const [statusArr, setStatusArr] = useState([]);

  const fixBrandArr = (arr) => {
    let tempArr = [];
    arr.map((item) => tempArr.push({ value: item.id, label: item.brand_name }));
    // console.log("tempArr",tempArr);
    setBrandArr(tempArr);
  };

  const fixStatusArr = (obj) => {
    let tempArr = [];
    Object.keys(obj).forEach((key) => {
      tempArr.push({ label: obj[key], value: key });
    });
    setStatusArr(tempArr);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      StudentNumber:
        studentData &&
        studentData.getStudent &&
        studentData.getStudent.student_crm_id
          ? studentData.getStudent.student_crm_id
          : '',
      StudentStatus:
        studentData && studentData.getStudent && studentData.getStudent.status
          ? studentData.getStudent.status
          : '',
      Brand:
        studentData && studentData.currentBrand ? studentData.currentBrand : '',
      Message: '',
      EmailNotification: false,
      emailNotification: false,
      SMSNotification: '',
    },
    validationSchema: Yup.object({
      StudentStatus: Yup.string().required('Student Status is required'),
      Message: Yup.string().trim(),
      Brand: Yup.string().required('Brand is required'),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append('student_id', id);
      formData.append('student_no', values.StudentNumber);
      formData.append('student_status', values.StudentStatus);
      formData.append('brand', values.Brand);
      formData.append(
        'status_message',
        selectedStatus
          ? selectedStatus == 'Active'
            ? 'Your Student Portal account has been set to Active'
            : selectedStatus == 'On Hold'
            ? `Your Student Portal account has been placed "On-Hold"`
            : ''
          : ''
      );
      UpdateStudentGeneralDetails(formData)
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Updated successfully',
          });
          setDisabled(false);
          setUpdateData(!updateData);
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
          setDisabled(false);
          setUpdateData(!updateData);
          console.log('err', err);
        });
    },
  });

  useEffect(() => {
    studentData && studentData.brand && fixBrandArr(studentData.brand);
    studentData &&
      studentData.student_current_status &&
      fixStatusArr(studentData.student_current_status);
    studentData &&
      studentData.getStudent &&
      studentData.getStudent.status &&
      setSelectedStatus(
        studentData.getStudent && studentData.getStudent.status
      );
    setBrand(studentData?.currentBrand || null)
    // if (brandError) {
    //   console.log("consosos", brandError)
    //   formik.setErrors({
    //     Brand: "Brand is requireddddd",
    //   });
    // }
  }, [studentData, brandError]);

  const handleSendNotification = async () => {
    if (formik.values.EmailNotification) {
      const formData = new FormData();
      formData.append('student_id', id);
      formData.append('email_notify', 1);
      SendMenualNotification(formData)
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Send successfully',
          });
          setUpdateData(!updateData);
        })
        .catch((err) => console.log('err', err));
    } else {
      // await formik.setErrors({EmailNotification:"Please Select Email"});
      formik.setErrors({
        EmailNotification: 'Please Check Email Notification',
      });
    }
  };
  // const handleSendLogin=()=>{
  //   const formData = new FormData();
  //     formData.append("userid", id);
  //     SendStudentLoginEmails(formData)
  //       .then((res) => {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success",
  //           text: "Send successfully",
  //         });
  //         // setUpdateData(!updateData);
  //       })
  //       .catch((err) => console.log("err", err));
  // }

  const formatAuditStatusLog = (statuslog) => {
    if (
      statuslog &&
      statuslog.includes('on') &&
      statuslog.includes('by') &&
      statuslog.includes('at')
    ) {
      const parts = statusLog.split('on');
      const createdByPart = parts[1].split('by');
      const createdBy = createdByPart[1];
      const subParts = createdByPart[0].split(' ');
      const datePart = subParts.slice(0, 4).join(' ');
      const timePart = subParts.slice(4).join(' ');

      return (
        <span>
          <span>
            Updated on <b>{datePart && datePart}</b> at{' '}
            <b>{timePart && timePart}</b> by <b>{createdBy && createdBy}</b>
          </span>
        </span>
      );
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">General</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StudentNumber">Student Number</label>
            <div className="form-icon-group mb-4">
              <input
                type="StudentNumber"
                id="StudentNumber"
                className={
                  'form-control' +
                  (formik.errors.StudentNumber && formik.touched.StudentNumber
                    ? ' is-invalid'
                    : '')
                }
                name="StudentNumber"
                placeholder="Enter Student Number"
                value={formik.values.StudentNumber}
                onChange={formik.handleChange}
                disabled
                onBlur={formik.handleBlur}
                title="Student Number"
              />
              {formik.errors.StudentNumber && formik.touched.StudentNumber ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StudentStatus">Student Status *</label>
            <div className="form-icon-group mb-4" title="Student Status">
              <PermissionsGate
                errorProps={{ isDisabled: true }}
                scopes={['saginfostedit']}
              >
                <Select
                  className={
                    'form-control custom-select-box ' +
                    (formik.errors.StudentStatus && formik.touched.StudentStatus
                      ? ' is-invalid'
                      : '')
                  }
                  name="StudentStatus"
                  value={statusArr.filter((item) => {
                    return item.value == formik.values.StudentStatus;
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue('StudentStatus', value.value);
                      setSelectedStatus(value.value);
                      formik.setFieldValue(
                        'Message',
                        value.value
                          ? value.value == 'Active'
                            ? 'Your Student Portal account has been set toActive'
                            : value.value == 'On Hold'
                            ? 'Your Student Portal account has been set to Active'
                            : ''
                          : ''
                      );
                    } else {
                      formik.setFieldValue('StudentStatus', '');
                      setSelectedStatus('');
                      formik.setFieldValue('Message', '');
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={statusArr.sort(myCompare)}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.StudentStatus
                      ? formik.values.StudentStatus
                      : 'Select'
                  }
                  isClearable
                />
              </PermissionsGate>
              {formik.errors.StudentStatus && formik.touched.StudentStatus ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            {studentData &&
            studentData.getStudent &&
            studentData.getStudent.status &&
            (studentData.getStudent.status == 'On Hold' ||
              studentData.getStudent.status == 'Results Witheld')
              ? statusLog && (
                  <div style={{ display: 'flex' }}>
                    <div>
                      <i
                        class="fal fa-history"
                        style={{ color: 'var(--topbar-color)' }}
                      >
                        &nbsp;
                      </i>
                    </div>
                    <div>
                      <p>{statusLog ? formatAuditStatusLog(statusLog) : ''}</p>
                    </div>
                  </div>
                )
              : null}
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Brand">Brand *</label>
            <div className="form-icon-group mb-4" title="Brand">
              <Select
                className={
                  'form-control custom-select-box ' +
                  (formik.errors.Brand && formik.touched.Brand
                    ? ' is-invalid'
                    : '')
                }
                name="Brand"
                value={brandArr.filter((option) => {
                  return option.value == formik.values.Brand;
                })}
                onChange={(value) => {
                  if (value) {
                    setBrandError(true);
                    formik.setFieldValue('Brand', value.value);
                  } else {
                    setBrandError(false);
                    formik.setFieldValue('Brand', '');
                  }
                  setBrand(value.value)
                  // setSelectedBrand(value.Brand);
                }}
                onBlur={formik.handleBlur}
                options={brandArr.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.Brand ? formik.values.Brand : 'Select'
                }
                isClearable
              />
              {formik.errors.Brand && formik.touched.Brand ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Message">Message</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                className={
                  'form-control' +
                  (formik.errors.Message && formik.touched.Message
                    ? ' is-invalid'
                    : '')
                }
                name="Message"
                id="Message"
                placeholder=""
                value={
                  formik.values.StudentStatus
                    ? formik.values.StudentStatus == 'Active'
                      ? 'Your Student Portal account has been set to Active'
                      : formik.values.StudentStatus == 'On Hold'
                      ? `Your Student Portal account has been placed "On-Hold"`
                      : ''
                    : ''
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Message"
                disabled
              />
              {formik.errors.Message && formik.touched.Message ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={['sgiedit']}>
            <button
              className="btn btn-save btn-success"
              type="submit"
              title="Save"
              disabled={disabled || !formik.dirty}
            >
              {disabled ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Save
            </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => formik.resetForm()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {Object.keys(formik.values).map((key) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              );
            }
          })}
          {/* {formik.touched.StudentNumber && formik.errors.StudentNumber ? <div className="invalid-feedback d-block">{formik.errors.StudentNumber}</div> : null}
          {formik.touched.StudentStatus && formik.errors.StudentStatus ? <div className="invalid-feedback d-block">{formik.errors.StudentStatus}</div> : null}
          {formik.touched.Brand && formik.errors.Brand ? <div className="invalid-feedback d-block">{formik.errors.Brand}</div> : null} */}
          {/* {formik.touched.Message && formik.errors.Message ? <div className="invalid-feedback d-block">{formik.errors.Message}</div> : null} */}
          {/* {formik.touched.EmailNotification && formik.errors.EmailNotification ? <div className="invalid-feedback d-block">{formik.errors.EmailNotification}</div> : null} */}
          {/* {formik.errors.EmailNotification ? <div className="invalid-feedback d-block">{formik.errors.EmailNotification}</div> : null}
          {formik.touched.SMSNotification && formik.errors.SMSNotification ? <div className="invalid-feedback d-block">{formik.errors.SMSNotification}</div> : null} */}
        </div>
      </form>
    </div>
  );
}

export default GeneralTab;
