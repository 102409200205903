import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { RenderPopupType } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import axios from 'axios';
import { RenderCourseAssignedlistStatus } from '../../../../utils/CommonStatusItems';
import CourseAvailableProgrames from './CourseAvailableProgrames'
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { getAllPagesFilters, getAllProgrameAndShortCourseList, getAssigendPagesAndProgrammeShortcourseFilter } from '../../../../services/PopupsPagesServer';
import HtmlParser from 'react-html-parser';
import { useHistory } from "react-router";
import SortableTree from "react-sortable-tree";
import TreeTheme from "react-sortable-tree-theme-minimal";
import { Placeholder } from "semantic-ui-react";

const statusArr = [
  {
    value: "Unpublished",
    label: HtmlParser(
      `<span className="red" title="Unpublished">Unpublished</span>`
    ),
  },
  {
    value: "Draft",
    label: HtmlParser(`<span className="blue" title="Draft">Draft  </span> `),
  },
  {
    value: "Published",
    label: HtmlParser(
      `<span className="green" title="Published">Published</span>`
    ),
  },
];

function CategoryAssignedProgrammes({ id, assignedRec, setAssignedRec, assignloading, setAssignLoading , type }) {
  const [availableLoading, setAvailableLoading] = useState(false);
  const history = useHistory();
  const [addedRec, setAddedRec] = useState([]);
  const [availableRec, setAvailableRec] = useState([]);
  const [availableCourseRec, setAvailableCourseRec] = useState([]);
  const [searchAssigned, setSearchAssigned] = useState("");
  const [assingIntake, setAssingIntake] = useState({ arr: [], checkObj: {} })
  const [assingStudyType, setAssingStudyType] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState({ intake: [], learning: [] })
  const [AvailablefilterData, setAvailableFilterData] = useState({ intake: [], learning: [] })
  const uniquekey = assignedRec?.map((id) => id.ref_id)
  
  useEffect(() => {
    const getProgramsList = async () => {
      setAssignLoading(true);
      
      try {
        let res = await getAssigendPagesAndProgrammeShortcourseFilter(id , 2);
        if (res.status === 200) {
          setFilterData({ ...res.data, intake: res?.data?.statusFilters, learning: res?.data?.typeFilters})
          setAssignLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAssignLoading(false)
        }
      }
      
    }
    
    const getsingleProgramFilter = () => {
      getAllPagesFilters(id, 2 , 0)
      .then((res) => {
        setAvailableFilterData({ intake: res?.data?.statusFilters, learning: res?.data?.typeFilters })
      })
    }
    
    getProgramsList()
    getsingleProgramFilter()
  }, [])
  
  useEffect(() => {
    const getProgramsList = async () => {
      setAvailableLoading(true);
      const data = {
        id: Number(id),
        type: 2
      };
      
      try {
        let res = await getAllProgrameAndShortCourseList(data);
        if (res.status === 200) {
          setAvailableRec(res?.data?.data);
          setAvailableLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAvailableLoading(false)
        }
      }
      
    }
    getProgramsList()
  }, [])
  
  const handleAdd = (rec, type) => {
    if (type === 'add') {
      if (rec.flag == 3 || rec.flag == 4 || rec.flag == 5 || rec.flag == 6 || rec.flag == 7) {
        
        availableCourseRec.map((elem, ind) => {
          if (elem.ref_id == rec.ref_id) {
            availableCourseRec.splice(ind, 1);
            setAssignedRec([...assignedRec, rec]);
            if (addedRec.length) {
              addedRec.map((e, i) => {
                if (e == rec.ref_id) {
                  addedRec.splice(i, 1);
                  setAddedRec([...addedRec]);
                } else {
                  setAddedRec([...addedRec, rec.ref_id]);
                }
              });
            } else {
              setAddedRec([...addedRec, rec.ref_id]);
            }
          }
        });
      } else if (rec.flag == 2 || rec.flag == 1) {
        
        availableRec.map((elem, ind) => {
          if (elem.ref_id == rec.ref_id) {
            availableRec.splice(ind, 1);
            setAssignedRec([...assignedRec, rec]);
            if (addedRec.length) {
              addedRec.map((e, i) => {
                if (e == rec.ref_id) {
                  addedRec.splice(i, 1);
                  setAddedRec([...addedRec]);
                } else {
                  setAddedRec([...addedRec, rec.ref_id]);
                }
              });
            } else {
              setAddedRec([...addedRec, rec.ref_id]);
            }
          }
        });
      }
    } else if (type === "Delete") {
      let duplicateRec = [...assignedRec]
      if (rec.flag == 3 || rec.flag == 4 || rec.flag == 5 || rec.flag == 6 || rec.flag == 7) {
        
        assignedRec.map((elem, ind) => {
          if (elem.ref_id == rec.ref_id) {
            duplicateRec.splice(ind, 1);
            setAssignedRec([...duplicateRec]);
            setAvailableCourseRec([...availableCourseRec, rec]);
            if (addedRec.length) {
              addedRec.map((e, i) => {
                if (e == rec.ref_id) {
                  addedRec.splice(i, 1);
                  setAddedRec([...addedRec]);
                } else {
                  setAddedRec([...addedRec, rec.ref_id]);
                }
              });
            } else {
              setAddedRec([...addedRec, rec.id]);
            }
          }
        });
      } else if (rec.flag == 2 || rec.flag == 1) {
        
        assignedRec.map((elem, ind) => {
          if (elem.ref_id == rec.ref_id) {
            duplicateRec.splice(ind, 1);
            setAssignedRec([...duplicateRec]);
            setAvailableRec([...availableRec, rec]);
            if (addedRec.length) {
              addedRec.map((e, i) => {
                if (e == rec.id) {
                  addedRec.splice(i, 1);
                  setAddedRec([...addedRec]);
                } else {
                  setAddedRec([...addedRec, rec.ref_id]);
                }
              });
            } else {
              setAddedRec([...addedRec, rec.ref_id]);
            }
          }
        });
      }
    }
  };
  function generateUrl(row) {
    switch (row.flag) {
      case 1:
      return `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}`;
      case 2:
      return `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}`;
      case 3:
      return `/website_management/pages/staticpages/table/update/${row.id}`;
      case 5:
      return `/website_management/pages/courselandingpages/table/open/${row.id}`;
      case 6:
      return `/website_management/pages/campuspages/table/update/${row.id}`;
      case 7:
      return `/website_management/pages/blogandnews/table/update/${row.id}`;
      default:
      return null;
    }
  }
  
  
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "title",
      sortField: "title",
      sortable: true,
      cell: (row) => ( // /websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/95
        row.title ? <Link
        className="as-text-blue curser feature-name"
        to={`${row.flag == 4 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 2 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 1 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}` : row.flag == 3 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 5 ? `/website_management/pages/courselandingpages/table/open/${row.id}` : row.flag == 7 ? `/website_management/pages/blogandnews/table/update/${row.id}` : `/website_management/pages/campuspages/table/update/${row.id}`}`}
        title={row.title}
        >
        <span className="textLimit100">{row.title}</span>
        </Link>
        : "-"
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status ? RenderCourseAssignedlistStatus(row.status).html : "-"),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => row?.type ? RenderPopupType(row.type, "popup")?.html : "-"
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
        <div className="as-buttons d-flex">
        <Link
        className="btn btn-primary h-auto rounded-circle"
        to={`${row.flag == 4 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 2 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}` : row.flag == 1 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}` : row.flag == 3 ? `/website_management/pages/staticpages/table/update/${row.id}` : row.flag == 5 ? `/website_management/pages/courselandingpages/table/open/${row.id}` : row.flag == 7 ? `/website_management/pages/blogandnews/table/update/${row.id}` : `/website_management/pages/campuspages/table/update/${row.id}`}`}
        title="Open"
        >
        <i className="fal fa-folder-open"></i>
        </Link>
        <button
        type="button"
        className="btn btn-danger rounded-circle"
        onClick={() => handleAdd(row, "Delete")}
        title='Unlink'
        >
        <i className="fal fa-unlink"></i>
        </button>
        </div>
        </div>
      ),
    },
  ]);
  
  const dataToRender = () => {
    let updatedData = [];
    let allData = assignedRec;
    
    if (searchAssigned.length) {
      let name = allData?.filter((item) => {
        let includes = item?.title?.toString()
        .toLowerCase()
        .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      
      let data = [...name];
      let unique = [...new Set(data)];
      
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    
    if (assingStudyType.arr.length) {
      let tempAssignStudy = updatedData;
      let tempIntakeHash = tempAssignStudy.filter((item) => {
        const startsMarking = assingStudyType.arr.find((it) => it == item.type)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });
      updatedData = tempIntakeHash;
    } 
    if(assingIntake.arr.length) {
      let tempIntake = updatedData;
      let tempIntakeHash = tempIntake.filter((item) => {
        const startsMarking = assingIntake.arr.find((it) => it == item.status)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });
      
      updatedData = tempIntakeHash;
    }
    
    if ( searchAssigned.length || assingStudyType.arr.length || assingIntake.arr.length) {
      return updatedData;
    } else {
      return assignedRec;
    }
  };
  
  
  const handleChangeData = (newData) => {
    setAssignedRec(newData);
  };  
  const exportData = () => {
    let data = dataToRender();
    const header = [
      "Name",
      "Status",
      "Type",
    ];
    data = data.map((row) => ({
      "Name": row.title ? row.title : "-",
      "Status": row.status,
      "Type": row?.type,
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Status"],
        row["Type"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };
  
  return (
    <>
    {
      (type == 'open' || type == 'create') ? (<>
        <DataTableComponentFrontPagination
        data={dataToRender()}
        columns={columns}
        loading={assignloading}
        search={searchAssigned}
        setSearch={setSearchAssigned}
        exportData={exportData}
        exportFileName={"Assigned Programmes & Pages"}
        progressComponent={<SkeletonTicketList />}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
        custumcss="border-0 p-0"
        keyField={uniquekey[1]}
        filters={[
          {
            filterName: " Status",
            optionArr: filterData.intake,
            // optionArr: statusArr,
            state: assingIntake,
            setState: setAssingIntake,
            uniqueId: "assignstatus",
            isOptionReversed: true,
            renderLabelFunction: RenderCourseAssignedlistStatus,
          },
          {
            filterName: " Type",
            optionArr: filterData.learning,
            state: assingStudyType,
            setState: setAssingStudyType,
            uniqueId: "assignType",
            renderLabelFunction: RenderPopupType,
          }
        ]}
        tableButton={
          [
            <div className='d-flex'>
            <button className="btn btn-primary mr-1" data-toggle="modal" data-target="#availableProgramme" title="Assign Programme" type="button">
            <i className="fal fa-laptop"></i>Assign Programme
            </button>  
            <button
            className="btn btn-primary mr-2"
            title="Re-Arrange"
            onClick={(e) => {e.preventDefault(); history.push(`/website_management/pages/courselandingpages/table/${type == 'open' ? 're-arrange' : 'open'}/${id}`)}}
            >
            <i className="fal fa-random"></i>Re-Arrange
            </button>            
            </div>
          ]
        }
        />   
        </>) : (<>
          <div className="filter-search-head">
          <h4>Drag items to change order:</h4>
        <div className="filter-search-head-right" >
          <button
          className="btn btn-primary mr-2"
          title="Re-Arrange"
          onClick={(e) => {e.preventDefault(); history.push(`/website_management/pages/courselandingpages/table/${type == 'open' ? 're-arrange' : 'open'}/${id}`)}}
          >
          <i className="fal fa-random"></i>Re-Arrange
          </button> 
          </div>
          </div>
          <div className="tree-wrapper">  
            <SortableTree
            theme={TreeTheme}
            className="my-class"
            isVirtualized={false}
            treeData={assignedRec ? assignedRec : []}
            onChange={(treeData) => handleChangeData(treeData)}
            maxDepth={2}
            // onDragStateChanged={(dragData) => handleDragNode(dragData)}
            style={{
              padding: "0px",
              width: "100%",
            }}
            canNodeHaveChildren = {(node)=>(node.type===1)}
            generateNodeProps={(rowInfo) => {
              const { node, parentNode } = rowInfo;
              return {
                // canDrag :node.type===0 ? true : false,
                canDrop :node.type===0 ? true : false,
                class:
                node.type === 1
                ? "main-category-node just1"
                : node.type === 0
                ? "sub-category-node just1"
                : "item-node just1",
                buttons: [
                  <>
                  </>
                ],
                style: { borderRadius: "10px", width: "100%" },
              };
            }}
            placeholderRenderer={Placeholder}
            />          
          </div>
          </>)
        }   
        <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableProgramme"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        >
        <div
        className="modal-dialog modal-dialog-centered available-assessment-modal"
        role="document"
        >
        <CourseAvailableProgrames
        AvailablefilterData={AvailablefilterData}
        availableRec={availableRec}
        assignedRec={assignedRec}
        setAssignedRec={setAssignedRec}
        availableLoading={availableLoading}
        handleAdd={handleAdd}
        />
        </div>
        </div>
        </>
      )
    }
    
    export default CategoryAssignedProgrammes